#maincontent{
  .cart-container {
    padding-top: 2.4rem;
      
    .cart-empty{
      .cart__title--empty{
        font-size: 2.4rem;
        line-height: 32px;
      }

      .cart__buttons--empty{
        flex-direction: column;
        gap: 1.6rem;

        .button{
          width: 100%;
        }
      }

    }

    #cart-empty{
      .cart--wrapper{
        width: 95%;
        .cart__text--empty{
          .title{
            font-size: 2rem;
            font-weight: 400;
            line-height: 2.8rem;
          }
        }
        .bag-overview--empty__button-container{
          flex-direction: column;
        }
      }
    }
    
    .cart-no-empty{
      padding: 0 2.4rem;
        
      .cart-cards{
        display: flex;
        flex-direction: column;

        .totals-card{
          max-width: 100%;
          margin: 0 auto 5rem;
          bottom: calc(var(--checkout-continue) - var(--totals-card) + 2rem - var(--promoAccordionTotalHeight));
          background-color: $white;

          .promo-card .accordion__panel{
            margin: 0 2.4rem;

            .form-group {
              display: flex;
              gap: 1.6rem;
              flex-direction: column;
            }
          } 

          .checkout-continue{
            margin: 0 0 2rem 0; 
            padding-top: 2rem;
            background-color: $white;
            border-top: 1px solid $light_grey;
          }
        }

        .product-cards {
          margin: 0 0 2rem 0;
          
          .card:is(:last-child) > .line-item-divider-wrapper {
            display: none;
          }

          .product-line-item{
            .item-attributes{
              .line-item-actions{
                grid-gap: 1.6rem;
                justify-content: end;

                .product-edit{
                  order: 2;
                }

                .product-delete{
                    order: 3;
                }

                .product-add-to-wishlist{
                  order: 1;
                }

                span {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .product-trending__carousel-container{
      margin-bottom: 5rem;
    }
  }
}

#maincontent .cart-cards .product-cards .product-line-item {
  grid-template-columns: 40% 1fr;
}

.cart-promo-b-wrapper, .cart-promo-a{
  position: relative;
  width: calc(100% + 4.8rem);
  left: -2.4rem;
}

.cart-promo-a {
  flex-direction: column;

  .cart-promo-code{
    width: 100%;
  }
}

.cart-remove-product{
  .tingle-modal-box__content{
    .modal-footer{
      flex-direction: column;
      gap: 1.6rem;

      button{
        width: 100%;
      }
    }

    .modal-body{
      max-width: unset;
    }
  }

  .tingle-modal-box {
    bottom: 2rem;
    position: absolute;
    max-width: 90vw;
  } 
}

.promo-card{
  .accordion__header{
    &[aria-expanded="false"] + .accordion__panel {
    }
  }

  .accordion__panel{
    .coupons-and-promos{
      .coupon-price-adjustment{
        .coupon-code {
          & :first-child{
            flex: 2 1 0;
          }
          & :last-child{
            flex: 0.7 1 0;
          }
          & :not(:last-child):not(:first-child){
            text-align: end;
          }
        }
      }
    }
  }
}