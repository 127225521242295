.sticky-tray {
  opacity: 0;
  &__modal.modal {
    // width: calc(50% + 3.2rem);
    padding: 1.2rem 3.2rem 2.4rem 3.2rem;
    .modal__close {
      padding: 2.6rem;
    }
  }
}
