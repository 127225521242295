// SIZE SELECTOR USED IN: PDP, PLP Tile, Reserve in boutique, Shop by Look

.selector-size-expanded {
  position: relative;

  color: $black;
  .recommended {
    flex: 1;
    color: $black_secondary;
    margin-left: ($space-unit * 4);

    span:only-of-type {
      display: none;
    }
  }

  .selector__header {
    display: flex;
    align-items: center;
    padding: 2.4rem 0 0;
    justify-content: space-between;
    white-space: pre;
  }

  .selector__label {
    margin-right: ($space-unit * 4);
    span {
      @extend %primary-xs;
    }

    i {
      margin-left: ($space-unit * 4);
      @extend %primary-xs;
    }

    &Few {
      background-color: $light_grey;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      padding: 0 0.8rem;
      
      &-text {
        font-size: 1rem;
        line-height: 2rem;
        text-transform: uppercase;
      }
    }
    
    &Selected {
      display: flex;
      
      &--wear-with{
        color: $black;
        font-size: 1.2rem;
        line-height: 2rem;
      }

      &-text {
        font-size: 1.2rem;
        line-height: 2rem;
        display: flex;
        align-items: center;
        gap: 0.9rem;

        &::before {
          content: '';
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 0.8rem;
        }

        &--soldout {
          &::before {
            background-color: $oos_red;
          }
        }

        &--low-availability {
          &::before {
            background-color: $lowavailability_yellow;
          }
        }
      }
    }
  }


  .errormessage {
    color: $status_error;
    margin-right: ($space-unit * 2);
    @include text-shorten;
  }

  .selector__menu {
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
    margin: 1.6rem 0 0;
    border: solid $grey_line;
    border-width: 0.03rem 0;

    .label {
      @extend .visually-hidden;
    }

    &__list {
      @include reset-list;
      display: flex;
      flex-wrap: nowrap;
      bottom: initial;
      min-width: 100%;
      justify-content: space-between;

      &__element {
        width: 100%;

        &[disabled]{
          opacity: 0.5;
          pointer-events: none;
        }

        &:not(:last-child) {
          border: solid $grey_line;
          border-width: 0 0.03rem 0 0  ;
        }
      }

    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 3.8rem;
      color: $black;


      &:hover,
      &.selected{
        .selector__menu__item__label {
          font-weight: 800;
        }
      }
      &__label {
        @extend %primary-s-u;
        position: relative;
        display: inline-block;
        font-size: 1.4rem;
        line-height: 2.0rem;

        &::after {
          content: '';
          height: 0.1rem;
          bottom: 0;
          background-color: $grey_line;
          width: 100%;
          min-width: 1.6rem;
          @include center('horizontal');
          @include animate(0.3);
          transform: translateX(-50%) scaleX(0);
        }
      }

      &.is-disabled {
        color: $black_inactive;
        position: relative;
      }

      &.notavailable {
        color: $black_inactive;
      }


      @include touchtarget-area();

      &:focus {
        outline: none;

        @include touchtarget-focus();
      }
    }
  }

  //INVERTED VARIANT

  &.inverted {
    border-color: $white_inactive;
    color: $white;
    background-color: $black;

    .recommended {
      color: $white_secondary;
    }

    .button--link {
      color: $white;

      &::after {
        filter: invert(1);
      }
    }

    .selector__menu__item {
      color: $white;
      &:focus {
        @include touchtarget-focus($outline-color-inverted);
      }

      &.is-disabled {
        color: $white;

        &::after {
          background-color: $white;
        }
      }

      &.selected {
        .selector__menu__item__label {
          position: relative;

          &::after {
            background-color: $white;
          }
        }
      }
    }
  }

  //INTILE VARIANT
  &.intile {
    .selector__menu {
      padding: $selector-size-intile-padding;
      &__list {
        row-gap: $selector-size-intile-rowgap;
        grid-template-columns: repeat($selector-size-intile-cols, 1fr);
      }
    }
  }
}
