.grid-container {
  gap: 1.6rem;

  .product-tile {
    grid-column: span 6;
  }

  .grid-footer {
    grid-column: span 12;
  }

  .pages-number {
    width: 100%;
    max-width: 340px;
  }

  &.grid-1 {
    .product-image:hover {
      .tile-swiper-icon {
        opacity: 1;
      }
    }

    .product-tile {
      grid-column: span 12;
    }
  }

  &:not(.grid-1) {
    .product-tile {
      &:nth-child(2n + 1) {
        .promo-text {
          &__tooltip {
            &__box {
              left: 0.6rem;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0.4rem;
              &::before {
                border-left: 0;
                border-right: 1.2rem solid transparent;
                left: 0;
                transform: translate(0, 100%);
              }
            }
          }
        }
      }
    }
  }
}

.subcategories-container {
  justify-content: flex-start;
}
.listing-container {
  margin-top: 15rem;
}

.change-grid {
  &.change-grid-1x {
    &::before {
      content: '';
      @include fixedSprite('icon-grid-1-x');
    }
    &.selected {
      &::before {
        content: '';
        @include fixedSprite('icon-grid-1-x-red');
      }
    }
  }
  &.change-grid-2x {
    &::before {
      content: '';
      @include fixedSprite('icon-grid-2-x');
    }
    &.selected {
      &::before {
        content: '';
        @include fixedSprite('icon-grid-2-x-red');
      }
    }
  }
}

.searchNoResults{
  margin-bottom: 3rem;

  &Header{
    &__title{
      &--soldout{
        margin-top: 2.4rem;
        font-size: 1.8rem;
        line-height: 2.6rem;
      }

      &--soon{
        margin-top: 4rem;
      }
      
      padding-bottom: 1.6rem;
    }

    &__subtitle{
      font-size: 1.4rem;
      font-weight: 2.2rem;
    }
  }
}
