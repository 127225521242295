.modal {
  
  &.remove-modal {
    min-width: unset;
    max-width: 90vw;
    padding: 2.4rem;
    height: fit-content;
    top: unset;
    margin-left: auto;
    margin-right: auto;
    bottom: 1.2rem;
  }

}