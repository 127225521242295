@use "sass:math";

@mixin lhCrop($top-crop: 0.3rem, $bottom-crop: 0.3rem) {
  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }
  &::before {
    margin-bottom: $top-crop;
  }
  &::after {
    margin-top: $bottom-crop;
  }
}

// Media Queries
@mixin breakpoint($point, $type: max) {
  $width: get($grid-breakpoints, $point);
  @if $point == xl {
    $type: min;
  }
  @if $type == max {
    $width: $width - 1px;
  }
  @media only screen and (#{$type}-width: $width) {
    @content;
  }
}

// Arrow mixin
@mixin arrow($direction: down, $size: 0.5rem, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == right) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if ($direction == down) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
}

// Trim text
@mixin text-shorten {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Define vertical, horizontal, or both position
@mixin center($position: '') {
  position: absolute;

  @if $position == 'vertical' {
    top: 50%;
    transform: translateY(-50%);
  } @else if $position == 'horizontal' {
    left: 50%;
    transform: translateX(-50%);
  } @else {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin animate($time: 0.5, $props: 'all', $easing: $easeOutQuart, $delay: 0, $important: '') {
  @if $props == 'all' {
    transition: all #{$time}s #{$delay}s $easing #{$important};
  } @else {
    transition: #{$props} #{$time}s #{$delay}s #{$easing} #{$important};
  }
}

@mixin hover($time: 0.5) {
  @include animate($time);
  &:hover {
    opacity: $hover-opacity;
  }
}

@mixin appearAnimation($duration: 0.4, $delay: 0) {
  opacity: 0;
  &.reveal-animations {
    @include animate($duration, 'all', $easeOutQuart, $delay, '!important');
    opacity: 1;
  }
}

@mixin labelIcon($name, $type: left) {
  content: '';
  position: absolute;
  top: 0.1rem; // non in percentuale per gestire il caso in cui c'è il messaggio di errore sotto
  transform: translateY(50%);
  @if $type == 'right' {
    right: $space-unit * 2;
  } @else {
    left: $space-unit * 2;
  }
  display: block;
  height: $space-unit * 3;
  width: $space-unit * 3;
  @include sprite($name);
  background-size: 100%;
}

// setting defaults values to current images ratio
@mixin fixedRatio() {
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: $product-ratio;
  }
  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }

  @return $number;
}

@mixin fixedSprite($name, $color: transparent) {
  $sprite: map-get($sprites, $name);
  background: #{$color} url($sprite) center no-repeat;
  @if map-has-key($sizes, $name) {
    $size: map-get($sizes, $name);
    width: (strip-unit(map-get($size, width)) * 0.1) * 1rem;
    height: (strip-unit(map-get($size, height)) * 0.1) * 1rem;
  }
  display: inline-block;
}

@mixin z($name: default) {
  z-index: get($z-layers, $name);
}

@mixin visually-hidden {
  position: absolute !important;
  height: 0.1rem;
  width: 0.1rem;
  overflow: hidden;
  clip: rect(0.1rem 0.1rem 0.1rem 0.1rem);
  white-space: nowrap;
}

@mixin reset-visually-hidden {
  position: relative !important;
  height: initial;
  width: initial;
  overflow: initial;
  clip: initial;
  white-space: initial;
}

@mixin reset-list {
  list-style: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  > li {
    list-style-position: outside;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@mixin fix-font-space($s: 0.2rem) {
  // margin-top: (-$s);
}

@mixin del-link {
  text-decoration: none;
  color: inherit;
}

@mixin gray-gradient {
  background: $gradient-separator;
  background-size: 100% $gradient-height;
  background-repeat: no-repeat;
}

@mixin gray-gradient-pseudo($type: before) {
  &::#{$type} {
    content: '';
    height: $gradient-height;
    background: $gradient-separator;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include z(neg);
  }
}

@mixin scrollbar {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.2rem;
  }

  &::-webkit-scrollbar-track {
    background: $grey_line;
  }

  &::-webkit-scrollbar-thumb {
    background: $black;
  }
}

@mixin hoverablelink {
  text-decoration: none;
  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

// mixin for interactions

@mixin focus-underline {
  [data-whatintent='keyboard'] & {
    outline: transparent;
    text-decoration: underline;
    -webkit-text-underline-position: under;
    text-underline-position: under;
  }
}

// default touch area
@mixin touchtarget-area($size: $target-size-desktop) {
  position: relative;

  &::before {
    content: '';
    @include center();
    height: $size;
    width: $size;
  }
}

// on focus
@mixin touchtarget-focus($color: $outline-color) {
  [data-whatintent='keyboard'] & {
    outline: none;

    &::before {
      outline: $outline-width solid $color;
    }
  }
}
@mixin link-focus-underline($crop: false) {
  position: relative;
  text-decoration: none;

  &::after {
    @include animate;
    content: '';
    height: 1px;
    width: 100%;
    background: $white;
    position: absolute;
    left: 0;
    opacity: 0;

    @if $crop {
      bottom: -0.5rem;
    } @else {
      bottom: 0;
    }
  }

  &:focus {
    outline: none;

    &::after {
      opacity: 1;
    }
  }
}

@mixin border($where: '') {
  @if ($where == 'top') {
    border-top: $space-line solid $grey_line;
  } @else if ($where == 'bottom') {
    border-bottom: $space-line solid $grey_line;
  } @else {
    border: $space-line solid $grey_line;
  }
}

@mixin border-black($where: '') {
  @if ($where == 'top') {
    border-top: $space-line solid $black;
  } @else if ($where == 'bottom') {
    border-bottom: $space-line solid $black;
  } @else {
    border: $space-line solid $black;
  }
}

@mixin rounded-box {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
}
