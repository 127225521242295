.container-nav.navigation .nav-wrapper {
  .feedbackbag__wrapper[data-visible=true] {
    .feedbackbag__container {
      .feedbackbag__overlay.open .feedbackbag {
        top: calc(var(--ticker-height) + 10.4rem + 1.6rem)
      } 
    } 
  }
  
}
