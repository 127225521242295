.bag-product {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
  
  &:not(:last-child){
    padding-bottom: $space-unit * 4;
    margin-bottom: $space-unit * 4;
    @include border('bottom');
  }

  &.product-outofstock{
    .bag-product_image, .product-outofstock{
      opacity: 0.5;
    }

    .label-outOfStock{
      font-weight: 700;
      font-size: 1.2rem;
      color: #BB0000;
    }
  }

  &--product-exchanged {
    .product-image {
      opacity: 0.5;
    }
  }

  .product-image {
    height: auto;
    flex-basis: 90px;
  }

  &_image{
    padding-right: 0;
  }

  > .row {
    flex-grow: 1;
  }

  &__divider {
    @include border('top');
    height: $space-line;
    margin-top: $space-unit * 4;
    margin-bottom: $space-unit * 4;
  }

  &__content {
    position: relative;
    padding-left: $space-unit * 4;
    display: flex;
    flex: 1;
    flex-direction: column;
    //justify-content: space-between;
    > .bag-product__preorderinfo {
      display: none;
    }
  }

  &__out-of-stock {
    position: absolute;
    color: $status_error;
    margin-top: 1rem;
  }

  &__pricename {
    margin-bottom: 1.6rem;
    line-height: 1.8rem;
    h4 {
      margin-bottom: 0.4rem;
    }
  }
  &__name-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -0.15rem;
    &__name {
      @extend %primary-s;
      @include del-link;
    }

    &__price {
      @extend %primary-s;
      margin-top: ($space-unit * 2);
      margin-bottom: ($space-unit * 4);
      del {
        color: $red;
      }
    }
    &__message {
      @extend %primary-xs;
      color: $black_secondary;
      margin-top: $space-unit * 2;
      margin-bottom: -($space-unit * 4);
      text-align: right;
    }
    .bag-product__actions {
      margin-top: 0;
    }
  }

  &__details {
    flex-grow: 1;
    flex-flow: column;
    color: $dark_grey;

    .product-card__promo {
      padding-top: 0.8rem;
    }

    .line-item-attributes, 
    .selector-quantity{
      font-size: 1.2rem; 
    }

    .line-item-attributes {
      margin-bottom: $space-unit * 1.5;
    }
  }

  &__actions {
    align-self: flex-start;
    text-transform: capitalize;
    gap: 2.4rem;

    button, a{
      padding-left: 2.4rem;
    }

    a{
      text-decoration: none;
    }

    button:not(:last-child) {
      margin-right: $space-unit * 6;
    }

    .cta{
      font-size: 1.2rem;
      font-weight: 700;
      height: 1.6rem;
    }
  }

  &__preorderinfo {
    margin-top: $space-unit * 5;
    &__text {
      margin-top: $space-unit * 2;
      @extend %primary-xxs;
    }
  }
}
