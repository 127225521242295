.form-control:has(input[type="date"]) {
  &:after {
    content: '';
    pointer-events: none;
    @include fixedSprite('calendar');
    position: absolute;
    right: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
input[type="date"] {
  &::-webkit-calendar-picker-indicator {
    content: '';
    cursor: pointer;
    @include fixedSprite('calendar');
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
  }

}