

.product-images {
  img {
    width: 100%;
  }
}
.product-gallery-with-modal {
  .product-container:first-child {
    padding-top: 0;
    margin-top: 0;
  }
  .product-image {
    margin-top: 0.8rem;
  }
}

.productGalleryWithModal-wrapper {
  min-height: calc((66vw - 4.8rem)* 8.1758 / 15);
}
