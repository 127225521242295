#checkout-main {
  .shipment-email {
    &-field:disabled {
      margin-bottom: 0.8rem;
    }
  }
}

.summary-details--shipping {
  flex-direction: column;

  .shipping-method {
    &-price {
      &-wrapper {
        padding: 0;
        padding-top: 0.8rem;
      }
    }
  }  
}



.info-tooltip {
  &[data-tooltip]:before {
    transform: translate(-86%, 0);
    max-width: 20rem;
  }
}

.billing-invoice-type,
.billing-address,
.shipping-method-radio-button-container,
.customer-information-block,
.billing-invoice-fields {
  .row {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}