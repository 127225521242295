[data-component='BenefitBarComponent'] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @include z(header);
  background-color: $black;
  display: flex;
  padding-right: 44px;
  overflow: hidden;
  transition: all 250ms ease-out;
  ul {
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 0;
    list-style: none;
    min-height: 32px;
  }
  li {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    color: $white;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.125;
    opacity: 0;
    z-index: -1;
    transition: all 0.3s;
    pointer-events: none;
    padding: 0 20px;

    &.active {
      transition: all 0.3s;
      padding: 0 20px;
      opacity: 1;
      z-index: 1;
      pointer-events: initial;
    }
    a {
      margin: 0;
      color: $white;
      font-size: 1.2rem;
      &::before {
        border-top: 1px solid $white;
      }
    }
  }
  &:hover,
  &:focus-within {
    button.icon.icon-chevron {
      opacity: 1;
      transition: all 0.5s;
    }
  }
  .icon--close-16-white {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    width: 24px;
    height: 24px;
    background-size: 16px;
  }

  .editmode & {
    ul li:first-child {
      opacity: 1;
      z-index: 1;
      transition: all 0.3s;
      pointer-events: initial;
    }
  }
}
