.bookanappointment {
  &__body {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    height: 100%;
  }
  &__right {
    overflow: hidden;
    margin-top: 2.2rem;
    padding-left: $space-unit * 4;
    padding-right: 0; // no padding right, deve essere nello scrollable altrimenti la scrollbar è attaccata ai campi
    display: flex;
    flex-direction: column;
  }
  &__left {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .divider {
    @include border('top');
    height: 1px;
    margin-top: $space-unit * 8;
    margin-bottom: $space-unit * 4;
  }
  .scrollable {
    height: 100%;
    padding-bottom: $space-unit * 8;
    padding-right: $space-unit * 4;
    overflow: hidden;
    overflow-y: auto;
  }
  .radiobox2 {
    margin-bottom: ($space-unit * 2);
  }
}
.account-appointments {
  margin-bottom: 2.4rem;
  .client-service__section {
    margin-top: 0;
    margin-bottom: 2.4rem;
  }
  .countryselector {
    margin-bottom: 3.2rem;
  }
  .checkout-pickup {
    // height: 248px;
    margin-bottom: $space-unit * 8;
    > .row {
      height: 100%;
      position: relative;
      > div {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
    }
    &__map {
      height: 248px;
      margin-top: 1.8rem;
      margin-bottom: 0;
    }
    .storelist {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      flex-grow: 10;
    }
    .option-store {
      display: flex;
      &__index {
        width: $space-unit * 4;
      }
    }
  }
  .button {
    margin-top: $space-unit * 4;
  }
  .button-row {
    .button--primary,
    .button--secondary {
      width: 100%;
    }
    .button {
      margin-bottom: $space-unit * 2;
    }
  }
  .title-edit {
    @extend %primary-s;
    margin-top: $space-unit * 4;
    margin-bottom: $space-unit * 4;
  }
  .dateselection-row {
    margin-bottom: $space-unit * 4;
  }
  .alert--success {
    width: 100%;
    text-align: right;
  }
}
.ReserveForm {
  .checkbox {
    margin-top: 1.2rem;
  }
}
