.account-dashboard {
  .image-box {
    &:not(:last-child) {
      margin-bottom: ($space-unit * 2);
    }
  }
  .link-box {
    &:not(:last-child) {
      margin-bottom: $space-unit * 16;
    }
  }
}
