.multicountryPopup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1100;
  pointer-events: none;

  &-box {
    opacity: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: $white;
    width: 100%;
    transition: all 0.3s ease-out;
    pointer-events: all;
    padding: 3.2rem 2.4rem;
    box-shadow: 0 0.4rem 2rem 0 rgba(0, 0, 0, 0.08);

    &.closed {
      opacity: 0;
      pointer-events: none;
    }

    &:not(.closed){
      &::after{
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $black;
        opacity: .3;    
      }       
    }
  }

  &-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 3.2rem;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 300;
    line-height: 2.6rem;

    &--btn{
      min-width: 1.6rem;
    }
  }

  &-body {

    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.6rem;
    

    button {
      width: auto;
      margin-bottom: 1.6rem;
      letter-spacing: 0.1rem;
    }
  }
}