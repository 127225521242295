.sizevariation-modal {
  &.whishlist-selectsize,
  &.quickbuy-selectsize,
  &.shopTheLookModal-selectsize,
  &.quickview-selectsize {
    border-radius: 0.8rem;
    .modal {
      &__body {
        padding-top: 1.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .product-selection {

      &__wrapper {
        .selector-size-expanded {
          border-top: none;

          .selector {
            &__menu {
              padding: 0;
              margin-top: 2.4rem;
              &__list {
                display: grid;
                grid-template-columns: 1fr 1fr;
                &__element {
                  padding: 1.6rem 0;
                  border:none;
                  border-bottom: 0.03rem solid $grey_line;

                  &:nth-child(odd){
                    .selector__menu__item {
                      border: solid $grey_line;
                      border-width: 0;
                      border-right-width: 0.03rem;                    
                    }
                  }
                  
                  &:nth-last-child(1):nth-child(even),
                  &:nth-last-child(2):nth-child(odd),
                  &:nth-last-child(1):first-child{
                    border: 0;
                  }
                }
                
                &.is-small {
                  display: flex;
                  .selector__menu__item {
                    border: none,
                  }
                }
              }  

              
              &__item {
                &:hover,
                &.selected {
                  .selector__menu__item__label {
                    font-weight: 400;
                  }
                }
              }
            }

            &__label {
              font-size: 1.6rem;
              line-height: 2.4rem;
              &_text  {
                font-size: 1.6rem;
                line-height: 2.4rem;                
              }
            }
          }
        }        
      }

      &__size {
        padding-top: 3.2rem;
        font-size: 1.2rem;
        line-height: 2.0rem
      }

    }
  }

  &.whishlist-selectsize {
    .selector-size-expanded {
      .errormessage,
      .selector__label i {
        display: none;        
      }

    }
  }

  &.modal {
    padding: 2.4rem 2.4rem;
  }

  .modal__close {
    padding: 2.4rem;
  }

  .modal__header,
  .modal-header {
    padding-top: 0;
    font-size: 16px;
    line-height: 1.25;

  }

  .product-selection__wrapper {
    .selector-size-expanded {
      border-top: none;

      .selector__menu {
        padding: 2rem 0 2.6rem;
      }
    }
  }

  &.modal--small.product-variant {
    padding: 5rem 2rem 2rem;

    .product-selection__wrapper {
      min-width: 44rem;

      .selector-size-expanded {
        border-top: none;

        .selector-label {
          font-size: 1.6rem;
        }

        .selector__menu {
          padding: 2rem 0 5rem;
        }
      }
    }
  }

  .sizevariation-product {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    overflow: hidden;
    padding-bottom: 0.8rem;

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }

    &__image {
      width: 9rem;
    }

    .product-image {
      height: auto;
      flex-basis: 90px;
    }

    >.row {
      flex-grow: 1;
    }

    &__divider {
      @include border('top');
      height: $space-line;
      margin-top: $space-unit * 4;
      margin-bottom: $space-unit * 4;
    }

    &__content {
      position: relative;
      margin-left: $space-unit * 4;
      display: flex;
      flex: 1;
      flex-direction: column;

      //justify-content: space-between;
      >.bag-product__preorderinfo {
        display: none;
      }
    }

    &__out-of-stock {
      position: absolute;
      color: $status_error;
      margin-top: 1rem;
    }

    &__pricename {
      margin-bottom: 1.6rem;
      line-height: 1.8rem;

      h4 {
        margin-bottom: 0.4rem;
      }
    }

    &__name-price {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: -0.15rem;

      &__name {
        font-size: 1.4rem;
        line-height: 2.2rem;
        @include del-link;
      }

      &__price {
        @extend %primary-s;
        margin-top: 0.4rem;
        margin-bottom: ($space-unit * 4);

      }

      &__message {
        @extend %primary-xs;
        color: $black_secondary;
        margin-top: $space-unit * 2;
        margin-bottom: -($space-unit * 4);
        text-align: right;
      }
    }

    &__details {
      flex-grow: 1;
      color: $dark_grey;
      //margin-top: $space-unit * 5;
      //margin-bottom: $space-unit * 5;

      p {
        @extend %primary-xs;
      }

      div:not(:last-child) {
        margin-bottom: $space-unit * 2;
      }
    }
  }
}