.order {
  // text-align: left;
  margin-bottom: ($space-unit * 16);

  &__title {
    @extend %primary-s-u;
    text-align: center;
    padding: ($space-unit * 10) 0 ($space-unit * 2);
  }

  .total-row {
    .text-section {
      padding: 1.6rem 0;
    }
  }
}

.order-totals {
  .items {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    font-size: 1.4rem;
    line-height: 1.8rem;

    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }

    &.items--dicount {
      color: $dark_grey;
    }

    &.items--totals {
      font-weight: bold;
    }

    &__divider {
      width: 100%;
      margin: 2.4rem 0;
      border-top: $space-line solid $grey_line;
    }

    &__label {
      text-align: left;
    }

    &__subtotal {
      margin-bottom: ($space-unit * 6);
      margin-top: 0;
      p {
        @extend %primary-xs;
      }
    }

    &__promocode {
      color: $dark_grey;
    }
  }
  .list-spacing {
    margin-bottom: $space-unit * 4;
  }
}
