.store-page {
  .store-detail__title {
    padding-top: $space-unit * 12;
    padding-bottom: 2.4rem;
    text-align: center;
    p {
      @extend %primary-xs-u;
      padding-bottom: $space-unit * 3;
    }
    h1 {
      @extend %primary-xl-u;
    }
  }
  .breadcrumb {
    margin: ($space-unit * 4) 0;
    padding: 0;
    li {
      position: relative;
      padding-right: 2.1rem;
      &::after {
        content: '';
        @include fixedSprite('chevron-right');
        margin: 0 0.3rem;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        opacity: 0.55;
      }

      &:last-child {
        padding-right: initial;
        &::after {
          content: initial;
        }
      }
    }
    a {
      &:not([aria-current='page']) {
        color: $black_secondary;
      }
      @extend %primary-xxs;
      @include hoverablelink;
    }
  }
  .store-quote {
    position: relative;
    height: 75vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .quote-wrapper {
      @extend %primary-m-u;
      color: $white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
      padding: 0 5%;
      @include z;
    }
  }
  .spaced-row {
    // margin-top: ($space-unit * 8);
    margin-bottom: ($space-unit * 8);
    > div:nth-child(2) {
      order: -1;
      padding-bottom: ($space-unit * 8);
    }
  }
  .store-locator__map {
    min-height: 30rem;
    height: 45vh;
  }
  .google-map {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
  .section-title {
    margin-bottom: ($space-unit * 8);
    @extend %primary-xl-u;
  }
  .boutiques-nearby {
    // padding-top: ($space-unit * 12);
    padding-bottom: ($space-unit * 16);
    > div:nth-child(2) {
      order: 1;
      padding-top: ($space-unit * 8);
    }
    .wrapper-title {
      text-align: center;
      .section-title {
        margin-bottom: ($space-unit * 4);
      }
      a {
        margin-bottom: ($space-unit * 10);
      }
    }
    .store-details {
      height: auto;
    }
    .nearby-store-details {
      > button {
        width: 100%;
        text-align: left;
      }
      .title {
        @extend %primary-m;
        margin-bottom: ($space-unit * 3);
      }
      .store__summary__hours__next {
        margin-bottom: ($space-unit * 4);
        @include border('bottom');
        padding-bottom: ($space-unit * 4);
      }
      .store-details {
        margin-bottom: ($space-unit * 5);
        > * {
          margin-top: 0;
          margin-bottom: $space-unit;
        }
      }
    }
  }
  .instore-services {
    padding-top: ($space-unit * 12);
    padding-bottom: ($space-unit * 18);
    text-align: center;
    .instore-service-wrapper {
      display: grid;
      row-gap: ($space-unit * 6);
      @include reset-list;
      .instore-service-title {
        @extend %primary-m-u;
        margin-bottom: ($space-unit * 3);
      }
    }
  }
  .store__moreinfo__section {
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  .picture {
    padding-bottom: ($space-unit * 4);
  }
  .store__summary__directions.get-directions {
    width: 100%;
  }
}
