.bookanappointment {
  &__right {
    margin-top: $space-unit * 8;
    padding-left: $space-unit * 8;
    padding-right: 0; // no padding right, deve essere nello scrollable altrimenti la scrollbar è attaccata ai campi
  }
  &.modal {
    min-height: 80vh;
    max-height: 80vh;
    min-width: 90vw;
  }
}
