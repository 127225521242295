.product-recommended {
  // @include gray-gradient;
  @extend .component;
  margin-bottom: ($space-unit * 8);
  &.in-pdp {
    width: 100%;
    margin: 0;
    background: initial;
    .product-recommended__title {
      word-wrap: break-word;
      margin-top: ($space-unit * 12);
      margin-bottom: ($space-unit * 8);
    }
  }

  .product-recommended__title {
    @extend .component__title;
  }

  .product-recommended__content {
    @extend %neg-container;
  }

  &.account {
    margin: 0;
    .product-recommended__title {
      margin: 0;
      padding-top: ($space-unit * 12);
      padding-bottom: ($space-unit * 8);
      @extend %neg-container;
    }
  }
}
