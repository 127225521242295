.account-dashboard {
  &__intro {
    margin-bottom: $space-unit * 10;
  }
  .link-section {
    padding: ($space-unit * 20) 0;
  }
  .link-box {
    &__desc {
      margin-bottom: ($space-unit * 8);
    }
    &__button.button {
      margin-top: auto;
    }
  }
}
