:root {
  --nprogress-color: $black;
  --nprogress-height: 0.2rem;
  --nprogress-spinner-opacity: 1;
  --nprogress-spinner-size: 1.8rem;
  --nprogress-spinner-stroke-width: 0.2rem;
}

#nprogress {
  /* Make clicks pass-through */
  top: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  z-index: 2000;
  width: 100%;
}

#nprogress .bar {
  background: #29d;
  background: var(--nprogress-color);
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: var(--nprogress-height);
}
