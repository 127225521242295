.client-service-content {
  .image-box {
    &:focus {
      outline: none;
      .image-box__title {
        @include focus-underline;
      }
    }

    &.big {
      .image-box__title {
        font-size: 3.2rem;
        line-height: 3.8rem;
      }
    }
  }
}
