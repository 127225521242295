// temporaneo solo per riuscire a navigare nelle vecchie category page non stilate
.main-filter-wrapper {
  margin-top: 6rem;
  margin-bottom: 2rem;
  border: 0.2rem solid red;
  width: 100%;

  .refinement-bar-wrapper {
    width: 100%;

    .refinements {
      width: 100%;
      display: flex;
    }
  }
}

.plp-seo-text strong {
  font-weight: bolder;
}

.plp-seo-text a {
  color: $black;
  text-decoration: underline;
}

.plp-seo-text h2 {
  font-size: large;
  font-weight: bolder;
  margin-top: 40px;
  margin-bottom: 10px;
}

.plp-seo-text h3 {
  font-size: small;
  font-weight: bolder;
  margin-top: 20px;
}

#search-show,
#product-show {
  main {
    padding-top: 0;
    transition: margin 0.3s ease-in-out;
  }
}

[data-react='catalog-app'] {
  position: relative;
}

.listing-container {
  margin-top: $header-menuheight-desktop;

  &.loading {
    pointer-events: none;

    .product-tile__image {
      transition: initial;
    }
  }

  .wrapper-refine-button {
    margin-top: $space-unit;
  }

  .refine {
    @include animate(0.4);
    transition-property: transform, opacity;
  }

  .shelf-container {
    &:first-child {
      .refine {
        position: fixed;
      }
    }

    &:not(:first-child) {
      .refine {
        position: relative;
        top: initial;
        z-index: 1;
      }
    }
  }

  .page-title {
    text-align: center;
    margin-bottom: 20px;
  }

  .filter-tab {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 4;
    display: flex;
    align-items: center;
    @include rounded-box;

    button {
      margin: 20px;
      display: flex;
      align-items: center;

      .filtericon {
        margin-right: 8px;
      }
    }

    div[data-component="switchViewComponent"] {
      display: flex;
      align-items: center;
      border-left: 1px solid #eee;
    }

    .filter-counter {
      width: 1.6rem;
      height: 1.6rem;
      background-color: #f42333;
      border-radius: 50%;
      color: #fff;
      font-size: .9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.8rem;
    }
  }

  .product-tile__card {
    .name {
      padding-top: 0.8rem;
    }
  }
}

.product-grid__item {
  border: $space-line solid red;
  padding: 1rem;
  margin: 1rem;
}

.grid-container {
  @include reset-list;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  width: 100%;
  gap: 2.4rem 1.7rem;

  .product-tile {
    grid-column: span 4;
  }

  li {
    min-width: 0;
  }

  .plppromo {

    &--2x2,
    &--2x1 {
      &:focus {
        outline: none;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 0 0.2rem $outline;
          @include z;
        }
      }
    }

    &--2x2 {
      @extend .plppromo;
      grid-column: span 2;
      grid-row: span 2;
    }

    &--2x1 {
      @extend .plppromo;
      grid-column: span 2;
    }
  }

  .pages-number {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-bottom: 40px;
    width: 215px;

    .btn.inactive {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.plp-loading,
.plp-error {
  display: flex;
  align-items: center;
  padding-top: ($space-unit * 16);
  padding-bottom: ($space-unit * 4);
  @extend %primary-s-u;
}

.plp-error {
  &::before {
    content: '';
    @include fixedSprite('alert');
    width: $space-unit * 4;
    height: $space-unit * 4;
    margin-right: $space-unit * 2;
  }
}

.plppromo {
  position: relative;

  .plppromo__link {
    display: block;
    position: relative;
    height: 100%;
  }

  .plppromo__title {
    position: absolute;
    top: $space-unit * 4;
    left: $space-unit * 4;
    text-transform: uppercase;
    @include z;

    &.white-title {
      color: $white;
    }
  }

  picture {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  img {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.shelf-container {
  .view-all {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: $space-unit * 3;
    padding-bottom: $space-unit * 6;
    text-decoration: initial;

    i {
      margin-top: -0.2rem;
    }
  }
}

.editmode {
  .plppromoarea {
    background-color: yellow;
  }

  .experience-region {

    &.experience-promo1,
    &.experience-promo2,
    &.experience-promo3,
    &.experience-promo4,
    &.experience-promo5,
    &.experience-promo6 {
      width: 100%;
      height: 100%;
    }
  }

  .experience-component_plppromo {
    width: 100%;
    height: 100%;
  }
}

a.btn-n-page,
span.btn-n-page {
  color: $dark_grey;
  font-size: 18px;
  text-decoration: none;
  padding: 0 4px;

  &.active {
    color: $black;
    font-weight: bold;
  }
}

.btn-subcategory {
  border-radius: 4px;
  padding: 8px 16px;
  border: 1px solid $grey_line;

  &.selected {
    border: none;
    background-color: #1B1B1A;
    color: $white;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.subcategories-container {
  display: flex;
  margin: 2.4rem 0;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    text-decoration: none;
    text-align: center;
  }

  .item-wrapper {
    flex: none;
    padding: 1rem 0;
    margin: 0 auto;
  }
}

.plp-seo {
  border-top: 1px solid $grey_line;
  padding-top: 40px;
  padding-bottom: 80px;
  color: $dark_grey;

  h2 {
    margin-top: 2.4rem;
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
    font-weight: bold;
  }
}

.grid-product.refinement-grid {
  margin-top: 2.4rem;
}


.searchNoResults {
  margin-bottom: 5rem;

  &Header {
    &__title {
      margin-top: 5rem;
      line-height: 2.8rem;
      padding-bottom: 1.4rem;

      &--soldout,
      &--soon {
        font-size: 2.4rem;
      }

      &--seasonal {
        font-size: 1.8rem;
      }
    }
  }
}