.tingle-modal {
  &.modal-go-back {
    justify-content: flex-end;

    .tingle-modal-box {
      width: 100%;
      padding: 3.2rem 1.6rem;
      border-radius: 0.8rem 0.8rem 0 0;

      .header-button-wrp {
        justify-content: space-between;

        &::before {
          content: "";
          width: 1.8rem;
          height: 1.8rem;
        }

        .title-4 {
          display: flex;
          justify-content: center;
        }
      }

      .icon--close {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }

  &.shipping-address-block,
  &.change-country-modal {
    .tingle-modal-box {
      position: fixed;
      width: 100%;
      height: auto;
      bottom: 0;
      right: unset;
      top: unset;
      left: 0;
      background-color: $white;
      border-radius: 0.8rem 0.8rem 0 0;

      .modal-content-body {
        height: 65vh;
        overflow: auto;
      }

      .modal-header {
        padding: 3.2rem 1.6rem;
        justify-content: space-between;
        gap: 1.6rem;

        &::before {
          content: "";
          width: 1.8rem;
          height: 1.8rem;
        }

        .modal-title {
          flex: 1 1 0;
          display: flex;
          justify-content: center;
          text-align: center;
        }

        button,
        .close {
          width: 1.8rem;
          height: 1.8rem;
        }
      }

      .change-country-confirmation-body {
        padding: 0 1.6rem 2.4rem;
      }

      .modal-footer {
        border-top: 0.1rem solid $black;
        position: relative;

        &.change-country-footer {
          border-top: unset;
          padding: 0 1.6rem 3.2rem;
        }
      }
    }
  }
}