[data-tooltip] {
  position: relative;
  display: inline-block
}

[data-tooltip]:after, [data-tooltip]:before{
  position: absolute;
  left: 50%;
  transform: translate(-50%,-12px);
  z-index: 1000;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  transition: opacity .2s ease-out;

}

[data-tooltip]:before {
  content: attr(data-tooltip);
  background: $black;
  color: #eee;
  padding: 1.6rem;
  bottom: 100%;
  border-radius: 4px;
  width: max-content;
  max-width: 320px;
  white-space: normal;
}

[data-tooltip]:after {
  content: "";
  background: transparent;
  border: 8px solid transparent;
  border-top-color: $black
}

[data-tooltip]:active:after,[data-tooltip]:active:before,[data-tooltip]:focus:after,[data-tooltip]:focus:before,[data-tooltip]:hover:after,[data-tooltip]:hover:before {
  opacity: 1
}