.store__summary__directions.get-directions {
  width: 70%;
}
.search-store {
  &__resultslist {
    [role='menu'] {
      padding: 0 2rem;
      [role='menuitemradio'] {
        padding-top: 2.4rem;
        padding-bottom: 3.2rem;
      }
    }
  }
  &__regions {
    .wrapper-regions {
      padding: 0 2rem;
    }
  }
}
