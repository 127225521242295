.widget-b-editorial {
  padding: 4.8rem;
  justify-content: space-between;

  &.bg-black {
    background-color: $black;
    color: $white;

    .tag-item {
      background-color: $white;
      color: $black;
    }

    .cta {
      color: $white;
    }
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    padding-right: 4.8rem;
  }

  .pretitle {
    margin-bottom: 1.6rem;
    line-height: 1;
  }
  .title {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.1;
  }

  .cta {
    position: relative;
    display: flex;
    margin-top: 3.2rem;
    padding-left: 1.4rem;
    text-decoration: none;
  }

  .paragraph {
    line-height: 1.44;
    font-size: 1.8rem;
    font-weight: 300;
  }
  .bottom {
    margin-top: auto;
  }

  .content-items {
    margin-top: 2.4rem;
    display: flex;
    overflow: auto;
    flex-wrap: wrap;
    gap: 0.8rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tag-item {
    &:first-child {
      margin-left: 0;
    }
    &:not(:last-child) {
      margin-right: 0;
    }
  }
}

.video-container {
  position: relative;
  padding: 0;

  .video-controls {
    position: absolute;
    bottom: 40px;
    right: 40px;

    .playpause {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .playpause[data-action='play'] {
      &::after {
        content: '';
        @include fixedSprite('play-white');
      }
    }
    .playpause[data-action='pause'] {
      &::after {
        content: '';
        @include fixedSprite('pause-white');
      }
    }
  }
}

.svg-progress-bar circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.2s linear;
  stroke: rgba($color: #fff, $alpha: 0.4);
  stroke-width: 3px;
}
.svg-progress-bar .bar {
  stroke: $red;
}
.progress-bar {
  display: block;
  height: 46px;
  width: 46px;
  border-radius: 100%;
  position: relative;

  .svg-progress-bar {
    transform: rotate(-90deg) scaleY(-1);
  }
}
