.editorial-multi-text {
  display: flex;
  @include appearAnimation();

  &.editorial-multi-text--inverted {
    flex-direction: row-reverse;
  }
}

.editorial-multi-text__img-column {
  width: 50%;
  display: block;
  overflow: hidden;
  position: relative;
  img {
    left: 0;
    top: 0;
    width: 100%;
    position: absolute;
    max-width: 100vw;
    object-fit: cover;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}

.editorial-multi-text__text-column {
  width: 50%;
  padding: 7.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.editorial-multi-text__body {
  @extend %primary-xs;

  &.body--justified {
    text-align: justify;
  }
}

.editorial-multi-text__subtitle {
  @extend %primary-m;
  margin-top: 2rem;
  margin-bottom: ($space-unit * 20);
}

.editorial-multi-text__title {
  display: block;
  text-transform: uppercase;
  padding-bottom: ($space-unit * 3);
}

.editorial-multi-text__label {
  display: block;
  @extend %primary-xs;
  text-transform: uppercase;
  margin-bottom: ($space-unit * 2);
}

.editorial-multi-text__cta {
  .button {
    color: $black;
  }

  margin-bottom: ($space-unit * 8);
}

.text-column--mode-white {
  background: $white;
  * {
    color: $black !important;
  }
}

.text-column--mode-black {
  background: $black;
  * {
    color: $white !important;
  }
}
