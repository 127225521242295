#loyalty {
  .barcode-wrapper {
    text-align: center;
  }
  .stefanel-points {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i {
      margin-bottom: 1.6rem;
    }
    span {
      color: $dark_grey;
      line-height: 2.2rem;
      margin-bottom: $space-unit;
    }
    p {
      color: $red;
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: bold;
    }
  }
  .details-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: -1.6rem;
    margin-top: 1.6rem;
  }
  .detail-row {
    display: flex;
    justify-content: space-between;
    padding: 2.4rem 0;
    &:not(:first-of-type) {
      border-top: 1px solid $grey;
    }
    p:first-of-type {
      color: $dark_grey;
    }
  }
}
body[id^='account-'] {
  .box.loyalty-register{
    padding: 0 2.4rem;
  }
}