.product-reserve {
  &__main {
    .product-image {
      &::before {
        padding-top: 100%;
      }
    }
  }
  &__detail {
    z-index: 3;
  }
}
