.product-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  //non mettere overflow hidden, rompe il position sticky
  // &:first-child {
  //   padding-top: $header-height-mobile;
  // }
  &.product-wrapper {
    padding-top: calc(11.2rem + 3.2rem + 2.4rem);
    margin-top: 0;
    top: 0;
    z-index: 0;
    display: grid;
    transition: padding 0.3s ease-in-out;

  }

  .product-col {
    flex: 1 0 100%;
    max-width: 100%;
  }

  .pdp-loyalty-banner {
    display: flex;

    .icon--loyalty {
      width: 1.6rem;
      height: 1.6rem;
    }
  }


}

.product {
  &-images {
    &-container {
      position: relative;
      padding: 0;
    }

    &__tag {
      position: absolute;
      padding: 0.4rem 0.8rem;
      bottom: 2.4rem;
      top: unset;
      left: -4.8rem;
      gap: 0.2rem;

      background: rgba($white, 0.6);
      z-index: 1;

      &_icon {
        width: 1.4rem;
        height: 1.4rem;
      }

      &_label {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.4rem;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
      }
    }

    &__wishlist.cta--wish {
      position: absolute;
      z-index: 1;
      width: 2.4rem;
      height: 2.4rem;
      padding: 0;
      top: 2rem;
      left: -2.8rem;

      &::after {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__stlButton {
      position: absolute;
      z-index: 1;

      bottom: 2.4rem;
      right: 2.4rem;

      &-container {
        position: relative;
        overflow: hidden;
        border-radius: 0.4rem;
        border: solid $grey_line;
        border-width: 0 0.1rem 0 0;

      }

      &-wrapper {
        background: $white;
        border-radius: 0.4rem;
        gap: 0.4rem;
        text-decoration: none;
        padding: 0.8rem;
        border: solid $grey_line;
        border-width: 0.1rem 0 0.1rem 0.1rem;
        transition: transform 0.5s ease-in-out;
        cursor: pointer;

        &:hover {
          transform: translateX(0);
          gap: 0.4rem;
        }
      }

    }
  }

  &-sidebar {
    &__details {
      border: solid $grey_line;
      border-width: 0.03rem 0;

      .product-promotion {
        &__label {
          color: $red;
          font-weight: 400;
          line-height: 2rem;
          font-size: 1.2rem;
          text-transform: uppercase;
          text-align: center;
        }

        &__tooltip {

          &_box {
            background-color: $black;
            border-radius: 0.4rem;
            padding: 1.6rem;
            text-align: center;
            font-size: 1.4rem;
            line-height: 2.2rem;
            font-weight: 400;
            color: $white;

            &::before {
              border-top-color: $black;
            }
          }
        }

        &__login {
          font-size: 1.2rem;
          line-height: 2rem;

          &_link {
            font-size: 1.2rem;
            line-height: 2rem;
            cursor: pointer;
          }
        }
      }
    }
  }

}

.discount-value,
.js-discount-msg {
  background: $grey_line;
  padding: 0.4rem;
  line-height: 1.2rem;
  font-size: 1rem;
  font-weight: 400;
}

.sales .value {
  display: inline-flex;
  align-items: center;
}

del {
  color: $dark_grey;
}

#sticky-bar-bottom {
  z-index: 1000;
}
