.product-gallery-carousel {
  // @include gray-gradient;
  @extend .component;
  margin-bottom: ($space-unit * 8);
  background-color: #F7F7F7;
  padding-top: 2.4rem;

  .labelgrayleft{
    padding: 0 0.4rem;
    text-transform: uppercase;
    color: $red;
  }

  .product-gallery-carousel__title {
    @extend .component__title;
  }

  .product-gallery-carousel__content {
    @extend %neg-container;
  }

  .swiper-pagination-progressbar {
    margin: 2.4rem 0;
    //width: calc(100% - 15px);
  }

  #product-gallery-carousel__variant__end {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #product-gallery-carousel__variant__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.4rem;
  }

  .swiper-button-container {
    display: none;
    align-items: center;

    .swiper-button-disabled {
      opacity: 0.5;
    }
  }

  .swiper-button-prev {
    display: flex;
    align-items: center;

    &::after {
      content: '';
      @include fixedSprite('arrow-sx-red');
    }
  }
  .swiper-button-next {
    display: flex;
    align-items: center;

    &::after {
      content: '';
      @include fixedSprite('arrow-dx-red');
      margin-left: 4rem;
    }
  }
}
