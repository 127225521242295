.product-grid {
  &__title {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }
  &__container {
    padding: 3.2rem 1.6rem 5.6rem;
  }
  &__content {
    &-container {
      .product-tile {
        grid-column: span 5;        
      }


    }
  }
  
}