header {
  .main-submenu {
    grid-template-columns: repeat(2, 1fr);
  }
  .submenu-group {
    grid-column: span 2;
    ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .container-nav {
    &.collapse-translate,
    &.collapse-translate-initial {
      transform: translateY(-100%);
    }

  }
}
