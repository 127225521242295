.form-select {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    @include fixedSprite('arrow-down-black');
    background-size: 150%;
    top: 1.6rem;
    right: 1.2rem;
    pointer-events: none;
  }

  select {
    @extend %primary-xs;
    border-radius: 0.8rem;
    appearance: none;
    height: $input-height;
    color: $black;
    line-height: 18px;
    padding: 1.2rem;
    background-color: $white;
    width: 100%;
    border: $space-line solid #ddd;
    transition: all 0.5s $easeOutQuart, outline 0s, border 0s, border-color 0s;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 32px;

    &:has(+ label) {
      padding: 1.6rem 1.2rem 0;
    }

    &:focus,
    &:active {
      border-color: $outline-color;
    }

    // since the select will always have a default
    &~label {
      top: 6px;
      font-size: 1.2rem;
      line-height: 1.6rem;
      pointer-events: none;
    }

    &:disabled {
      border-color: rgba($grey_input, $input-disabled-opacity);
      cursor: none;
      pointer-events: none;
      opacity: $input-disabled-opacity;
    }
  }

  .invalid-feedback {
    text-align: right;
  }
}