html {
  // scroll-behavior: smooth;
  /* evita gli spostamenti quando appare il menu che fa sparire la scrollbar */
  // disattivo temporaneamente per testare il fix sull'ovcerflow hidden sul body
  // overflow-y: scroll;
  font-size: 62.5%;
  scroll-behavior: smooth;

  &.initial-scroll {
    scroll-behavior: initial;
  }
}

body {
  @extend %primary-xs;
  color: $black;
  background-color: $white;
  position: relative;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // fallback
  --scrollbar-width: 0px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $overlay;
    opacity: 0;
    pointer-events: none;
    @include z(overlay);
    // @include animate(0.15);
    @include animate(0.3);
  }

  &.is-loading {
    .loader {
      opacity: 0.5;
      background: $overlay;
      visibility: visible;
      z-index: 1100;
    }
  }

  &.overlay {
    &::before {
      opacity: 0.5;
      pointer-events: initial;
    }
  }

  &.no-overflow {
    overflow: hidden;
  }

  &.no-overflow {
    .cookie-bar {
      z-index: 2;
    }
  }
}

#top-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.1rem;
}

main {
  min-height: calc(var(--viewport-height) - var(--footer-height));
  // @include gray-gradient;
  // background-attachment: fixed;
  display: flex;
  flex-direction: column;
  // for test purposes
  // background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);

  &.no-padding {
    padding-top: 0;
  }

  &.main--autoheight {
    min-height: calc(100vh - #{$footer-clientservice-height-desktop} - #{$header-height-desktop});
    padding-bottom: $space-unit * 9;
  }

  // &.main--checkout {
  //   min-height: calc(100vh - #{$footer-clientservice-height-desktop} - #{$header-height-desktop});
  //   padding-bottom: $space-unit * 9;
  //   padding-top: 3.2rem;
  //   margin-top: $space-unit * 8;
  //   background-color: $light_grey;
  //   .checkout-container {
  //     min-height: 20rem;
  //   }
  // }
}

img {
  max-width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  height: auto;
}

ul,
ol {
  padding-top: $space-unit * 3;
  padding-bottom: $space-unit * 3;
  list-style-position: outside;
  list-style-type: square;
  padding-left: 1.3rem;

  li {
    padding: $space-unit 0;
  }
}

a,
a:visited {
  color: inherit;
}

button,
[role='button'],
[role='option'] {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  font: inherit;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

input[type='search']::-webkit-search-cancel-button {
  /* Remove default cancel button */
  -webkit-appearance: none;
}

.greyed {
  opacity: 0.5 !important;
  filter: grayscale(1) !important;
  border-color: #5a5a5a !important;
  pointer-events: none !important;
}
.page-template-textpage,
.page-template-webtocase {
  padding-top: 15rem;
}
