.three-column-product__single-column {
  &:hover {
    &:not(.show-text) {
      .single-column__image-hover {
        display: block;
      }
    }
    .single-column__base-image {
      display: none;
    }
  }
  &.show-text {
    .single-column__text-hover {
      display: block;
    }
  }
}

.single-column__text-hover,
.single-column__image-hover {
  display: none;
}

.single-column__base-image {
  display: block;
}

.three-column-product__description-mobile {
  display: none;
}

.three-column-product__single-column {
  &:nth-child(1) {
    order: 0;
  }
  &:nth-child(2) {
    order: 1;
  }
  &:nth-child(3) {
    order: 2;
    margin: 0;
  }
}

.three-column-product--internal-border {
  .three-column-product__single-column {
    &:nth-child(2) {
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }
}

.three-column-product__description-mobile {
  display: none;
}
