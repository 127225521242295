.orders-listing__order-detail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: ($space-unit * 4) 0 ($space-unit * 3);
}

.order-detail__column {
  width: 33.33%;
  overflow: hidden;
  text-align: left;

  .dt {
    display: block;
    @extend %primary-xxs;
    text-transform: uppercase;
    margin-bottom: $space-unit;
  }

  .dd {
    @extend %primary-xs;
    display: block;
  }
}

.bag-product__image {
  max-width: 9.5rem;
  flex-basis: 9.5rem;
}

.myaccount-wrapper {
  .orders-listing {
    .accordion__header {
      //to do: refactor
      .header__open,
      .header__close {
        top: 1.4rem;
        bottom: initial;
        transform: none;
      }
    }

    .bag-product {
      flex-wrap: wrap;
      &__content {
        max-width: calc(100% - 11.1rem);
        flex-basis: calc(100% - 11.1rem);
      }
      &__details {
        margin-top: ($space-unit * 2);
        div:not(:last-child) {
          margin-bottom: $space-unit;
        }
      }
    }
  }
}

.account-orders-return {
  .order-detail-page .order-item__details {
    margin-top: unset;
  }
  .order-item {
    flex-wrap: wrap;
  }
}
