.hero-v2 {
  display: flex;

  a {
    display: flex;
    text-decoration: none;
  }

  .video-img-container {
    flex-basis: 70%;

    img {
      width: 100%;
    }
  }

  .hero2-text {
    padding: 48px;
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .item-content-text {
      z-index: 2;
      color: $black;
      line-height: 1.2;

      position: sticky;
      bottom: 4.8rem;
    }

    &.black {
      .cta {
        color: $white;
      }

      .item-content-text {
        color: $white;
      }
    }

    .item-title {
      font-size: 3.2rem;
      font-weight: 300;
      margin-bottom: 1.2rem;
    }

    .item-subtitle {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }

    .item-cta {
      font-size: 1.4rem;
      padding-left: 14px;
      position: relative;
      text-decoration: none;
    }
  }
}