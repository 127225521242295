.switch {
  height: 2rem;
  width: 6.8rem;
  background-color: $white;
  border: 1px solid $dark_grey;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .checkbox-wrapper{
    position: relative;
  }

  &:focus-within {
    outline: 0.1rem solid $black;
    border-color: $black;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .value {
    width: 3.2rem;
    height: 1.6rem;
    font-size: 1rem;
    line-height: 1.4rem;

    text-transform: uppercase;
    border-radius: 1rem;
    text-align: center;
    color: $dark_grey;

    &.active {
      color: $white;
      background-color: #1b1b1a;
    }
  }
}