/*icona minimized*/
.embeddedServiceSidebarMinimizedFabDefaultUI button,
.embeddedServiceSidebarMinimizedFabDefaultUI button.fab,
.embeddedServiceSidebarMinimizedFabDefaultUI button.fab:hover,
.embeddedServiceSidebarMinimizedFabDefaultUI button.fab:focus,
.waitingCancelChat {
    background-color: #000000 !important; 
    background:  #000000;
}


.embeddedServiceLiveAgentStateChatItem.chatMessage{
    background: unset;
}

/*fumetto agnte*/
.embedded-service .fab{
    background: #000000 !important;    
}
.embeddedServiceSidebarMinimizedFabDefaultUI .dismiss-button{
 border-style: none;
 background-color: #000000;
}

.embeddedServiceSidebarMinimizedFabDefaultUI .message{
    background-color: white;
    border-style: none;
}

/*cta*/
.embeddedServiceSidebarButton,
.embeddedServiceSidebarButton:not(:disabled):hover, 
.embeddedServiceSidebarButton:not(:disabled):focus,
.embeddedServiceSidebarButton.uiButton--inverse,
.embeddedServiceSidebarButton.uiButton--inverse:hover,
.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):hover,
.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):focus {
    background: #000000 !important;
}


/*testo cta*/
.embeddedServiceSidebarButton,
.embeddedServiceSidebarButton.uiButton--inverse .label,
.embeddedServiceSidebarButton .label,
.embeddedServiceLiveAgentStateChat .uiButton--default:focus .label,
.embeddedServiceLiveAgentStateChat .uiButton--default:hover .label
{
    color: #FFFFFF !important;
}
.embeddedServiceIcon svg{
    fill: #FFFFFF;
}

.embeddedServiceSidebarButton:focus{
    text-decoration: none;
    text-decoration-color: none;    
}


.embedded-service .channelmenu_ctas > li:first-child:not(.chatDisabled) + li,
.embedded-service .channelmenu_ctas > li:not(.chatDisabled):first-child > li,
.embedded-service .channelmenu_ctas > li[class~="chatDisabled"]{
    display: none !important;
}

.embeddedServiceLiveAgentStateChatAvatar.isLightningOutContext .agentIconColor1{
    background-image: url('https://ovsspaclienti--c.eu35.content.force.com/servlet/servlet.ImageServer?id=0155p000008KxvOAAS&amp;oid=00D0N000000jojk');
    
}
.embeddedServiceLiveAgentStateChatItem .isLightningOutContext .avatar.iconAvatar,
.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent a
{
    color: #000000;
}

header#esw-channelmenu_header {
    position: relative;
    top: 0;
}

.embedded-service .fab, 
.embedded-service .channelmenu, 
.embeddedServiceSidebar.layout-float .dockableContainer
.embeddedServiceSidebarMinimizedFabDefaultUI,
.embeddedServiceSidebar.layout-float .dockableContainer.showDockableContainer,
.embeddedServiceSidebarMinimizedFabDefaultUI,
.embeddedServiceSidebarMinimizedFabDefaultUI .content
{
    left: 30px;
    right: unset;
}

.embeddedServiceSidebar.sidebarMaximized.layout-float .minimizedContainer{
    max-width: 58px;
}

@media only screen and (max-width: 600px) {
    .embedded-service .fab, 
    .embedded-service .channelmenu, 
    .embeddedServiceSidebar.layout-float .dockableContainer
    .embeddedServiceSidebarMinimizedFabDefaultUI,
    .embeddedServiceSidebar.layout-float .dockableContainer.showDockableContainer,
    .embeddedServiceSidebarMinimizedFabDefaultUI,
    .embeddedServiceSidebarMinimizedFabDefaultUI .content
    {
        left: 10px;
        right: unset;
    }

}

.queuePositionMessage p::first-line{
    font-weight: bold;
}

.embedded-service #esw-fab.showAnimation,
.embedded-service #esw-channelmenu.showAnimation{
    z-index: 99;
}

@supports not (-ms-high-contrast:none) {
.embedded-service .channelmenu {
    bottom: 100px !important;
} }

.embedded-service #esw-fab.showAnimation,
.embedded-service #esw-channelmenu.showAnimation{
    z-index: 99;
}

.embedded-service > #esw-modaloverlay.isMaximized,
.embedded-service #esw-fab.showAnimation,
.embedded-service #esw-channelmenu.showAnimation{
    z-index: 4;
}

@media only screen and (max-width: 47.9375em) and (orientation: portrait){
    .embedded-service > #esw-modaloverlay.isMaximized,
    .embedded-service > #esw-modaloverlay.isMaximized+div#esw-container > button.fab,
    .embedded-service > #esw-modaloverlay.isMaximized+div#esw-container > div.channelmenu{
        z-index: 4;
    }
}

@media only screen and (max-width: 768px) {

  .embedded-service #esw-fab.showAnimation, .embedded-service #esw-channelmenu.showAnimation{

      z-index: 2;

  }

}