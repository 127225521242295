.sticky-tray {

  @include z('stickybar');
  opacity: 1;
  transition-property: transform;
  transition-duration: 0.3s;

  // transform: translateY(-100%);
  &::before {
    content: initial;
  }


  &__modal.modal {
    width: calc(50% + var(--scrollbar-width) / 2);
    top: $header-height-desktop + 4.6rem;
    left: initial;
    bottom: initial;
    padding: 0.8rem 2.8rem 2rem 2.8rem;

    &.ReactModal__Content {
      transform: translateY(-3.2rem);
    }

    &.ReactModal__Content--after-open {
      transform: translateY(0);
    }

    &.ReactModal__Content--before-close {
      transform: translateY(-3.2rem);
    }

    .modal__close {
      padding: 2.2rem;
    }

    .selector-size-expanded .selector__menu ul {
      min-width: initial;
    }
  }

  &__overlay {
    &.overlay-modal {
      @include z;
    }
  }
}