.myaccount-hero {
  height: 17.6rem;
}

.hero-column__inner-container {
  padding: ($space-unit * 8);
}

.hero__main-title {
  width: 76%;
}

.hero {
  .hero-column__img {
    video {
      transform: scale(1.05);
    }
    .mobile-version {
      video {
        transform: scale(3);
      }
    }
  }
}
