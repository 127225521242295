.section-menu {
  @include reset-list;
  li {
    @extend %primary-s;
    color: $black_secondary;
    padding-right: ($space-unit * 6);
    &.selected {
      color: $black;
    }
    &:not(:last-child) {
      margin-bottom: ($space-unit * 2);
    }
    a {
      text-decoration: initial;
    }
  }
}
.contact-link-button {
  margin-top: ($space-unit * 8);
  margin-left: -($space-unit);
  a {
    @extend %primary-s;
    display: flex;
    align-items: center;
    margin-bottom: ($space-unit * 2);
    text-decoration: none;
    &::before {
      flex-shrink: 0;
    }
  }
}
.call {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('phone');
  }
  @include hover(0.2);
}

.chat {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('chat');
  }
  @include hover(0.2);
}

.mail {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('mail');
  }
  @include hover(0.2);
}

.whatsapp {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('instantmessage-whatsapp');
  }
  @include hover(0.2);
}

.kakao {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('instantmessage-kakao');
  }
  @include hover(0.2);
}

.linechat {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('instantmessage-line');
  }
  @include hover(0.2);
}
