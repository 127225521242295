.three-column-product__title {
  margin-top: ($space-unit * 8);
}

.three-column-product__product-holder {
  overflow: auto;
  scroll-snap-type: x mandatory;
}

.three-column-product__description-mobile {
  margin: 2.5rem auto ($space-unit * 10);
}

.three-column-product__title,
.three-column-product__subtitle,
.three-column-product__description-mobile {
  max-width: 100%;
  padding: 0 ($space-unit * 4);
}

.three-column-product__single-column {
  min-width: calc(100vw - 3.2rem);
  padding-top: 150%;
  scroll-snap-align: center;
  &:nth-child(1) {
    order: 1;
  }
  &:nth-child(2) {
    margin: 0;
  }
  &:nth-child(3) {
    order: 0;
  }
}
.three-column-product {
  &:not(.three-column-product--without-border) {
    .three-column-product__product-holder {
      &::before,
      &::after {
        content: '';
        display: block;
        width: ($space-unit * 4);
        min-width: ($space-unit * 4);
        height: 5rem;
        background: $white;
        order: 0;
      }
      &::after {
        order: 4;
      }
    }
  }
}

// margin
.three-column-product--internal-border {
  .three-column-product__product-holder {
    padding: 0;
  }
  .three-column-product__single-column {
    min-width: calc(100vw - 3.6rem);
    &:nth-child(1) {
      order: 1;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
    &:nth-child(3) {
      margin: 0;
    }
  }
}

.three-column-product--external-border {
  .three-column-product__product-holder {
    padding: 0;
  }
  .three-column-product__single-column {
    min-width: calc(100vw - 3.2rem);
  }
}

//alignment
.three-column-product--centered-bottom {
  padding-top: ($space-unit * 4);
}
