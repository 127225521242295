.newsletterConfirm {

  &__header {
    &__title {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    &__subtitle {
      font-size: 1.4rem;
      line-height: 2.2rem;
      padding-top: 0.8rem;
    }    
  }

  &__body {
    padding-top: 2.4rem;

    &__text {
      font-size: 1.4rem;
      line-height: 2.2rem;
      padding-bottom: 2.4rem;
      color: $grey7;
    }

    &__title {
      font-size: 1.8rem;
      line-height: 2.8rem;
      padding-bottom: 1.2rem;
      color: $black;
    }
    &__btn {
      padding: 1.4rem 3.2rem;
      background-color: $grey1;

      &__label {
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
    }
    &__terms {
      padding-top: 0.8rem;
      font-size: 1.2rem;
      line-height: 2rem;
      color: $grey6;
    }

    &__confirm {
      margin: 1.6rem 0;
    }

    .login-registration__section__registration__list {
      margin-top: 2.4rem;
      &__text {
        padding-left: 0;
      }
    }

    .divider {
      margin: 3.2rem 0;
    }
  }
}