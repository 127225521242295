// https://www.w3.org/TR/wai-aria-practices-1.1/examples/radio/radio-1/radio-1.html

.radiobox {
  display: flex;
  flex: 1 1 auto;
  // flex-wrap: wrap;
  // height: 56px;
  padding: 4px;
  border-radius: 8px;
  border: solid 1px #ddd;
  background-color: #fff;

  &__option {
    @include animate;
    height: 48px;
    padding: 16px;
    text-align: center;
    border-radius: 8px;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;

    text-transform: uppercase;
    color: #232323;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__option--no-outline {
    color: $black_secondary;
    @include animate;
    padding: 0 0 $space-unit * 4;
    border: none;
  }

  [role='radio'] {
    // @include col;
    cursor: pointer;
    @extend %primary-s;

    &[aria-checked='true'] {
      .radiobox__option {
        /*
        background-color: #232323;
        color: #fff;
        */
        border: 1px solid $black;
      }

      .radiobox__option--no-outline {
        color: $black;
      }
    }

    &:focus {
      outline: none;

      .radiobox__option {
        @include focus-underline;
      }
    }
  }

  &--horizontal-two-items {
    [role='radio'] {
      display: flex;
      align-items: center;
      justify-content: center;
      @include col-width;
    }
  }

  &--horizontal-more-items {
    [role='radio'] {
      @include col-width;
    }
  }

  &--vertical {
    [role='radio'] {
      @include col-width(6);
    }
  }
}

.is-invalid {
  .radiobox {
    color: $status_error;
  }
}