.video-module {
  padding: 9.6rem 14.7rem;
  @include appearAnimation();
}

.video-module__inner-container {
  height: 64.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}

.video-module__title {
  color: $white;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-weight: 300;
  position: relative;
  z-index: 2;
  text-align: center;
}

.video-module__cta {
  position: relative;
  z-index: 2;
  .button {
    color: $white;
  }

  .button--secondary {
    border-color: $white;
    color: $white;
  }
}

.video-module__video {
  width: 100%;
}
