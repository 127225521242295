.product-gallery-carousel {
  .swiper-pagination-progressbar {
    margin: 3.2rem 0 4.8rem 0;
    //width: 100%;
  }

  .swiper-button-container {
    display: flex;
  }

  #product-gallery-carousel__variant__end {
    margin-top: 1.5rem;
  }

  #product-gallery-carousel__variant__container {
    flex-direction: column;
  }
}
