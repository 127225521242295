.loyalty-register {
  .switch-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.4rem;
  }
  .register-info {
    display: block;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $dark_grey;
    margin-top: -($space-unit * 2);
    margin-bottom: 3.2rem;
  }
  .privacy-info,
  .privacy-info2,
  #profilingPolicyLoyalty-label {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .privacy-info {
    margin-top: 1.6rem;
  }
  .privacy-info2 {
    margin-top: 2.4rem;
    margin-bottom: ($space-unit * 2);
    color: $dark_grey;
  }
  .register-card-info {
    line-height: 2.2rem;
    color: $dark_grey;
    margin-bottom: 2.4rem;
  }
}
