#countryselector-modal {
  overflow: auto;
  max-width: 100%;
  margin-top: 8rem;
  padding-bottom: 10rem;

  /*
  .modal__body {
    height: calc(100% - 19rem);
    overflow-y: auto;
  }
  */

  .modal__close {
    display: none;
  }
}
