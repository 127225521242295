.sizechart {
  margin: ($space-unit * 6) 0 0;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .table {
    margin-bottom: 6rem;
    table-layout: fixed;
    white-space: nowrap;
    width: auto;
    th,
    td {
      min-width: 10rem;
    }

    @media (max-width: 768px) {
      margin-bottom: 3.5rem;
    }
  }
}
.sizechart-tabs {
  .react-tabs__tab-panel {
    display: none;
    &--selected {
      display: block;
    }
  }
}

.sizechart.measmerizecontainer {
  width: 100%;
  height: 500px;
  position: relative;
  margin-top: 0;
  padding-top: 0;
  iframe {
    width: 100%;
    height: 100%;
  }
}

#tmp-measmerize-iframe {
  display: none;
}

.sizechart__mannequin {
  width: 100%;

  &--man,
  &--woman,
  &--kid,
  &--baby,
  &--children, //come fallback
  &--hand,
  &--dog {
    @extend .sizechart__mannequin;
  }

  &--man {
    @include fixedSprite('sizeguide-man');
  }
  &--woman {
    @include fixedSprite('sizeguide-woman');
  }
  &--kid {
    @include fixedSprite('sizeguide-kid');
  }
  &--baby,
  &--children {
    @include fixedSprite('sizeguide-baby');
  }
  &--hand {
    @include fixedSprite('sizeguide-hand');
  }
  &--dog {
    @include fixedSprite('sizeguide-dog');
  }
}

.sizechart__legend {
  span {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    border: $space-line solid black;
    border-radius: 100%;
    text-align: center;
    line-height: 2rem;
    margin-right: $space-unit * 2;
  }

  dd,
  dt {
    break-inside: avoid-column;
  }

  dd {
    margin: ($space-unit * 3) 0 ($space-unit * 6);
  }
}

.sizechart__filters {
  display: flex;
  align-items: center;
  min-height: ($space-unit * 10);
  align-self: baseline;

  .metricselector {
    margin-right: ($space-unit * 12);
    list-style-type: none;
  }
}
