.bag-summary-wrapper {
  position: relative;
  bottom: initial;
}
.bag-summary {
  &__ctas {
    .button {
      height: 4.8rem;
      span {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
  .button-row {
    &__spacing {
      margin-bottom: $space-unit * 2;
    }
  }
}
