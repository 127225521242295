.genius-designer,
.genius-designers {
  @include appearAnimation();
}

.genius-designers__designers-row {
  margin: 1rem 0;
  position: relative;
  height: 12.8rem;
  will-change: transform;
  img,
  a {
    user-select: none;
    -webkit-user-drag: none;
  }
  .designers-row__inner-row {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.genius-designers {
  padding: 10rem 0;
  position: relative;
  overflow: hidden;
}

.single-designer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-right: 4.5rem;
  &.editmode {
    white-space: nowrap;
    overflow: hidden;
  }
}

.single-designer__name {
  font-weight: 800;
  font-size: 16.5rem;
  line-height: 12.8rem;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 9rem;
  opacity: 1 !important;
  transition: none !important;
  img {
    display: block;
    position: absolute;
    top: 0.6rem;
    left: 0;
    height: 87%;
    width: auto;
    * {
      flex-shrink: 0;
      white-space: nowrap;
      display: block;
      line-height: 1;
      position: relative;
      z-index: 1;
    }
  }
}

.genius-designer__title {
  @extend %primary-l-u;
  display: none;
  padding: 2.4rem 1.6rem;
  text-align: center;
}

.single-designer__caption {
  @extend %primary-l;
  font-weight: 300;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  @include animate(0.3);
}

.single-designer__caption-overlay {
  @include animate(0.3);
}

.single-designer__caption-wrapper {
  position: relative;
  margin: 0 2.2rem 0 1rem;
}

.genius-designer__controls {
  @extend %primary-s-u;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;

  &--bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 3.9rem 0 4.1rem;

    .btn--pause {
      position: absolute;
      left: 1.4rem;
    }
  }

  &--inverted {
    button {
      filter: invert(1);
    }
  }
}

.genius-designer {
  &__tiles-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__designer-tile {
    width: 25%;
    display: flex;
    flex-direction: column;
  }
}

.designer-tile {
  text-decoration: none;
  &__name {
    position: absolute;
    left: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    color: #fff;
    @extend %primary-l-u;
  }

  &__img-wrapper {
    position: relative;

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  &__info {
    padding: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  &__description {
    margin-bottom: 1.4rem;
    @extend %primary-xs;
  }

  &__label {
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    display: block;
    @extend %primary-xs-u;
  }

  &__countdown {
    margin-bottom: 2.4rem;
    @extend %primary-l-u;
  }

  &__button.button {
    margin-top: auto;
  }
}
