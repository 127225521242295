.checkout-payment {
  padding: ($space-unit * 2) 0 ($space-unit * 16);

  .accepted-payments {
    flex-grow: 1;
    margin-top: 2.4rem;
    padding: 0 24px 24px 24px;
  }

  &__options {
    margin-bottom: $space-unit * 4;

    &__option {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      text-align: left;

      &:not(:last-child) {
        margin-bottom: $space-unit * 4;
      }
    }

    &__option-name {
      @extend %primary-xs;
    }
  }

  .subtitle {
    margin-bottom: $space-unit * 3;
  }
  .paymentmethods__preorder {
    margin-top: $space-unit * 4;
    margin-bottom: $space-unit * 8;
  }
  > form {
    padding-top: 2.4rem;
    padding-bottom: 3.2rem;
  }

  #component-container {
    // margin-top: 2.4rem;
    width: 100%;
    padding: 0 24px;
  }
  #component-container-button.buttons-disabled {
    > .adyen-checkout__paypal,
    > .adyen-checkout__amazonpay,
    > .adyen-checkout__applepay {
      position: relative;
      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $white;
        opacity: 0.5;
      }
    }
  }
}
