.carousel-slide__inner-slide,
.carousel-slide__caption-holder {
  width: 100%;
  &::before {
    padding-top: 56.25%;
    height: auto;
  }
}

.editorial-carousel {
  .editorial-carousel__carousel-slide {
    &:not(.carousel-slide--text-slide) {
      width: 80rem;
    }
  }
}

.editorial-carousel__swiper-container {
  &::before,
  &::after {
    content: none;
  }
}

.editorial-carousel__arrow {
  display: none;
}

.swiper-slide--mobile-gab {
  display: block;
}

.carousel-slide--text-slide + .swiper-slide--mobile-gab {
  display: none;
}

.carousel-slide__text-holder {
  width: 60.6rem;
}
