.widget-a {
  padding: 3.4rem 0 1.6rem;
  &-content {
    gap: 1.6rem;
  }

  &-title {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  &-subtitle {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  &-caption {
    padding-top: 0.8rem;
    font-size: 1rem;
    line-height: 1.8rem;
  }

}
