.shop-by-look-module {
  > .container {
    padding-top: ($space-unit * 2);
    padding-bottom: ($space-unit * 2);
  }
  .look-products {
    display: flex;
    flex-direction: column-reverse;
  }
  .product-selection {
    padding-bottom: ($space-unit * 5);
    .product-selection__description {
      margin-bottom: ($space-unit * 4);
    }
  }
  .products-selector {
    padding-top: 0;
    padding-bottom: ($space-unit * 6);
  }
  .preview-image .product-image {
    margin: 0 auto;
    width: 90%;
  }
}
