.product-tile {
  @include animate;
  .product-images {
    &__tag {
      position: absolute;
      padding: 0.4rem 0.8rem;
      bottom: 1.6rem;
      top: unset;
      right: unset;
      gap: 0.2rem;
      width: fit-content;
      height: fit-content;

      background: rgba($white, 0.6);
      z-index: 1;
      &_icon {
        width: 1.4rem;
        height: 1.4rem;
      }
      &_label {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.4rem;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
      }
    }
  } 
  &__image-wrapper {
    &>img {
      transform: none;
    }
  }

  &--carousel {
    @extend .product-tile;

    .product-tile__info {
      padding-right: 0;
      padding-left: 0;
      padding-top: 1.6rem;
    }
  }


  &__card {
    position: relative;
    overflow: visible;

    .name {
      display: block;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .collection-in-tile {
      display: none;
    }
    .cta.cta--wishlist {
      margin: 1.2rem;
      padding: 0.4rem;
    }
  }

  &__actions_btn-wrapper {
    display: none;
  }

  &__link {
    display: block;
    transition: none !important;
    opacity: 1 !important;
    text-decoration: none;

    [data-whatinput='keyboard'] & {
      &:focus {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 0 $outline-width $outline-color-inverted;
          @include z;
        }
      }
    }
  }

  &__image {
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    right: initial;
    transform-origin: top center;
    @include animate;
  }

  &__quick-buy-panel {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-left: ($space-unit * 4);
    padding-right: ($space-unit * 4);
  }

  &__info {
    position: relative;
    padding: ($space-unit * 4) ($space-unit * 4) ($space-unit * 6);
  }

  &__name {
    @extend %primary-s;
    @include text-shorten;
    display: block;
    margin-bottom: $space-unit;
  }

  &__price {
    @extend %primary-xs;
    flex-wrap: wrap;

  }

  &__quick-buy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 ($space-unit * 4);
    @include z;

    .button {
      width: auto;
      border: none;
      // border: $space-line solid transparent;
      box-shadow: none;
      padding: 0 $space-unit;
      margin-right: -#{$space-unit};
      margin-bottom: -#{$space-unit};

      &[class] {
        span {
          @extend %primary-xxs;
        }
      }

      &:hover {
        opacity: 0.7;
        // border-color: black;
      }
    }
  }

  .button {
    transition: opacity 166ms $easeOutQuart, color 0.5s $easeOutQuart;
  }

  &.is-look {
    .look-toggle {
      position: relative;
      display: block;
      width: 100%;

      &:focus {
        outline: none;

        &::before {
          content: '';
          @include z;
          border: $space-line solid $white;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          box-sizing: border-box;
        }
      }

      &.no-looks {
        cursor: initial;
      }
    }

    .look-number__wrapper {
      position: absolute;
      display: flex;
      justify-content: space-between;
      padding: ($space-unit * 4);
      bottom: 0;
      left: 0;
      width: 100%;
      @include z;
      pointer-events: none;
    }

    .look-number {
      @extend %primary-xxs;
      color: $white;
    }
  }

  &.quickshopactive {
    background-color: $black;
    color: $white;

    .product-tile__image {
      // height: calc(100% - 8rem);
      width: auto;
      margin: 0 auto;
      // cursor: ew-resize;
    }

    .button {
      color: $white;
    }

    .selector--color {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $space-line;
        background-color: $white_inactive;
      }
    }
  }

  .product-tile:hover {

    .tile-swiper-icon {
      opacity: 1;

      &.swiper-button-disabled {
        opacity: 0.5;
      }
    }

    .cta--wishlist,
    .cta-quick-add-to-bag {
      opacity: 1;
    }

    .variation-color-plp {
      // display: flex;
      opacity: 1;
      visibility: visible;
    }

    .cta-show-colors {
      display: none;
    }
  }

  .tile-swiper-icon {
    opacity: 0;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: 50%;
    transform: translateY(50%);
    z-index: 1;
    bottom: unset;

    &.swiper-button-next {
      right: 1.2rem;
      left: unset;
    }

    &.swiper-button-prev {
      left: 1.2rem;
      right: unset;
    }

    &.swiper-button-prev,
    &.swiper-button-next {
      z-index: 2;
      transform: translateY(-50%);
      width: 2.4rem;
      height: 2.4rem;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  .tile-body {
    padding-top: 0.8rem;
    width: 100%;
    overflow: hidden;

    div,
    p {
      &:not(.price-wrapper,.price) {
        margin: 8px 0;
      }
    }

    .price-wrapper {
      margin: 0.2rem 0 0.8rem;

      .price {
        &-strike {
          margin-right: 0.6rem;
        }
        .discount-value {
          margin-left: 0.6rem;
        }        
      }

    }
  }

  &-labels {
    padding-bottom: 0.8rem;
  }

  .cta--wishlist {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 10px;

    &::after {
      position: static;
      transform: translateY(0);
    }
  }

  .cta-quick-add-to-bag {
    width: 3.2rem;
    height: 3.2rem;
    opacity: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    margin: 1.2rem;
    padding: 0.4rem;
    
    &::after {
      content: '';
      @include fixedSprite('bag-24');
      position: static;
      transform: translateY(0);
    }
  }

  .cta-quick-add-to-bag-variation {
    width: 1.6rem;
    height: 1.6rem;

    &::after {
      position: static;
      transform: translateY(0);
    }
  }

  .variation-color-plp {
    display: flex;
    opacity: 0;
    visibility: hidden;

    .color-show & {
      display: flex;
    }

    img {
      margin-right: 12px;
      height: 40px;

      &.selected {
        border-bottom: 2px solid $red;
      }
    }
  }
}

@keyframes loading-pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}