.expresspayment-modal {
  .expresspayment-modal__intro {
    margin-bottom: $space-unit * 6;
  }
  button {
    display: block;
    margin-top: 0;
    margin-bottom: $space-unit * 3;
    &.dw-apple-pay-button {
      border-radius: 0;
      margin-top: 0;
      margin-bottom: $space-unit * 3;
    }
  }
  .button-payment-container {
    height: 3.6rem;
    // border: $space-line solid get($black, default);
    overflow: hidden;
    margin-top: 0;
    margin-bottom: $space-unit * 3;
    .adyen-checkout__spinner__wrapper {
      display: none;
    }
    .adyen-checkout__paypal {
      margin-left: -0.2rem;
      margin-right: -0.2rem;
      margin-top: -0.1rem;
    }
  }

  #container-paywithgoogle {
    display: none;
  }
}
