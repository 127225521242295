.container--search {
  top: $space-unit * 8;
  position: absolute;
  @include z('filters');
}
#storelocator {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.store-locator {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__wrapper {
    position: relative;
    overflow: hidden;
  }

  &__map {
    position: relative;
    height: $storelocator-height;
  }
  .google-map {
    width: 100%;
    height: 100%;
  }
  .breadcrumb {
    padding-top: 2rem;
    padding-bottom: 1.6rem;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      @include border('top');
    }
    li {
      position: relative;
      padding-right: 2.1rem;
      &::after {
        content: '';
        @include fixedSprite('chevron-right');
        margin: -0.1rem 0.3rem 0;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      &:last-child {
        padding-right: initial;
        &::after {
          content: initial;
        }
      }
    }
    a {
      @include hoverablelink;
      // @extend %primary-xs-u
      text-transform: uppercase;
      color: $black_secondary;
      &[aria-current='page'] {
        color: $black;
      }
    }
  }
}

.search-store {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;

  &__autocomplete {
    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      &::before {
        content: '';
        @extend .icon--search;
        @include center(vertical);
        left: 0.7rem;
        pointer-events: none;
        z-index: 1;
      }
    }
    &.show-list {
      > div {
        @include z;
      }
    }

    input {
      @extend %primary-s;
      position: relative;
      width: 100%;
      height: 100%;
      border: 0;
      background: $white;
      padding: 0 ($space-unit * 3) 0 3.4rem;
      appearance: initial;
      &::selection {
        background: $black;
        color: $white;
      }

      &:focus {
        // z-index: 1;
        outline-offset: -0.2rem;
      }
    }
  }

  // WRAPPER RESULTS/REGIONS/FILTERS
  &__results-filters {
    background: $white;
    height: 3.6rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 ($space-unit * 3);
  }

  // RESULTS LABEL
  &__resultslabel {
    position: relative;

    .alllocations__toggle {
      padding: $space-unit 0;
    }
    i {
      color: $black_secondary;
    }
  }

  // DROPDOWN REGIONS
  &__regions {
    position: absolute;
    background: $white;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    .icon--close {
      position: absolute;
      top: 0.6rem;
      right: 0.6rem;
    }

    &__title {
      height: 3.6rem;
      padding: 1.2rem 1.2rem 0.8rem;
      @include border('bottom');
    }
    .wrapper-regions {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: $space-unit;
      padding: 0 1.2rem;
      @include scrollbar;
      max-height: 70vh;
    }
    .region-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      row-gap: 1.2rem;
      padding: 2.4rem 0;
      p {
        text-transform: uppercase;
        margin-bottom: $space-unit;
      }
      button {
        @include text-shorten;
        display: block;
        max-width: 100%;
      }
    }
  }

  // DROPDOWN STORE RESULTS
  &__resultslist {
    position: absolute;
    background: $white;
    top: 0;
    left: 0;
    right: 0;
    min-height: 3.6rem;

    .icon--close {
      position: absolute;
      top: 0.6rem;
      right: 0.6rem;
      z-index: 1;
    }

    &__menuitem {
      @include border('bottom');
      &:last-child {
        border-bottom: initial;
      }
      &__name {
        @extend %primary-m;
      }
      &__collection {
        @extend %primary-s;
      }
      &__hours {
        margin: ($space-unit * 3) 0;
        display: flex;
        flex-direction: column;
        &__now {
          text-transform: uppercase;
        }
      }
      &__address,
      address {
        padding: ($space-unit * 3) 0;
        display: block;
      }
    }

    [role='menu'] {
      @include reset-list();
      margin-top: 3.6rem;
      min-height: 0.5rem;
      background-color: $white;
      padding: 0 ($space-unit * 3);
      @include border('top');
      @include scrollbar;
      max-height: 50vh;
      [role='menuitemradio'] {
        padding: ($space-unit * 4) 0;
        cursor: pointer;
      }
    }
  }

  // DROPDOWN FILTERS
  &__filters {
    position: absolute;
    @include border('top');
    background: $white;
    top: 3.6rem;
    left: 0;
    right: 0;
    padding: ($space-unit * 6) ($space-unit * 8);

    &__container {
      width: 100%;
      position: relative;
    }

    .storelocator-filters {
      @include reset-list;
      display: grid;
      // grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(4, auto);
      column-gap: 3.8rem;
      row-gap: 1.2rem;
      justify-content: start;
      grid-auto-flow: column;
      justify-items: start;
      align-items: center;
      li {
        position: relative;
        cursor: pointer;
        padding-right: 1.8rem;
        &[aria-checked='true'] {
          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            // margin-top: -0.2rem;
            @include animate;
            @include fixedSprite('checkmark');
          }
        }
      }
    }
  }
}

// TODO move to component
.store {
  background-color: $white;
  max-height: $storelocator-height;
  position: relative;

  a {
    text-decoration: none;
  }

  .icon--close {
    position: absolute;
    right: ($space-unit * 3);
    top: ($space-unit * 3);
    z-index: 1;
  }

  & > div {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    position: relative;
  }

  &__summary {
    padding: ($space-unit * 6) 0 ($space-unit * 8);
    flex-shrink: 0;
    @include border('top');
    @include border('bottom');

    &__name {
      @extend %primary-m;
      margin-bottom: ($space-unit * 4);
    }

    &__hours {
      margin-bottom: ($space-unit * 6);

      &__now {
        text-transform: uppercase;
      }
    }

    &__directions {
      margin-bottom: ($space-unit * 6);
      &.button.get-directions {
        span {
          @extend %primary-s-u;
        }
      }
    }
  }

  &__moreinfo {
    flex-grow: 1;

    @include scrollbar;

    &__section {
      padding: ($space-unit * 4) 0 ($space-unit * 8);
      @include border('bottom');

      .store__summary__directions {
        margin-bottom: 0;
      }

      &:first-child {
        padding-top: ($space-unit * 8);
        padding-bottom: ($space-unit * 8);
      }

      &:last-child {
        border-bottom: none;
      }

      &__title {
        @extend %primary-s;
        margin-bottom: ($space-unit * 4);
      }

      ul {
        @include reset-list;
      }

      .boutique-collections li {
        margin-bottom: $space-unit;
      }
    }
  }
  .instore-service-wrapper {
    li {
      margin-bottom: ($space-unit * 3);
    }
    .instore-service-title {
      @extend %primary-xxs-u;
      margin-bottom: ($space-unit * 2);
    }
  }
}

// stile listbox autocomplete
body:not(#checkout-begin, #account-addressbook) {
  .pac-container {
    box-shadow: none;
    border: none;
    z-index: 2;
    &::after {
      content: initial;
    }
    .pac-item {
      min-height: 3.6rem;
      @include border('top');
      display: flex;
      align-items: center;
      padding: 0 ($space-unit * 3);
    }
    .pac-item,
    .pac-item-query {
      @extend %primary-s;
      color: $black_secondary;
    }
    .pac-item-query {
      .pac-matched {
        color: $black;
        font-weight: normal;
      }
    }
    .pac-icon {
      display: none;
    }
  }
}