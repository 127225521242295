.newsletter-landing {
  &__page {
    margin-top: calc(var(--ticker-height) + 9.6rem + 0.8rem);
    display: flex;
    flex-direction: column;   
    gap: unset;
  }

  &__body {
    padding: 3.2rem 1.6rem 4rem;
    &__form {
      width: 100%;
    }
  }
}