.my-account-sidebar {
  padding-bottom: 0;
  .title {
    margin-bottom: 0.8rem;
  }
  ul {
    padding: 0;
  }
  li {
    list-style: none;
  }
  a {
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 2.4rem 2.4rem 2.4rem 0;
    z-index: 2;
  }

  .sidebar-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0;

    &::after {
      content: '';
      @include fixedSprite('icon-accordion-dw');
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 1;
    }

    &:not(:first-of-type) {
      @include border('top');
    }
  }
}
