.listing-container {
  .widget-plp {
    .widget-a{
      .widget-a-content {
        .widget-a-cta{ 
        }
      }
    }
    
    .widget-b-editorial{
      .img-wrapper{
        video{
          width: 100%;
          height: auto;
          max-width: unset;
          max-height: unset;
          padding: 2.4rem 0;
        }
        img{
          width: 100%;
          height: auto;
          max-width: unset;
          max-height: unset;
          padding: 2.4rem 0;
        }
      }
    }
  }
}
