.checkoutpage {
  &__title {
    margin-top: ($space-unit * 8);
    margin-bottom: ($space-unit * 8);
    text-align: center;
    @extend %primary-l-u;
  }

  .paymentmethods {
    margin-bottom: 6rem;

    .terminalselection {
      margin-top: $space-unit * -2;
      margin-bottom: $space-unit * -2;

      .form-group {
        margin-bottom: 0;
      }
    }

    .radio-wrapper .form-group.boxed-radio .radio {
      display: flex;
      justify-content: space-between;

      input[type='radio'] {
        margin: auto 24px auto 0;
      }

      label {
        max-width: 75%;
      }
    }
  }

  #paymentMethod-error {
    margin-top: ($space-unit * 8); // avoid overlapping with the last option
  }

  .billingAddressForm {
    overflow: hidden;
    @include animate;

    >.row:nth-child(1) {
      margin-top: ($space-unit * 6);
    }

    .form-title {
      margin-top: ($space-unit * 8);
      margin-bottom: ($space-unit * 3);
    }
  }

  .checkout-navigations {
    margin-top: ($space-unit * 6);
  }

  .adyen-checkout__paypal__button {
    margin-bottom: 0;
  }

  .checkout-login {
    padding: 6rem 0;

    &__column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .title {
      font-size: 24px;
      line-height: 32px;

      font-weight: 300;
      text-align: center;
    }

    .subtitle {
      margin-top: ($space-unit * 2);
      margin-bottom: ($space-unit * 6);
      text-align: center;
      font-size: 1.6rem;
      line-height: 1.5;
    }

    .subtitle-credentials {
      margin-top: ($space-unit * 4);
      text-align: center;
      font-size: 1.4rem;
    }

    .pw-error {
      margin-top: -($space-unit * 2);

      .invalid-feedback {
        margin: 0;
        justify-content: flex-end;
      }
    }

    .forgot-pw-error {
      margin-top: -($space-unit * 2);
      display: flex;
      align-items: baseline;
      margin-bottom: ($space-unit * 8);
    }

    .forgot-pw {
      @extend %primary-xxs;
      white-space: nowrap;
      margin-top: ($space-unit * 8);
    }

    .guest-button {
      margin-top: ($space-unit * 2);
    }

    .dev-button {
      margin-top: ($space-unit * 10);
    }
  }

  #component-container-button {

    // display: none;
    >div+button {
      display: none;
    }
  }

  &.review #component-container-button {
    display: block;
  }

  .placeorderdisabled {

    >.adyen-checkout__paypal,
    >.adyen-checkout__amazonpay {
      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $white;
        opacity: 0.5;
      }
    }
  }

  .checkout-privacyflags {
    margin-bottom: 32px;

    .checkbox {
      display: flex;
      align-items: center;
    }
  }

  .divider {
    margin: ($space-unit * 8) 0;
  }

  .form-title {
    font-size: 18px;
    line-height: 22px;

  }

  .form-subtitle {
    font-size: 14px;
    line-height: 16px;

    color: $dark_grey;
    margin-top: ($space-unit * 2);
  }

  .text-section__description {
    font-size: 14px;
    line-height: 22px;

    color: $dark_grey;
  }

  .order-number {
    font-size: 1.8rem;
    line-height: 2.8rem;

    font-weight: 400;
    margin-bottom: .8rem;
  }
}

.option-packaging {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  // justify-content: space-between;
  align-items: baseline;

  &>div {
    width: 100%;
  }

  &__name {
    @extend %primary-s;
    margin-bottom: $space-unit * 5;
  }

  &__img {
    margin: 0 auto $space-unit * 6;
    width: 100%;
    height: auto;
  }

  &__detail {
    padding-top: 0;
    padding-bottom: 0;
    @extend %primary-xxs;
  }
}

.option-card,
.option-address {
  min-height: 6rem;

  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;

  &__name {
    @extend %primary-s;
    margin-bottom: $space-unit * 2;
    display: flex;
    align-items: center;
    flex: 1;

    [class^='icon'] {
      position: relative;
      @include fix-font-space;
      margin-left: $space-unit * -2;
    }
  }

  &__association {
    @extend %primary-s;
    margin-bottom: $space-unit * 2;
    flex: 1;
  }

  &__detail {
    flex: 100%;

    p {
      @extend %primary-xs;
    }
  }
}

#checkout-begin {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  header {
    top: initial;

    &.rt-iw__heading {
      position: relative;
    }

    .navigation {
      height: auto;
      padding-top: 16px;
      padding-bottom: 16px;
      justify-content: space-between;
      align-items: center;
    }

    .checkout-steps {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;
      border-bottom: 4px solid #ddd;
      position: relative;
      flex-direction: row;

      &.hide {
        display: none;
      }

      &[data-step='1'] {
        &::before {
          content: '';
          height: 4px;
          width: 0%;
          background-color: #EB2B35;
          z-index: 1;
          position: absolute;
          bottom: -4px;
        }

        .step:nth-child(1) {
          color: #232323;

          &::before {
            bottom: -14px;
            background-color: #232323;
          }
        }

        .step:not(:nth-child(1)) {
          &::before {
            width: 4px;
            height: 4px;
          }
        }
      }

      &[data-step='2'] {
        &::before {
          content: '';
          height: 4px;
          width: 50%;
          background-color: #EB2B35;
          z-index: 1;
          position: absolute;
          bottom: -4px;
        }

        .step:nth-child(2) {
          color: #232323;

          &::before {
            bottom: -14px;
            background-color: #232323;
          }
        }

        .step:not(:nth-child(2)) {
          &::before {
            width: 4px;
            height: 4px;
          }
        }
      }

      &[data-step='3'] {
        &::before {
          content: '';
          height: 4px;
          width: 100%;
          background-color: #EB2B35;
          z-index: 1;
          position: absolute;
          bottom: -4px;

          .step:nth-child(3) {
            color: #232323;

            &::before {
              bottom: -14px;
              background-color: #232323;
            }
          }

          .step:not(:nth-child(3)) {
            &::before {
              width: 4px;
              height: 4px;
            }
          }
        }
      }

      .step {
        position: relative;
        font-size: 12px;
        line-height: 16px;

        color: #6f6f6f;
        text-transform: capitalize;

        &::before {
          content: '';
          position: absolute;
          bottom: -12px;
          width: 8px;
          height: 8px;
          background-color: #6F6F6F;
          z-index: 1;
        }

        &:nth-child(2) {
          &::before {
            left: 50%;
            transform: translateX(-50%);
          }
        }

        &:nth-child(3) {
          &::before {
            right: 0;
          }
        }

        &:disabled {
          cursor: initial;
        }
      }
    }
  }
}

.checkout-container {
  min-height: 50vh;
  flex-grow: 1;

  .checkout__left {
    background-color: #f7f7f7;
    padding-top: 6rem;
    padding-bottom: 12rem;
  }
}

.checkoutpage .checkout__left {
  padding-right: 6.1rem;
}

.checkout-footer {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 24px;
    text-align: center;
  }

  .legals {
    line-height: 22px;

    color: #6f6f6f;
    padding-bottom: 16px;
  }
}

.savedcards {
  width: 100%;
  margin-top: 10px;
  padding: 0 24px;

  .radiobox {
    height: auto;
    border: none;

    .radiobox__option--no-outline {
      height: 100%;
      padding-right: 2px;

      .option-card {
        border: 1px solid #ccc;
        padding: 10px;
        height: 100%;
      }
    }
  }
}

.accepted-payments {
  display: flex;

  i {
    width: 3.5rem;
    height: 2.4rem;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.shipping-box {
  .shipping-headings {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.4rem;

    h3,
    button {
      display: flex;
      align-items: center;
    }

    button {
      font-size: 12px;
      font-weight: bold;

      i {
        margin-right: ($space-unit * 2);
      }
    }

    h3 {
      font-size: 18px;
      line-height: 22px;

      i {
        margin-right: ($space-unit * 4);
      }
    }
  }

  .shippinginfo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;

    &.column {
      display: flex;
      flex-direction: column;
    }

    h4 {
      font-size: 16px;
      line-height: 20px;

      margin-bottom: $space-unit;
    }

    p {
      font-size: 14px;
      line-height: 22px;

      color: $dark_grey;
    }
  }
}

.bag-review {
  list-style: none;
  padding: 0;
}

.accordion-mini__panel>.bag-review {
  padding-top: 2.4rem;
}

// NEW SCSS
.card.ghost {
  opacity: 0.5;
}

.arrival-time {
  white-space: pre;
}

.billing-address {
  display: block;
}

.billing-invoice-type,
.billing-address,
.shipping-method-radio-button-container,
.customer-information-block,
.billing-invoice-fields {
  .row {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
}

.checkout-checkbox {
  font-size: 0.875em;
}

.customer-information-block .btn-link {
  padding: 0;
  vertical-align: baseline;
}

.edit-button,
.btn-show-details,
.btn-add-new {
  float: right;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.edit-button {
  font-size: 1.4rem;
  line-height: 1.4rem;

  position: absolute;
  right: 2.4rem;
  top: 3rem;
  display: flex;
}

.error-message {
  display: none;
}


.multi-ship .multi-shipping {
  display: block;
}

span.ship-to-name,
span.ship-to-address1,
span.ship-to-address2,
span.ship-to-phone,
span.ship-to-city-st-zip {
  display: block;
}

.data-checkout-stage {

  // Initial states ------------------------
  &[data-checkout-stage] {

    .card.payment-summary,
    .shipping-summary {
      display: none;
    }

    button.place-order {
      display: none;
    }

    button.submit-payment {
      display: none;
    }

    button.submit-shipping {
      display: none;
    }
  }

  // Customer ------------------------------
  &[data-checkout-stage=customer] {
    .card.ghost.customer {
      display: none;
    }

    .card.customer-summary {
      display: none;
    }

    .card.shipping-section {
      display: none;
    }

    .card.payment-form {
      display: none;
    }

    button.submit-customer {
      display: block;
    }
  }

  // Shipping ------------------------------
  &[data-checkout-stage=shipping] {

    button.submit-customer {
      display: none;
    }

    .card.ghost.customer {
      display: none;
    }

    .card.ghost {
      display: none;
    }

    &.multi-ship .order-product-summary {
      display: none;
    }

    .card.payment-form {
      display: none;
    }

    button.submit-shipping {
      display: block;
    }

    .shipment-selector-block {

      .btn-show-details,
      .btn-add-new {
        border: none;
        display: flex;
        align-items: center;
        gap: 0.8rem;
      }
    }

    [data-address-mode=customer] {
      .shipping-address-block {
        &:not(.is-store-pickup) {
          display: none;
        }
      }
    }

    [data-address-mode=shipment] {
      .shipping-address-form {
        display: none;
      }
    }

    [data-address-mode=edit] {
      .shipping-address-block {
        &:not(.is-store-pickup) {
          display: none;
        }
      }
    }

    // [data-address-mode=new] {
    //   .btn-show-details,
    //   .btn-add-new {
    //     display: none;
    //   }
    // }
  }

  // Payment -------------------------------
  &[data-checkout-stage=payment] {
    button.submit-customer {
      display: none;
    }

    .customer-section,
    .shipping-section,
    .card.ghost {
      display: none;
    }

    .card.payment-form,
    .shipping-summary {
      display: block;
    }
    
    .checkout-continue-buttons {
      .googlepayBtn__container {
        #gpay-button-online-api-id{
          width: 100%;
          .gpay-card-info-animation-container-fade-out {
            animation-duration: 0s;
          }
        }
      }
    }

    button.submit-payment {
      display: block;
    }

    .address-selector-block {

      .btn-show-details,
      .btn-add-new {
        border: none;
      }
    }

    [data-address-mode=customer] {
      .billing-address {
        display: none;
      }
    }

    [data-address-mode=shipment] {
      .billing-address {
        display: none;
      }
    }

    [data-address-mode=edit] {
      .billing-address {
        display: none;
      }
    }

    [data-address-mode=new] {

      .btn-show-details,
      .btn-add-new {
        display: none;
      }
    }

    [data-address-mode=details] {

      .btn-show-details,
      .btn-add-new {
        display: none;
      }
    }
  }

  // Place Order -----------------------------
  &[data-checkout-stage=placeOrder] {

    button.submit-customer,
    .customer-section,
    .shipping-section,
    .card.payment-form,
    .card.ghost {
      display: none;
    }

    .card.payment-summary,
    .shipping-summary {
      display: block;
    }

    button.place-order {
      display: block;
    }
  }

  &[data-checkout-stage=submitted] {

    .shipping-form,
    .card.payment-form,
    button.submit-customer,
    .card.ghost {
      display: none;
    }

    .summary-section-label.shipping-addr-label {
      display: none;
    }

    .card.payment-summary,
    .shipping-summary {
      display: block;
    }

    button.place-order {
      display: none;
    }
  }

  h5>span {
    font-size: 0.8em;
  }
}

.shipping-method-radio-button-container .row.storePickup-explanation {
  margin-bottom: 2.4rem;
}

#checkout-main .card-body.shipping-content .customer-summary.logged-user {
  background: $white;
  border: 1px solid #EEEEEE;
  padding: 2.4rem;
  border-radius: .8rem;
  position: relative;
  margin: 0 0 2.4rem 0;

  .customer-information-block {
    .row {
      margin-bottom: 0;
    }
  }
}

#shipping-container-form-block {
  .shipment-selector-block {
    .row {
      button {
        margin-right: 2.4rem;

        .edit-label {
          font-weight: 700;
        }
      }
    }
  }

}

.shipping-summary {
  .card-body {
    background: $white;
    padding: 2.4rem;
    border-radius: .8rem;
    position: relative;

    .edit-label {
      font-size: 1.2rem;
      line-height: 2.4rem;
      font-weight: 700;
    }

    &:not(:last-child) {
      margin: 0 0 1.6rem 0;
    }

    &:last-child {
      margin: 0 0 4rem 0;
    }

    .edit-button {
      .icon--edit {
        width: 1.4rem;
        height: 1.4rem;
      }

      p {
        font-size: 1.2rem;
      }
    }

    .line-item-divider {
      margin: 2.4rem 0;
      border: 1px solid $black;
      border-width: 1px 0 0 0;
      /* background: $black;
      background-color: $black; */
      height: 1px;
    }

    .summary-details.shipping {
      margin-top: .4rem;
    }
  }
}

[data-customer-type=guest] .single-shipping .shipment-selector-block {
  display: none;
}

.single-shipping .multi-ship-action-buttons {
  display: none;
}

.single-shipping .view-address-block {
  display: none;
}

.btn-show-details {
  padding-top: 0;
}

.multi-ship-address-actions .btn-save-multi-ship {
  margin-left: 10px;
}

.checkout-footer {
  border-top: 2px solid $red;

  .container {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .legals {
    padding-bottom: 0;
    max-width: 40%;
  }
}

.checkoutpage {
  padding-left: 0;
  padding-right: 0;

  >.row {
    margin-left: 0;
    margin-right: 0;

    >[class^='col-'] {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}

.info-tooltip {
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;

  &[data-tooltip]:before {
    transform: translate(-75%, 0);
    max-width: 25rem;
    bottom: 3.2rem;
  }

  &[data-type^="storedCard"] {
    display: none;
  }
}

#checkout-main {
  .radio input[type='radio'] {
    &::after {
      content: none;
    }

    &:checked {
      &::after {
        content: none;
      }

      &::before {
        content: "";
        @include fixedSprite('check');
        margin-top: -2px;
      }
    }
  }

  .checkbox {
    display: flex;
    align-items: center;

    .policy-payment {
      padding-left: 2.4rem;
    }

    &.condition-sale-list-wrapper {
      flex-wrap: wrap;
      align-items: center;

      .invalid-feedback {
        width: 100%;
        padding-left: 2.4rem;
      }
    }

    input {

      &[type=checkbox],
      &.radioButton[type=radio] {
        background: white;
        width: 1.3rem;
        height: 1.3rem;
        position: absolute;
        top: 0.5rem;

        +label {
          padding-left: 2.4rem;
        }

        &:before {
          content: "";
          @include fixedSprite('checkmark-radio-empty');
          background-color: $white;
          transform: translate(-50%, -50%);
          position: absolute;
          left: 50%;
          top: 50%;
        }

        &:checked {
          background: #232323;

          &::after {
            @include fixedSprite('checkmark-radio-new');
          }
        }
      }
    }
  }

  .radio {
    margin-bottom: 0;
    align-items: center;

    &_card {
      align-items: start;
    }
  }

  .customer-information-block .row {
    margin-bottom: 3.2rem;
  }

  .form-group {
    position: relative;
    background: transparent;
    margin-bottom: 0;

    input::placeholder {
      color: transparent;
    }

    input:not([type=radio]):placeholder-shown,
    input:not([type=radio]):not(:placeholder-shown):is(:focus) {
      padding: 2.4rem 1.2rem 0.6rem;
    }

    input:not([type="radio"]):placeholder-shown+label {
      cursor: text;
      max-width: 66.66%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform-origin: left bottom;
      transform: translate(0, 0) scale(1);
    }

    input::-webkit-input-placeholder {
      opacity: 0;
      transition: inherit;
    }

    input:focus::-webkit-input-placeholder {
      opacity: 1;
    }

    input:not([type="radio"]):focus+label {
      transform: translate(0, -1.2rem) scale(0.9);
      cursor: pointer;
    }

    input:not([type="radio"]):focus {
      border: 1px solid $black;
    }

    input:not(:placeholder-shown):not(:focus):not([type="radio"])+label {
      transform: translate(0, -1.2rem) scale(0.9);
      opacity: 0;
    }

    input:not([type="radio"])+label {
      position: absolute;
      left: 0;
      top: 0;
      padding: 1.6rem 1.2rem;
      background: transparent;
      transition: all 0.4s;
      transform-origin: left bottom;
      color: $dark_grey;
      user-select: none;
      cursor: text;
      pointer-events: none;
    }

    input:not([type="radio"]) {
      -webkit-appearance: none;
      color: $black;
      width: 100%;
      padding: 1.5rem 1.2rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
      transition: all 0.4s $easeOutQuart;
      background: white;
      border: 1px solid $grey_line;
      border-radius: 0.8rem;

      &:disabled {
        color: $dark_grey;
        background: $light_grey;
      }
    }

    input::-webkit-date-and-time-value {
      text-align: left;
    }

    @media screen and (max-width: 1024px) {
      input[type='date'][value='']:empty:not(:focus):after {
        color: $dark_grey;
        position: absolute;
        left: 0;
        min-height: 2rem;
        padding: 1.5rem 1.2rem;
        font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
        font-size: 1.4rem;
        line-height: 1.8rem;
  
      }
    }
  
    input[type='date'][value='']:empty {
      &::-webkit-datetime-edit-ampm-field,
      &::-webkit-datetime-edit-day-field,
      &::-webkit-datetime-edit-hour-field,
      &::-webkit-datetime-edit-millisecond-field,
      &::-webkit-datetime-edit-minute-field,
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-second-field,
      &::-webkit-datetime-edit-week-field,
      &::-webkit-datetime-edit-year-field,
      &::-webkit-datetime-edit-text,
      &::-webkit-datetime-edit {
        color: transparent;
      }
    }
  }

  .box {
    box-shadow: none;
    border: 1px solid #EEEEEE;
  }

  .cart-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .form-wrapper {
      background-color: $light_grey;

      .checkout-continue-buttons {
        button {
          font-weight: 700;
        }
      }

      .shipping-section {
        .customer-summary {
          .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .login-cta {
              display: flex;
              align-items: center;
              gap: 0.8rem;
              text-align: end;

              a {
                min-width: fit-content;
              }
            }
          }
        }

        .shipping-address {
          .shipping-method-radio-button-container {
            background-color: $white;
            border: 0.1rem solid transparent;
            border-radius: 0.4rem;
            padding: 2.4rem;

            &.checked {
              border: 1px solid $black;
            }

            &:not(.checked) #shipping-container-form-block {
              display: none;
            }

            .shipping-store-address-block {
              display: flex;
              flex-direction: column;


              .form-store-pickup-selector {
                order: 1;
              }

              .radiobox {
                order: 3;
              }

              .row-pickup-point-container {
                order: 2;
              }
            }
          }
        }
      }

      .bag-login-slot {
        padding: 1.6rem;
        background-color: $white;
        margin-bottom: 3.2rem;

        &-title {
          margin-bottom: 0.8rem;
          font-size: 1.4rem;
          line-height: 2.2rem;
          font-weight: 300;
        }

        .enhanced-checkout-login-button {
          font-size: 1.2rem;
          line-height: 1.6rem;
          font-weight: 600;
        }
      }
    }

    .totals-wrapper {
      width: 42.7083vw;
      padding: 4rem 19.5rem 12rem 10.8rem;
      height: calc(100vh - var(--checkoutFooterHeight) - var(--checkoutHeaderHeight));
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .order-product-summary {
        .product-summary-block {
          .item-image {
            width: 100%;
            height: fit-content;
          }

          .item-attributes {
            .attributes {
              padding-top: 1.6rem;
            }
          }
        }
      }

      hr {
        margin: 2rem 0;
        background-color: #EEEEEE;
        border-color: #EEEEEE;
        color: #EEEEEE;
        height: .1rem;
        border: 0;
      }

      .order-total-summary {
        background-color: $white;
        margin: 0 0 1.6rem 0;

        &-price {
          border-top: 0.1rem solid $black;
          padding-top: 1.6rem;

          strong,
          p {
            font-weight: 700;
          }
        }

        [data-pp-message] {
          justify-content: start;
        }

        h2 {
          font-size: 1.8rem;
          line-height: 2.6rem;
          border-bottom: 0.1rem solid $black;
          padding-bottom: 1.6rem;
        }

        .spaceBetween {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          flex-direction: row;
        }
      }

      .order-product-summary {
        .card-body {
          border: 0;
          border-radius: unset;
          margin: 0;

          .accordion__header {
            padding: 2.4rem 0 1.6rem;
            border-top: 0;
            border-bottom: 0.1rem solid $black;
            margin-bottom: 0.8rem;

            &[aria-expanded="true"] {
              border-bottom: 0;
            }

            span {
              font-size: 1.8rem;
              line-height: 2.6rem;
              font-weight: 400;
            }
          }

          .accordion__panel {
            overflow: visible;
          }
        }
      }

      /* .card-body {
        border: 1px solid #EEEEEE;
        border-radius: 0.8rem;
        margin-bottom: 1.6rem;

        .T6 {
          font-size: 1.8rem;
        }

        .accordion__header {
          padding: 2.4rem;
          border-top: 0;

          &[aria-expanded="false"]+.accordion__panel {
            opacity: 0;
            margin: 0 2.4rem;
          }
        }

        .accordion__panel {
          border-bottom: 0;
          margin: 0 2.4rem 0;

          .product-line-item {
            &:is(:last-child) {
              .line-item-divider {
                display: none;
              }
            }

            .product-line-item-details {
              display: grid;
              grid-template-columns: 30% 1fr;
              gap: 1.6rem;

              .line-item-name {
                margin-bottom: .4rem;
              }

              .item-attributes {
                .attributes {
                  display: flex;
                  flex-direction: column;
                }
              }
            }
          }
        }
      } */
    }
  }

  [id^="zoid-paypal-buttons"] {
    max-width: 100% !important;
  }

  #buttons-container .paypal-button-container {
    max-width: 100% !important;
  }
}

// ADYEN CHECKBOX
.paymentMethod {
  .adyen-checkout__checkbox__input {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:checked {
      +.adyen-checkout__checkbox__label {
        &:before {
          opacity: 1;
        }

        &:after {
          border: 1px solid $black;
          background-color: $black;
        }
      }

      &:hover+.adyen-checkout__checkbox__label:after {
        border-color: $black;
      }
    }

    &:focus+.adyen-checkout__checkbox__label:after {
      border: 1px solid $black;
    }


    &:hover:not(:focus)+.adyen-checkout__checkbox__label:after {
      border-color: #99a3ad;
      box-shadow: 0;
    }
  }
}