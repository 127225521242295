.modal.sizeguide-pdp-modal {
  .sizeGuide {
    &Modal {
      &__wrapper {
        &_levels {
          position: sticky;
          top: 0;
        }

        &_body {
          max-height: min(calc(80vh - 24.4rem + 2.2rem + 3.2rem), calc(66.4rem - 24.4rem + 2.2rem + 3.2rem));
        }
      }
      &--smaller {
        .sizeGuideModal__wrapper {
          &_body {
            max-height: min(calc(80vh - 24rem), calc(66.4rem - 24rem));
          }
        }
        
      }
    }
    
    &Measure {
      &__card {
        display: grid;
        grid-template-columns: 25% 75%;
      }
    }
  }
}

