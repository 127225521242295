.sizevariation-modal {
  &.modal {
    padding: 2.4rem;
  }

  &.whishlist-selectsize,
  &.shopTheLookModal-selectsize,
  &.quickview-selectsize{
    &.modal {
      min-width: 46rem;
      .sizevariation-product {
        &__modelInfo {
          display: none;
        }
        &__color {
          color: $dark_grey;
        }
        &__content {
          justify-content: space-between;
        }
      }
    }
  }
}
