.hero-carousel {
  &__slider {
    width: 100%;
    background-color: $black;
    .hero__hero-column::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 20%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
      z-index: 5;
      pointer-events: none;
    }
  }

  .swiper-pagination--bars {
    position: absolute;
    bottom: ($space-unit * 10);
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 16rem);
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 6rem;
      width: 100%;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
    }
    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.25);
      z-index: 2;

      &.swiper-pagination-bullet-active {
        background: $white;
      }
    }
  }
  .hero--center-bottom {
    .hero-column__inner-container {
      padding-bottom: 9rem;
    }
  }

  .swiper-slide {
    transition-property: all;
    transition-timing-function: linear;
    transition-delay: 0s;
    transition-duration: 400ms !important;
    .hero-column__headline,
    .hero-column__cta {
      transform: translate3d(-2.35vw, 0, 0);
      // opacity: 0;
      transition: transform 0.4s $pseudoInQuart, opacity 0.4s linear;
    }
    .hero-column__img {
      @include animate(0.4, 'opacity', 'linear');
      // opacity: 0;
    }
  }

  .swiper-slide-active {
    transition-delay: 400ms;
    .hero-column__headline,
    .hero-column__cta {
      transition-duration: 0s;
      transform: translate3d(2.35vw, 0, 0);
    }
    &.swiper-slide--custom-active {
      z-index: 4;
      .hero-column__headline,
      .hero-column__cta {
        transition: transform 0.4s 0.4s $pseudoOutQuart, opacity 0.4s 0.4s linear;
        transform: translate3d(0, 0, 0);
        // opacity: 1;
      }
      .hero-column__img {
        transition-delay: 0.4s;
        // opacity: 1;
      }
    }
  }
  .resizing {
    .swiper-slide {
      transition: initial !important;
    }
  }
}

.reveal-slider {
  .swiper-pagination,
  .hero-column__cta,
  .hero-column__headline,
  .hero-column__img {
    opacity: 1;
  }
}
