.lookbook-component {
  .product-carousel__headline-hover {
    display: none;
  }
  .swiper-slide {
    @include animate();
  }
  .product-carousel__carousel-container {
    &.carousel-container--intro-cards {
      padding: 0 !important;
    }
  }
}
