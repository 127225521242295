.product-labels {
  &__text {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.8rem;
    letter-spacing: 0.04rem;
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } 
}

.product-editorial {
  color: $blue_editorial;
}

.promo-text {
  display: flex;
  align-items: center;

  &__label {
    color: $red_primary;
  }
} 