#minibag-container {
  display: none;
  .overlay {
    pointer-events: all;
  }
  .minibag {
    top: 100%;
    width: 46%;
    padding: $space-unit * 4;
    padding-top: 2.2rem;

    &::after {
      top: 0;
    }
    &__title {
      padding-bottom: 1.4rem;
      > p {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
  }
}

.minibag {
  &__product {
    &__promo,
    &__editorial {
      padding-top: 0.6rem;
    }

    &__info {
      margin-top: 0.6rem;
    }
  }
}
