.modal__close {

  &.modal__close--inverted-sm {
    &::before {
      @include fixedSprite('close-white');
    }
  }
}
.country-modal {
  .accordion__panel {
    ul,
    ol {
      padding-bottom: ($space-unit * 10);
    }
  }
}
.modal {
  .withimage {
    &__body {
      grid-template-columns: 100%;
    }
    &__left {
      display: none;
    }
  }
}

.button-row {
  margin-top: ($space-unit * 12);
}

.checkbox {
  margin-top: ($space-unit * 6);
  display: flex;
  align-items: center;
}
