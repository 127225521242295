.order-detail-page {
  .order-footer {
    grid-template-columns: 1fr 1fr;
    row-gap: 1.6rem;
    .order-footer__tracking {
      grid-column-start: 1;
      grid-column-end: 3;
      margin-top: 0.8rem;
    }
    .order-downloadBill {
      grid-column: span 2;
      width: fit-content;
      justify-self: center;
    }
  }
}
