.quickview-modal .tingle-modal-box{
  height: auto;
  bottom: 0;
  top: unset;
  margin-bottom: unset;
  width: 100%;
  border-radius: 0;
  max-width: unset;

  .sizevariation-modal.modal--small.product-variant .product-selection__wrapper{
    min-width: unset;
  }

  .tingle-modal-box__content .quickview-content{
    padding: 0;

    .selector__menu{
      padding: 0;

      .size-selector-label-wrapper{
        border: 0;

        .size-default-wrapper{
          display: none;
        }

        .custom-select.select-size{
          margin: 0 1.2rem;
        } 
      }
    }
  }
}

.quickview-modal .findinstore-wrapper.tingle-modal-box{
  height: 85%;
}

.cart-remove-product .tingle-modal .tingle-modal-box{
  max-width: 90vw;
}

.header-enhanced-login .tingle-modal-box {
  margin: auto 0 0;
  max-width: unset;

  &__content {
    .modal {
      &-header {
        .modal__close {
          padding: 2.4rem;
        }
      }
      &-body{
        margin: 2.4rem 0;

        .login {
          &__btn {
            margin-top: 0;
          }
        }
      }

      &-title {
        margin-bottom: 3.2rem;
      }

      &-content{
        margin: 0 2.4rem;
      }

      &-footer{
        margin: 2.4rem 0;
      }
    }
  }
}