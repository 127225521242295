.refine {
  .modal__close {
    position: relative;
    display: block;
    padding: 1rem;
    right: -1rem;
  }

  >.container {
    .refine__button {
      display: none;
    }
  }

  &.expanded {
    @include z(modal);
  }

  .refine__options {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    flex-direction: column;
    display: flex;
    background: $white;
    opacity: 0;
    visibility: hidden;
    @include animate(0.15);

    &.is-visible {
      opacity: 1;
      height: 100%;
    }

    >.container {
      flex-direction: column-reverse;
      align-items: baseline;
      height: auto;
      max-height: calc(100% - 12rem);
      padding-bottom: 3.2rem;
      overflow-y: auto;
    }
  }

  .refine__footer,
  .refine__header {
    height: $header-height-mobile;
    width: 100%;
    display: block;

    >.container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
  }

  .refine__header {
    @include border('bottom');

    &__title {
      font-size: 1.8rem;
      line-height: 2.2rem;

      padding-top: 2.2rem;
      padding-bottom: 1.6rem;

      sup {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }
  }

  .refine__footer {
    position: absolute;
    bottom: 0;
    @include border('top');
  }
}