.product-tile {
  &__card {
    .collection-in-tile {
      display: block;
      position: absolute;
      top: ($space-unit * 4);
      left: ($space-unit * 4);
      @include z;
      span[class^='icon--'] {
        height: 3rem;
        width: 2rem;
      }
      &:focus {
        outline-color: $white;
        outline-offset: 2px;
      }
    }
  }
  &__info {
    padding: ($space-unit * 4) ($space-unit * 4) ($space-unit * 8);
  }
  &__quick-buy {
    .button {
      height: ($space-unit * 6);
      background-color: transparent;
    }
  }
}
