.product-selection {
  // padding-top: ($space-unit * 20);

  .product-selection__actions-shipping {
    .button {
      height: auto;
    }
  }

  .product-selection__modelinfo {
    position: absolute;
    bottom: $space-unit * 8;
    margin-bottom: 0;
  }

  &__size {
    &_btn {

      transition: transform 0.5s ease-in-out;
      transform: translateX(calc(100% - 1.6rem));
    }
  }

  &__wrapper {
    .selector {
      &__header {
        padding-top: 1.6rem;        
      }
      &__menu {
        margin-top: 0.8rem;
      }

      &__labelFew {
        margin-top: 0.8rem;
      }

    }
  }
}
