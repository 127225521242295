.listing-container {
  .widget-plp {
    grid-column: span 12;
  }
  
  .grid-container {
    &.grid-1 {
      .widget-plp {
        display: none;
      }
    }
  }
}
