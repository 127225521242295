.modal {
  min-width: 80%;
  .dialog__description {
    width: 55%;
  }

  &.modal--small {
    @include modal-width(6);
  }

  &.size-modal {
    min-height: 62.4rem;
    height: 72vh;
    width: 64vw;
  }
}

.ReactModal__Body--open {
  overflow: hidden !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0 !important;
}

.modal__close {
  padding: 3rem 2.4rem;
}

.country-modal {
  .card-header {
    line-height: 1.6rem;
    margin-bottom: ($space-unit * 2) + 2;
  }
  .col-md-6 {
    ul,
    ol {
      column-count: 2;
    }
  }
}
