.filters__menu {
  ul {
    row-gap: $space-unit * 2;
  }
  &--size {
    ul {
      grid-template-columns: repeat(12, 1fr);
    }
  }
  &--size-grouped {
    padding-top: $space-unit * 2;

    ul {
      @include col-width(4);
      column-count: 3;

      li {
        margin-bottom: $space-unit;
      }
    }
  }

  &--refinementColor {
    ul {
      grid-template-columns: repeat(12, 1fr);
    }
  }
}
