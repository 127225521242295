.section-menu {
  display: flex;
  flex-direction: column;
  &[data-expanded='false'] {
    li:not(.selected) {
      display: none;
    }
  }
  &[data-expanded='true'] {
    .selected {
      &::after {
        transform: rotate(0);
      }
    }
  }
  li {
    position: relative;
    color: $black;
    display: flex;
    align-items: center;
    padding-right: 0;
    a {
      opacity: 1;
      padding: 1.8rem 0 1.4rem;
      width: 100%;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100vw;
      height: $space-line;
      bottom: 0;
      background: $grey_line;
      left: 50%;
      transform: translateX(-50%);
    }
    &:not(:last-child) {
      margin-bottom: 0;
    }
    &.selected {
      order: -1;
      &::after {
        content: '';
        position: absolute;
        right: -($space-unit * 2);
        top: 1.4rem;
        @include fixedSprite('chevron-up-small');
        transform: rotate(180deg);
        background-size: 120%;
      }
    }
  }
}
