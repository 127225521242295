.sizevariation-modal {
  .modal__close {
    display: none;
  }

  &.whishlist-selectsize,
  &.quickbuy-selectsize,
  &.shopTheLookModal-selectsize,
  &.quickview-selectsize  {
    padding: 0.8rem 1.65rem 3.2rem;
    overflow: visible;
    border-radius: 0.8rem 0.8rem 0 0;

    .modal {
      &__header {
        .dialog__title {
          font-size: 2.4rem;
          font-weight: 300;
          line-height: 3.2rem;
          justify-content: center;
          padding: 2.4rem 0;
        }

        &::before {
          content: '';
          background-color: $grey_line;
          width: 8rem;
          height: 0.2rem;
          border-radius: 1.6rem;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
        }
      }

      &__body {
        padding: 0;
        .product-selection {
          &__size {
            position: absolute;
            right: 1.6rem;
            padding: 1.6rem;
            background: $white;
            border-radius: 0.8rem;
            top: -6.8rem;

            &_btn {
              &_icon {
                width: 1.6rem;
                height: 1.6rem;
              }
            }
          }

          &__wrapper {
            .selector {
              &__header {
                display: none;
              }
              &__menu {
                margin-top: 1.6rem;
              }
            }
          }
        }

        .sizevariation-product {
          padding: 0;
          &__name-price__name {
            font-size: 1.4rem
          }
          &__modelInfo {
            width: 100%;
            &_label {
              font-size: 1.2rem;
              line-height: 2rem;
              text-align: center;
            }
          }
          &__color {
            font-size: 1.2rem;
            line-height: 2rem;
            color: $dark_grey;
          }
        }
      }
    }
  }

  &.shopTheLookModal-selectsize,
  &.quickview-selectsize  {
    .modal {
      &__header {
        .dialog__title {
          display: none;
        }
      }
      &__body {
        padding-top: 2.4rem;

        .sizevariation-product {
          flex-direction: column;
          gap: 4rem;
        }
      }
    }
  }
  


  &.modal--small.product-variant{
    padding: 2.4rem 1.6rem 3rem;
    .product-selection__wrapper {
      min-width: 44rem;
      .selector-size-expanded {
        border-top: none;
        .selector-label{
          font-size: 1.6rem;
        }
        .selector__menu {
          padding: 2rem 0 5rem;
        }
      }
    }
  }
}
