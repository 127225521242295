.product-notify {
  display: flex;
  flex-direction: column;

  &__main {
    display: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-height: 90vh;

    .product-image {
      img {
        width: auto;
        margin: 0 auto;
      }
    }
  }

  &__detail {
    flex: 1;
    padding: 2.2rem $space-unit * 4;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow: auto;

    &__form {
      position: relative;
      height: 100%;

      form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    fieldset {
      margin-top: 0;
      &::before {
        display: none;
      }
    }

    button {
      margin-bottom: $space-unit * 4;
    }

    &__confirm {
      margin-top: $space-unit * 10;

      div.divider {
        margin-top: $space-unit * 8;
        margin-bottom: $space-unit * 4;
        @include border('top');
        height: $space-line;
      }
    }

    &__resend-prompt {
      margin-bottom: $space-unit * 2;
    }
  }
}
