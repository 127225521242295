.order-tile {
  margin-bottom: 16px;
  .order-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;
  }
  .order-footer {
    p {
      @extend %primary-xxs;
    }
    &__totals {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 4px;
    }
    &__tracking {
      align-content: center;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.order-detail-page {
  .order-heading {
    margin-bottom: 2.4rem;

    &__title {
      @extend %primary-xl;
    }
  }
  .order-downloadBill {
    line-height: 1.6rem;
    align-self: center;
    height: fit-content;
    font-weight: 500;
    border-bottom: 0.1rem solid $black;
    text-decoration: none;
  }
}
.order-items {
  border-bottom: 1px solid $grey_line;
  margin-bottom: 16px;
  .order-item {
    display: flex;
    margin-bottom: 16px;
    &__name {
      @extend %primary-s;
    }
    &__image {
      height: auto;
      flex-basis: 15rem;

      img {
        width: 100%;
      }
    }
    &__price {
      margin-top: 8px;
      margin-bottom: 8px;
      @extend %primary-xxs;
    }
    &__details {
      color: $dark_grey;
      p {
        @extend %primary-xxs;
      }
    }
    .order-item__content {
      padding-left: 16px;
      flex: 1;
    }
  }

  .checkbox {
    margin-top: 0;
  }
}

.order-detail-page {
  .order-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2.4rem;
    p {
      display: flex;
      flex-direction: column;
    }
    .label {
      font-size: 1.2rem;
      color: $dark_grey;
      margin-bottom: 0.6rem;
    }
  }
  .order-items {
    border-bottom: 0;
    margin-top: 2.4rem;
    margin-bottom: 0;
    .order-item-wrapper {
      &:not(:last-child) {
        margin-bottom: 1.8rem;
        border-bottom: 1px solid $grey_line;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .order-item__content {
    display: flex;
    flex-direction: column;
  }
  .order-item__details {
    margin-top: auto;
  }
  .shipping-headings {
    display: none;
  }
  .return-item-box {
    &:not(:last-of-type) {
      margin-bottom: 2.4rem;
      padding-bottom: 2.4rem;
      border-bottom: 1px solid $grey_line;
    }
  }
}

.order-summary-label {
  font-size: 1.8rem;
}

.order-guest {
  padding-top: 40px;
}
