.single-banner {
  &::before {
    height: auto;
    padding-top: 66.66%;
  }
}

.list__empty-message {
  width: 100%;
  margin: ($space-unit * 20) auto 9.6rem;
  .empty-message__text {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .button {
    width: 100%;
  }
}
