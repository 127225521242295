.addressban {
  padding-top: $space-unit * 8;
  a {
    text-decoration: none;
  }
}

.bag-overview {
  position: relative;
  text-align: left;
  overflow: hidden;
  // margin-bottom: $space-unit * 6;
  &__products {
    padding: $space-unit * 8 0;
  }

  .cta--remove {
    &::after {
      top: 0;
      transform: translateY(0);
    }
  }
}

.bag-overview--empty {
  //padding-top: 11.2rem;
  padding-bottom: ($space-unit * 30);
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    @extend %primary-s;
    text-align: center;
    margin-bottom: $space-unit * 4;
  }

  &__button-container {
    width: 32rem;
  }
}

.bag-overview__sectioncheckout:first-child {
  margin: 24px 0;
}

.bag-overview__sectiontitle {
  @extend %primary-m;
  margin-bottom: $space-unit * 6;
  margin-top: $space-unit * 2;
}

.remove-margin {
  margin: 0 -3.2rem;
}
