.product-notify {
  flex-direction: row;

  &__main {
    flex: 0 0 50%;
    display: flex;
    padding: 0;
    align-items: center;
    background-color: $black;

    .selector__button,
    .selector__menu__item {
      color: $white;
    }

    p {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 300;
      color: $white;
    }
  }

  &__detail {
    flex: 0 0 50%;
    padding: $space-unit * 8;
  }
}
