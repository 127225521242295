.shop-by-look-module {
  position: absolute;
  left: 0;
  width: 100vw;
  overflow: hidden;
  height: auto;
  background-color: $black;
  @include animate;
  .close-shopbylook {
    position: absolute;
    top: ($space-unit * 3);
    right: ($space-unit * 6);
    filter: invert(1);
    @include z;
  }
  .look-products {
    color: $white;
  }
  .preview-image {
    display: flex;
    align-items: center;
    .product-image {
      height: auto;
      img {
        height: 100%;
      }
    }
  }
  .product-selection {
    padding-top: 0;
    background: transparent;
    height: auto;
    .product-selection__heading {
      padding-top: 0;
    }
    .product-selection__description {
      display: -webkit-box;
      line-height: 1.6rem;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: ($space-unit * 12);
    }
    .product-selection__ctas {
      margin-bottom: ($space-unit * 6);
    }
    .button--arrow-right::after {
      filter: invert(1);
    }
  }
  .products-selector {
    padding-top: ($space-unit * 6);
    display: flex;
    margin: 0 - ($space-unit*0.5);
    overflow: auto;
    > button {
      width: calc(20% - #{$space-unit});
      padding-bottom: ($space-unit * 2);
      border-bottom: $space-line solid $white_inactive;
      margin: 0 ($space-unit*0.5);
      flex-shrink: 0;
      @include animate(0.2);
      &.selected {
        border-bottom-color: $white;
      }
    }
  }
}
