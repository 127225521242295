.listing-container {
  .widget-plp {
    grid-column: span 6;

    .widget-a{
      .widget-a-content {
        gap: 2.4rem;
        .widget-a-cta{ 
          margin-top: 0;
        }
      }
    }
    
    .widget-b-editorial{
      .img-wrapper{
        video{
          max-height: 70rem;
          margin-left: auto;
          display: flex;
          width: auto;
          max-width: -webkit-fill-available;
          max-width: -mox-available;
          max-width: fill-available;
        }
        img{
          max-height: 55rem;
          width: auto;
          margin-left: auto;
        }
      }
    }
  }

  .plp-full-width {
    @each $breakpoint, $metrics in $grid-metrics {
      @if $breakpoint== 'xs' {
        transform: translateX(-#{map-get($metrics, 'container-padding')});
        width: calc(100% + #{map-get($metrics, 'container-padding')} * 2);
      } @else {
        @include breakpoint($breakpoint, min) {
          transform: translateX(-#{map-get($metrics, 'container-padding')});
          width: calc(100% + #{map-get($metrics, 'container-padding')} * 2);
        }
      }
    }
  }
}
