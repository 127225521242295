#countryselector-modal {
  .modal-container {
    margin: 2.4rem;
    border-radius: 8px;
  }
}

#countryselector-modal {
  height: calc(100% - 4.8rem);
  right: 2.4rem;
  left: unset;
  transform: unset;
  top: 2.4rem;
  max-height: unset;
  width: 460px;
  min-width: unset;
}

#countryselector-modal {
  .country-selector-container {
    .country-selector-element {
      flex: 1 0 33%;
    }
  }
}
