.listing-container {
  padding-top: $header-height-desktop - 0.1rem;
  &.is-shelf {
    padding-top: $header-height-desktop + 10rem - 0.1rem;
  }
  .product-list {
    .product-tile {
      flex-basis: 25%;
    }
  }
}

.grid-container {
  grid-template-columns: repeat(6, 1fr);
  column-gap: 4rem;
  row-gap: 2.4rem;
  &.grid-2 {
    grid-template-columns: repeat(2, 50%);
  }
  &.grid-6 {
    grid-template-columns: repeat(6, 1fr);
  }

  .product-tile {
    grid-column: span 2;
    &:nth-child(5n), &:nth-child(5n + 4)  {
      grid-column: span 3;
    }
    &:nth-child(5n + 3) {
      .promo-text {
        &__tooltip {
          &__box {
            right: -0.8rem;
            transform: unset;
            &:before {
              right: 0.7rem;
            }
          }          
        }

      }
    }
  }

  .grid-footer {
    grid-column: span 6;
  }

  .plppromo {
    .plppromo__title {
      top: $space-unit * 8;
      left: $space-unit * 8;
    }

    &.is-double {
      picture {
        height: 100%;
      }
      img {
        position: relative;
        top: initial;
        transform: initial;
      }
    }
  }
  .firstcol {
    .product-tile__info {
      // padding-left: get($grid-container-padding, md);
    }
  }
  &.lookbook {
    li {
      &:nth-child(4n + 2) {
        .shop-by-look-module {
          left: -100%;
        }
      }
      &:nth-child(4n + 3) {
        .shop-by-look-module {
          left: -200%;
        }
      }
      &:nth-child(4n + 4) {
        .shop-by-look-module {
          left: -300%;
        }
      }
    }
  }
}

.plppromo {
  picture {
    height: calc(100% - 8.8rem);
  }
  img {
    position: absolute;
    height: auto;
    min-height: 100%;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}


.subcategories-container {
  margin: 24px 0 40px;
}

