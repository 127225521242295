.product-reserve {
  position: relative;
  max-height: inherit;
  &__main {
    padding: 2rem ($space-unit * 4) 0;
    background-color: black;
    display: flex;
    flex-direction: column;
    max-height: inherit;

    .product-image {
      img {
        width: auto;
        margin: 0 auto;
      }
    }

    > img {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    .selector__button,
    .selector__menu__item {
      color: white;
    }

    .dialog__title {
      color: $white;
      padding-bottom: 0;
    }

    &__name {
      @extend %primary-s;
      color: $white;
    }

    &__wrapper {
      &.product-selection {
        padding-top: 0;
        background: initial;
      }
    }
  }

  &__detail {
    position: relative;
    min-height: 16.4rem;
    padding: ($space-unit * 2) ($space-unit * 4) ($space-unit * 6);

    .store-info {
      margin-right: 0;
    }

    .accordion__group {
      .accordion__header {
        border-top: initial;
        @include border('bottom');
        &[aria-expanded='true'] {
          border-bottom: initial;
        }
      }
    }

    &__line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1.6rem;
      padding-bottom: 0.6rem;
      span {
        @extend %primary-xs;
        color: $black_secondary;
      }
      .use-location {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        height: 1.6rem;

        &::before {
          content: '';
          @extend .icon--pin-full;
          display: block;
          margin-top: -0.2rem;
        }
      }
    }
    .react-tabs__tab-list {
      padding: 0;
    }

    .dialog__title {
      padding-top: 0;
      padding-bottom: 2.4rem;
    }

    &__text {
      margin-top: $space-unit * 4;
      margin-bottom: 3.2rem;
      p {
        padding-bottom: $space-unit * 4;
      }
      > button {
        margin-top: $space-unit * 2;
      }
    }

    &__selected-store {
      position: relative;
      padding-top: $space-unit * 6;
      padding-bottom: $space-unit * 8;
      padding-left: $space-unit * 4;
      @include border('bottom');
      .back-button {
        position: absolute;
        left: -0.8rem;
        top: 2.2rem;
        @include fixedSprite('arrow-left-menu');
      }

      .name {
        @extend %primary-s;
        margin-bottom: $space-unit * 2;
      }

      .content {
        @extend %primary-xs;
      }
    }

    &__form {
      &__legend {
        font-size: 1.6rem;
        line-height: 2rem;
      }

      &__submit {
        margin-top: ($space-unit * 3);
      }

      &__note {
        margin: ($space-unit * 6) 0 0;
      }
    }

    sup {
      vertical-align: super;
      font-size: 0.9rem;
      color: $black_secondary;
    }

    &__resend-prompt {
      margin-bottom: $space-unit * 2;
    }

    &__reserve-review {
      margin-top: 1rem;
    }

    &__review {
      margin-bottom: $space-unit * 8;
    }

    &__review-grid {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-areas:
        'product price'
        'divider divider'
        'store store'
        'customer customer';
      gap: $space-unit * 4;
    }

    &__review-header {
      @extend %primary-s;
      margin-bottom: $space-unit * 2;
    }

    &__review-content {
      @extend %primary-xs;
      margin-bottom: $space-unit;
      display: block;
    }

    &__review-product {
      grid-area: product;
    }

    &__review-price {
      grid-area: price;
      justify-self: end;
    }

    &__review-store {
      grid-area: store;

      .details {
        .tel {
          margin-top: ($space-unit * 3);
        }
      }
    }

    &__review-customer {
      grid-area: customer;
    }

    &__review-divider {
      grid-area: divider;
      @include border('top');
      height: $space-line;
    }

    &__review-divider-top {
      grid-area: divider-top;
      @include border('top');
      height: $space-line;
    }

    &__review-divider-bottom {
      grid-area: divider-bottom;
      @include border('top');
      height: $space-line;
    }
  }

  .scrollable {
    overflow-y: auto;
    padding-right: 1rem;
  }

  .accordion__panel.accordion__panel_open {
    margin-bottom: $space-unit * 4;
  }
}

.reserve-form-modal {
  .modal__header {
    margin-left: -1.6rem;
    padding-left: 1.6rem;
    margin-right: -1.6rem;
    padding-right: 1.6rem;
    border-bottom: $space-line solid $grey_line;
  }
}
