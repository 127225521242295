.minibag {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
  width: 460px;
  height: calc(var(--viewport-height) - 48px - var(--ticker-height));
  padding: 24px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  @include animate(0.3);
  @include rounded-box;
  z-index: 1;

  &.empty {
    justify-content: space-between;
    height: 197px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.2rem;
    @extend %primary-s;

    &--empty {
      display: flex;
      justify-content: space-between;

      >p {
        @extend %primary-s;
      }
    }
  }

  .icon--close {
    position: absolute;
    top: 0.8rem;
    right: 2rem;
    z-index: 2;
    transform: scale(1.5);
  }

  &__mobile {
    padding-bottom: 1.4rem;
  }

  &__mobile-label {
    @include border('top');
    padding: $space-unit * 4 0;
  }

  &__total {
    @include border('top');
    display: flex;
    justify-content: space-between;
    padding: $space-unit * 4 0;

    &>* {
      text-transform: uppercase;
      @extend %primary-s-u;
    }
  }

  &__buttons {
    @include animate;

    .button:not(:last-child) {
      margin-bottom: $space-unit * 2;
    }
  }

  &__productcontainer {
    padding-bottom: $space-unit * 0.5;

    &:not(:last-of-type) {
      @include border('bottom');
      margin-bottom: $space-unit * 4;
      padding-bottom: $space-unit * 4;
    }
  }

  &__product {
    display: flex;

    &__image {
      flex-basis: 9rem;

      .product-image {
        height: 12rem;

        &:before {
          content: initial;
        }

        // img {
        //   top: 50%;
        //   transform: translateY(-50%);
        // }
      }
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: $space-unit * 4;
      position: relative;
      padding-right: 2.4rem;
    }

    &__columns {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }

    &__promo,
    &__editorial {
      padding-top: 1.6rem;
    }

    &__title {
      @extend %primary-xs;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .minibag {
        &__product {
          &__promo {
      
            .tooltip {
              &__box {
                right: 0.6rem;
                transform: unset;
                border-bottom-right-radius: 0;
                
                &:before {
                  right: 0;
                  border-right: 0;
                  border-left-width: 1.2rem;
                }
              }
            }
          }
        }
      }
    }

    &__price {
      white-space: nowrap;
      margin-top: 0.8rem;
      // margin-left: $space-unit * 2;
    }

    &__label {
      @extend %primary-xxs;
      display: block;
      text-align: right;
      color: $black_secondary;
      line-height: 1.3;
    }

    &__info {
      @extend %primary-xxs;
      color: $dark_grey;
      margin-top: 1.6rem;

      p {
        margin-top: $space-unit;
      }
    }

    &__actions {
      margin-bottom: -($space-unit * 0.5);
      display: flex;
      justify-content: space-between;
      @extend %primary-xs;
      position: absolute;
      top: -2rem;
      right: 0;
    }
  }

  &__list {
    overflow-y: auto;
    flex-grow: 1;
    margin-bottom: 1.4rem;
    padding-right: ($space-unit * 4);

    &::-webkit-scrollbar {
      width: 0.2rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $grey_line;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $black;
    }
  }

  &__buttons__wrapper {
    @include animate;

    &.hide {
      transition-delay: 0.2s;
      visibility: hidden;
    }
  }

  &__expresspayment {
    position: absolute;
    width: 100%;
    min-height: 100%;
    background-color: $white;
    top: 0;
    left: 0;
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
    @include animate;

    &.open {
      transition-delay: 0.2s;
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
    }

    &__intro.button {
      width: 100%;
      padding-right: 1.6rem;
      padding-bottom: 0.8rem;
      text-align: center;
      font-size: 1.6rem;
      line-height: 2rem;
      text-transform: capitalize;
    }

    #container-paywithgoogle {
      display: none;
    }

    button {
      display: block;

      &.apple-pay-button,
      &.apple-pay-button:hover {
        border-radius: 0;
        margin-top: 0;
      }
    }
  }
}

[data-react='minicart-app'] {
  .overlay {
    z-index: 0;
  }

  .icon--bag {
    span {
      display: inline;
    }
  }
}

#cart-icon {
  &[aria-expanded='true'] {
    +#minibag-container {
      .overlay {
        opacity: 0.5;
        visibility: visible;
      }

      .minibag {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.feedbackbag-wrapper {
  &[data-visible='true'] {
    #feedbackbag-container {
      .overlay {
        opacity: 0.5;
        visibility: visible;
      }
    }
  }
}

// #cart-icon:not([aria-expanded='true']) {
//   + #minibag-container {
//     .minibag__expresspayment.open {
//       visibility: hidden;
//     }
//   }
// }
// #minibag-container .overlay {
//   pointer-events: none;
// }