.product-slide__img {
  width: 34rem;
}

.product-highlight--img-bigger {
  .product-slide__img {
    width: 39.6rem;
  }
}
.product-highlight__text-holder {
  padding: 4.6rem ($space-unit * 4) ($space-unit * 8);
}

.product-highlight__cta {
  margin-bottom: ($space-unit * 8);
}
