@each $font-type, $font-data in $font-desktop {
  %primary-#{$font-type},
  .primary-#{$font-type} {
    font-family: nth($font-data, 1), Arial, sans-serif;
    font-size: nth($font-data, 2);
    line-height: nth($font-data, 3);
    font-weight: nth($font-data, 5);
    @if str-index($font-type, '-u') {
      text-transform: uppercase;
    }
  }
}
