.wishlist {
  &__single-product {
    display: flex;
    justify-content: space-between;
    &__img-holder {
      max-width: 150px;
      width: 45%;
      min-width: 130px;
      height: auto;
      img {
        width: 100%;
      }
    }
    &__product-info {
      padding-left: 16px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      h3 {
        @extend %primary-s;
      }
      &__name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;

        h3{
          max-width: calc(100% - 2rem);
        }

        .cta--remove{
          position: absolute;
          right: 0;
          top: 10px;
        }
      }

      &__to-add {
        margin-top: 1rem;
        color: $dark_grey;
        h3,
        p {
          font-size: 1.2rem;
        }
      }
    }
    .btn-addtobag {
      margin-top: auto;
      padding: 0 0 0 2.2rem;
      text-align: left;
      background-color: transparent;
      color: $black;
      display: flex;
      height: auto;
      width: auto;
      justify-content: flex-start;

      span {
        text-transform: none;
        // font-weight: normal;
      }
      &::before {
        content: '';
        @include fixedSprite('bag');
        @include center('vertical');
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
      }

      &:hover {
        opacity: 0.5;
        transition: opacity 0.5s ease-in-out;
        span {
          &::before {
            transform: none;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

[data-whatintent='keyboard'] {
  .wishlist__single-product__remove-btn:focus {
    outline: 1px solid white;
    text-decoration: initial;
    text-underline-position: initial;
  }
  // }
}
