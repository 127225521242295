.product-details {
  padding-top: ($space-unit * 8);
  padding-bottom: ($space-unit * 10);

  .made-in {
    @include reset-list;
  }

  .accordion__panel {
    line-height: 1.6rem;
    @extend %primary-xs;

    >div {
      padding-bottom: ($space-unit * 10);
    }

    &#composition-care-panel {
      img {
        width: 3rem;
        height: 3rem;
        filter: invert(100%);
        display: inline-block;
        margin: 1rem 1rem 1rem 0;
      }
    }

    .findmysizecta {
      text-decoration: underline;
      margin-top: $space-unit * 4;
    }
  }

  &__category {
    margin-bottom: 1em;
    text-transform: uppercase;
  }
}

//Stefanel

.product-detail {

  &__info-assets {
    .content-asset:first-child {
      margin-top: 2.4rem;
    }

    .content-asset:last-child {
      margin-bottom: 2.4rem;
    }

    p {
      margin: 2.4rem 0;
      display: flex;
      align-items: flex-start;

      &::before {
        margin-right: 0.8rem;
      }

      &.shipping {
        &::before {
          content: '';
          @include fixedSprite('pack');
        }
      }

      &.return {
        &::before {
          content: '';
          @include fixedSprite('return');
        }
      }

      &.eco {
        &::before {
          content: '';
          @include fixedSprite('eco');
        }
      }
    }
  }

  .product-gallery-button {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 2.4rem;
    right: 2.4rem;
    padding: 2rem;
    z-index: 1;
    @include rounded-box;

    &::before {
      content: '';
      @include fixedSprite('grid');
      margin-right: 1rem;
    }
  }
}

.product-detail-labels {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.8rem;
}

.product-label {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.4;

  color: $dark_grey;

  &:not(:last-of-type) {
    margin-right: 1.6rem;
  }

  &.respect {
    color: #50a12a;
  }
}

.product-info__item {
  display: grid;
  grid-template-columns: 12rem 1fr;
  grid-column-gap: 4rem;
  padding: 2rem 2rem 2rem 0;
  @include border(bottom);

  &:first-of-type {
    padding-top: 0;
  }

  &--label {
    text-transform: uppercase;
    color: $dark-grey;
    font-size: 1.2rem;
  }
}

.product-thumbnails {
  display: none;
}



.product-bottom {
  &-container {
    background-color: $white;
    
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0,0,0);
    z-index: 2;
    position: relative;
  }

  &__header {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    min-width: 100%;
    justify-content: space-between;
    padding: 1.6rem 0;
    text-align: center;

    &_element {
      list-style-position: outside;
      padding-top: 0;
      padding-bottom: 0;
      width: 100%;
      padding: 1.4rem 1.6rem;
      border-bottom: 0.1rem solid $grey_line;
      cursor: pointer;

      &.selected {
        border-color: $red
      }
    }
  }



  &__content {
    &_text {

      &--left,
      &--right {
        padding-top: 0;
      }

      &_title {
        font-weight: 300;
        font-size: 1.8rem;
        line-height: 2.6rem;
      }
    }

    &_img-wrapper {
      padding-top: 0;
    }

    &_description {


      &_details {

        &_promo_item {
          font-size: 1.2rem;
          line-height: 2rem;
          padding: 0.8rem;
          background-color: $light_grey;

          &_img {
            width: 2.4rem;
            height: 2.4rem;
            min-width: 2.4rem;
            min-height: 2.4rem;
          }
        }

        &_textDescription {
          font-weight: 300;
          font-size: 1.8rem;
          line-height: 2.6rem;
        }

        &_code {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.8rem;
        }

        &_made {
          font-weight: 300;
          border-left: 0.6rem solid $grey;
          padding-left: 2.4rem;
        }
      }

      &_vestibility {
        &-container {
          font-size: 1.4rem;
          line-height: 2.2rem;
          font-weight: 300;
        }

        &_labels {
          flex-wrap: wrap;

          &_element {
            text-transform: uppercase;
            padding: 0 0.8rem;
            font-size: 1.2rem;
            line-height: 2rem;
            background-color: $grey;
            font-weight: 400;
          }
        }
      }
    }

    &_lounge {
      &_user {
        &__greetings {
          font-size: 1.8rem;
          line-height: 2.6rem;
          font-weight: 600;
          padding-bottom: 3.2rem;
        }

        &_title {
          font-weight: 300;
          font-size: 1.8rem;
          line-height: 2.6rem;
        }

        &_text {
          font-weight: 300;
          line-height: 2.2rem
        }
      }

      &_how {
        &_list {
          list-style: disc;
          border-left: $red solid 0.05rem;
          padding-top: 0;
          padding-bottom: 0;

          &_element {
            padding: 0;
            font-weight: 300;
            line-height: 2.2rem;
            padding-left: 1.6rem;
            position: relative;

            &:not(:last-child) {
              margin-bottom: 2.4rem;
            }

            &::before {
              content: '';
              position: absolute;
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 0.8rem;
              background: $red;
              left: -0.8rem;
              top: 50%;
              transform: translate(43%, -50%);
            }
          }
        }
      }
    }

    &_shippingReturn {
      display: grid;
      grid-template-columns: 50% 50%;

      &_content {
        padding-top: 2.4rem;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;

        &_element {
          display: flex;
          align-items: center;
          gap: 1.6rem;
          line-height: 2.2rem;
          font-weight: 300;

          [class*='icon--'] {
            min-width: 2.4rem;
            align-self: flex-start;
          }
        }
      }
    }

    &_compositionCare {
      &_text {
        line-height: 2.2rem;
        font-weight: 300;

        &_care {
          &_list {
            list-style: none;
            display: flex;
            gap: 1.6rem;
            padding: 2.4rem 0;
            ;
            text-align: center;

            &_element {
              list-style-position: outside;
              padding: 0.8rem;
              cursor: pointer;
              transition: border-color 0.5s ease-in-out;

              &.selected {
                border-bottom: 0.03rem solid $black;
              }

              &_img {
                width: 1.6rem;
                height: 1.6rem;
              }
            }
          }
        }
      }
    }
  }

}