
.feedbackbag__wrapper {
  .feedbackbag {
    &__overlay {
      @include animate(0.3);
      &.closed {
        opacity: 0;
        visibility: hidden;
      }
      &.open {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &[data-visible='true'] {
    .feedbackbag{
      position: absolute;
      right: 0;
      top: 0;
      background: $white;
      width: 44rem;
      border-radius: 0.8rem;
      box-shadow: 0 0.4rem 2rem 0 #00000014;
      padding: 3.2rem 2.4rem;

      &__msg {
        &__added {
          font-size: 1.8rem;
          line-height: 2.2rem;
        }
      }

      &__container{
        position: relative;      
      }

      &__overlay {
        position: absolute;
        top: 1.6rem;
        right: 0;
        width: 100vw;
        height: calc(100vh);
        transition: all 0.3s ease-out;
        z-index: 1;
        &--wishlist {
          top: 4.2rem;
        }
      }

      &__item-wrapper{
        max-height: calc(80vh - 6.9rem - 8.8rem - 7.9rem);

        &::-webkit-scrollbar {
          display: none;
        }
        .minibag__product{
          &-container{
            border: 0;
            padding-bottom: 1.6rem;            
          }

          &__columns {
            height: 100%;
          }

          &__title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }

          &__actions{
            display: none;
          }

          &__info {
            &__quantity {
              display: none;
            }

            .product {
              &__size {
                margin-top: 0.6rem;
                font-size: 1.2rem;
                line-height: 1.2rem;
              }
            }
          }

          &__price {
            margin-top: 0.4rem;
            font-size: 1.2rem;
            line-height: 1.2rem;
            
            .discount-value {
              padding: 0.2rem 0.4rem;
            }
          }
        }
      }

      &__bottom{
        &-btn{
          flex: 1;
          width: 100%;
          overflow: hidden;

          &-wrapper{
            display: flex;
          }          
        }

      }

      img,
      [class*='icon--']::after,
      [class*='icon--']::before {
        filter: none !important;
      }
    }
  }
}
