.hero {
  position: relative;
  display: flex;
  overflow: hidden;

  .btn--pause {
    position: absolute;
    left: 3.2rem;
    bottom: 3.4rem;
    z-index: 6;
  }

  .hero-column__title {
    text-transform: uppercase;
    color: $white;
    position: relative;
    z-index: 2;
  }

  .hero-column__headline {
    max-width: 100%;
    z-index: 1;
  }

  // label
  .hero-column__label {
    @extend %primary-l-u;
    color: $white;
    display: block;
    position: relative;
    z-index: 2;
    text-shadow: 0 0 0.5rem rgba(15, 15, 15, 0.15);
    margin-bottom: ($space-unit * 3);
  }

  .button {
    color: $white;
  }

  .hero-column__img {
    object-fit: cover;
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      bottom: 0;
      top: 0;
      object-fit: cover;
      max-width: 100%;
      height: 100%;
    }
  }
}

.hero__hero-column {
  position: relative;
  width: 100%;
  display: block;
  z-index: 3;
}

.hero-column__inner-container {
  padding: ($space-unit * 12);
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-decoration: initial;
  &:focus {
    .button {
      text-decoration: underline;
      text-underline-position: under;
    }
  }
}

.hero--hero-with-columns {
  &:not(.hero-carousel) {
    .hero__hero-column {
      &:nth-child(2) {
        .hero-column__headline,
        .hero-column__cta {
          transition-delay: 0.35s !important;
        }
      }
      &:nth-child(3) {
        .hero-column__headline,
        .hero-column__cta {
          transition-delay: 0.45s !important;
        }
      }
    }
  }
}

// Alignment property

.hero--centered {
  text-align: center;
  .hero-column__inner-container {
    justify-content: center;
    align-items: center;
  }
}

.hero--left-top {
  .hero-column__inner-container {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.hero--left-bottom {
  .hero-column__inner-container {
    justify-content: flex-end;
    align-items: flex-start;
  }
}
.hero--center-bottom {
  text-align: center;
  .hero-column__inner-container {
    padding-bottom: 4.8rem;
    justify-content: flex-end;
    align-items: center;
  }
}
.hero--center-top {
  text-align: center;
  .hero-column__inner-container {
    padding-top: 10rem;
    justify-content: flex-start;
    align-items: center;
  }
}
.hero--center-left {
  .hero-column__inner-container {
    justify-content: center;
    align-items: flex-start;
  }
}
.hero--center-cta-lower {
  text-align: center;
  .hero-column__inner-container {
    justify-content: space-between;
    align-items: center;
    .hero-column__headline {
      margin: auto 0;
    }
    .hero-column__cta {
      margin-top: 1.9rem;
    }
  }
}

.hero--center-right {
  .hero-column {
    &__inner-container {
      justify-content: center;
      align-items: flex-end;
    }
    &--logo,
    &__label {
      text-align: right;
    }
  }
}

.hero--right-top {
  .hero-column {
    &__inner-container {
      justify-content: flex-start;
      align-items: flex-end;
    }
    &--logo,
    &__label {
      text-align: right;
    }
  }
}
.hero--right-bottom {
  .hero-column {
    &__inner-container {
      justify-content: flex-end;
      align-items: flex-end;
    }
    &--logo,
    &__label {
      text-align: right;
    }
  }
}

// Margin property
.hero--without-border {
  &,
  &.hero__hero-column {
    margin: 0;
  }
}
.hero--with-border {
  margin: ($space-unit * 8) 3rem;
  .hero__hero-column {
    margin: 0 0.2rem;
  }
}

// Button styles

.hero-column__cta {
  @extend %primary-xs;
  position: relative;
  z-index: 2;
  display: flex;
  margin-top: ($space-unit * 3);
  .button {
    color: $white;
    @extend %primary-xs;
    &:nth-child(2) {
      margin-left: 1.6rem;
    }
  }
}

.hero-column__cta,
.hero-column__headline,
.swiper-pagination,
.hero-column__img {
  opacity: 0;
}

.reveal-animations {
  .hero-column__cta {
    @include animate(0.4, 'all', $easeOutQuart, 0.25, '!important');
    opacity: 1;
  }
  .hero-column__headline {
    @include animate(0.4, 'all', $easeOutQuart, 0.25, '!important');
    opacity: 1;
  }
  .swiper-pagination {
    @include animate(0.4, 'all', $easeOutQuart, 0.35, '!important');
    opacity: 1;
  }
  .hero-column__img {
    @include animate(0.4, 'all', $easeOutQuart, 0, '!important');
    opacity: 1;
  }
}

.hero--cta-button {
  .button {
    border: $space-line solid $white;
    // padding: 1rem 2.3rem 0.6rem;
    // padding: 0.9rem 2.3rem;
    padding: 0 2.3rem;
    height: 3.6rem;
    align-items: center;
    &::after {
      content: none;
    }
  }
}
.hero--cta-inline {
  .button {
    border: none;
  }
}

// logo
.hero-column--logo {
  position: relative;
  margin-bottom: 1rem;
  @include z;
  img {
    display: inline-block;
  }
}

// text with background image
.hero--hero-background {
  position: relative;
  background: $black;
  @include appearAnimation();
}

.hero__img-bg {
  position: relative;
  img {
    top: 0;
    max-width: 100vw;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.hero__main-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $white;
  text-transform: uppercase;
  text-align: center;
  font-weight: 300;
  width: 63.6%;
}

.myaccount-hero {
  position: relative;
  height: 21rem;
  // prettier-ignore
  // background: linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 50%),
  //             radial-gradient(42.86% 42.86% at 50% 57.14%, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%),
  //             linear-gradient(0deg, rgba(15, 15, 15, 0.1), rgba(15, 15, 15, 0.1)),
  //             var(--url);
  // background-blend-mode: normal, normal, multiply, normal;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  &.client-services {
    // background: var(--url);
    // background-size: cover;
    // background-repeat: no-repeat;
    background-position: 50% 40%;
  }
  &__title {
    @extend %primary-xl-u;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    color: $white;
    margin-bottom: ($space-unit * 12);
    text-align: center;
    width: 100%;
  }
}

.title--hidden {
  .hero-column {
    &__headline,
    &__cta {
      opacity: 0 !important;
    }
  }
}
