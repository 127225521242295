.search-container {
  .search-field {
    margin: 16px 16px 0;
    position: sticky;
    top: 16px;
    @include rounded-box;
  }
  .results-container {
    width: 100%;
    border-top: initial;
    .submenu-group ul {
      grid-template-columns: 100%;
      li {
        grid-column-end: initial;
      }
    }
  }
  .wrapper-search {
    position: fixed;
    z-index: 2;
    height: var(--viewport-height);
    top: var(--ticker-height);
    border-radius: initial;
    overflow-y: auto;
  }
  .minitile-group {
    gap: 24px;
  }
}
