.findinstore {
  .findinstore-wrapper {
    width: 100%;
    height: 100%;
  }

  #findinstore {
    margin: 0;
    top: unset;
    border-radius: 0.8rem 0.8rem 0 0;
    padding-top: 0.8rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    max-width: 100%;
    width: 100%;
    max-height: 40rem;

    &:has(.findinstore-map-section:not(.hide)) {
      max-height: 58.7rem;
      &:has(.result ){
        height: unset;
      }
    }

    .title {
      font-size: 2.4rem;
      line-height: 3.2rem;
      font-weight: 300;   
        width: 100%;
        text-align: center;   

      &-wrapper {
        padding: 2.4rem 0;
        
        .close-custom {
          display: none;
        }
        &::before {
          content: '';
          background-color: $grey_line;
          width: 8rem;
          height: 0.2rem;
          border-radius: 1.6rem;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
        }
      }


    }

    .findinstore{
      &-size-section {
        padding: 0;

        .selector-size-expanded {
          border-top: none;
    
          .selector {
            &__header {

              padding: 0;
              
            }
            &__label {
              margin: auto;
              &_text {
                font-size: 1.8rem;
                line-height: 2.6rem;
                font-weight: 300;                
              }
            }
            &__menu {
              padding: 0;
              margin-top: 2.4rem;
    
              &__list {
                display: grid;
                grid-template-columns: 1fr 1fr;
                &__element {
                  padding: 1.6rem 0;
                  border:none;
                  border-bottom: 0.03rem solid $grey_line;   

                  &:nth-child(odd){
                    .selector__menu__item {
                      border: solid $grey_line;
                      border-width: 0;
                      border-right-width: 0.03rem;                    
                    }
                  }
                  &:nth-last-child(1):nth-child(even),
                  &:nth-last-child(2):nth-child(odd),
                  &:nth-last-child(1):first-child{
                    border: 0;
                  }                  
                  
                }
              }  
              
              &__item {
                height: 4.4rem;
              }
            }
    
            &__label {
              font-size: 1.6rem;
              line-height: 2.4rem;
              &_text  {
                font-size: 1.6rem;
                line-height: 2.4rem;                
              }
            }
          }
        } 
      }

      &-map-section {
        padding: 0;
        &-title {
          font-size: 1.8rem;
          font-weight: 300;
          line-height: 2.6rem;
          text-align: center;
          padding-bottom: 1.6rem;
        }

        .findinstore-results {
          max-height: 53vh;
          margin-bottom: 3.2rem;
          margin-top: 2.6rem;
          &::-webkit-scrollbar {
            width: 1rem;
          }
          &::-webkit-scrollbar-track,
          &::-webkit-scrollbar-thumb {
            border-left: 0.8rem $white solid;
          }
        }
      }
    }
  
  }
}