[aria-expanded='true'] + #account-container {
  .account-dropdown,
  .overlay {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
#account-container .overlay {
  pointer-events: none;
}
.account-dropdown {
  position: absolute;
  top: calc(100% - (#{$space-unit} * 4));
  padding: ($space-unit * 8);
  padding-top: ($space-unit * 12);
  min-width: 22.6rem;
  opacity: 0;
  visibility: hidden;
  background-color: $white;
  transform: translateY(-3rem);
  z-index: -1;
  @include animate(0.3);

  // @include gray-gradient-pseudo(after);
  &::after {
    top: ($space-unit * 4);
    z-index: initial;
  }

  > * {
    position: relative;
    @include z;
  }

  .menuheading {
    display: inline-block;
    small {
      display: block;
      color: $black_secondary;
      @extend %primary-xxs;
      margin-bottom: 0.2rem;
    }
    margin-bottom: ($space-unit * 4);
  }

  .menuheading,
  .menuitem {
    @extend %primary-s;
  }

  .menuitem {
    display: inline-block;
    text-decoration: none;
  }
}

#accountDropdown {
  li {
    &:not(:last-child) {
      margin-bottom: $space-unit;
    }
  }
}
