.store__summary__directions.get-directions {
  width: 64.2%;
}

.store,
.store-locator__map {
  height: $storelocator-height-desktop;
}

.search-store {
  &__resultslist {
    [role='menu'] {
      padding: 0 2.4rem;
    }
  }
  &__regions {
    .wrapper-regions {
      padding: 0 2.4rem;
    }
  }
}

.store-locator {
  .col-md-5 {
    max-width: calc(41.66667% + 2px);
    flex-basis: calc(41.66667% + 2px);
  }
  .col-md-7 {
    max-width: calc(58.33333% - 2px);
    flex-basis: calc(58.33333% - 2px);
  }
}
