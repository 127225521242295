.single-designer__name {
  font-size: 10.4rem;
  line-height: 7.8rem;
  padding-left: 5rem;
}
.genius-designers__designers-row {
  height: 7.8rem;
}
.single-designer__name img {
  top: 0.2rem;
  height: 90%;
}

.single-designer {
  padding-right: 3rem;
}

.single-designer__caption {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.genius-designer {
  &__tiles-container {
    flex-wrap: nowrap;
    overflow: auto;
    &::before,
    &::after {
      content: '';
      display: inline-block;
      height: 1rem;
      width: 1.6rem;
      min-width: 1.6rem;
    }
  }
  &__designer-tile {
    width: calc(100% - 3.2rem);
    min-width: calc(100% - 3.2rem);
    margin-right: 0.4rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
.designer-tile {
  &__name {
    bottom: 1.6rem;
    left: 1.6rem;
  }
  &__info {
    padding: 3.2rem 1.6rem 4rem;
  }
}
