.sizevariation-modal.modal.quick-view {
  max-height: 100vh;
  top: 50%;
  transform: translateY(-50%);
  right: 3.5rem;
  left: unset;
  width: 100%;
  max-width: 70%;
  border-radius: .8rem;
  padding: 3.2rem;
  padding-bottom: 0;

  .modal__close{
    padding: 2rem;
  }

  .modal__body{
    display: flex;
    gap: 1.6rem;
    overflow-y: scroll;
    max-height: calc(90vh - 2.4rem);
    position: relative;

    &::-webkit-scrollbar {
      width: 0;
    }

    .product-details__images{
      width: 50%;
      height: fit-content;
      overflow: visible;
      padding-bottom: 2.4rem;

      .js-imgnode{
        margin-bottom: 0.8rem;
      }
    }

    .product-details__info-and-cta{
      position: sticky;
      top: 0;
      width: 50%;
      height: initial;

      .wrapper-info-quickview{
        overflow-y: auto;
        max-height: calc(100% - 14rem);

        .selector-color-expanded {
          padding-top: 1.6rem;
        }

      }

      .quickview-title{
        font-size: 1.8rem;
        line-height: 2.6rem;
        margin-bottom: 2.4rem;
      }



      .sizevariation-product__content{
        margin-left: 0%;

        div[role="navigation"]{
          padding: 0;

          .breadcrumb{
            padding: 0;
            margin-bottom: 1.2rem;
          }
        }

        .sizevariation-product__name-price{
          border: solid $grey_line;
          border-width: 0.03rem 0;
          gap: 0.8rem;
          padding: 1.6rem;
          flex-direction: column;
    
          .product-promotion {
            &__login {
              font-size: 1.2rem;
              line-height: 2rem;
              &_link {
                cursor: pointer;
        
              }
            }
          }
          
          &__name{
            font-size: 1.4rem;

            &--wrapper{
              position: relative; 
            }
          }

          &__price{
            margin: 0;
            del{
              color: $dark_grey;
            }
          }
        }
      }

      .product-selection__wrapper{

        .selector-size-expanded{
          .selector {
            &__menu {
              padding: 0.8rem 0;
            }
          }
        }
      }

      .product-selection__ctas{
        position: absolute;
        bottom: 3.2rem;
        width: 100%;

      }
    }
  }
}