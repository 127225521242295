.product-reserve-store {
  display: flex;
  margin-top: $space-unit * 4;
  padding-bottom: $space-unit * 6;
  @include border('bottom');

  &:last-of-type {
    border-bottom: none;
  }

  &__content {
    width: 100%;
    p {
      margin-bottom: $space-unit * 2;
    }
    &__name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @extend %primary-s;
      margin-bottom: $space-unit * 3;
      .store-distance {
        @extend %primary-xs;
        color: $black_secondary;
      }
    }
  }
}
