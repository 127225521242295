.radiobox2 {
  display: flex;
  margin-right: -0.2rem;
  margin-left: -0.2rem;
  &__option {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    overflow: hidden;
    input {
      position: absolute;
      left: -99999em;
      top: -99999em;

      & + label {
        @extend %primary-s;
        cursor: pointer;
        border: $space-line solid $black_inactive;
        padding: $space-unit * 3;
        display: block;
        width: 100%;
        margin-bottom: 8 * $space-unit;
      }

      &:checked + label {
        border-color: $black;
      }
    }
    label {
      @include text-shorten;
    }
  }
}
