.faq-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    @extend %primary-m-u;
    margin-bottom: ($space-unit * 3);
    text-align: center;
  }
  .subtitle {
    @extend %primary-xs;
    margin-bottom: ($space-unit * 5);
    text-align: center;
  }
  .button {
    margin-bottom: ($space-unit * 6);
  }
  .details {
    margin-bottom: ($space-unit * 12);
    width: 100%;
  }

  mark {
    background-color: $input_background;
    position: relative;
    padding: 0.2rem;
  }
}
.faq--card {
  .faq--card-body {
    @extend %primary-xs;
    padding-top: ($space-unit * 2);
    padding-bottom: ($space-unit * 6);
    ul,
    ol {
      p {
        display: inline;
      }
    }
  }
}
