.btn--pause {
  @include fixedSprite('pause');

  &[aria-pressed='true'] {
    @include fixedSprite('play');
  }

  &-white {
    @include fixedSprite('pause-white');
    &[aria-pressed='true'] {
      @include fixedSprite('play-white');
    }
  }
}

.btn--forward {
  @include fixedSprite('double-arrow-right');
  @include fix-font-space(0.1rem);
}
.btn--backward {
  @include fixedSprite('double-arrow-left');
  @include fix-font-space(0.1rem);
}
