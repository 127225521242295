.product {
  &-container {
    &:first-child {
      padding-top: $header-height-desktop;
    }
    .product-col {
      width: 50%;
      flex: 1 0 auto;
    }

    .product-col--gutter-dx {
      padding-right: ($space-unit * 7);
    }

    .product-col--gutter-sx {
      padding-left: ($space-unit * 7);
    }   
    
    &.product-wrapper {
      padding-bottom: 2.8rem;
      grid-template-columns: calc(66vw - 4.8rem) auto;  

    }
  }

  &-images {
    &-container {
      margin: 0;
      min-height: calc((66vw - 4.8rem)* 8.1758/15);
    }
  }

  &-sidebar {
    height: fit-content;
    padding: 0 1.6rem 0 6.4rem;
    margin: 0;    
  }

  &-bottom {
    &-container {
      background-color: $white;
      z-index: 1;
    }
  }



}

