.modal {
  &--country-selector.modal {
    height: 100%;
    .modal {
      &__header {
        padding: 1.6rem 1.6rem 1.6rem;
        .dialog__title {
          margin: 0;
          padding: 0;
          height: auto;
        }
      }
    }
  }
}
