.selector-size-expanded {
  .selector {
    &__menu {
      margin-top: 0.8rem;
    }
    &__label {
      &Few {
        margin-top: 0.8rem;
      }
    }
    &__header {
      padding-top: 1.6rem;
    }
  }
}
