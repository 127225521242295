.breadcrumb {
  display: flex;
  flex-grow: 1;
  padding: 0 0 1.2rem;
  flex-wrap: wrap;
  .plp-title {
    flex-grow: 1;
  }

  ol {
    @include reset-list;
  }

  &-item {
    display: inline;
    font-size: 1rem;
    line-height: 1.8rem;
    letter-spacing: 0.1rem;
    color: $dark-grey;
    font-weight: 400;

    &-link {
      text-decoration: none;
    }

    &:not(:last-child) {
      &::after {
        content: '\00B7';
        margin: 0 ($space-unit * 2);
        font-weight: 900;
      }
    }
  }

  &__item {
    @extend %primary-s-u;
    text-decoration: none;
    display: inline-block;
  }
}

.product-breadcrumb {
  .breadcrumb {
    padding: 0 0 0.8rem 0;
    transition: all 0.3s ease-in-out;
    
    &-item {
      padding-top: 0;
    }
  }
}
