.grenoble-details {
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 2.8rem ($space-unit * 2) 0.6rem;
    text-transform: initial;
    label {
      margin-top: ($space-unit * 2);
    }
  }
}
