.forgot-password_form {
  .header {
    text-align: center;
    .subtitle {
      margin-top: ($space-unit * 3);
      margin-bottom: ($space-unit * 6);
      @extend %primary-s;
    }
  }
  .alert {
    margin-bottom: $space-unit * 4;
  }
  .dev-button {
    margin-top: ($space-unit * 10);
  }
  .back-button {
    text-align: left;
    align-self: center;
  }
}

/* checkout forgot password flow */

.checkoutpage {
  .checkout-forgot-password-form {
    .step__header__title {
      @extend %primary-m;
    }
    .subtitle {
      margin-top: ($space-unit * 2);
    }
    .formfields {
      margin-top: ($space-unit * 3);
    }
    .form-group {
      margin-bottom: ($space-unit * 6);
    }
  }
}
