.footer {
  .footer-menu__section {
    margin-top: 0.5rem;
  }
  .footer-menu--col {
    .footer-menu__menuitem,
    .footer-menu__section__menuitem {
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $black;
      @include border('top');
      // padding-top: 0.5rem; // TYPOGRAPHY TRICK
      min-height: 4.2rem;
      width: 100%;
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }
  }

  .social-legals {
    margin-top: 3.2rem;
    align-items: center;
    .copyright {
      text-align: center;
    }
  }

  .footer-row {
    flex-direction: column-reverse;
  }
}

.newsletter-container {
  .invalid-feedback.globalerrors {
    left: 0.4rem;
    bottom: 1.6rem;
  }
}


#newsletter-foreign-modal,
#newsletter-modal {
  &.modal-isml.modal-right {
    .modal {
      &-container {
        margin: 0 auto 3.2rem;
        &.newsletter-modal--noimg {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
