#checkout-main .cart-wrapper {
  flex-direction: column;

  .condition-sale-list-wrapper {
    display: flex;
    align-items: flex-start;

    label {
      width: calc(100% - 2.4rem);
    }
  }
}

.g-recaptcha div {
  margin: auto;
}

.checkoutpage .checkout__left {
  padding-right: 1.6rem;
}

.shipment-selector-block {
  .action-buttons {
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-end;
    justify-content: flex-start;

    /* span:not([class^='icon--']) {
      display: none;
    } */
  }
}

#shipping-container-form-block .shipment-selector-block .row button:last-child {
  margin-right: 0;

  .edit-label {
    font-weight: 700;
  }
}

.row .col-md-6:not(:last-child) {
  margin-bottom: 0.8rem;

  .edit-label {
    font-weight: 700;
  }
}

#checkout-begin {
  header {
    .checkout-header-wrapper>div {
      width: auto;
    }

    .container.navigation {
      border-bottom: 1px solid $light_grey;
      margin: 0 2.4rem;
      padding: 2.4rem 0;
      width: unset;
      align-items: center;
    }

    .checkout-steps.d-block.d-md-none.steps-mobile {
      max-width: unset;
      width: unset;
      margin: 1.6rem 2.4rem;
    }

  }
}

.checkout-footer .container {
  flex-direction: column;

  .legals {
    max-width: unset;
    text-align: center;
    margin-bottom: .8rem;
  }
}