.checkout-shipping {
  &__mode-selector {
    display: flex;
    margin-bottom: $space-unit * 6;

    button:not(:last-child) {
      margin-right: $space-unit;
    }

    .radiobox {
      [role='radio'] {
        &[aria-checked='true'] {
          .radiobox__option {
            background-color: $black;
            color: $white;
          }
        }
      }
    }
  }

  &__address {
    &__address-book {
      margin-top: $space-unit * 6;
      margin-bottom: $space-unit * 8;

      .subtitle {
        margin-bottom: $space-unit * 3;
      }

      p {
        @extend %primary-s;
      }

      &__list {
        display: grid;
        grid-auto-rows: 8.2rem;
        grid-template-columns: repeat(3, 1fr);
        gap: $space-unit;
        margin-top: $space-unit * 3;

        button {
          border: $space-line solid black;
          padding: $space-unit * 3;
          @extend %primary-s;
          text-align: left;
          display: flex;
        }
      }

      &__new {
        margin-top: $space-unit * 6;
      }
    }
  }

  &__options {
    margin-top: 6rem;
    margin-bottom: $space-unit * 4;

    &__title {
      margin-bottom: $space-unit * 3;
      @extend %primary-s;
    }

    &__deliveryinfo {
      margin-top: $space-unit * 4;
      margin-bottom: $space-unit * 5;

      button {
        text-decoration: underline;
        text-align: left;
      }
    }

    &__option {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 1.6rem;
    }

    &__option-name {
      font-size: 14px;
      line-height: 18px;

    }

    &__option-description {
      font-size: 12px;
      line-height: 16px;

      color: $dark_grey;
      margin-top: $space-unit;
    }

    &__option-cost {
      font-size: 14px;
      line-height: 18px;

      margin-top: $space-unit * 2;
    }

    .is-disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.25;
    }

    .shipping-radio {
      margin-top: ($space-unit * 6);
    }
  }

  .checkoutcountryselector {
    margin-bottom: $space-unit * 7;
  }

  // store option

  .option-store {
    display: grid;
    grid-template-columns: 2rem auto;

    &__index {
      grid-column: 1;
      grid-row: 1 / 3;
    }

    &__title {
      @extend %primary-s;
      margin-bottom: $space-unit;
    }

    &__address {
      @extend %primary-xxs;
    }
  }

  .address_form {
    text-align: left;

    >.form-title {
      margin-bottom: ($space-unit * 6);
    }

    >.row {
      margin-left: -8px;
      margin-right: -8px;

      >[class^='col-'] {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    .back-button {
      align-self: center;
    }
  }

  .addressbook-container {
    flex-wrap: wrap;
    padding: 20px;

    [role='radio'][aria-checked='true'] {
      border: 1px solid $black;

      &::after {
        content: '';
        @include fixedSprite('checkmark-radio');
        background-color: $black;
        border-radius: 50%;
        position: absolute;
        top: 16px;
        right: 16px;
      }

      .radiobox__option {
        border: none;
      }
    }

    [role='radio'] {
      position: relative;
      border: 1px solid #ddd;
      border-radius: 8px;
      max-width: 30%;

      &::after {
        content: '';
        background-color: $white;
        border: 1px solid $black;
        border-radius: 50%;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 1.6rem;
        height: 1.6rem;
        display: block;
      }

      &:last-child {
        max-width: 100%;
        background-color: $black;
        border-radius: 8px;

        &::after {
          display: none;
        }
      }

      &:not(:last-child) {
        margin: 0 3.33% 20px 0;
      }
    }

    .radiobox__option {
      height: auto;
    }

    .option-address_newaddress {
      min-height: 100%;
      color: $white;
    }

    .details {
      text-align: left;
    }
  }
}