[data-whatintent='keyboard'] {
  header {

    .wrapper-submenu,
    .menu-actions .icon--menu {
      transition: none;
    }
  }
}

header {
  position: fixed;
  top: 3.2rem;
  left: 0;
  width: 100%;
  // transition-property: background, transform;
  // transition-duration: 0.4s;
  // transition-timing-function: $easeOutQuart;
  // @include animate;
  @include z(header);

  [data-react='minicart-app'] {
    .tmploader {
      display: flex;
    }
  }

  ul {
    @include reset-list;
  }

  &.checkout {
    position: relative;

    +main {
      background: $white;
      padding-top: ($space-unit * 8);
    }

    .navigation {
      h1 {
        @extend %primary-m-u;
        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $overlay;
    @include z(neg);
    @include animate(0.3);
    opacity: 0;
    visibility: hidden;
  }

  .ticker-bar {
    position: relative;
    width: 100%;
    height: $ticker-bar;
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 0.2s ease-out;
    @extend %primary-xs-u;

    &.clickfromstore {
      background-color: $status_error;
    }

    .icon--close-white {
      position: absolute;
      right: $space-unit;
      top: 50%;
      transform: translateY(-50%);
    }

    &.close {
      height: 0;
    }
  }

  .navigation {
    // height: $header-height-desktop;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include animate(0.3);
  }

  [data-menu-back] {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 56px;
    height: 56px;
    z-index: 1;
    background-size: 16px;
    @include rounded-box;
    @include animate(0.3);
  }

  .logo-header {
    // position: absolute;
    // top: 0;
    // left: 24px;
    display: block;
    outline-offset: 1px;
    @include z(logo);
    @include animate(0.3);
    
    &--unsubscription {
      img {
        margin: 0 auto;
      }
    }

    img {
      display: block;
      width: 5.6rem;
    }
  }

  &.header-unsubscription {
    top: 2.4rem;
  }

  .menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-footer {
    display: none;
  }

  .menu-heading,
  .submenu-heading {

    &,
    a {
      display: block;
      @include del-link;
    }
  }

  nav {
    sup {
      vertical-align: super;
      font-size: 0.9rem;
      display: contents;
    }
  }

  .menu-heading {
    padding: 19px 12px;
    // to avoid button appereance on some browsers
    appearance: initial;
    @include animate(0.3);
    @include z;

    &,
    a {
      @extend %primary-xs;
    }

    &:focus {
      @include focus-underline;
    }

    &.featured {
      color: $red;
    }
  }

  .submenu-heading {
    margin-bottom: ($space-unit * 3);
    color: $dark_grey;
    font-size: 10px;
    line-height: 14px;

    &,
    a {
      text-transform: uppercase;

      &:focus {
        @include focus-underline;
      }
    }
  }

  .submenu-group {
    ul li {
      margin-bottom: ($space-unit * 3);

      &:last-child {
        margin-bottom: 0;
      }
    }

    // &:last-of-type {
    //   padding-bottom: ($space-unit * 8) + 1;
    // }
  }

  .submenu-item {
    @extend %primary-xs;
    @include del-link;
    // @include hover(0.3);

    &.submenu-item-empty {
      color: red;
    }
  }

  .wrapper-submenu {
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    transform: translateX(3.2rem);
    opacity: 0;
    visibility: hidden;
    @include animate(0.3);
    overflow: hidden;
    background-color: $white;
  }

  .visual-submenu,
  .container-submenu {
    // display: flex;
    justify-content: center;
  }

  .container-submenu {
    padding: 32px 24px 24px;
  }

  .main-submenu {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  .visual-submenu__item {
    flex: 1 0 25%;
    display: block;
    max-width: 25%;
  }

  .menu-actions {
    display: flex;
    align-items: center;
    height: 100%;

    .menu-icon {
      border-left: 1px solid $grey_line;

      .icon--account,
      .icon--bag,
      .icon--wish,
      .icon--wish-full,
      .icon--search {
        @include hover(0.3);
      }

      button,
      .icon--account,
      .icon--bag,
      .icon--wish,
      .icon--wish-full {
        &:not(.icon--info-red) {
          padding: 20px;
          width: 5.6rem;
          height: 5.6rem;
          background-origin: content-box;          
        }

      }

      .icon--wish,
      .icon--wish-full {
        display: flex;
      }
    }

    .account-wrapper {
      .account-cta {
        text-decoration: none;
        display: flex;
        align-items: center;

        .account-cta__label {
          @extend %primary-s;
          margin-left: 0.2rem;
        }
      }

      .account-logout {
        @extend %primary-s;
        display: none;
      }
    }

    .icon--bag {
      position: relative;

      span {
        @extend %primary-xxs;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-15%, -15%);
        width: 1.6rem;
        height: 1.6rem;
        background-color: $red;
        border: 1px solid $white;
        border-radius: 50%;
        color: $white;
        font-size: 0.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .hamburger {
      display: flex;
      flex-direction: column;
      height: $space-unit * 4;
      width: $space-unit * 4;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &__line {
        width: 16px;
        height: 2px;
        background-color: $black;
        @include animate;

        &:nth-child(1) {
          transform-origin: top right;
        }

        &:nth-child(2) {
          margin: 4px 0;
        }

        &:nth-child(3) {
          transform-origin: bottom right;
        }
      }

      &[aria-expanded='true'] {
        .hamburger__line {
          &:nth-child(1) {
            transform: rotate(-45deg) translate(-2.5px, -1.5px);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(45deg) translate(-2.5px, 1.5px);
          }
        }
      }
    }
  }

  .minibag-wrapper {
    display: flex;
  }

  .container-nav {
    transition: transform 0.3s ease-in-out;
    padding: 0.8rem 0.8rem 0;
  }

  .nav-wrapper {
    display: flex;
    align-items: center;
  }

  .image-column {
    position: relative;

    >a {
      display: block;
      outline-offset: 1px;
      height: 100%;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    &::after {
      content: '';
      position: absolute;
      opacity: 0.4;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000 100%);
      height: 85px;
      width: 100%;
      bottom: 0;
    }
  }

  .image-heading {
    position: absolute;
    color: white;
    bottom: 16px;
    left: 16px;
    right: 16px;
    z-index: 1;
  }

  .icon--menu {
    display: none;
  }
}

body#storelocator-stores {
  padding-top: 14rem;
}