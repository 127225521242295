.product-carousel {
  padding: ($space-unit * 8) 0 ($space-unit * 4);
}

.product-carousel__carousel-container {
  .single-slide__labels {
    margin-bottom: $space-unit * 2;
  }
}

.product-carousel__text {
  text-align: left;
  margin-top: $space-unit * 8;
  margin-bottom: $space-unit * 6;
}

.product-carousel__title {
  padding-left: 1.6rem;
}

.swiper-pagination-progressbar {
  width: 100%;
  .swiper-pagination-progressbar-fill {
    width: 100%;
  }
}
