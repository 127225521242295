.paymentMethod {
  margin: 0;
  background: #fff;
  border: 0;
  padding: 2.4rem;
  border-radius: 0.8rem;
  position: relative;

  transition: all 0.4s ease-in-out;

  &.selected {
    border-color: $black;
  }

  .additionalFields>* {
    margin: 2.4rem 0 0;
  }
}

.paymentMethod_img_scheme::after {
  content: '';
  margin: 0 0.8rem 0 0;
  @include fixedSprite('multiple-card');
}

.paymentMethod_img_DW_APPLE_PAY::after {
  content: '';
  margin: 0 0.8rem 0 0;
  @include fixedSprite('payment-applepay');
}

.paymentMethod_img_cashondelivery::after {
  content: '';
  margin: 0 0.8rem 0 0;
  @include fixedSprite('pay-cashondelivery');
}

.additionalFields {
  display: inline-block;
  width: 100%;
}

.adyen-checkout__input-wrapper .adyen-checkout__dropdown__list {
  z-index: 10;
}

#paymentMethodsUl {
  width: 80%;
  padding-inline-start: 0;
  margin: auto;
}

.adyen-checkout__input {
  border: 1px solid $medium_grey;
  border-radius: 4px;

  &:hover {
    border-color: $medium_grey;
  }
}

.adyen-checkout__input:focus {
  box-shadow: none;
  border: 1px solid $medium_grey;
}