// COLOR SELECTOR USED IN: PDP, Reserve in boutique

.selector-color-expanded {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  color: $black;

  .selector__label {
    display: flex;
    align-items: center;
    padding: 0;

    &_text {
      font-size: 1.2rem;
      line-height: 2rem;
    }

    .errormessage {
      display: block;
      text-transform: none;
      position: absolute;
      right: 2rem;
      color: $status_error;
    }

    .selector__button__info {
      margin-left: ($space-unit * 2);
      opacity: 0.5;
      text-transform: initial;
    }
  }

  .selector__menu {
    display: flex;
    align-items: center;
    padding-top: 0.8rem;

    .label {
      @extend .visually-hidden;
    }

    &__list {
      @include reset-list;
      display: flex;

      &__element {
        &:not(:first-child){
          .selector__menu__item {
            margin-left: 0.8rem;
          }
        }
      }


    }

    &__item {
      display: flex;
      align-items: center;
      padding-bottom: 0.8rem;

      &:hover:not(.selected) {
        .swatch {
          &::after {
            transform: translateX(-50%) scaleX(1);
          }
        }
      }

      &__label {
        @extend .visually-hidden;
      }

      .swatch {
        margin-right: 0;
        @include touchtarget-area();
        &::after {
          content: '';
          height: 0.1rem;
          bottom: -0.5rem;
          background-color: $grey_line;
          width: $swatch-size-desktop;
          @include center('horizontal');
          @include animate(0.3);
          transform: translateX(-50%) scaleX(0);
        }
      }

      &.is-disabled {
        position: relative;
        .stroke {
          content: '';
          position: absolute;
          width: 3.2rem;
          height: 0.1rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          background-color: $black;
        }
      }

      // &.is-disabled:not(.selected) {
      //   .swatch--blue,
      //   .swatch--black {
      //     & + .stroke {
      //       background-color: $white;
      //     }
      //   }
      // }

      &:focus {
        outline: none;

        .swatch {
          @include touchtarget-focus();
        }
      }

      &.selected {
        position: relative;
        border-bottom: 0.03rem solid $black;
        .swatch {
          // border-bottom: 2px solid $red;
        }
      }
    }
  }

  &.bottom-divider {
    @include border('bottom');
  }

  //INVERTED VARIANT

  &.inverted {
    border-color: $white_inactive;
    color: $white;

    .selector__menu__item {
      color: inherit;
      &.selected {
        .swatch {
          &::after {
            background-color: $white;
          }
        }
      }
      &:focus {
        .swatch {
          @include touchtarget-focus($outline-color-inverted);
        }
      }
    }
  }

  //INTILE VARIANT

  &.intile {
    height: $selector-size-intile-height;
  }
}
