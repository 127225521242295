[data-component='BenefitBarComponent'] {
  li {
    top: unset;
    left: unset;
    transform: unset;
    width: 100%;
    text-decoration: underline;
    
    &.active{
      position: relative;
      padding: 0.8rem 2rem;
    }
  }
}
