// @font-face {
//   font-family: Lato;
//   src: local(Lato-Light), url(/path/to/Lato-Light.woff2) format('woff2'), url(/path/to/Lato-Light.woff) format('woff'), url(/path/to/Lato-Light.ttf) format('truetype');
//   font-weight: 300;
//   font-style: normal;
//   font-stretch: normal;
// }
// @font-face {
//   font-family: Lato;
//   src: local(Lato-Regular), url(/path/to/Lato-Regular.woff2) format('woff2'), url(/path/to/Lato-Regular.woff) format('woff'), url(/path/to/Lato-Regular.ttf) format('truetype');
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
// }
// @font-face {
//   font-family: Lato;
//   src: local(Lato-Bold), url(/path/to/Lato-Bold.woff2) format('woff2'), url(/path/to/Lato-Bold.woff) format('woff'), url(/path/to/Lato-Bold.ttf) format('truetype');
//   font-weight: bold;
//   font-style: normal;
//   font-stretch: normal;
// }

html {
  letter-spacing: 0.4px;
  -webkit-text-size-adjust: none;
}


.T3blackleft {
  font-family: Lato;
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;

  color: var(--black);
}

.labelgrayleft {
  font-family: Lato;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;

  color: #6f6f6f;
}


.T1 {
  font-size: 5.6rem;
  line-height: 6.4rem;
}

.T2 {
  font-size: 4.8rem;
  line-height: 5.6rem;
}

.T3 {
  font-size: 4rem;
  line-height: 4.8rem;
}

.T4 {
  font-size: 3.2rem;
  line-height: 4rem;
}

.T5 {
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.T6 {
  font-size: 2rem;
  line-height: 2.8rem;
}

.Par {
  font-size: 1.4rem;
  line-height: 2.2rem;

  &-small {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  &-big {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  &-large {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }

  &-desc {
    font-size: 3.2rem;
    line-height: 4rem;

    &2 {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  &-mini {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

.Val1 {
  font-size: 2rem;
  line-height: 2rem;
}

.Val2 {
  font-size: 1.4rem;
  line-height: 2rem;
}

.CTA {
  font-size: 1.4rem;
  line-height: 2rem;
  text-decoration: none;

  &-u {
    font-size: 1.4rem;
    line-height: 2rem;
    text-transform: uppercase;
    text-decoration: none;
  }
}

.Caption {
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;
}

.Caption-l {
  font-size: 1.4rem;
  line-height: 2rem;
}

.underline {
  text-decoration: underline;
}

.text {
  &-light {
    font-weight: 300;
  }

  &-normal {
    font-weight: 400;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.text {
  &-normal {
    font-weight: 400;
  }
  &-bold {
    font-weight: 700;
  }

  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-gray {
    color: #6f6f6f;
  }
  &-grey {
    color: #6f6f6f;
  }
  &-white {
    color: rgb(255, 255, 255);
  }
  &-black {
    color: #1B1B1A;
  }
  &-green {
    color: #428623;
  }
  &-grey8 {
    color: #404A52;
  }
}

.bold {
  font-weight: 700;
}

.no-underline {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}