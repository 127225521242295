
body.modal-open-hidden {
  overflow: hidden;
}


.modal {
  padding: 0 ($space-unit * 4) ($space-unit * 4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  height: 100%;
  width: 100%;
  overflow: auto;
  border-radius: .4rem;

  .row.button-row{
    margin-top: 0.8rem; 
  }

  &.modal--small {
    max-width: 500px;
  }

  &.sizevariation-modal {
    height: fit-content;
  }

  &#filters-modal {
    opacity: 0;
    visibility: hidden;
    &.is-open {
      @include z('modal');
      opacity: 1;
      visibility: visible;
    }
  }

  // overflow-x: hidden;
  &.modal--no-padding {
    padding: 0;
  }

  &__body {
    max-height: inherit;
  }

  &.modal--centered {
    text-align: center;
    min-height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 68.6rem;
    min-width: 40%;
  }

  &.modal--minheight {
    min-height: 90vh;
    display: flex;
    align-items: stretch;
    .modal__body {
      width: 100%;
    }
  }

  .withimage {
    &__body {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 100%;
      height: 100%;
      min-height: 300px;
    }
    &__right {
      overflow: hidden;
      margin-top: 2.2rem;
      padding-left: $space-unit * 4;
      padding-right: $space-unit * 4;
      display: flex;
      flex-direction: column;
    }
    &__left {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $black;
    }
  }

  &.remove-modal {
    width: 44rem;
    min-width: 44rem;
    padding: 1.6rem 6.4rem 2.6rem;
    border-radius: 0.8rem;

    .modal {
      &__close {
        padding: 2.4rem;
      }

      &__header {
        align-items: center;
        
        .dialog__title {
          line-height: 2.6rem;
          padding-bottom: 0.8rem;
        }
      }

      &__body {
        .remove-item {
          font-size: 1.4rem;
          line-height: 2.2rem;

          &-btn {
            padding: 1.2rem 2.4rem;
            align-self: center;
            margin: auto 0;
            width: fit-content;

            &-wrapper {
              padding-top: 1.6rem;              
            }
          }
        }
      }
    }
  }
}

.modal__header {
  display: flex;
  // position: relative;
  flex-direction: column;
  padding-top: ($space-unit * 6);
}

.modal__divisor {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  border: none;
  @include border('top');
  &.noabs {
    position: relative;
  }
}

.dialog__title {
  @extend %primary-m;
  //padding-top: ($space-unit * 8);
  font-size: 1.8rem;
  padding-bottom: 1.6rem;
  font-weight: 400;
}

.dialog__description {
  line-height: 1.6rem;
  margin-top: -0.6rem;
  margin-bottom: ($space-unit * 6);
}

.modal__close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1.8rem 1.6rem;
  z-index: 3;

  &::before {
    content: '';
    @include fixedSprite('close');
    display: block;
  }

  &:focus {
    outline: transparent;
    &::before {
      outline: $outline-width solid $outline-color;
    }
  }
}

.modal__intro {
  margin: ($space-unit * 6) 0;

  .status {
    @extend %primary-s;
    display: block;
    margin-bottom: ($space-unit * 2);
  }
}

.overlay-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $black_secondary;
  @include z(modal);
}

.country-modal,
.language-wrapper {
  ul,
  ol {
    @include reset-list;
    li {
      a {
        @extend %primary-s;
        @include del-link;
        padding: ($space-unit) 0;
        text-transform: capitalize;
        display: inline-flex;
        align-items: center;

        .countryname {
          margin-right: 0.6rem;
          display: inline-block;
        }

        .icon--bag {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  @include animate(0.4, 'opacity');
  &--after-open {
    opacity: 1;
  }
  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {
  transform: translate(-50%, -50%) translateY(3.2rem);
  @include animate(0.4, 'transform');
  &--after-open {
    transform: translate(-50%, -50%);
  }
  &--before-close {
    transform: translate(-50%, -50%) translateY(3.2rem);
  }
}

// .ReactModal__Body--open {
//   padding-right: var(--scrollbar-width);
//   header,
//   .sticky-tray,
//   .cookie-bar {
//     padding-right: var(--scrollbar-width);
//   }
// }

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.modal-isml {
  display: none;
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform;
    z-index: 999;
  }

  .modal-container {
    position: relative;
    background-color: #fff;
    padding: 0;
    max-width: 500px;
    width: 100%;
    max-height: 100vh;
    border-radius: 4px;
    overflow-y: hidden;
    box-sizing: border-box;
    will-change: transform;

    &.tiny {
      max-width: 38rem;
    }
  }

  .modal-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;
    &::after {
      content: '';
      @include fixedSprite('close');
    }
  }

  .modal-header {
    padding: 2.4rem 6.4rem 1.6rem 2.4rem;
  }

  .modal-footer {
    margin-top: 2rem;
  }

  .modal-footer-buttons {
    button {
      width: 100%;
    }
  }

  .modal-content {
    padding: 2.4rem;
    padding-top: 0;
    height: calc(100% - 62px);
    overflow-y: auto;
  }

  &.modal-full-width {
    .modal-container {
      max-width: 100vw;
    }
  }

  &.modal-left,
  &.modal-right {
    .modal-container {
      border-radius: 0;
    }

    .modal-header {
      @extend %primary-l;
    }
  }

  &.modal-left {
    .modal-overlay {
      justify-content: flex-start;
      align-items: stretch;
    }
  }
  &.modal-right {
    .modal-overlay {
      justify-content: flex-end;
      align-items: stretch;
    }
  }
  &.modal-bottom {
    bottom: 0;
    .modal-overlay {
      background: transparent;
      top: inherit;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .modal-container {
      border-radius: 0;
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.25);
    }
  }

  @media (max-width: get($grid-breakpoints, sm)-1) {
    .modal-container.newsletter-foreign-modal {
      max-width: 100%;
    }
    &.modal-md-bottom {
      bottom: 0;
      .modal-overlay {
        background: transparent;
        top: inherit;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .modal-container {
        border-radius: 0;
        box-shadow: 0 0 11px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &.map-modal {
    .modal-container {
      width: 100%;
      height: 100%;
      max-width: 71rem;
      max-height: 58rem;
    }
  }

  &.is-open {
    display: block;
  }

  &[aria-hidden='false'] {
    .modal-overlay {
      animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
    .modal-container {
      animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &[aria-hidden='true'] {
    .modal-overlay {
      animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
    .modal-container {
      animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
