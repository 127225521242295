.account-appointments {
  &__list {
    margin-bottom: $space-unit * 6;
    .button.button--secondary {
      width: auto;
    }
    .detail-row {
      .dt {
        @extend %primary-xxs-u;
        margin-bottom: $space-unit;
        display: block;
      }
      .dd {
        > p {
          @include text-shorten;
          margin-bottom: $space-unit;
        }
      }
    }
  }
  .virtual-message {
    margin-bottom: 3.2rem;
  }
}
.item-actions {
  display: flex;
  padding-top: ($space-unit * 4);
  .cta,
  button {
    margin-right: ($space-unit * 3);
  }
}
.remove-item {
  position: relative;
  .item-actions {
    @extend %primary-s;
    padding-top: ($space-unit * 2);
    padding-bottom: ($space-unit * 12);
  }
}
.detail-wrapper {
  position: relative;
  .remove-appointment {
    position: absolute;
    @include z;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
  }
}
