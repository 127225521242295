.divider {
  border: none;
  background-color: $grey_line;
  height: 0.1rem;
  margin: ($space-unit * 3) 0;

  &--large {
    margin: ($space-unit * 6) 0;
    background-color: $black;
  }

  &.divider--no-margin {
    margin: 0;
  }
}