#pdp-gallery-modal {
  .modal-container {
    height: 100%;
  }

  .modal-content {
    height: 100%;
  }

  .icon--close {
    background: none;
  }

  .modal-close {
    top: 4.5rem;
    margin-right: 3.2rem;
    right: 1.6rem;
    z-index: 2;
    padding: 0.4rem;
    cursor: pointer;
    
    &::after {
      @include sprite('close-16');
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  .product-zoom-modal {
    padding: 0;
  }
  .swiper-wrapper {
    overflow: auto;
    flex-direction: column;
    cursor: url(../../images/zoom-out32.png) 16 16, zoom-out;
    background-position: -16px -16px; 
  }
}
.product-gallery {
  width: 100%;
}
.zoom-image-wrapper {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.zoom-thumbnails {
  &-wrapper {
    display: flex;
    width: 5.6rem;
    height: auto;
    position: fixed;
    top: 0;
    left: 2.4rem;
    bottom: 0;
    justify-content: center;
    align-items: center;
    will-change: auto;
    z-index: 1000;
    flex-direction: column;    
  }

  &-img {
    padding: 1px;
    margin-bottom: 0.6rem;
    max-width: 100%;
    display: block;
    height: auto;    



    &-wrapper {
      &.active {
        border-bottom: $black solid 0.03rem;
      }
      &:not(:first-child) {
        margin-top: 0.8rem;
      }

    }
  }

}
