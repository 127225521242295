#cart-show {
  main {
    padding-bottom: 2.4rem;
    background: $white;
    padding-top: $header-height-desktop;
  }

  .selector-size-expanded {
    .selector__header {
      white-space: normal;
    }
  }

  #countryselector-modal {
    display: block;
  }

  .promo-card {
    .form-group {
      position: relative;
      background: transparent;
      margin-bottom: 0;

      input::placeholder {
        color: transparent;
      }

      input:not([type=radio]):placeholder-shown,
      input:not([type=radio]):not(:placeholder-shown):is(:focus) {
        padding: 2.4rem 1.2rem 0.6rem;
      }

      input:not([type="radio"]):placeholder-shown+label {
        cursor: text;
        max-width: 66.66%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 0) scale(1);
      }

      input::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
      }

      input:focus::-webkit-input-placeholder {
        opacity: 1;
      }

      input:not([type="radio"]):focus+label {
        transform: translate(0, -1.2rem) scale(0.9);
        cursor: pointer;
      }

      input:not([type="radio"]):focus {
        border: 1px solid $black;
      }

      input:not(:placeholder-shown):not(:focus):not([type="radio"])+label {
        transform: translate(0, -1.2rem) scale(0.9);
        opacity: 0;
      }

      input:not([type="radio"])+label {
        position: absolute;
        left: 0;
        top: 0;
        padding: 1.6rem 1.2rem;
        background: transparent;
        transition: all 0.4s;
        transform-origin: left bottom;
        color: $dark_grey;
        user-select: none;
        cursor: text;
        pointer-events: none;
      }

      input:not([type="radio"]) {
        -webkit-appearance: none;
        color: $black;
        width: 100%;
        padding: 1.5rem 1.2rem;
        font-size: 1.6rem;
        line-height: 1.8rem;
        transition: all 0.4s $easeOutQuart;
        background: white;
        border: 1px solid $grey_line;
        border-radius: 0.8rem;
      }

      input::-webkit-date-and-time-value {
        text-align: left;
      }

      @media screen and (max-width: 1024px) {
        input[type='date'][value='']:empty:not(:focus):after {
          color: $dark_grey;
          position: absolute;
          left: 0;
          min-height: 2rem;
          padding: 1.5rem 1.2rem;
          font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
          font-size: 1.4rem;
          line-height: 1.8rem;
    
        }
      }
    
      input[type='date'][value='']:empty {
        &::-webkit-datetime-edit-ampm-field,
        &::-webkit-datetime-edit-day-field,
        &::-webkit-datetime-edit-hour-field,
        &::-webkit-datetime-edit-millisecond-field,
        &::-webkit-datetime-edit-minute-field,
        &::-webkit-datetime-edit-month-field,
        &::-webkit-datetime-edit-second-field,
        &::-webkit-datetime-edit-week-field,
        &::-webkit-datetime-edit-year-field,
        &::-webkit-datetime-edit-text,
        &::-webkit-datetime-edit {
          color: transparent;
        }
      }
    }
  }

  #cart-empty {
    .cart--wrapper {
      width: 40%;
      max-width: 55rem;

      .cart__text--empty {
        margin-bottom: 3.2rem;

        .title {
          font-size: 3.2rem;
          font-weight: 300;
          line-height: 3.6rem;
          margin-bottom: .8rem;
        }
      }

      .bag-overview--empty__button-container {
        display: flex;
        gap: 1.6rem;
        margin: auto;
        width: 100%;
      }
    }
  }

  .cart-no-empty {
    .expressCheckout {
      &__btn {
        &--paypal {
          border-color: $yellow;
          background: $yellow;
        }
      }
    }
    
    .product-cards {
      .cards-header {
        .primary-xl {
          font-weight: 400;
        }
      }
    }

    .promo-code-submit {
      margin-top: 1.2rem;
    }

    del {
      opacity: 0.7;
      margin-right: .8rem;

      &+span {
        margin-right: .8rem;
      }
    }
  }

  .cart-info-discount {
    padding: 1.4rem 1.5rem;
    margin-bottom: 2.4rem;
    align-items: center;
    background-color: $grey;
    line-height: 1.8rem;

    &.icon--cart-pack::before{
      content: '';
      @include fixedSprite('pack');
      margin-right: 1.2rem;
      align-self: flex-start;
      min-width: 1.6rem;
    }
  }

  .bag-summary {
    .cart__info-assets p {
      font-weight: bold;
      margin: 1.6rem 0;
      display: flex;
      align-items: flex-start;

      i {
        margin-right: 0.8rem;
      }
    }
  }
}