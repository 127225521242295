.account-addressbook{
  .myaccount__section {
    .box {
      position: relative;
    }

    .cta--remove {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      &::after{
        transform: translateY(0);
        background-origin: content-box;
        width: 1.6rem;
        height: 1.6rem;
        padding: 24px;
      }
    }

    .cta--edit {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
     padding: 24px;
    }

    .primary-xs {
      color: $dark_grey;

    }

    .name{
      margin-bottom: 8px;
    }
  }
}