#checkout-begin {
  header {
    .navigation {
      padding-top: 24px;
      padding-bottom: 24px;
    }
    .need-help {
      display: block;
      text-decoration: none;
    }
    .checkout-steps {
      max-width: 400px;
    }
  }
}
.checkout-footer {
  .container {
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: initial;
  }
  .legals {
    padding-bottom: 0;
    max-width: 40%;
  }
}