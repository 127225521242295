.row-info__column {
  width: 100%;
}
.bag-product__image {
  max-width: 12.2rem;
}

.myaccount-wrapper {
  .orders-listing {
    .bag-product__content {
      max-width: calc(100% - 13.6rem);
      flex-basis: calc(100% - 13.8rem);
    }
  }
}

.bag-product__name-price__name {
  max-width: 50%;
}