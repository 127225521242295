#filters-modal {
  .modal-container {
    margin-top: 120px;
    max-width: 100%;
    overflow: visible;
    .modal-close {
      display: none;
    }
    &::before {
      content: '';
      width: 120px;
      height: 2px;
      position: absolute;
      top: -14px;
      left: 50%;
      transform: translateX(-50%);
      background-color: $white;
    }
  }
}
