.button {
  position: relative;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  @include animate(0.2);
  cursor: pointer;

  &:disabled {
    cursor: none;
    pointer-events: none;
    opacity: 0.25;
  }

  // PRIMARY & SECONDARY

  &[class*='primary'],
  &[class*='secondary'] {
    height: $button-height-desktop;
    text-align: center;
    width: 100%;

    span {
      @extend %primary-xs-u;
      font-size: 1.2rem;
      font-weight: bold;

    }

    &:focus {
      @include focus-underline;
    }
  }

  // PRIMARY

  &--primary,
  &--primary:link,
  &--primary:visited {
    background-color: $black;
    color: $white;

    text-transform: uppercase; // force in case of lowercase properties

    &:focus {
      span {
        text-decoration-color: $white;
      }
    }

    &:hover {
      // color: rgba($white, 0.6);
      text-decoration: none;

      &:focus {
        span {
          text-decoration-color: rgba($white, 0.6);
        }
      }
    }
  }

  &--primary-inverted,
  &--primary-inverted:link,
  &--primary-inverted:visited {
    background-color: $white;
    color: $black;
    text-transform: uppercase; // force in case of lowercase properties

    &:focus {
      span {
        text-decoration-color: $black;
      }
    }

    &:hover {
      color: rgba($black, 0.6);
      text-decoration: none;

      &:focus {
        span {
          text-decoration-color: rgba($black, 0.6);
        }
      }
    }
  }

  // SECONDARY

  &--secondary,
  &--secondary:link,
  &--secondary:visited {
    color: $black;
    border: $space-line solid $black;
    text-transform: uppercase; // force in case of lowercase properties

    &:focus {
      span {
        text-decoration-color: $black;
      }
    }

    &:hover {
      // box-shadow: inset 0 0 0 .1rem $black;
      text-decoration: none;
      // color: rgba($black, 0.6);
      // border-color: rgba($black, 0.6);

      &:focus {
        span {
          text-decoration-color: rgba($black, 0.6);
        }
      }
    }
  }

  &--secondary-inverted,
  &--secondary-inverted:link,
  &--secondary-inverted:visited {
    color: $white;
    border: $space-line solid $white;

    text-transform: uppercase; // force in case of lowercase properties

    &:hover {
      // box-shadow: 0 0 0 .1rem $white;
      text-decoration: none;
      opacity: 0.6;
    }
  }

  // TERTIARY

  &[class*='tertiary'] {
    @extend %primary-xs-u;
    @include hover(0.2);

    &[data-animated] {
      opacity: 0;

      &[data-animated='true'] {
        opacity: 1;
        @include animate(0.4, 'opacity', $easeInOutQuart);
      }
    }

    text-transform: unset;
  }

  &--tertiary,
  &--tertiary:link,
  &--tertiary:visited {
    color: $black;

    font-size: 1.4rem;
    line-height: 1.29;

    border-bottom: solid 0.1rem;

    [class^='icon'] {
      color: $black;
    }

    &:hover {
      &:focus {
        text-decoration-color: rgba($black, $hover-opacity);
      }
    }

    &:focus {
      @include focus-underline;
    }
  }

  &--tertiary-inverted,
  &--tertiary-inverted:link,
  &--tertiary-inverted:visited {
    color: $white;

    [class^='icon'] {
      color: $white;
    }

    &:hover {
      &:focus {
        text-decoration-color: rgba($white, $hover-opacity);
      }
    }

    &:focus {
      @include focus-underline;
    }
  }

  // WITH ARROWS

  &--arrow-right {
    padding-right: ($space-unit * 4);

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-right-black');
      @include fix-font-space(0.1rem);
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('arrow-right-white');
      }
    }

    @include hover(0.2);
  }

  &--arrow-left {
    padding-left: ($space-unit * 4);

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-right-black');
      @include fix-font-space(0.2rem);
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('arrow-right-white');
      }
    }

    @include hover(0.2);
  }

  &--arrow-down {
    padding-bottom: ($space-unit * 4);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-right-black');
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('arrow-right-white');
      }
    }

    @include hover(0.2);
  }

  &--arrow-up {
    padding-top: ($space-unit * 4);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) rotate(-90deg);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-right-black');
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('arrow-right-white');
      }
    }

    @include hover(0.2);
  }

  // LINK

  &--link {
    @extend %primary-xs;

    &:focus {
      @include focus-underline;
    }
  }

  &--fileupload {
    cursor: pointer;

    label {
      &:focus-within {
        outline: 0.3rem solid #80bdff;
      }

      cursor: pointer;
    }

    input {
      position: absolute;
      opacity: 0;
    }

    .tooltip {
      position: absolute;
      right: 1.2rem;
      display: block;
      z-index: 1;
    }
  }

  &.loading {
    overflow: hidden;

    div {
      position: absolute;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border: 0.3rem solid $black;
      animation: 1.5s ripple infinite;
    }
  }

  &--myaccount {
    width: 100%;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    border-radius: 8px;
    margin-bottom: 24px;
  }

  //ANIMATION

  &--primary,
  &--secondary {
    &:not(.expressCheckout__btn--paypal) {
      &:hover {
        span {
          &:before {
            // opacity: 0;
            // transform: translateY(-110%);
            animation: button-rotate 0.5s cubic-bezier(0.1, 0.3, 0.4, 1);
          }

          &:after {
            display: none;
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      span {
        position: relative;
        display: block;
        overflow: hidden;
        // width: 100%;
        height: auto;
        text-align: center;

        &:before,
        &:after {
          content: attr(data-label);
          display: block;
          width: 100%;
          height: auto;
          transition: all 0.4s cubic-bezier(0.27, 0, 0.59, 0.99);
        }

        &:before {
          opacity: 1;
        }

        &:after {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transform: translateY(110%);
        }
      }      
    }

  }
}

button {
  color: $black;
}

.button--paypal {
  border-color: $black;
  @include sprite('payment-paypalbutton');
  background-size: 5.6rem 1.8rem;
  background-position: center;
}

.button--googlepay {
  @include sprite('payment-googlepayexpress');
  background-size: 5.6rem 1.8rem;
  background-position: center;
}

.button--amazonpay {
  @include sprite('payment-amazonpay');
  background-size: 8.6rem 2.8rem;
  background-position: center 0.4rem;

  // .amazonlogo {
  //   @include fixedSprite('payment-amazonpay');
  //   background-size: 8.6rem 2.8rem;
  //   background-position: center 0.4rem;
  // }
  // .chevron {
  //   @include fixedSprite('payment-amazonpay-chevron');
  //   position: absolute;
  //   right: 10px;
  //   width: 16px;
  // }
  // .paywith {
  //   position: absolute;
  //   left: 10px;
  // }
  .adyen-checkout__amazonpay,
  .adyen-checkout__amazonpay__button {
    width: 100% !important;
    height: 36px !important;
    overflow: hidden;
    opacity: 0.2;
  }
}

[data-whatintent='keyboard'] {

  .button.button--paypal,
  .button--googlepay,
  .button--amazonpay,
  .button--applepay {
    &:focus {
      outline: 1px inset $black !important;
      outline-offset: -4px;
    }
  }
}

.use-location {
  position: relative;
  padding: $space-unit;
  margin: 0 $space-unit;

  &::before {
    content: '';
    @extend .icon--pin-full;
    display: block;
    margin-top: -0.2rem;
  }
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes animation-underline {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(100%);
  }

  51% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes button-rotate {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-105%);
  }

  51% {
    transform: translateY(105%);
  }

  100% {
    transform: translateY(0%);
  }
}

.cta--animated {
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;

  &:before {
    content: '';
    display: block;
    border-top: 1px solid $black;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    // animation: link-animation-underline-reverse .8s ease-in-out;
  }

  &:hover,
  &:focus {
    &:before {
      animation: animation-underline 1s cubic-bezier(0.4, 0, 0.4, 1);
    }
  }
}

.cta--editorial-animated {
  position: relative;
  width: fit-content;
  overflow: hidden;
  display: flex;
  padding-bottom: 1px;

  &::before {
    content: '';
    width: 6px;
    height: 6px;
    background-color: $red;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) scale(1);
    transition: all 0.4s cubic-bezier(0.1, 0.3, 0.4, 1);
  }

  span {
    position: relative;
    padding-bottom: 2px;
    transition: all 0.4s cubic-bezier(0.1, 0.3, 0.4, 1);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: $red;
      opacity: 0;
      transition: opacity 0.4s, transform 0.4s;
      transform: translate3d(-100%, 0, 0);
    }
  }

  &:hover {
    &::before {
      transform: translateY(-50%) scale(0);
      transition: all 0.4s cubic-bezier(0.1, 0.3, 0.4, 1);
    }

    span {
      transform: translateX(-14px);
      transition: all 0.4s cubic-bezier(0.1, 0.3, 0.4, 1);

      &::after {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}