.countryselector-navigation {
  margin-top: 5.6rem;
}
.countryselector-wrapper {
  padding: 6.4rem 0 0;

  .modal__header {
    margin-bottom: 6.4rem;

    .dialog__title {
      //text-align: center;
    }
    .dialog__description {
      //justify-content: center;
    }
  }

  .modal__header + .modal__divisor {
    display: none !important;
  }

  .modal__body {
  }
}
