.unsubscriptionPage {
  &__container {
    width: 100%;
    padding-top: 7.7rem;
    margin-top: 10.4rem;
    padding: 0 1.6rem;
  }

  &__header {
    &__title {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }
  &__body {
    padding: 3.2rem 0;

    &--confirm {
      padding: 2.4rem 0 3.2rem;
    }
    
    &__input {
      &_wrapper {
        padding-bottom: 3.2rem;
      }
    }
  }
}

.unsubscription-layout {
  .footer-bottom {
    padding: 0 6rem;

    .social-legals {
      padding: 1.8rem 0;
    }
  }

}