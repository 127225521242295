#order-confirm {
  main {
    background: $white;
    padding-top: $header-height-desktop - 3.2rem;
  }

  .login-form .form-group {
    margin-bottom: ($space-unit * 2);
  }

  .forgot-pw-error {
    margin-bottom: ($space-unit * 8);
  }

  .uppercased {
    text-transform: uppercase;
  }

  .box {
    box-shadow: none;
    border: 1px solid #EEEEEE;
  }
}