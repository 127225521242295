.modal__header {
  position: sticky;
  top: 0;
  right: 0;
  background-color: $white;
  justify-content: center;
  @include z;

  /*&::after {
    content: '';
    position: absolute;
    height: $space-line;
    width: 100vw;
    left: 50%;
    background-color: $grey_line;
    transform: translateX(-50%);
    bottom: 0;
  }*/
  .dialog__title {
    display: flex;
    align-items: center;
  }

  .dialog__description {
    margin-top: ($space-unit * 6);
    margin-bottom: ($space-unit * 4);
  }
}

.modal__body {
  .accordion__group {
    padding-top: ($space-unit * 7);
  }
}

.modal__divisor {
  display: none;
}

.modal {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: none;

  &.sizevariation-modal {
    height: fit-content;
    bottom: 0;
    top: unset;
    max-width: 100%;
  }
}

.ReactModal__Content {
  transition-property: opacity;
  transition-delay: 150ms;
  opacity: 0;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Overlay {
  &.modal-line-overlay::before {
    content: '';
    width: 120px;
    height: 2px;
    position: absolute;
    top: 6.6rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: $white;
  }
}

.modal.profile-modal,
.modal.remove-modal {
  height: fit-content;
  top: unset;
  padding: 2.4rem 1.6rem;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  bottom: 1.4rem;

  .modal {
    &__header {
      padding-top: 0;
    }

    &__close {
      padding-top: 2.4rem;
      padding-right: 1.6rem;
    }
  }
}

.info-modal {
  max-height: 90vh;
  height: fit-content;
  margin-top: auto;
  border-radius: 0.4rem 0.4rem 0 0;
}

.modal {
  &__body {
    max-height: unset;
  }
}