.container--search {
  top: $space-unit * 4;
}
.search-store {
  flex-direction: column;

  &__resultslist {
    z-index: 1;
    .icon--close {
      top: calc(-3.6rem + 0.6rem);
      background-color: white;
    }

    [role='menu'] {
      margin-top: 0;
      border-top: initial;
      max-height: $storelocator-list-height;
    }
  }

  &__results-filters {
    @include border('top');
    position: relative;
    transform: translateY(100%);
    justify-content: space-between;
    padding-right: ($space-unit * 2);
    z-index: 2;
  }

  &__regions {
    top: -3.6rem;
    .wrapper-regions {
      display: block;
      height: $storelocator-list-height;
      max-height: initial;
    }
    .region-col {
      padding-top: 1.2rem;
      padding-bottom: 3.2rem;
    }
    .accordion__item {
      &:first-child {
        .accordion__header {
          border-top: initial;
          text-transform: uppercase;
        }
      }
    }
  }

  &__filters {
    padding: ($space-unit * 4) ($space-unit * 3) ($space-unit * 6) ($space-unit * 3);
    top: 3.5rem;
    border-top: none;

    .filters__menu__menuitem--location {
      position: relative;
      margin-top: 6rem;
      left: -0.7rem;
    }

    .storelocator-filters {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, auto);
      column-gap: 0.4rem;
    }
  }

  .use-location {
    position: absolute;
    top: -50%;
    transform: translateY(-50%);
    right: $space-unit*0.5;
    padding: 0;
  }
}

.store {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: auto;
  padding-top: 3.2rem;
  background-color: initial;

  &.open {
    .store__moreinfo {
      max-height: 55vh;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: ($space-unit * 5);
    transform: translateX(-50%);
    width: 3.2rem;
    height: 0.3rem;
    background-color: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  }

  .store-wrapper {
    background-color: white;
  }

  .icon--close {
    top: calc(3.2rem + 1.2rem);
  }

  &__summary {
    padding: ($space-unit * 4) 0;
  }

  &__moreinfo {
    max-height: 0;
    @include animate;

    &__section {
      padding: ($space-unit * 4) 0;
    }
  }
}
