.carousel-slide__text-holder {
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.editorial-carousel {
  padding: ($space-unit * 8) 0 ($space-unit * 10);
}

.editorial-carousel {
  .editorial-carousel__carousel-slide {
    &:not(.carousel-slide--text-slide) {
      width: 70rem;
    }
  }
}
