.discount{

  &-container {
    margin-bottom: 2.4rem;
    .icon--delivery::before{
      content: '';
      @include fixedSprite('shipping-16');
      margin-right: 1.2rem;
      align-self: flex-start;
      min-width: 1.6rem;
    }
  }

  &-content{

    &--approaching{

      &-progress{
        height: 0.4rem;
        border-radius: 1rem;
        margin-top: 0.8rem;
        background-color: $red;
        width: 0;
      
        &-border {
          height: 0.4rem;
          border-radius: 1rem;
          width: 100%;
          margin-top: 0.8rem;
          background-color: $grey;
        }
      }
    }

    &--applied{
      padding: 1.4rem 1.6rem;
      background-color: $light_grey;
      line-height: 1.8rem;
    } 
  }
}
