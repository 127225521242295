.bag-product {
  &__actions {
    bottom: -2.4rem;
    transform: translateY(100%);
    position: absolute;
    height: 1.6rem;
  }

  &__details {
    .product-card__promo {
      padding-top: 1.6rem;
    }
  }

  &:not(:last-child) {
    margin-bottom: 3.2rem;
    padding-bottom: calc(2.4rem + 2.4rem + 1.6rem);
  }

  &:last-child {
    padding-bottom: calc(2.4rem + 4rem + 1.6rem);
  }
}
