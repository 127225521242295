.text-assets {
  padding: ($space-unit * 16) 3.2rem;
}

.sub-headline {
  &__inner-container {
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
    max-width: 100%;
  }
  &--short {
    .sub-headline__inner-container {
      width: 32.7rem;
    }
  }
  &--long {
    .sub-headline__inner-container {
      width: 56rem;
    }
  }
}

.centered-text-block {
  &__inner-container {
    width: 50%;
    margin: 0 auto;
    max-width: 100%;
  }

  &--left-justified {
    text-align: justify;
  }

  &--left-aligned {
    text-align: left;
  }

  &--centered {
    text-align: center;
  }
  &__cta {
    text-align: center;
    margin-top: ($space-unit * 6);
    & > .button {
      display: inline-flex;
      width: auto;
      min-width: auto;
    }
  }
}

.two-column-text-block {
  &__inner-container {
    width: 66.67%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }

  &__inner-container > * {
    width: calc(50% - 1.4rem);
  }

  &--left-justified {
    text-align: justify;
  }

  &--left-aligned {
    text-align: left;
  }
}

.text-column {
  &__inner-container {
    width: 33.33%;
    max-width: 100%;
    margin: 0 auto;
    & > *:first-child {
      margin-bottom: ($space-unit * 6);
    }
  }

  &--capitalize:first-letter {
    text-transform: uppercase;
    font-size: 6.2rem;
    line-height: 7.4rem;
    margin-right: $space-unit;
    margin-bottom: -2rem;
    float: left;
  }
}
