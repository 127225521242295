.container-two-col {
  max-width: 70%;
  flex-direction: column;
}
.sidebar-col {
  order: 2;
}
.main-col {
  order: 1;
}
