$checkout-font-weight: 600;

$receipt-font-size: 0.875rem;
$receipt-spacing: 0.625em;
$stored-payment-spacing: 1rem;

.page {
  background-color: grey;
}

.checkout-card-header {
  font-size: 1.5rem;
}

.grand-total-price {
  float: right;
  font-weight: $checkout-font-weight;
}

.order-receipt-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.product-divider {
  hr {
    border-top: dashed 0.063em;
  }
}

.shipment-block+.shipment-block::before {
  content: "";
  display: block;
}

.hero-confirmation {
  //background-image: url('../../images/thankyou.jpg');
  background-position-y: -8.125em;
}

.product-summary-block {
  margin: 0 0 2.4rem 0;
}

.leading-lines {
  overflow: hidden;

  &:not(.row) {
    margin: 0;
  }

  label {
    background-color: white;
  }

  .end-lines {
    span {
      position: relative;
      background-color: white;
      z-index: 2;
    }
  }
}

.summary-details .address-summary {
  .info-shipping {
    margin-bottom: 0.4rem;
  }
}

.add-payment {
  margin-top: $stored-payment-spacing;
}

.selected-payment {
  background-color: gray;
}

.saved-security-code {
  margin-top: $stored-payment-spacing;
}

.saved-credit-card-type {
  font-weight: 600;
}

.saved-payment-information {
  margin-top: $stored-payment-spacing;
  margin-bottom: $stored-payment-spacing;
}

.payment-information {
  margin-bottom: $stored-payment-spacing;
}

.checkout-hidden {
  display: none;
}

.card-image {
  margin-top: 0.5rem;
  width: 100%;
}

.cancel-new-payment {
  margin-top: $stored-payment-spacing;
}

.multi-ship .single-shipping .shipping-content {
  display: none;
}

.multi-ship .shipping-summary .single-shipping {
  display: none;
}

.gift-message-block {
  padding-bottom: 1em;
  padding-top: 1em;
}

.multi-ship .confirm-details .single-shipping {
  display: none;
}

.multi-shipping {
  display: none;
}

.view-address-block h3,
.shipping-method-block h3 {
  font-size: 1.25rem;
}