.lunettes__component-wrapper {
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include appearAnimation();
  &.open {
    .lunettes__product-slider {
      visibility: visible;
    }
  }
}

.lunettes__component {
  transition: all 0.4s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
  background-color: #000;
  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.lunettes__text-holder {
  position: absolute;
  left: 50%;
  bottom: 2.4rem;
  transform: translateX(-50%);
  z-index: 4;
  width: 100%;
  padding: 0 1.6rem;
}

.lunettes__title {
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 1.75rem;
  text-align: center;
}

.lunettes__cta-container {
  text-align: center;
}

.lunettes__product-slider {
  visibility: hidden;
  transition: all 0.4s;
  background-color: $black;
  padding: 0 ($space-unit * 8);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.product-slider__single-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }
}
