#countryselector-modal {
  .countryselector-wrapper {
    margin: 2.4rem 0 0;
    padding: 0;
  }

  .modal__divisor {
    display: block;
  }

  .modal__header {
    //margin-bottom: 2.4rem;
    //margin-top: 3.2rem;
    padding-bottom: 2.4rem;
    padding-top: 3.2rem;
    .dialog__title {
      @extend .Par-large;
      height: unset;
    }

    .dialog__description {
      color: $dark_grey;
      margin-top: 0.8rem;
      margin-bottom: 0;
    }
  }

  //.modal__header {
  //  position: unset;
  //}
  .modal-close {
    display: none;
  }

  .country-selector-container {
    .country-selector-element {
      flex: 1 0 50%;
    }
  }
}
