.checkoutpage {
  .adyen-checkout__input {
    @extend %primary-s; // minimum input size is s, should never be xs
    border-radius: 0.8rem;
    border: $space-line solid $grey_line;
    color: $black;
    padding: 0.9rem 0.7rem;
    background-color: $input_background;
  }
  .adyen-checkout__button.adyen-checkout__button--pay {
    border-radius: 0;
  }
  .adyen-checkout__label {
    &--focused {
      .adyen-checkout__label__text {
        @extend %primary-xs;
        color: $black;
      }
      .adyen-checkout__input {
        border: $space-line solid $grey_line;
      }
    }
    .adyen-checkout__label__text {
      @extend %primary-xs;
    }
  }
  .adyen-checkout__card__holderName__input {
    border: $space-line solid $grey_line;
  }
  // .adyen-checkout__card-input {
  //   margin-bottom: ($space-unit * 8);
  // }

  .adyen-checkout__field--storedCard {
    margin-left: 0 !important; // since date is hidden there should be no margin
    // &:not(.adyen-checkout__field__cvc) {
    //   display: none; // hide expiry date
    // }
  }
  .adyen-checkout__card__cardNumber__brandIcon,
  .adyen-checkout__icon,
  .adyen-checkout__spinner__wrapper {
    filter: grayscale(100%);
  }
  .adyen-checkout__button--pay {
    @extend .button;
    @extend .button--primary;
    height: $button-height-desktop;
    padding: 0 ($space-unit * 4);
    width: 100%;
  }
  .adyen-checkout__amazonpay__button--changeAddress {
    @extend .button;
    @extend .button--secondary;
    height: $button-height-desktop;
    padding: 0 ($space-unit * 4);
    margin-top: $space-unit * 4;
    width: 100%;
  }
}
#threedscontainer {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  > * {
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    > iframe {
      flex-grow: 4;
    }
  }
}
