.product-carousel__carousel-container {
  &:not(.without-border) {
    padding: 0 0 0 2.4rem;
  }

  .swiper-button-container {
    display: none;
  }
}

.product-carousel__title {
  font-size: 2.8rem;
  padding-left: 2.4rem;
}

.swiper-pagination-progressbar {
  margin: 3rem 0 6rem 0;
  width: calc(100% - 24px);
  .swiper-pagination-progressbar-fill {
    width: calc(100% - 24px);
  }
}

.product-carousel__carousel-container .wrapper-swiper-scrollbar{
  padding-right: 0;
}