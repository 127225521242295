.modal {
  &--country-selector.modal {
    height: 67.1rem;
    padding: 0;
    .modal {
      &__header {
        padding: 2.2rem 1.6rem 1.2rem;
        border-bottom: 1px solid #fff;
      }

      &__body {
        padding: 0 1.6rem;
      }
    }
  }
}
