.account-header {
  background-color: #c69983; // placeholder for image, similar color
  background-image: url('../../images/account-header.jpg');
  background-size: cover;
  height: 244px;

  &+nav{
    background: white;
    top: 0;
  }
}

.testbox {
  border: 2px solid black;
  background-color: pink;
  min-height: 200px;
}

.component_title {
  @extend %primary-xl;
  margin-bottom: $space-unit * 6;
}