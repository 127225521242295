.widget-l-text {
  margin: 12rem auto;
  .eyelet {
    font-size: 1.4rem;
    margin-bottom: 0.8rem;
    font-weight: bold;
  }
  .title {
    font-size: 2.4rem;
    margin-bottom: 2.4rem;
    font-weight: 300;
    line-height: 1.33;
  }
  .content-text {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
    font-weight: 300;
    line-height: 1.44;
  }
  .item-cta {
    font-size: 1.4rem;
    font-weight: bold;
    display: block;
    width: fit-content;
    text-decoration: none;
  }
}
