.refinements {
  .filters form {
    display: flex;
    flex-direction: column;
  }
  .refinement {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    border: 0;
    padding: 10px 0;
    font-size: 1.4rem;
    font-weight: normal;
    text-transform: none;
    &::before {
      content: '';
      @include fixedSprite('checkbox-off');
      margin-right: 0.8rem;
    }

    &.selected,
    &:active,
    &:focus {
      &::before {
        @include fixedSprite('checkbox-on');
        margin-right: 0.8rem;
      }
    }
  }
  .filters-actions {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.6rem;
    padding: 2.4rem;
    background-color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
  }
}

.filters-container {
  padding: 0;
  border-bottom: 1px solid $grey_line;

  .accordion__header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &::after{
      position: absolute;
      right: 0;
    }

    .filters-applied{
      margin-top: 0.8rem;
      color: #6f6f6f;
    }
  }

  ul {
    padding: 0;
    padding-bottom: 3.2rem;
  }

  li {
    list-style: none;
    padding: 0;

    &.form-radio {
      display: flex;
      input {
        order: 1;
      }
      label {
        order: 2;
      }
    }

    &:first-child {
      .accordion__header {
        border-top: none;
      }
    }
  }
}
