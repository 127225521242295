.bag-product {
  &__content {
    > .bag-product__preorderinfo {
      display: block;
    }
  }

  > .row > .bag-product__preorderinfo {
    display: none;
  }
}
