.product-carousel {
  padding: 4.8rem 0 ($space-unit * 8);
  overflow: hidden;
  @include appearAnimation();
  .swiper-container {
    overflow: visible;
  }
  .single-slide__img {
    position: relative;
    display: block;
    transition: none !important;
    opacity: 1 !important;
    overflow: hidden;
  }
  .swiper-wrapper {
    @include animate(1);
  }
}

.product-carousel__title {
  font-size: 3.2rem;
  line-height: normal;
  font-weight: 300;
  padding-left: 15rem;
  margin-top: 6rem;
  height: 4rem;
  margin-bottom: -4rem;
}

.product-carousel__cta-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ($space-unit * 2);
}

.product-carousel__cta {
  margin: 0 1rem;
}

.product-carousel__text {
  @extend %primary-xs;
  width: 43.8rem;
  max-width: calc(100% - 3.2rem);
  margin: 2.8rem auto 0;
  text-align: center;
}

.product-carousel__carousel-container {
  z-index: 0;
  position: relative;
  //border
  &.without-border {
    &:not(.labels-position--on-img) {
      .single-slide__labels {
        margin-left: ($space-unit * 4);
      }
    }
  }
  &:not(.without-border) {
    // padding: 0 3.2rem;
    padding: 0 0 0 15rem;
  }


  .wrapper-swiper-scrollbar{
    padding-right: 4.6rem;

    .swiper-scrollbar{
      background: #EEEEEE;
      
      .swiper-scrollbar-drag {
        height: 0.2rem;
        background: #F42333;
      }
    }
  }
    
  .product-tile {
    .name {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1;
      margin-top: 0.8rem;
    }
    .variations-number {
      font-size: 1.2rem;
      color: $dark_grey;
      margin-top: 1rem;
    }
  }

  .swiper-container:not(.product-image) {
    padding-top: 8rem;
  }

  .swiper-button-container {
    display: flex;
    z-index: 1;
    position: absolute;
    top: 1rem;
    right: 4.8rem;
    gap: 4rem;

    .swiper-button-disabled {
      opacity: 0.5;
    }
  }

  .cta--wishlist{
    padding: 1.5rem;
  
    &::after{
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  
  .product-tile .tile-body div{
    margin: 1rem 0;
  }
}

.carousel-container__navigation-button[role='button'] {
  position: absolute;
  top: -0.1rem;
  bottom: -0.1rem;
  // width: 26%;
  z-index: 2;
  left: 0;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.carousel-container__button__wrapper {
  @include animate(0.3);
  height: 100%;
  width: 3.2rem;
  transform: translateX(-100%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  z-index: 1;
  .button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    &__text {
      opacity: 0;
      display: none;
      @include animate(0.3, 'opacity');
    }
  }
  &:hover {
    width: 14rem;
    .button {
      &__text {
        display: block;
        opacity: 1;
      }
    }
  }
}

.swiper-button-next.carousel-container__navigation-button {
  left: initial;
  right: 0;
  justify-content: flex-end;
  .carousel-container__button__wrapper {
    transform: translateX(100%);
  }
}

.show-left-arrow {
  .swiper-button-prev {
    .carousel-container__button__wrapper {
      transform: translateX(0);
    }
  }
}
.show-right-arrow {
  .swiper-button-next {
    .carousel-container__button__wrapper {
      transform: translateX(0);
    }
  }
}

.swiper-button-disabled {
  .carousel-container__button__wrapper {
    transform: translateX(-100%) !important;
  }
  &.swiper-button-next {
    .carousel-container__button__wrapper {
      transform: translateX(100%) !important;
    }
  }
}

// labels position
.labels-position--on-img {
  .single-slide__labels {
    position: absolute;
    padding: 0;
    left: ($space-unit * 4);
    bottom: ($space-unit * 4);
    color: $white;
  }
}

//hover style
.product-carousel__headline-hover {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-transform: uppercase;
  color: $white;
  font-size: 3.2rem;
  line-height: 3.8rem;
  width: 90%;
  display: none;
  pointer-events: none;
}

.single-slide__img {
  &:hover {
    .product-carousel__headline-hover {
      display: block;
    }
  }
}

.swiper-pagination-progressbar {
  margin: 3rem 0 6rem 0;
  height: 2px;
  width: calc(100% - 150px);
  background: $grey;
  .swiper-pagination-progressbar-fill {
    background: $red;
    width: calc(100% - 150px);
    height: 2px;
    transform-origin: left top;
    position: absolute;
  }
}
