@use 'sass:math';

@each $multiply in $spacing-multiplier {
  $space: $space-unit * $multiply;
  $space-nounit: math.div($space, 0.1rem);

  .mb-#{$space-nounit} {
    margin-bottom: $space;
  }

  .mt-#{$space-nounit} {
    margin-top: $space;
  }

  .pb-#{$space-nounit} {
    padding-bottom: $space;
  }

  .pt-#{$space-nounit} {
    padding-top: $space;
  }

  .mr-#{$multiply} {
    margin-right: $space;
  }

  .ml-#{$multiply} {
    margin-left: $space;
  }

  .my-#{$multiply} {
    margin-bottom: $space;
    margin-top: $space;
  }

  .mx-#{$multiply} {
    margin-left: $space;
    margin-right: $space;
  }

  .pr-#{$multiply} {
    padding-right: $space;
  }

  .pl-#{$multiply} {
    padding-left: $space;
  }

  .py-#{$multiply} {
    padding-bottom: $space;
    padding-top: $space;
  }

  .px-#{$multiply} {
    padding-left: $space;
    padding-right: $space;
  }

  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .py-auto {
    padding-top: auto;
    padding-bottom: auto;
  }

  .px-auto {
    padding-left: auto;
    padding-right: auto;
  }

  .mt-none {
    margin-top: 0 !important;
  }

  .gap-#{$multiply} {
    gap: $space;
  }

  @include breakpoint(lg, min) {
    .gap-lg-#{$multiply} {
      gap: $space;
    }

    .mb-lg-#{$multiply} {
      margin-bottom: $space;
    }

    .mt-lg-#{$multiply} {
      margin-top: $space;
    }

    .ml-lg-#{$multiply} {
      margin-left: $space;
    }

    .mr-lg-#{$multiply} {
      margin-right: $space;
    }

    .pb-lg-#{$multiply} {
      padding-bottom: $space;
    }

    .pt-lg-#{$multiply} {
      padding-top: $space;
    }

    .pl-lg-#{$multiply} {
      padding-left: $space;
    }

    .pr-lg-#{$multiply} {
      padding-right: $space;
    }

    .my-lg-#{$multiply} {
      margin-bottom: $space;
      margin-top: $space;
    }

    .mx-lg-#{$multiply} {
      margin-left: $space;
      margin-right: $space;
    }

    .py-lg-#{$multiply} {
      padding-bottom: $space;
      padding-top: $space;
    }

    .px-lg-#{$multiply} {
      padding-left: $space;
      padding-right: $space;
    }
  }

  @include breakpoint(lg, max) {
    .gap-sm-#{$multiply} {
      gap: $space;
    }

    .mb-sm-#{$multiply} {
      margin-bottom: $space;
    }

    .mt-sm-#{$multiply} {
      margin-top: $space;
    }

    .ml-sm-#{$multiply} {
      margin-left: $space;
    }

    .mr-sm-#{$multiply} {
      margin-right: $space;
    }

    .pb-sm-#{$multiply} {
      padding-bottom: $space;
    }

    .pt-sm-#{$multiply} {
      padding-top: $space;
    }

    .pl-sm-#{$multiply} {
      padding-left: $space;
    }

    .pr-sm-#{$multiply} {
      padding-right: $space;
    }

    .my-sm-#{$multiply} {
      margin-bottom: $space;
      margin-top: $space;
    }

    .mx-sm-#{$multiply} {
      margin-left: $space;
      margin-right: $space;
    }

    .py-sm-#{$multiply} {
      padding-bottom: $space;
      padding-top: $space;
    }

    .px-sm-#{$multiply} {
      padding-left: $space;
      padding-right: $space;
    }
  }
}

// class used by js to generate mqObj
.custom-mq {
  $mqObj: '';

  @each $name,
  $value in $grid-breakpoints {
    @if $mqObj !='' {
      $mqObj: $mqObj +'&';
    }

    $mqObj: $mqObj + $name + '=' + $value;
  }

  font-family: $mqObj;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-column {
  display: flex;
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}

@each $name,
$br in $grid-breakpoints {
  @include breakpoint($name, min) {
    .d-#{$name}-none {
      display: none !important;
    }

    .d-#{$name}-hide {
      visibility: hidden !important;
    }

    .d-#{$name}-block {
      display: block !important;
    }

    .d-#{$name}-flex {
      display: flex !important;
    }
  }
}

@include breakpoint(md, max) {
  .hide-mobile {
    display: none !important;
  }
}

.hidden {
  // note that this is hidden to screen reader
  visibility: hidden;
}

.hide {
  display: none !important;
}

.link {
  text-decoration: underline;
}

.block {
  display: block;
}

.full-width {
  width: 100%;
}

img[data-sizes='auto'] {
  display: block;
  width: 100%;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

.link-button {
  text-decoration: underline;
}

.info-box {
  padding: 2.4rem;
  border-radius: 0.8rem;
  background-color: $light_grey;

  h4 {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;

    i {
      margin-right: 0.8rem;
    }
  }

  p {
    @extend %primary-s;
    color: $dark_grey;
  }
}

.no-wrap {
  white-space: nowrap;
}

