.product-grid {
  &__container {
    padding: 4.8rem 4rem 8rem;
    background-color: $white;
    
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0, 0, 0);
    z-index: 2;
    position: relative;
  }
  &__title {
    font-size: 3.2rem;
    line-height: 4rem;
    font-weight: 300;
    padding-bottom: 4rem;
  }
  &__content {
    &-container {
      @include reset-list;
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-template-rows: auto;
      width: 100%;
      gap: 2.4rem 1.6rem;
    
      .product-tile {
        grid-column: span 2;
        .product-tile{
          .variation-color-plp {
            display: none;
          }  
          
          &:hover{
            .variation-color-plp {
              display: none;
            } 
        
            .cta-show-colors {
              display: flex;
            }
          } 
        }   
    
      }
    }
  }
}