.color-variation-carousel__wrapper {
  //padding: 1.6rem 0 0 0;

  .color-variation-carousel__disclaimer {
    display: flex;
    flex-direction: column;
    h2 {
      margin-bottom: 8px;
    }
  }
  #color-variation-carousel__product_name {
    margin-bottom: 1.6rem;
    max-width: 70%;
  }

  #color-variation-carousel__variant__end {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #color-variation-carousel__variant__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.4rem;
    height: 100%;
    flex-direction: column;
  }

  .variation-color-tile {
    .product-tile__detail {
      position: absolute;
      display: flex;
      top: unset;
      right: 0;
      bottom: 0;
      left: 0;
      height: unset;
      object-fit: unset;
      justify-content: space-between;
      padding: 10px;
      color: white;
    }
  }

  .swiper-pagination-progressbar {
    margin: 2.4rem 0;
    //width: calc(100% - 15px);
  }
  .swiper-button-container {
    display: flex;
    align-items: center;

    .swiper-button-disabled {
      opacity: 0.5;
    }
  }

  .swiper-button-prev {
    display: flex;
    align-items: center;

    &::after {
      content: '';
      @include fixedSprite('arrow-sx-red');
    }
  }
  .swiper-button-next {
    display: flex;
    align-items: center;

    &::after {
      content: '';
      @include fixedSprite('arrow-dx-red');
      margin-left: 4rem;
    }
  }
}
