.checkout-review {
  .text-section__description {
    width: 100%;
  }
  .text-section {
    padding-top: 3.2rem;
  }
  .order-recap {
    padding-top: 0;
    padding-bottom: 2.4rem;
    .text-section__title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      font-weight: 300;
      padding-bottom: 2.4rem;
    }
  }
  .border-top {
    border-top: 1px solid $grey_line;
    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0.2rem;
    //   width: calc(100% - #{$space-unit});
    //   height: $space-line;
    // }
  }
  .bag-review {
    padding-top: $space-unit * 6;
  }
  .shipping-review,
  .payment-review {
    h3 {
      @extend %primary-s;
      padding-top: ($space-unit * 6);
      padding-bottom: ($space-unit * 4);
    }
    p {
      @extend %primary-xs;
    }
    p:not(:last-child) {
      margin-bottom: $space-unit;
    }
  }
  .card_expiration,
  .card_cvv {
    display: inline-block;
  }
  .card_cvv {
    margin-left: 2rem;
  }
  .paybylinkwait {
    margin-top: $space-unit * 8;
    text-align: center;
    line-height: 3.2rem;
  }
  .changebutton {
    margin-top: $space-unit * 4;
    margin-bottom: $space-unit * 4;
  }
  .bag-summary {
    top: 14rem;
  }
}
