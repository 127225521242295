body#account-thankyouregister {
  .thankyou__register {
    padding-top: calc(3.2rem + 11.2rem + 4.8rem);
    margin-bottom: 7.2rem;

    &__container {
      .product-carousel__title,
      .swiper-button-container{
        display: none;
      }

      .product-carousel__carousel-container {
        .recommendations-carousel {
          .swiper-container {
            padding-top: 0;
          }
        }
      }
    }

    &__title {
      color: $black_primary;
    }

    &__subtitle {
      margin: 0.8rem auto 4rem;
      max-width: 80%;
      color: $black_primary;
    }
  }  
}