[data-whatintent='touch'] {
  header {
    .wrapper-submenu {
      transition: none;
    }
  }
}
header {
  // .menu-wrapper {
  // gap: 24px;
  //   height: inherit;
  //   &:focus-within {
  //     .menu-item:not(:focus-within) {
  //       .menu-heading {
  //         color: $black_secondary;
  //       }
  //     }
  //   }

  // &.expanded {
  //   .menu-item:not(:hover) {
  //     .menu-heading {
  //       color: $black_secondary;
  //     }
  //   }
  // }
  // }
  .menu-heading {
    position: relative;
    display: flex;
    align-items: center;
  }

  .menu-item {
    white-space: nowrap;
    // height: inherit;
    &.open {
      // border-bottom: 2px solid $red;
      > .menu-heading::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: $red;
      }
      .wrapper-submenu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        pointer-events: all;
        // & > * {
        //   opacity: 1;
        // }
      }
    }
  }
  // .main-submenu {
  //   column-count: 3;
  // }

  .visually-hidden-d {
    @include visually-hidden;
  }

  .wrapper-submenu {
    border-radius: 0 0 8px 8px;
    top: 100%;
    transform: translateY(-3.2rem);
    pointer-events: none;
  }

  .nav-wrapper {
    position: relative;
    @include rounded-box;
    // @include animate(0.3);

    > nav {
      padding: 0 12px;
      transition: max-width 0.3s ease-in-out;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &.expanded {
      border-radius: 8px 8px 0 0;
      box-shadow: initial;
      // border-bottom: 1px solid $grey;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $grey;
      }
    }

    &.collapsing {
      pointer-events: none;
    }

    &.collapse {
      .menu-item {
        display: none;
      }
      #mainnav {
        padding: 0;
        max-width: 56px !important;
      }
      .menu-icon.hamburger-menu {
        padding: 20px;
      }
      .icon--menu {
        // width: 5.6rem;
        // height: 5.6rem;
        // padding: 20px;
        display: flex;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
  }
}
