.my-account-banner {
  position: relative;
  padding-top: calc(100% - 24px); //square ratio
  background-repeat: no-repeat;
  background-size: cover;

  .text-wrapper {
    padding-right: 2.4rem;
    position: absolute;
    bottom: 24px;
    left: 24px;
    color: $white;

    .title {
      font-size: 2.4rem;
      font-weight: 300;
      line-height: 1.33;
    }

    .link {
      display: block;
      margin-top: 1.6rem;
      text-decoration: none;
      width: fit-content;
      &::before {
        border-top: 1px solid $white;
      }
    }
  }
}
