.product {
  &GalleryWithModal {
    &-wrapper {
    margin-top: 0;

    }
  }
}
.product-gallery-with-modal {
  .swiper-slide {
    margin-top: auto;
    margin-bottom: auto;
  }
}
.zoom-thumbnails-wrapper {
  left: 1.6rem;
}
.zoom-image-wrapper {
  height: 100%;
}
#pdp-gallery-modal {
  .modal-close {
    top: 1.2rem;
    margin-right: 0;
    right: 1.2rem;
  }
}

#sticky-bar-bottom {
  display: none;
}