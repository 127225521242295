.product-wrapper.product-container {
  padding-top: calc(0.8rem + 3.2rem + 9.6rem);
  transform: translate3d(0,0,0);
  -webkit-transform: translate3d(0,0,0);
  .product {
    &-images {
      &-container {
        &:not(:has(.swiper-container--tablet)) {
          min-height: calc(100vw*4/3);        
        }
      }

      &__tag {
        left: -1.6rem;
      }
    }
  }
}
body{
  &:has(.collapse-translate-initial){
    .product-wrapper.product-container {
      padding-top: 3.2rem;

    }   
    
    .product-breadcrumb {
      display: none;
    }

    main {
      margin-top: 9.6rem;
    }
  }
}