.hero-v1 {
  position: relative;
  // display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  .item-content-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &.video {

    //height: unset;
    .item-content-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  .item-content-text {
    z-index: 2;
    text-align: center;
    color: $white;
    line-height: 1.2;

    .item-cta {
      &::before {
        border-top: 1px solid $white;
      }
    }

    &.inverted {
      color: $black;

      .item-cta {
        &::before {
          border-top: 1px solid $black;
        }
      }
    }
  }

  .item-title {
    font-size: 4rem;
    font-weight: 300;
    margin-bottom: 1.2rem;
  }

  .item-subtitle {
    width: 80%;
    font-size: 1.4rem;
    margin: 0 auto 2.4rem auto;
  }

  //Slider
  .slider-wrapper {
    position: absolute;
    bottom: 12rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    &.open {
      z-index: 2;
    }
  }

  .slider {
    opacity: 0;
    visibility: hidden;
    max-width: 30rem;
    transition: all 0.3s ease-in-out;
    @include rounded-box;

    &.open {
      opacity: 1;
      visibility: visible;
      transition: all 0.5s ease-in-out;
    }
  }

  .swiper-slide {
    padding: 2rem;
  }

  .swiper-pagination {
    position: absolute;
    top: 10px;
    left: 20px;
    width: calc(100% - 40px);
  }

  .swiper-pagination-bullet {
    width: 100%;
    height: 2px;
    background-color: $grey;

    &-active {
      background-color: $red;
    }
  }

  .slide-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem 1rem 0 1rem;
    margin-top: 3rem;
  }

  div[class^='swiper-button'],
  div[class*=' swiper-button'] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-disabled {
    opacity: 0.5;
  }

  //Button that triggers slider opening
  .btn-product-slider {
    @include rounded-box;
    width: 5.6rem;
    height: 5.6rem;
    position: absolute;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .closed {
    .vertical {
      transition: all 0.3s ease-in-out;
      transform: rotate(-90deg);
    }

    .horizontal {
      transition: all 0.3s ease-in-out;
      transform: rotate(-90deg);
      opacity: 1;
    }
  }

  .opened {
    opacity: 1;

    .vertical {
      transition: all 0.3s ease-in-out;
      transform: rotate(90deg);
    }

    .horizontal {
      transition: all 0.3s ease-in-out;
      transform: rotate(90deg);
      opacity: 0;
    }
  }

  .btn-product-slider .circle {
    position: relative;
  }

  .btn-product-slider .circle .horizontal {
    position: absolute;
    background-color: $red;
    width: 16px;
    height: 2px;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -1px;
  }

  .btn-product-slider .circle .vertical {
    position: absolute;
    background-color: $red;
    width: 2px;
    height: 16px;
    left: 50%;
    margin-left: -1px;
    top: 50%;
    margin-top: -8px;
  }
}