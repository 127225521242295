.sort {
  position: relative;

  .sort__button {
    margin-right: 0;
    // min-width: 11rem;
    text-align: left;

    &[aria-expanded='true'] + .sort__list {
      opacity: 1;
      visibility: visible;
    }

    // span {
    //   text-transform: lowercase;
    // }
  }
  .sort__list {
    position: absolute;
    right: -2rem;
    // left: -($space-unit * 8);
    padding: ($space-unit * 2) ($space-unit * 8) 2rem;
    display: flex;
    opacity: 0;
    visibility: hidden;
    background-color: $white;
    z-index: 1;
    flex-direction: column;
    align-items: baseline;
  }

  .sort__option {
    display: inline-flex;
    white-space: nowrap;
    padding-right: 1.8rem;
    margin-bottom: ($space-unit * 3);
    position: relative;
    @include hover;

    &[aria-selected='true'] {
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -0.2rem;
        @include animate;
        @include fixedSprite('checkmark');
      }
    }
  }
}
