.tooltip {
  .tooltip__box {
    transform: unset;
    right: 0.6rem;
    border-bottom-right-radius: 0;
    max-width: 13rem;
    min-width: 13rem;
    &::before {
      left: 100%;
      transform: translate(-100%, 100%);
      border-right: 0;
      border-left-width: 1.2rem;
      border-top-width: 1rem;
    }
  }
}
