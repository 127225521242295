.cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem 0;
  background-color: $white;
  box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.1);
  @include z('cookiebar');
  .cookie-msg {
    button {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .row {
    align-items: center;
  }
}
.cookie-center {
  .modal__header {
    @include border('bottom');
  }
  &__form {
    display: grid;
    grid-template-areas:
      'table table'
      'table table'
      'row row'
      '. submit';
    column-gap: 4.8rem;
    row-gap: 2.4rem;
    align-items: start;
    > button {
      grid-area: submit;
    }
  }
  .cookie-row {
    grid-area: row;
  }
  .cookie-item {
    display: grid;
    grid-template-areas:
      'title switch'
      'desc desc';
    align-items: end;
    row-gap: 1.6rem;
    h3 {
      margin-top: 2.4rem;
    }
    .cookie-switch {
      position: relative;
      justify-self: end;
      .divisor {
        padding: 0 $space-unit * 2;
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
        cursor: pointer;
        appearance: initial;
        @include z;
        &:not(:checked) {
          + .yes-or-no {
            .yes {
              opacity: 0.55;
            }
          }
        }
        &:checked {
          + .yes-or-no {
            .no {
              opacity: 0.55;
            }
          }
        }
      }
    }
    > p {
      grid-area: desc;
    }
  }
}
.overlay-modal.cookie-overlay {
  @include z('cookieoverlay');
}
html[data-whatinput='keyboard'] {
  .cookie-switch {
    input:focus {
      + .yes-or-no {
        outline: 0.3rem solid $outline;
        outline-offset: 0.3rem;
      }
    }
  }
}