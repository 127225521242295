.checkout-packaging {
  .back-button {
    height: auto;
    display: flex;
  }

  &__message {
    &__aspect-ratio {
      padding-bottom: 48.16%;
    }
    &__field {
      margin: ($space-unit * 16) auto ($space-unit * 16);
      width: 54.2rem;
      &__background {
        .message-button {
          padding: $space-unit * 16;
        }
      }

      &::before {
        left: ($space-unit * 5);
        top: ($space-unit * 5);
        width: ($space-unit * 12);
        height: ($space-unit * 9);
      }

      &::after {
        left: $space-unit;
        top: $space-unit;
      }

      .form-control {
        textarea {
          height: 7.8rem;
        }
      }
    }
  }
}
.message-form {
  width: calc(100% - 16rem);
  padding-top: 5rem;
  padding-bottom: 3rem;
  .form-group {
    margin-bottom: 0;
  }
  .charleft {
    padding: 0;
  }
}
