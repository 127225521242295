.remove-margin {
  margin: 0 - ($space-unit * 4);
}

.bag-overview {
  &--empty {
    padding-bottom: ($space-unit * 10);
  }
}

.wishlist-overview {
  .bag-overview__products {
    padding-top: 2.4rem;
    padding-bottom: 0.8rem;
  }
}
