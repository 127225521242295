header.editmode {
  position: relative;
  #mainnav {
    width: 100%;
    max-width: 100%;
  }

  .container-submenu {
    padding: 0;
  }

  .navigation {
    height: auto;
    display: block;
    justify-content: center;
  }

  .ticker-wrapper {
    background-color: #ccc;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .menu-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    margin-bottom: 5rem;
  }

  .menu-item {
    margin-bottom: ($space-unit * 6);
    width: 100%;
    border: 0.2rem solid red;
    // padding-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      .wrapper-submenu {
        transform: initial;

        &::before {
          content: initial;
        }
      }
    }
  }

  .menu-heading {
    padding: 2rem 0 2rem 0;
    background-color: #eee;
    display: inline-block;
    width: 100%;
    text-align: center;
    // margin-bottom: ($space-unit * 6);
  }

  .wrapper-submenu {
    position: relative;
    left: initial;
    transform: initial;
    opacity: 1;
    visibility: visible;
    z-index: auto;
    pointer-events: all;
    top: initial;
    padding: 3.2rem 0;
  }

  .main-submenu {
    min-height: 10rem;
  }
  .logo-header {
    display: none;
  }
}
