header {
  .menu-actions {
    // z-index: 1;
    position: relative;
    @include rounded-box;
    .menu-icon:first-child {
      border-left: none;
    }
  }

  .menu-wrapper {
    position: absolute;
    right: 0;
    background-color: #fff;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 32px;
    top: 0;
    height: calc(var(--viewport-height) - var(--ticker-height));
    padding: 12.8rem 2.4rem 3.2rem;
    transform: translateX(100%);
    overflow: hidden;
    overflow-y: auto;
    @include animate(0.3);
    width: 100%;
    max-width: initial;
    transition-delay: 150ms;
    opacity: 0;
    visibility: hidden;
  }

  &:has(.menu-footer .item-box) {
    .menu-wrapper {
      padding-bottom: 10rem;
    }
  }

  &.header-unsubscription {
    top: 0.8rem;
  }

  .menu-footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    transition-delay: 175ms;
    opacity: 0;
    visibility: hidden;
    @include animate(0.3);
    transform: translateX(100%);
    background-color: $white;
    width: 100%;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);

    .item-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;
      text-decoration: none;
      height: 6.8rem;

      .label {
        font-size: 12px;
        font-weight: bold;
        line-height: 1.33;
        margin-top: 4px;
      }
    }
  }

  .wrapper-submenu {
    overflow: auto;
    padding: 10.4rem 2.4rem 10rem;
    height: 100%;
  }

  .visually-hidden-d {
    &,
    a {
      display: block;
      @include del-link;
      font-size: 24px;
      line-height: 32px;
      font-weight: 300;
    }

    + .main-submenu > .submenu-group:nth-child(1) > .submenu-heading {
      margin-top: 24px;
    }
  }

  .container-submenu {
    padding: 0;
  }

  .open {
    .menu-wrapper,
    .menu-footer {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }

  .menu-heading {
    font-size: 24px;
    line-height: 32px;
    font-weight: 300;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &.featured {
      color: $red;
    }
    &::after {
      content: '';
      @include fixedSprite('arrow-right');
    }
  }

  [data-menu-back] {
    transform: translateX(3.2rem);
  }

  .first-level {
    [data-menu-back] {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }

    .logo-header {
      opacity: 0;
      visibility: hidden;
      transform: translateX(-3.2rem);
    }
    .menu-heading {
      opacity: 0;
      visibility: hidden;
    }

    .menu-heading[aria-expanded='true'] {
      + .wrapper-submenu {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }
    }
  }

  .submenu-heading {
    margin-bottom: 0;
  }

  .submenu-group {
    grid-column: span 3;
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
      border-bottom: 1px solid $grey_line;
      padding: 24px 0;
      li {
        margin-bottom: 0;
        &:nth-child(even) {
          grid-column-end: 3;
        }
        &:nth-child(odd) {
          grid-column-end: 2;
        }
      }
    }
  }
}
