.product-tile {
  &__info {
    padding-top: ($space-unit * 3);
  }

  &__quick-buy-panel,
  &__quick-buy {
    display: none;
    background-color: greenyellow !important;
  }
  .product-images {
    &__tag {
      bottom: 0.8rem;
    }

  }

  .tile-body {
    padding-top: 0;
    .price-wrapper {
      margin: 0.2rem 0 0.4rem; 
      .price {
        margin: 0;
      }
    }
  }

  &-labels {
    padding-bottom: 0.4rem;
  }
}

.product-tile__card{

  .wrapper-quick-add-to-bag {
    display: inherit;
    .cta-quick-add-to-bag {
      opacity: 1;
      content: '';
      margin: 0.4rem;
      width: 2.4rem;
      height: 2.4rem;

      &::after {
        @include fixedSprite('bag');
      }
    }
  }
  .cta{
    &.cta--wishlist  {
      display: inherit;
      opacity: 1;
      margin: 0.4rem;
      width: 2.4rem;
      height: 2.4rem;
      
      &::after {
        content: '';
        @include fixedSprite('wish');
      }
      &.full {
        &::after {
          content: '';
          @include fixedSprite('wish-full');
        }
      }      
    }

    &.icon--wish-24.full {
      &::after {
        content: '';
        @include fixedSprite('wish-full-24');
      }
    }

  }
}

#search-show {
  .product-tile__card {
    .cta.cta--wishlist {
      opacity: 0;
    }
    .tile-swiper-icon, .wrapper-quick-add-to-bag, .cta.cta--wishlist{
      display: none;
    }
  }
}
