.tabs {
  > ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    > li {
      list-style: none;
      position: relative;
      display: flex;
      flex-grow: 1;
      padding-top: 0;
      padding-bottom: 0;
      text-align: center;
      border-right: 1px solid $grey_line;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        @include border('bottom');
      }
    }
  }

  [role='tab'] {
    @extend %primary-s;
    cursor: pointer;
    padding: 1.6rem 0 1.4rem;
    position: relative;
    display: flex;

    &[aria-selected='true'] {
      &::after {
        content: '';
        position: absolute;
        height: 2px;
        background-color: $red;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    > a {
      flex-grow: 1;
      text-decoration: none;
    }
  }

  &.tabs--stefanelId {
    > ul > li {
      border: initial;
      justify-content: center;
      &::before {
        border-bottom: 2px solid $grey;
      }
    }
    .react-tabs__tab-list {
      margin-bottom: 3.2rem;
    }
    .react-tabs__tab {
      flex: 1 0 50%;
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 0.6rem 0;
      color: $dark_grey;
      &.react-tabs__tab--selected {
        color: $black;
      }
    }
  }

  &.tabs--fluid {
    .react-tabs__tab {
      flex: 1 0 50%;
      &:not(:first-child) {
        padding-left: $space-unit;
      }
    }
  }
  &.tabs--capitalize {
    .react-tabs__tab {
      text-transform: capitalize;
    }
  }
  &.tabs--nocapitalize {
    .react-tabs__tab {
      text-transform: none; // forced default is uppercase
    }
  }
  &.tabs--uppercased {
    .react-tabs__tab {
      text-transform: uppercase;
    }
  }
  &.tabs--centered {
    .react-tabs__tab {
      text-align: center;
    }
  }
  &.tabs--padding {
    .react-tabs__tab {
      margin-right: ($space-unit * 8);
    }
  }
  &.tabs--sticky {
    .react-tabs__tab-list {
      position: sticky;
      top: 0;
      background: $white;
      z-index: 1;
    }
  }
}
