.img-column--aspect-ratio--3-4,
.img-column--aspect-ratio--2-3 {
  padding-top: 0;
}

.editorial-multi-text__text-column {
  width: 100%;
  padding: ($space-unit * 8) ($space-unit * 4) ($space-unit * 10);
}

.editorial-multi-text {
  display: block;
}

.editorial-multi-text__img-column {
  width: 100%;
  height: calc(93.3vh - 6rem);
}

.editorial-multi-text__subtitle {
  margin-bottom: 7rem;
}
