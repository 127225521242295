.myaccount-wrapper {
  overflow: hidden;
  padding-bottom: ($space-unit * 10);
  background-color: $light_grey;
  flex-grow: 1;

  button[disabled] {
    opacity: 0.5;
    cursor: default;

    * {
      cursor: default;
    }
  }

  .myaccount-content {
    margin-top: ($space-unit * 8);
  }

  .myaccount__section {
    position: relative;

    &:not(:last-child) {
      padding-bottom: ($space-unit * 10);
      @include border('bottom');
      margin-bottom: ($space-unit * 4);
    }

    .subtitle {
      @extend %primary-m;
      margin: 0 0 ($space-unit * 3);
      display: inline-block;
    }

    ul {
      @include reset-list;
    }

    .button {
      margin-top: ($space-unit * 6);
    }

    .new-address-label {
      &:before {
        content: '';
        @include fixedSprite(plus-new);
        margin-right: ($space-unit * 2);
      }

      span {
        font-weight: bold;
        font-size: 1.2rem;
      }
    }
  }

  .accordion__group {
    .accordion__header {
      &[aria-expanded='true'] {
        border-bottom-color: transparent;

        +.accordion__panel {
          padding-bottom: $space-unit * 8;
        }
      }
    }

    .accordion__panel {
      .default-item {
        @extend %primary-xxs-u;
        color: $black_secondary;
        margin-bottom: $space-unit * 2;
      }
    }
  }

  .button-row {
    align-items: center;
    margin-top: ($space-unit * 6);

    .button {
      margin-top: 0;
    }
  }

  .new-item-title {
    @extend %primary-s;
    margin-bottom: ($space-unit * 3);
  }

  .checkoutpage .adyen-checkout__card-input {
    margin-bottom: 0;
  }

  .text-block__inner-container {
    width: 100%;
  }

  .account-payment,
  .account-stefanelId {
    .wrapper-loyalty-accordion {
      padding-bottom: 2.4rem;
    }

    .box-wrapper {
      background-size: cover;
      height: 27rem;
      max-width: 25rem;
      min-width: 20rem;
    }

    .card-wrapper {
      position: absolute;
      bottom: 2.4rem;
      color: white;

      .label {
        font-size: 1rem;
        line-height: 2rem;

        text-transform: uppercase;
      }

      .numbers {
        font-size: 3.2rem;
        font-weight: 300;
        line-height: 3.6rem;


        &-small {
          @extend .numbers;
          font-size: 2.2rem;
        }
      }
    }
  }
}

.single-banner {
  background-color: #1b1b1b;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: ($space-unit * 8);

  &__text-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }

  &__title {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 300;
    font-size: ($space-unit * 6);
    line-height: ($space-unit * 7);
    margin-bottom: ($space-unit * 2);
  }
}

.list__empty-message {
  width: 43rem;
  margin: ($space-unit * 10) auto ($space-unit * 10);
  text-align: center;

  .empty-message__text {
    // display: inline-block;
    color: $dark_grey;
    margin-bottom: ($space-unit * 6);
    @extend %primary-s;
  }

  .button {
    width: 34rem;
    max-width: 100%;
    margin: 0 auto;
  }
}

.account-dashboard__intro {
  a {
    display: flex;
    align-items: center;
    text-decoration: none;

    &::before {
      content: '';
      @include fixedSprite('exit');
      margin-right: 0.8rem;
    }
  }
}

ul.checkmark {
  li {
    padding: 0.4rem;

    &::marker {
      content: '✓ ';
      color: $black;
    }
  }
}