.checkout-packaging {
  background-color: $white;
  padding: ($space-unit * 2) 0 ($space-unit * 16);
  &__intro {
    margin-bottom: $space-unit * 4;
  }

  .back-button {
    align-self: center;
    display: none;
  }

  &__message {
    &__aspect-ratio {
      z-index: -1;
      pointer-events: none;
      padding-bottom: 53.4%;
    }

    &__field {
      position: relative;
      margin: ($space-unit * 10) auto ($space-unit * 10);
      width: calc(100% - 0.2rem);

      &::before {
        content: '';
        @include fixedSprite('logo-white');
        position: absolute;
        left: ($space-unit * 3);
        top: ($space-unit * 3);
        width: 2.5rem;
        height: 2.3rem;
        @include z('filters');
      }

      &::after {
        content: '';
        // background-image: url(./../../media/gift-bg.png);
        background-size: contain;
        position: absolute;
        left: 0.2rem;
        top: 0.2rem;
        width: 100%;
        height: 100%;
      }
      &__background {
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: white;
        position: absolute;
        border: $space-line solid $black;
        @include z;
        .message-button {
          @extend %primary-xs;
          padding: $space-unit * 4;
          height: 100%;
          width: 100%;
          word-break: break-word;
        }
        .message-button-row {
          margin-top: ($space-unit * 2);
          &__right-button {
            text-align: right;
          }
        }
      }

      .form-control {
        text-align: center;
        position: relative;
        padding-bottom: $space-unit;
        input,
        textarea {
          padding: 1.1rem 2rem 0.9rem;
        }
        textarea {
          // margin-bottom: 0;
          height: 16.8rem;
        }
      }
    }
    .checkbox {
      margin-bottom: $space-unit;
    }
    input[name='emailGift'] {
      margin-bottom: 0;
    }
  }
}
.message-form {
  padding-top: ($space-unit * 6);
  .form-group {
    margin-bottom: $space-unit;
    input,
    textarea {
      text-align: center;
    }
  }
  .charleft {
    display: block;
    padding-top: $space-unit;
    padding-bottom: $space-unit * 8;
  }
}
