.client-service-content {
  .link-box__button.button {
    width: 100%;
  }
}
.client-service {
  .tabs {
    margin-top: -3.2rem;
  }
  &__section {
    margin-top: ($space-unit * 10);
  }
  .section {
    &--little-snippet {
      display: block;
      margin: 0;
      padding: ($space-unit * 4) 0;
      .button {
        width: 100%;
      }
      > div {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.tracking-item {
  &__item {
    text-align: left;
    padding-left: ($space-unit * 2);
    padding-right: ($space-unit * 2);
    word-break: break-word;
  }
}
