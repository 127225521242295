.sizevariation-modal {
  &.quickbuy-selectsize{
    .modal {
      &__body {
        padding-top: 0;

        .selector {
          &__header {
            display: none;            
          }

          &__menu {
            margin-top: 1.6rem;
          }

        }
      }
    }

  }
}