.product-image:not(.no-after) {
  @include fixedRatio;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #e1e2df;
  object-fit: cover;

  >.swiper-wrapper {
    position: absolute;
    display: flex;
  }
}

.product-image:is(.no-after) {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 2/3;
  object-fit: cover;
  background-color: #e1e2df;
}