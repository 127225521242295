.tabs {
  > ul {
    // column-gap: $space-unit;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    // grid-template-columns: repeat(2, 1fr);
  }
  &.tabs--fluid {
    .react-tabs__tab {
      &:not(:first-child) {
        padding-left: 0;
      }
    }
  }
  &.tabs--padding {
    .react-tabs__tab {
      margin-right: 0;
      text-align: center;
    }
  }

  [role='tab'] {
    padding: 1.6rem 2rem 1.4rem 2rem;
    flex: none;
  }

  &.tabs--stefanelId {
    .react-tabs__tab {
      font-size: 1.4rem;
      line-height: 2.2rem;
      padding: 0.8rem 0;
    }
  }
}
