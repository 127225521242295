.single-image {
  width: 100%;
  flex-shrink: initial;
  &__image {
    position: relative;
    overflow: hidden;
  }

  .swiper-wrapper {
    .single-image {
      flex-shrink: 0;
    }
  }

  &__wrapper {
    display: flex;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--center {
    .single-image__caption {
      text-align: center;
    }
  }
}

.image-module {
  overflow: hidden;
  &--margin {
    margin: ($space-unit * 16) ($space-unit * 8);
  }

  &--small-square {
    .single-image {
      &__image {
        width: 50%;
        padding-top: 50%;
        margin: 0 auto;
        max-width: 100%;
      }
      &__caption {
        width: 50%;
        margin: ($space-unit * 4) auto;
      }
    }
  }

  &--big-square {
    .single-image {
      &__image {
        width: 66.67%;
        padding-top: 66.67%;
        margin: 0 auto;
        max-width: 100%;
      }
      &__caption {
        width: 66.67%;
        margin: ($space-unit * 4) auto;
      }
    }
  }

  &--big-landscape {
    .single-image {
      &__image {
        width: 100%;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
        &::before {
          content: '';
          padding-top: 57.42%;
          display: block;
        }
        &__caption {
          width: 100%;
          padding: 0 ($space-unit * 4);
          margin: ($space-unit * 4) auto;
        }
      }
    }
  }

  &--small-landscape {
    .single-image {
      &__image {
        width: 66.67%;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
        &::before {
          content: '';
          padding-top: 66.67%;
          display: block;
        }
        &__caption {
          width: 66.67%;
          margin: ($space-unit * 4) auto;
        }
      }
    }
  }

  &.swiper-container {
    .single-image {
      &__image {
        width: 68.6rem;
      }
    }
  }

  &--potrait-image {
    .single-image {
      &__image {
        width: 100%;
        height: auto;
        margin: 0 auto;
        max-width: 100%;

        &::before {
          content: '';
          padding-top: 133%;
          display: block;
        }
        &__caption {
          width: 100%;
          padding: 0 ($space-unit * 4);
          margin: ($space-unit * 4) auto;
        }
      }
    }
    &.swiper-container {
      .single-image {
        &__image {
          width: 45.7rem;
        }
      }
    }
  }
}
