.modal.persistentModal {
  transform: unset;

  .modal {
    &__close {
      padding: 3.2rem;
    }

    &__header {
      padding-top: 3.2rem;

      .dialog__title {
        font-size: 1.8rem;
        line-height: 2.6rem;
        padding-bottom: 4rem;
      }
    }

    &__body {

      .forgot-pw-error__pw {
        font-size: 1.2rem;
        line-height: 2.4rem;
      }

      .persistentModal {
        &__body {
          &__form {
            &__btn {
              margin-top: 1.6rem;
              margin-bottom: 0.8rem;
            }
          }
          
          &__text {
            padding-bottom: 2.4rem;
          }
      
          &__link {
            font-size: 1.2rem;
            line-height: 2rem;
          }

          &__guest {
            margin-top: 3.2rem;

            &__btn {
              margin-top: 3.2rem;
            }   
            
            .divider-line {
              border: none;
              background-color: $grey_line;
              height: .1rem;
              margin: 1.2rem 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}