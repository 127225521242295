body {
  &#product-show {
    .findinstore {
      .findinstore-map-section {
        .row-pickup-point-container {
          height: 38.2rem;
        }
      }
    }
  }
}

.findinstore {
  #findinstore {
    background: $white;
    margin: 2.4rem;
    padding: 0 2.4rem;
    position: absolute;
    right: 0;
    overflow: unset;
    display: flex;
    flex-direction: column;
    height: 100%;

    max-height: calc(100vh - 4.8rem);
    border-radius: 0.8rem;
    width: 46rem;
    .title-wrapper {
      padding: 2.4rem 0;
    }
  }

  .title-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 1.8rem;
    line-height: 2.2rem;
    justify-content: space-between;

    .close-button {
      position: absolute;
      display: flex;
      align-items: center;
      right: 2.4rem;
      width: 1.6rem;
    }

    .findinstore-come-back-size {
      margin-right: 1.6rem;
    }
  }

  &-size-section {
    padding: 0.8rem 0 4.8rem;
    height: auto;

    .selector-size-expanded {
      .selector {
        &__header {
          padding-top: 4.8rem;
        }
      }
    }
  }



  &-map-section {
    position: relative;

    &-title {
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding-bottom: 2.4rem;
    }


    .search-wrapper {

      .row {
        padding-top: 2.4rem;
        padding-bottom: 1.6rem;
        gap: 0.8rem;

        .store-gotomps-box {
          flex: 1 1 0;
          margin-left: 0.6rem;
          display: flex;
          padding: 1.2rem 1.6rem;

          &.button {
            &::before {
              content: unset;
            }
          }
        }

        .js-choose-store {
          width: 50%;
          margin-right: 0.6rem;
        }
      }
    }

    .floating-search {
      //position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;

      #findinstore-form {
        .icon--search {
          position: absolute;
          padding: 1.7rem 1.6rem;
          display: none;
        }

        .input-text-woosmap {
          padding: 1.6rem;
          line-height: 1.6rem;
          width: 100%;
          border-radius: 0.8rem;
          border: 0.1rem solid $grey_line;

          &:focus {
            border: solid 0.1rem $black;
          }
        }

        .input-group-button {
          position: absolute;
          top: 0;
          padding: 1.6rem;
          line-height: 1.6rem;
          right: 0;
        }
      }
    }

    .row-pickup-point-container {
      height: 24rem;
    }

    .findinstore-results {
      margin: 2.4rem 0;
      max-height: calc(100vh - 4.8rem - 7rem - 18.1rem - 4.8rem - 5rem - 2.4rem - 2.4rem - 13.8rem);
      height: auto;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 1.8rem;
        visibility: visible;
        transition: all 0.3s ease-out;
      }

      &::-webkit-scrollbar-track {
        background-color: $grey;
        visibility: visible;
        border-left: 1.6rem $white solid;

      }

      &::-webkit-scrollbar-thumb {
        background-color: $red;
        transition: all 0.3s ease-out;
        visibility: visible;
        border-left: 1.6rem $white solid;
        background-clip: padding-box;
      }

      .result {
        padding: 2.4rem;
        border: 0.1rem solid $grey_line;
        border-radius: 0.8rem;

        .store {
          &-name-container {
            font-size: 1.6rem;
            line-height: 2rem;
          }

          &-address-container {
            line-height: 2.2rem;
            color: $dark_grey
          }
        }

        &-size-wrapper {
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: $dark_grey;
          padding-top: 2.6rem;
          margin-bottom: 0.4rem;
        }

        .details {
          position: relative;

          .availability-wrapper {

            .button-cta-wrapper {
              display: none;
            }

            .availability-in-store-label {
              font-size: 1.2rem;
              line-height: 1.6rem;
              color: $oos_red;

            }

            &[data-availability=high] {

              .availability-in-store-label {
                color: $status_success;

              }
            }


            &[data-availability=low] {

              .availability-in-store-label {
                color: $lowavailability_yellow;
              }
            }
          }
        }
      }
    }
  }

  &-store-selected {
    overflow-y: auto;

    .store-details {
      padding-top: 1.6rem;
      color: $dark_grey;
      height: fit-content;
      flex-direction: row;
      justify-content: space-between;

      &_product {
        align-self: flex-end;
        text-align: end;

        &_availability {
          color: $oos_red;

          &--low {
            color: $lowavailability_yellow;
          }

          &--high {
            color: $status_success;
          }
        }
      }

      .distance {
        display: none;
      }
    }

    &_btn {
      margin: 2.4rem 0;
    }

    &_actions {
      padding-bottom: 2.4rem;

      &_link {
        line-height: 2.2rem;
      }
    }
  }

  .mini-product-card-findinstore {
    display: flex;


    .mini-product-card-findinstore-image {
      max-width: 9rem;
    }

    &-details {
      justify-content: space-between;

      &-color {
        color: $dark_grey;
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }

    .mini-product-card-findinstore-name {
      width: 100%;
      margin-bottom: 0.4rem;
    }

    .mini-product-card-findinstore-price {
      width: 100%;

      .price {
        .par-big.price {
          margin: 0;
        }
      }
    }

    .row {
      width: 100%;
    }
  }


}

.selected-store-hours {
  .store-hours {
    .js-toggle-store-hours {
      .store-hours-info {
        cursor: pointer;

        .icon--chevron-down {
          /* &::after { */
          transform: rotatez(0);
          transition: all .3s ease-out;
          /* } */
        }

        &.opened {
          .icon--chevron-down {
            /* &::after { */
            transform: rotatez(180deg);
            transition: all .3s ease-out;
            /* } */
          }
        }
      }
    }
  }
}

.findinstore,
.pickuppoint {
  &-wrapper {
    position: relative;
  }

  #findinstore-usecurrentposition {
    &.disabled {
      cursor: not-allowed;
    }
  }

  &__usecurrentposition {
    text-align: center;
  }

  &__input-group {
    position: relative;

    &.has-loader {
      transition: padding 0.3s ease;
    }

    [type="submit"] {
      position: relative;
      margin-right: 0;
    }
  }

  &-results {
    .result {

      .button {
        margin-bottom: 0;
      }
    }

    .store-details {
      p {
        display: inline-block;
      }

      .distance {
        display: flex;
        justify-content: space-between;
        min-width: fit-content;
      }
    }
  }
}


.nearby-stores {
  &__icon {
    display: inline-block;
  }

  .result {
    &:not(:last-child) {
      border-bottom: 0.1rem solid grey;
    }

    .button {
      &.wide {
        min-width: 8rem;
      }
    }
  }
}

.prod-select-size-modal {
  .find-in-store-size-select-btn {
    display: none;
  }

  ul.list-styled-sizes {
    display: flex;
    flex-flow: wrap;
    border-top: 0.1rem solid $grey_line;
    border-bottom: 0.1rem solid $grey_line;

    li {
      width: 25%;
      display: flex !important;
      justify-content: center;
      align-items: center;

      &.hide {
        display: none !important;
      }
    }

    hr {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.fis-select-size-modal {
  .add-to-cart {
    display: none;
  }
}

#pickupPointMap {
  height: 0;
  background-color: grey;
  display: none;
}

.pickup-input-group-field {
  height: 4rem;
}

.pickup-input-group-button {
  margin-left: 20rem;
  margin-top: 2rem;
}

.js-pup-summary {
  background-color: rgb(244, 244, 244);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.pickup-input-bar {
  border: none;
  border-bottom: 0.1rem solid rgb(128, 128, 128);
  margin-left: 1rem;
  margin-right: 1rem;
}

.label-required-pickup {
  margin-left: 2.5rem;
  color: rgb(47, 79, 79);
  font-size: 1.1rem;
  position: absolute;
  margin-top: 2.5rem;
}

.radius-pickup {
  margin-right: 1rem;
  margin-left: 1rem;
}

.pickupboolean {
  padding-right: 1.14286rem;
}

.pickuppointresults {

  .result {
    &:not(:last-of-type) {
      border-bottom: 0.1rem solid grey;
    }

    .button {
      margin-bottom: 0;
    }

    i {
      &.fas {}
    }
  }


  .store-details {
    p {
      display: inline-block;
    }

    .distance {
      display: flex;
      justify-content: space-between;
    }
  }
}

#store-locator-root {
  height: calc(100vh - var(--nav-wrapper) - 8rem);
  min-height: 63rem;
}