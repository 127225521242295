.hero {
  display: block;
  // label property
  .hero-column__label {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  .btn--pause {
    left: 2rem;
  }
}

// Margin property
.hero--with-border {
  margin: 1.4rem ($space-unit * 4);
  .hero__hero-column {
    margin: 0.2rem 0;
  }
}

.hero-column__inner-container {
  padding: ($space-unit * 4);
}

.hero--center-bottom {
  .hero-column__inner-container {
    padding-bottom: 3.2rem;
  }
}

.hero__hero-column:nth-child(2) {
  .hero-column__img {
    transition-delay: 0.1s !important;
  }
}

.hero__hero-column:nth-child(3) {
  .hero-column__img {
    transition-delay: 0.2s !important;
  }
}
