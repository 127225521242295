.modal {
  padding: 0 ($space-unit * 8) ($space-unit * 8);
  width: auto;
  min-width: 70%;
  height: auto;
  max-height: 90vh;
  max-width: 90vw;
  // background-color: rgba($color: $black, $alpha: 0.5); // non di default, deve essere bianco

  &.modal--small {
    @include modal-width(8);
  }
}
.overlay-modal {
  background-color: rgba($color: $black, $alpha: 0.5);
  // opacity: 0.5;
  transition-duration: 0s;
}
