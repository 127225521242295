.filters__menu {
  &--productLineCode,
  &--eCommerceCategory {
    ul {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, auto);
      grid-auto-flow: column;
    }
  }
  &--eCommerceCategory {
    ul {
      grid-template-rows: repeat(8, auto);
    }
  }
  &--refinementColor {
    ul {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(4, auto);
      grid-auto-flow: column;
    }
  }
}
