body#account-registerphone,
body#account-registercomplete,
body#account-otp,
body#account-stefanelId,
body#account-otpphone,
body#account-registeremail,
body#account-registersocialemail,
body#account-passwordreset {
  main {
    min-height: calc(var(--viewport-height) - 20.8rem);
  }

  header {
    .login__back {
      padding-top: 4rem;
      padding-left: 1.6rem;
    }    
  }


  .register {
    &__steps {
      padding-top: 2.7rem;
      max-width: 27rem;
      margin: 0 auto;
    }

    &_form {
      margin-top: 4rem;

      &__title {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }

      &__subtitle {
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 3.2rem;
      }     
    }

  }
}