.container-two-col {
  max-width: 944px;
  div:first-child {
    margin-right: 8rem;
  }

  .main-col {
    width: 60%;
  }

  .sidebar-col {
    width: 40%;
  }
}
