.cookie-center {
  &__form {
    grid-template-areas:
      'table'
      'table'
      'table'
      'table'
      'row'
      'submit';
    row-gap: initial;
  }
  .cookie-row {
    margin: 2.4rem 0;
  }
  .cookie-item {
    padding: ($space-unit * 4) 0;
    @include border('bottom');
    &:last-of-type {
      border-bottom: initial;
      padding-bottom: 2.4rem;
    }
    h3 {
      margin-top: 0;
    }
  }
}