.newsletter-landing {
  &__page {
    margin-top: calc(var(--ticker-height) + 11.2rem + 2.4rem);
    display: grid;
    grid-template-columns: 57% 1fr;
    gap: 4rem;    
  }

  &__body {
    &__form {
      width: 34vw;

      &__text {
        padding-bottom: 3.2rem;

        &__subtitle {
          line-height: 2.2rem;
          padding-top: 1.9rem;
        }
      }

      .newsletter {
        &__btn {
          margin-top: 2.4rem;
        }
      }

      .privacyflag {
        &.first-flag {
          margin-top: 1.6rem;
        }
      }
    }
  }

}