.accordion-component {
  margin-bottom: 12rem;

  .accordion__title {
    font-size: 2.8rem;
    font-weight: 300;
    margin-bottom: 3.2rem;
  }

  .accordion__item {
    @include border('bottom');
  }

  .accordion__header[aria-expanded='true'] + .accordion__panel {
    padding-bottom: 2.4rem;
    color: $dark_grey;
  }

  .accordion__header > span {
    text-transform: none;
    font-size: 1.6rem;
    font-weight: 400;
    padding-right: 2.4rem;
  }

  .accordion-actions {
    padding-top: $space-unit * 10;
    align-items: center;
    p {
      text-align: right;
    }
  }

  .accordion__header {
    border-top: none;
    padding: 1rem 0;
  }
  .wrapper-text {
    padding-bottom: ($space-unit * 6);
    p {
      padding-bottom: ($space-unit * 4);
    }
  }
}
