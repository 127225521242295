.tooltip {
  position: relative;
  button[aria-expanded='true'] {
    + .tooltip__box {
      opacity: 1;
      visibility: visible;
    }
  }
  &:hover{
    .tooltip__box{
      opacity: 1;
      visibility: visible;
    }
  }
  .tooltip__box {
    position: absolute;
    @extend %primary-xxs;
    right: 0;
    transform: translateX(50%);
    bottom: calc(100% + (#{$space-unit} * 3));
    border-radius: 0.4rem;
    z-index: 2;
    padding: 1.6rem;
    opacity: 0;
    visibility: hidden;
    background-color: $black;
    color:$white;
    text-align: center;
    min-width: 20rem;
    max-width: 20rem;
    font-size: 1.4rem;
    line-height: 2.2rem;

    @include animate(0.15);
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 50%;
      transform: translate(0.1rem, 100%);
      width: 0;
      height: 0;
      border-left: ($space-unit * 2) solid transparent;
      border-right: ($space-unit * 2) solid transparent;
      border-top: ($space-unit * 2) solid $black;
    }
  }

  &__icon {
    &:after {
      width: 1.2rem;
      height: 1.2rem;      
    }

  }

  &-persistent {
    position: relative;

    &__icon {
      cursor: pointer;
    }

    &-modal {
      position: absolute;
      right: 0;
      transform: translateX(50%);
      bottom: calc(100% + (#{$space-unit} * 3));
      border-radius: 0.4rem;
      z-index: 2;
      padding: 1.6rem;
      background-color: $black;
      color:$white;
      width: max-content;
      max-width: 29rem;
      font-size: 1.4rem;
      line-height: 2.2rem;
      text-align: left;
  
      @include animate(0.15);

      &__header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        align-items: center;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 50%;
        transform: translate(0.1rem, 100%);
        width: 0;
        height: 0;
        border-left: ($space-unit * 2) solid transparent;
        border-right: ($space-unit * 2) solid transparent;
        border-top: ($space-unit * 2) solid $black;
      }
    }
  }
}
