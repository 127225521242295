.icon {
  border: none;
  min-width: initial;
  padding: 0;
  height: auto;
  @each $name, $uri in $sprites {
    &--#{$name}{
      &:after {
        content: '';
        @extend .icon;
        @include fixedSprite($name);
        > span {
          display: none;
        }
      }
    }
  }
}

[class*='.icon--']{
  p{
    line-height: 2rem;
  }
}