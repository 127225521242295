.orders-listing__order-detail {
  display: flex;
  justify-content: space-between;
}
.order-detail__column {
  width: 50%;
  margin-bottom: ($space-unit * 4);
  &:last-child {
    margin-bottom: 0;
  }
}

.row-info__column {
  width: 50%;
}
