.product-selection {
  position: relative;
  padding-top: 1.6rem;
  padding-right: 0;
  padding-left: 0;
  height: 100%;

  &--loading {
    opacity: 0;
  }

  &__size {
    position: relative;
    overflow: hidden;
    &_btn {

      &_label {

        font-size: 1.2rem;
        line-height: 2.0rem;
        font-weight: 400;
        font-family: "Lato",Arial,sans-serif;
        color: $black;

      }

      &:hover,
      &.hovered {
        transform: translateX(0);
      }
    }


  }

  &__wrapper {
    .errormessage {
      padding-top: 2.4rem;
      display: flex;
      align-items: center;
      gap: 0.9rem;

      &-text {
        font-size: 1.2rem;
        line-height: 2rem;
      }
      &::before {
        content: '';
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 0.8rem;
        background-color: $oos_red;
      }
    }
  }

  .product-selection__line {
    margin: 0 0 ($space-unit * 2) 0;
    padding: 0 0 ($space-unit * 2) 0;
    @include border('bottom');

    [class^='icon'] {
      margin-right: ($space-unit * 2);
      width: 1.4rem;
      height: 2rem;
    }
  }
  .product-selection__line-name_exclusive {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .product-selection__line-name {
    text-align: left;
    display: flex;
    align-items: center;
    a {
      font-size: 1.4rem;
      line-height: 1.6rem;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .product-selection__description {
    @extend %primary-xs;
    margin: 0 0 ($space-unit * 7);
  }

  .product-selection__preorder {
    @extend %primary-xs-u;
    margin-bottom: $space-unit * 4;
    text-align: left;
  }

  .product-selection__name-price {
    position: relative;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    padding-top: ($space-unit * 2);
  }

  .product-selection__name-price__name {
    @extend %primary-m;
    text-align: left;
    padding-right: ($space-unit * 8);
    line-height: 1.2;
    padding-bottom: 2.4rem;
  }

  .product-selection__name-price__price {
    @extend %primary-m;
    flex-shrink: 0;
  }

  .product-selection__ctas {
    display: flex;
    margin-bottom: ($space-unit * 3);

    .button {
      margin-right: $space-unit;
      @include text-shorten;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .product-selection__actions-shipping {
    display: flex;
    flex-direction: row;
    // align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;

    &__contact-us {
      height: 1.6rem;
    }

    .button + .button {
      margin-left: ($space-unit * 5);
    }

    &__shipping {
      @extend %primary-xs;
      text-transform: capitalize;
    }
  }

  .product-selection__modelinfo {
    position: relative;
    margin: $space-unit * 9 0 $space-unit * 1;
    padding-left: $space-unit * 6;
    @include sprite('info');
    background-position: left;
    background-repeat: no-repeat;
    background-size: $space-unit * 4 $space-unit * 4;
    color: $black_secondary;
    min-height: $space-unit * 4;
    display: flex;
    align-items: center;

    span {
      @extend %primary-xs;
    }
  }
  &__size {
    display: flex;
    justify-content: space-between;
  }
}

.collection-modal {
  &.modal {
    min-width: 0;
    width: 54.9rem;
    max-width: 100%;
    padding: 3.2rem 3.6rem;
  }
  &__title-wrapper {
    @extend %primary-m;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    [class^='icon'] {
      width: 1.4rem;
      height: 2rem;
    }
  }

  &__title {
    margin-left: ($space-unit * 2);
  }
  &__btn-container {
    text-align: center;
    margin-top: 2.7rem;
  }
  .button {
    display: inline-flex;
    width: auto;
    min-width: 22.6rem;
  }
}
