.modal.sizeguide-pdp-modal {
  max-width: 100vw;
  min-width: 100vw;
  max-height: 85%;
  top: unset;
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 0.8rem 1.6rem 0;

  .modal {

    &__close {
      display: none;
    }

    &__header {
      position: relative;
      .dialog__title {
        font-size: 2.4rem;
        font-weight: 300;
        line-height: 3.2rem;
        justify-content: center;
        padding-top: 2.4rem;
      }

      &::before {
        content: '';
        background-color: $grey_line;
        width: 8rem;
        height: 0.2rem;
        border-radius: 1.6rem;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
    }

  }

  .sizeGuide {
    &Modal {
      &__wrapper {
        &_level {
          flex-direction: column;
          gap: 0;
          padding-bottom: 0;
        }
        &_body {
          max-height: 48vh;
        }
      }

    }
    &Table {
      &__wrapper {
        padding: 0;
        table {
          thead {
            tr {
              th {
                width: 15rem;
                min-width: 15rem;
              }
            }
          }
        }

      }

      &__container,
      &__wrapper--international {
        overflow-x: auto;

        &::-webkit-scrollbar {
          height: 0.2rem;
          visibility: visible;
          transition: all 0.3s ease-out;
        }

        &::-webkit-scrollbar-track {
          background-color: $grey;
          visibility: visible;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $red;
          transition: all 0.3s ease-out;
          visibility: visible;
        }  
      }
    }

    &Accordion {
      padding-top: 3.2rem;
    }

    &Measure {
      &__card {
        flex-direction: column;
        gap: 2.4rem;
        
        &_img {
          max-width: 15rem;
          &-container {
            margin: auto;  
            width: 100%;          
          }
        }
        &_content {
          padding-left: 0;
        }
      }
    }
  }

}