.color-variation-carousel__wrapper {

  #color-variation-carousel__variant__container {
    flex-direction: row;
    flex-grow: 1;
    margin-bottom: 2.4rem;
  }

  #color-variation-carousel__product_name {
    max-width: unset
  }

  .color-variation-carousel__disclaimer {
    padding-right: 0;
    justify-content: flex-end;
    
    .swiper-button-container{
      display: none;
    }
  }

  .color-variation-carousel__variant__end{
    justify-content: flex-end;
    position: absolute;
    right: 2.4rem;
  }

  .swiper-pagination-progressbar {
    margin: 3.2rem 0 4.8rem 0;
    //width: 100%;
  }

}
