.ccpa-form {
  margin-top: 3.2rem;
  overflow: hidden;

  .alert {
    margin-left: 0;
  }

  .disabled-overlay {
    opacity: 0.5;
    pointer-events: none;
    z-index: 1;
    @include animate;
  }

  .ccpa-radio {
    .radio-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3.2rem;

      .form-group__legend {
        padding-bottom: 0;
        width: 50%;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;

      }

      .form-group {
        flex-grow: 1;
        display: flex;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;

        .radio {
          margin-bottom: 0;

          &:not(:last-child) {
            margin-right: 20%;
          }
        }
      }
    }

    .not-requester {
      a {
        display: block;
        padding-top: 1.2rem;
        padding-bottom: 2.4rem;
      }
    }
  }

  .confirm-msg {
    padding-top: 2rem;
    padding-bottom: 1.2rem;
  }
}