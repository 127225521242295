.filters {
  width: 100%;
}

.filters__menu {
  position: relative;
  padding: 0;
  display: none;
  &__menuitem--clear {
    display: none;
  }
  &__menuitem {
    &:hover {
      opacity: 1;
    }
  }
}

.filters__menuitem {
  @include border('bottom');
  height: 4.2rem;
  padding: 1.5rem 0 1.1rem;
  margin: 0;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  &:hover {
    opacity: 1;
  }
  &::after {
    @include fixedSprite('plus');
  }

  &[aria-expanded='true'] {
    border-bottom-color: transparent;
    + .filters__menu {
      @include border('bottom');
      display: block;
    }
    &::after {
      @include fixedSprite('minus');
      transform: translateY(-50%) rotate(0);
    }
  }
}
