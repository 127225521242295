//spacings
$space-unit: 0.4rem;
$space-line: 1px;
$header-menuheight-desktop: 59px;
$header-height-desktop: 13.6rem + 1.6rem;
$header-height-mobile: 6rem;
$footer-clientservice-height-desktop: 31.6rem; // arbitrary height, could vary depending on content
$footer-clientservice-height-mobile: 57rem; // arbitrary height, could vary depending on content
$ticker-bar: 3.2rem;
$gradient-height: 12rem;
$spacing-multiplier: (
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  10,
  12,
  15,
  16,
  18,
  20,
  24,
  28,
  30,
  32,
  48,
  64,
  80,
  120
);