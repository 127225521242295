.widget-m-media-quote {
  .img-wrapper {
    margin-right: -16px;
    margin-left: -16px;
  }

  .text {
    margin: 2rem auto 6rem auto;
  }
}
