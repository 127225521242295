.product-look {
  margin-top: 0;
  margin-bottom: 6rem;
  .product-look__content {
    grid-template-columns: 1fr;
    grid-gap: 48px 0;
  }
  .product-tile__color {
    margin-top: 2.4rem;
  }
}
