.product-details {
  padding-top: 9.6rem;
}

.product-thumbnails {
  display: block;
  padding: 0 2.4rem;
  height: fit-content;
  position: sticky;
  top: 15.2rem;

  img {
    margin-bottom: 0.8rem;
  }
}

.product-bottom {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;



    &_img {
      &-wrapper {
        margin-right: 8rem;
      }
    }

    &-container {
      padding: 4rem 9.6rem 8rem;
      .accordion__header {
        display: none;
        &[aria-expanded='false'] + .accordion__panel {
          height: auto !important;
          visibility: visible;
        }
      }      
    }

    &_text {
      &_title {
        padding-bottom: 1.6rem;
        border-bottom: 0.03rem solid $grey_line;
      }
      &--left,
      &--right {
        margin-top: 4.8rem; 
        height: fit-content
      }

      &--left {
        padding-right: 4rem;
      }

      &--right {
        padding-left: 4rem;
        border-left: solid $grey_line 0.03rem;
      }
    }
    &_shippingReturn {
      &_text {
        margin-top: 0;
      }
    }
    &_description {
      &_details {
        &-container {
          .find-in-store-cta-wrapper {
            display: none;
          }
        }
      }
    }
  }
}
