.carousel {
  display: flex;

  .carousel__item {
    @each $breakpoint, $gutter in $grid-gutter {
      padding-right: $gutter * 0.5;
      padding-left: $gutter * 0.5;
    }
    flex: 1 0 auto;
  }
}
// to hide slides resizing
.swiper-container {
  @include animate;
  &--loading {
    position: absolute;
    opacity: 0;
  }
}
.swiper-container:not(.swiper-container-initialized) {
  opacity: 0;
  visibility: hidden;
}
