.refine {
  top: $header-height-desktop - 0.1rem;
  // background-position-y: $header-height-desktop;
  > .container {
    padding-top: ($space-unit * 16) + 0.1rem;
    padding-bottom: ($space-unit * 4);
  }

  // .refine__options {
  //   display: flex;
  //   visibility: hidden;
  //   z-index: 1;
  //   @include animate;
  //   transform: translateY(-100%);

  //   &.is-visible {
  //     visibility: visible;
  //     transform: translateY(0);
  //   }
  // }
}
