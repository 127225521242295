.inline-alert-message {
  background-color: $input_background;
  padding: 0.9rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  @extend %primary-xs;
  &__text-holder {
    display: inline-flex;
    align-items: center;
  }
  &__icon {
    @include fixedSprite('info-black');
    display: inline-block;
    width: ($space-unit * 4);
    height: ($space-unit * 4);
    min-width: ($space-unit * 4);
    min-height: ($space-unit * 4);
    margin-right: ($space-unit * 2);
    @include fix-font-space(0.2rem);
  }
  &__close-btn {
    position: absolute;
    right: $space-unit * 2;
    top: 50%;
    transform: translateY(-50%);

    &::before {
      content: '';
      @include fixedSprite('close');
      display: block;
    }
  }
}
