.box {
  margin-bottom: 16px;
  padding: 24px;
  @include rounded-box;
  .box-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    &__title {
      @extend %primary-l;
    }
  }
}
