.checkoutpage {
  >.row {
    >[class^='col-'] {
      padding-left: 60px;
      padding-right: 60px;
    }

    >.col-lg-7 {
      padding-left: 14%;
    }

    >.col-lg-5 {
      padding-right: 14%;
    }
  }
}

.shipping-method {
  &-price {
    &-wrapper {
      color: $dark_grey;
      text-align: right;
      align-self: end;
      padding: 0;
    }
  }
}