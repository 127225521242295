.footer {
  position: relative;
  z-index: 4;
  background-color: $white;
  border-top: $space-line solid $grey;
  // @include gray-gradient-pseudo;

  .container {
    border-bottom: $space-line solid $grey_line;
  }

  &.footer--clientservice {
    margin-top: 0;
    padding: ($space-unit * 10) 0;
    border-top: 0;
    // @include gray-gradient-pseudo;

    .container:nth-of-type(1) {
      padding-top: 0;
    }

    .wrapper-footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: $space-unit * 8;
      row-gap: 4rem;
    }

    .footer-item {
      &:nth-child(2) {
        grid-column-start: 1;
        grid-row-start: 2;
      }

      h3 {
        @extend %primary-xs-u;
        padding-bottom: ($space-unit * 2);
      }

      p {
        @extend %primary-s;
        margin-bottom: ($space-unit);
      }

      div {
        margin-top: ($space-unit);

        >a {
          @extend %primary-s;
          text-decoration: none;
          display: flex;
          align-items: center;
        }
      }
    }

    .start-chat {
      @extend %primary-s;
      display: flex;
      align-items: center;

      i {
        margin-right: $space-unit;
      }
    }

    .accepted-payments {
      @include reset-list;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;

      li {
        margin-right: ($space-unit * 2);
        margin-bottom: ($space-unit * 2);
      }
    }
  }

  .social-wrapper {
    display: flex;
    justify-content: space-between;
    width: 50px;
    padding-bottom: 40px;
  }

  .footer__nl {
    padding-top: 3.2rem;
    padding-bottom: 2.4rem;

    .newsletter-footer {
      &__title {
        line-height: 2.2rem;
      }
    }

    .form-control {
      input {
        &::placeholder {
          color: $dark_grey;
          line-height: 48px;
          font-size: 1.4rem;
        }

        margin-top: 2.6rem;
        padding: 0;
      }
    }

    .button {
      border: none;
      position: absolute;
      right: 0;
      bottom: 0;
      padding-right: 0;
      min-width: initial;
      width: initial;
      padding-left: 2.3rem;
      margin-left: $space-unit * 2;
    }
  }

  .follow-us {
    margin-bottom: 25px;
  }

  .footer-menu {
    @include reset-list;
  }

  .footer-menu__menuitem {
    @extend %primary-xs-u;
    text-decoration: none;
    color: $black;
    display: none;
    @include border('bottom');
    padding: 1rem 0 1rem;
    width: 100%;
    text-align: left;

    &:focus {
      @include focus-underline;
    }
  }

  .footer-countryselector {
    text-decoration: none;
  }

  .footer-menu__menuitem__current {
    @extend %primary-xs;
    text-transform: capitalize;
    color: $black_secondary;
    padding-left: ($space-unit * 3);
  }

  .footer-menu__section {
    @include reset-list;
    padding-bottom: ($space-unit * 10);
    text-align: left;

    li {
      &:last-child {
        .footer-menu__section__menuitem {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer-menu__section__menuitem {
    display: inline-block;
    text-decoration: none;
    color: $black;
    margin-bottom: $space-unit;
    @extend %primary-s;
    @include hover(0.2);

    &:focus {
      @include focus-underline;
    }
  }

  .footer-menu--col {
    display: flex;
    flex-direction: column;
    @include border('bottom');

    .footer-menu__menuitem {
      display: flex;
      align-items: center;
      border-bottom: 0;
      @include border('top');
      height: 4.2rem;
      padding: 0;
    }

    .footer-menu__section__menuitem {
      @extend %primary-xs;
      padding: 0;
      margin-bottom: $space-unit * 3;
    }

    .footer-menu__menuitem,
    .footer-menu__section__menuitem {
      &::after {
        content: '';
        @include fixedSprite('plus');
        position: absolute;
        right: -0.8rem;
        top: 50%;
        transform: translateY(-50%);
        width: 2.4rem;
        height: 2.4rem;
        display: none;
      }
    }

    li:nth-child(1) {
      order: 3;
    }

    li:nth-child(2) {
      order: 4;
    }
  }

  .social-legals {
    display: flex;
    flex-direction: column;
    padding-bottom: ($space-unit * 6);
    align-items: flex-end;

    .social-wrapper {
      padding-bottom: ($space-unit * 16);
      display: flex;
      align-items: center;

      >a {
        width: ($space-unit * 4);
        height: ($space-unit * 4);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: $space-unit * 4;
        @include hover(0.2);

        &[href*='facebook'] {
          width: ($space-unit * 3);
        }
      }
    }

    .copyright {
      @extend %primary-xxs;
      text-align: right;
      background-color: $white;
      color: $dark_grey;
    }
  }

  .socials-languages {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .languageselector {
    display: flex;
    justify-content: center;
    color: #6f6f6f;
    background-color: #f7f7f7;
    width: 82px;
    padding: 2px 2px 2px;
    border-radius: 5px;

    .language {
      color: #6f6f6f;
      padding: 6px 8px 6px;
      border-radius: 5px;
      width: 40px;
      text-align: center;

      &.active {
        background-color: #fff;
        padding: 6px 8px 6px;
        border-radius: 5px;
      }

      a {
        text-decoration: none;
        @extend %primary-xs-u;
      }
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.newsletter {
  &-container {
    position: relative;
    display: flex;
    justify-content: stretch;

    .form-group {
      margin-bottom: 0;
      flex-grow: 1;
    }      
    
    .input--success {
      border-color: $green_success;
    }
  }

  &-wrapper {
    .invalid-feedback.globalerrors {
      margin-top: 0.8rem;
      margin-bottom: 0;
    }
  
    #newsletter-success-message {
      font-size: 1.2rem;
      line-height: 2rem;
      margin-top: 0.8rem;
    }
  }
}


#newsletter-foreign-modal,
#newsletter-modal {
  &.modal-isml.modal-right {
    .modal {
      &-overlay {
        align-items: flex-end;
      }

      &-container {
        border-radius: 0.8rem;
        margin-right: 2.3rem;
        margin-left: 0.8rem;
        margin-bottom: 3.5rem;
        max-width: 32.7rem;

        &.newsletter-modal--noimg {
          margin-bottom: 1.7rem;
          .modal {
            &-close {
              right: 2.4rem;
              top: 2.4rem;
            }
          }

          .newsletter-popup-wrapper {
            padding-top: 4rem;
          }
        }
      }

      &-close {
        top: 1rem;
        right: 1rem;
      }
    }
  }

  .modal-content {
    padding: 0;

    .footer__nl {
      padding-top: 0;
      padding-bottom: 0;

      .newsletter-popup {
        &-wrapper {
          padding: 2.4rem;      
        }
    
        &-title {
          font-size: 1.8rem;
          line-height: 2.6rem;
          padding-bottom: 0.8rem;
        }
    
        &-content {
          line-height: 2.4rem;
          color: $dark_grey;
        }
        &-btn {
          width: 100%;
          position: relative;
          margin-top: 2.4rem;
          padding-left: 0;
          margin-left: 0;
        }
      }
    }
  }
}

#newsletter-foreign-modal {
  .privacyflag {
    text-align: start;
  }

  .newsletter-popup-wrapper {
    text-align: center;
    padding: 3.4rem;
  }
}

.newsletter-foreign-modal {
  .form-group {
    margin-bottom: 1.6rem;
  }

  &.modal-container {
    max-width: 440px;
  }
}

.newsletter-foreign-modal,
.newsletter-modal {
  &.modal {
    text-align: center;
    min-width: 0;
    height: 45.5rem;
    width: 68.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 95%;
  }

  .modal__header {
    padding: 0;
    margin-bottom: ($space-unit * 4);

    h2 {
      padding-bottom: 0;
    }
  }

  &__description {
    span {
      max-width: 46rem;
      margin: 0 auto ($space-unit * 6);
      display: block;
    }
  }

  &__img-container {
    position: relative;
    width: 50%;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__columns-wrapper {
    width: 100%;
    display: flex;
    height: 100%;
  }

  &--subscription-modal.modal {
    width: 91.6rem;
    height: auto;
    padding: 0;

    .modal {
      &__body {
        width: 100%;
        height: 100%;
      }

      &__header {
        display: none;
      }
    }

    &__description {
      width: 50%;
    }
  }

  &__title {
    margin-bottom: ($space-unit * 2);
    @extend %primary-m;
  }

  &__text-holder {
    text-align: left;
    padding: ($space-unit * 8);
    overflow: auto;
    width: 50%;
    min-height: 60rem;
  }

  &__columns-wrapper {
    .newsletter-modal__description {
      span {
        max-width: 100%;
      }
    }
  }

  &__radio-wrapper {
    margin-top: 5rem;
    margin-bottom: 5rem;

    >p {
      text-transform: uppercase;
      @extend %primary-xxs;
      margin-bottom: 1.3rem;
    }

    label {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__privacy-flag {
    .checkbox input[type='checkbox']+label {
      @extend %primary-xxs;
    }
  }

  &__legal-label {
    margin-top: ($space-unit * 4);
    @extend %primary-xxs;
    color: $black_secondary;
  }
}

.privacyflag {
  &.checkbox {
    display: flex;
    margin-top: 0.8rem;
    align-items: unset;   

    .privacyflag__label {
      font-size: 1.2rem;
      line-height: 2rem;
      color: $dark_grey;
      padding-left: 1.6rem;
    } 

    &.first-flag {
      margin-top: 2.4rem;
    }

    &.second-flag {
      pointer-events: none;
      opacity: .5;
  
      &.active {
        pointer-events: unset;
        opacity: 1;
      }
    }
  }
}


.newsletter-module {
  margin: 7.9rem 0 9.9rem;
  padding: 0 1.6rem;

  .footer__nl {
    padding: 0;
  }

  &__title {
    @extend %primary-l;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  &__description {
    @extend %primary-s;
    width: 44.6rem;
    max-width: 100%;
    margin: 0 auto 3.7rem;
    text-align: center;
  }
}

// for pages with react content, loaded gets added after a delay - set in loader.txt
// experimental - see how to handle it properly :(
#cart-show {
  .footer {
    display: none;
  }

  &.loaded {
    .footer {
      display: block;
    }
  }
}

.multicountryPopup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  pointer-events: none;

  &-box {
    opacity: 1;
    position: absolute;
    right: 2.4rem;
    bottom: 4rem;
    background-color: $white;
    border-radius: 0.8rem;
    width: 42.8rem;
    transition: all 0.3s ease-out;
    pointer-events: all;
    padding: 3.2rem 2.4rem;

    &.closed {
      opacity: 0;
      pointer-events: none;
    }
  }

  &-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 3.2rem;
  }

  &-body {
    button {
      width: auto;
      margin-bottom: 1.6rem;
    }
  }
}