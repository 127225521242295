.list__empty-message {
  width: 100%;
  margin: ($space-unit * 10) auto ($space-unit * 20);
  .empty-message__text {
    margin-bottom: ($space-unit * 6);
  }
  .button {
    width: 24rem;
  }
}
