.checkbox {
  margin-top: ($space-unit * 4);
  break-inside: avoid-column;
  position: relative;

  input[type='checkbox'],
  input.radioButton[type='radio'] {
    position: relative;
    color: $black;
    width: $checkbox-size;
    height: $checkbox-size;
    margin: 0;
    background-color: $white;
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    flex-shrink: 0;

    &:disabled {
      border: $space-line solid $black_inactive;
      opacity: .3;

      +label {
        color: $black_inactive;
      }
    }

    +label {
      display: inline-block;
      color: $black;
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;
      font-weight: normal;
      left: initial;
      top: initial;
      position: relative;
      padding-left: ($space-unit * 2);
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @include fixedSprite('checkbox-off');
    }

    &:checked {
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include fixedSprite('checkmark-radio');
      }
    }
  }

  // container
  .checkbox__field {
    display: flex;
    align-items: center;

    .switch {
      &:has(input:disabled) {
        pointer-events: none;
        border-color: $black_disabled;

        .value {
          &.active {
            background-color: $black_disabled;
          }
          &:not(.active) {
            color: $black_disabled;
          }
        }
      }
    }

    // margin-bottom: 1rem;
  }
}

input.radioButton[type='radio'] {
  position: relative;
  color: $black;
  width: $checkbox-size;
  height: $checkbox-size;
  margin: 0;
  background-color: $white;
  border: $space-line solid $black;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: ($space-unit * 2);

  &:disabled {
    border: $space-line solid $black_inactive;

    +label {
      color: $black_inactive;
    }
  }

  +label {
    display: inline-block;
    color: $black;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    left: initial;
    top: initial;
    position: relative;
    padding-left: ($space-unit * 2);
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include fixedSprite('checkbox-off');
  }

  &:checked {
    background-color: $black;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @include fixedSprite('checkmark-radio');
    }
  }
}

.is-invalid {
  .checkbox {
    color: $status_error;
  }
}