.hero-v2 {
  flex-direction: column;

  a {
    flex-direction: column;
  }

  .video-img-container {
    width: 100%;
  }

  .hero2-text {
    align-self: auto;
    padding: 50px 20px 20px;

    .item-title {
      font-size: 2.8rem;
    }
  }
}
