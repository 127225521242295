#klarna-container {
  margin-top: 1.6rem;
  display: flex;
  & > div {
    border: 0;
    display: flex;
    gap: 8;
  }

  &::part(osm-container) {
    flex-direction: row;
    gap: 0.8rem;
    border: none;
    padding: 0;
    justify-content: center;
  }

  &::part(osm-message),
  &::part(osm-cta) {
    font-size: 1rem;
    line-height: 1.8rem;
  }

  &::part(osm-message) {
    margin-right: -1.4rem;
  }

}


