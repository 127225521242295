.text-section {
  padding: ($space-unit * 4) 0 ($space-unit * 4);

  height: 100%;

  &__title {
    font-size: 1.8rem;
    line-height: 2.2rem;

  }

  &__subtitle {
    font-size: 1.6rem;
    line-height: 2.4rem;

    margin-top: 0.8rem;
  }

  &__description {
    width: 100%;
    padding-bottom: ($space-unit * 4);

    &:last-of-type {
      padding-bottom: 0;
    }

    &__line {
      display: block;
      // padding-bottom: ($space-unit);
    }
  }

  &__dl {
    padding-bottom: ($space-unit * 6);
    display: grid;
    grid-template-columns: 40% 60%;

    &:last-of-type {
      padding-bottom: 0;
    }

    dt,
    dd {
      margin-bottom: ($space-unit);
      display: block;
      @include text-shorten;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    dd {
      .label {
        display: inline-block;
        color: $black_secondary;
        margin-right: ($space-unit * 4);
      }
    }

    &--justified {
      dd {
        text-align: right;
        justify-self: flex-end;
      }
    }
  }

  &__justified {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}