.container:has(.widget-a){
  padding: 0;
}

.widget-a {
  height: 31.1rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 2.4rem;

  .widget-a-content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 31.1rem;
    width: 100%;
    padding: 1rem 4rem;
    gap: 2.4rem;
  }

  &-title {
    font-size: 3.2rem;
    font-weight: 300;
    line-height: 4rem;
    text-align: center;
  }
  
  &-subtitle {
    font-size: 1.8rem;
    line-height: 2.6rem;
    text-align: center;
  }

  &-cta {
    position: relative;
    display: flex;
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-decoration: underline;
    &.widget-a-cta--white {
      color: $white;
    }
    &.widget-a-cta--black {
      color: $black;
    }
  }

  &-caption {
    padding-top: 2.4rem;
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: center;
  }

  .widget-a-bg {
    img {
      width: 100%;
      height: 31.1rem;
      object-fit: cover;
    }
  }
}
