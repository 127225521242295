.contact-us {
  &__title {
    text-align: center;
    margin-bottom: ($space-unit * 3);
    @extend %primary-m-u;
  }
  &__form-title {
    text-align: center;
    margin-bottom: ($space-unit * 3);
    @extend %primary-m-u;
    margin-top: ($space-unit * 10);
  }
  &__description {
    text-align: center;
    margin-bottom: ($space-unit * 6);
  }
  &__faqs {
    margin-bottom: ($space-unit * 6);
    .accordion__group {
      @include border('top');
    }
  }
  &__submit-message {
    text-align: center;
    margin-top: ($space-unit * 3);
    .capitalize {
      text-transform: capitalize;
    }
  }
  .line {
    margin-top: ($space-unit * 4);
    @include border('top');
  }
  .link-box {
    &__title {
      @extend %primary-s-u;
      margin-top: ($space-unit * 4);
      margin-bottom: ($space-unit * 3);
    }

    &__button.button {
      margin-top: ($space-unit * 4);
    }
  }
}
