.filters {
  display: flex;
  flex-direction: row;
  .filters__menu__menuitem {
    @include hover(0.3);
  }
  &.expanded {
    .filters__menuitem {
      &:hover {
        color: $black;
        opacity: 1;
      }
    }
  }
}

.filters__menu {
  ul {
    padding-top: $space-unit * 2;
  }
  &--productLineCode {
    ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &--refinementColor {
    ul {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.filters__menuitem {
  @include hover(0.3);
  &--off {
    opacity: 0.55;
  }
}
