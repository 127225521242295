// todo refactor

fieldset {
  margin: $space-unit * 8 0;
  position: relative;

  &.payment-form-fields {
    margin: 1.6rem 0;
  }

  &::before {
    content: '';
    background-color: $grey_line;
    position: absolute;
    height: $space-line;
    width: 100%;
    top: 0;
  }

  &.fieldset--plain {
    margin: 0;

    &::before {
      content: none;
    }
  }

  &.fieldset--extra-top-space {
    margin-top: $space-unit * 3;
  }
}

legend {
  @extend %primary-xs-u;
  padding: $space-unit * 4 0;
  display: block;
}

.note {
  @extend %primary-xxs;
  color: $black_secondary;
}

/* form group */

.form-group-title {
  display: block;
  @extend %primary-xxs;
  padding: 0 0 $space-unit;
  margin-bottom: $space-unit * 3;
}

.row:last-child>*>.form-group {
  margin-bottom: 0;
}

.form-group {
  position: relative;
  margin-bottom: $space-unit * 4;

  input::-webkit-date-and-time-value {
    text-align: left;
  }

  @media screen and (max-width: 1024px) {
    input[type='date'][value='']:empty:not(:focus):after {
      color: $dark_grey;
      position: absolute;
      left: 0;
      min-height: 2rem;
      padding: 1.5rem 1.2rem;
      font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
      font-size: 1.4rem;
      line-height: 1.8rem;

    }
  }

  input[type='date'][value='']:empty {
    &:not(:focus) {
      &::-webkit-datetime-edit-ampm-field,
      &::-webkit-datetime-edit-day-field,
      &::-webkit-datetime-edit-hour-field,
      &::-webkit-datetime-edit-millisecond-field,
      &::-webkit-datetime-edit-minute-field,
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-second-field,
      &::-webkit-datetime-edit-week-field,
      &::-webkit-datetime-edit-year-field,
      &::-webkit-datetime-edit-text {
        color: transparent;
      }      
    }
  }

  .input {
    appearance: none;
    background-color: $white;
    color: $black;
    width: 100%;
    border: 0;
    padding: 1.6rem 1.2rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    transition: all 0.4s $easeOutQuart;
  }

  &.fullwidth {
    width: 100%;
  }

  &__legend {
    display: block;
    @extend %primary-xxs;
    padding: 0 0 $space-unit * 3;
    text-align: left;
    text-transform: none;
  }

  &__label {
    display: block;
    @extend %primary-xxs;
    position: absolute;
    font-size: 1.4rem;
    line-height: 2.2rem;

    left: 1.2rem;
    top: 1.6rem;
    transition: 0.2s ease all;
    text-align: left;
    text-transform: none;
    user-select: none;
    pointer-events: none;
    cursor: text;

    &:not(.spacer) {
      &:empty {

        // debugger for empty labels
        &::before {
          content: 'visually hidden label needed';
          color: royalblue;
        }
      }
    }

    &.spacerlabel {
      &::before {
        content: '\00a0'; // non breaking space
      }
    }
  }

  &__label-radio {
    width: 100%;
  }

  &__label--floating {
    position: absolute;
    left: 1px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    background: $white;
    padding: 0 ($space-unit * 3);
    align-items: center;
    z-index: 2;
    pointer-events: none;
    @extend %primary-xs-u;
  }

  input:focus+&__label--floating {
    display: none;
  }

  &--cols {
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &--gender-radio {
    display: flex;
    flex-wrap: wrap;

    .radio {
      margin-bottom: ($space-unit * 4);
      padding-right: ($space-unit * 8);
    }
  }

  &--inline {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:focus-within {
      .form-group__label--floating {
        display: none;
      }
    }
  }

  &__helper-text {
    display: block;
    @extend %primary-xxs;
    margin: $space-unit 0 $space-unit * 3;
  }

  &--cols {
    .invalid-feedback {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.invalid-feedback {
  color: $status_error;
  display: flex;
  @extend %primary-xxs;
  margin: $space-unit 0 $space-unit * 3;
  align-items: baseline;

  &::before {
    content: '';
    @include sprite('alert-red');
    position: relative;
    width: 1rem;
    height: 1rem;
    color: $status_error;
    margin-right: $space-unit;
    display: block;
    top: 0.1rem;
  }

  &--float {
    position: absolute;
  }
}

.invalid-feedback:empty {
  margin: 0;

  &::before {
    display: none;
  }
}

@keyframes cssAnimation {
  to {
    opacity: 0;
  }
}

.alert {
  display: inline-block;
  margin: 0 0 0 $space-unit * 4;

  &-danger {
    display: none;
    margin: 0 0 3.2rem;
    padding: 1.6rem;
    background: $red_tertiary;
    border: .1rem solid $red_secondary;
    border-radius: .4rem;
    color: #bc4736;
    width: 100%;
  }

  &--success {
    color: $status_success;
  }

  &--error {
    color: $status_error;
  }

  &--temp {
    animation: cssAnimation 0.5s $easeOutQuart 5s forwards;
    animation-fill-mode: forwards;
  }
}
