.preferences-changepassword {
  .button-row {
    align-items: center;
  }
}
.preferences-socialaccounts {
  .social-row {
    margin-top: ($space-unit * 4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .is-connect {
      position: relative;
      .connected-tick {
        content: '';
        right: -($space-unit * 5);
        width: ($space-unit * 2);
        height: ($space-unit * 2);
        @include fixedSprite('checkmark');

        position: absolute;
        top: -($space-unit * 0.5);
      }
    }
    .button-connect {
      color: $black_inactive;
    }
  }
}

.preferences-countrylanguage {
  .spacing {
    margin-top: ($space-unit * 6);
  }
}

.preferences-marketing {
  .radio-wrapper {
    margin-top: ($space-unit * 4);
  }
}

.preferences-newsletter {
  .email-spacing {
    margin-top: $space-unit;
    margin-bottom: ($space-unit * 4);
  }
  .checkbox-wrapper {
    margin-top: ($space-unit * 2);
    margin-bottom: ($space-unit * 8);
  }
  .legal-label {
    color: $black_secondary;
    margin-bottom: ($space-unit * 4);
  }
}
