//colors
$black: #232323;
$black_secondary: rgba(0, 0, 0, 0.5);
$black_inactive: rgba(0, 0, 0, 0.3);
$black_disabled: #969696;

$white: rgb(255, 255, 255);
$white_secondary: rgba(255, 255, 255, 0.5);
$white_inactive: rgba(255, 255, 255, 0.25);

$red: #f42333;
$light_grey: #f7f7f7;
$medium_grey: #8b8b8b;
$dark_grey: #6f6f6f;
$grey: #eeee;
$oos_red: #BB0000;
$lowavailability_yellow: #F79E1B;

$status_error: #d92b30;
$status_error_secondary: rgba(244, 35, 51, 0.15);
$status_alert: #ffbb00;
$status_alert_secondary: rgba(255, 187, 0, 0.15);
$status_success: #50a12a;
$status_success_secondary: rgba(80, 161, 42, 0.15);
$status_notify: #2f5fb4;
$status_notify_secondary: rgba(47, 95, 180, 0.15);
$yellow: #FBBA07;
$blue_editorial: #003566;

$grey_input: $dark_grey;
$grey_disabled: #fbfbfb;
$grey_line: #e4e4e4;
$grey_gradient: #f9f9f9;
$grey_loader: #e5e5e5;

$input_text: $dark_grey;
$input_background: $grey_disabled;
$input_border: $dark_grey;

$overlay: $black;
$overlay_base: linear-gradient(360deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);

$outline: #80bdff;
$gradient-separator: linear-gradient(180deg, #f9f9f9 0%, #ffffff 100%);
$input-outline: #333;
$loader_background: rgba(#0015264D, 0.3);

// 1588 Express
$grey1: #F9FAFA;
$grey2: #F1F2F4;
$grey3: #E3E6E8;
$grey4: #D8DCDF;
$grey5: #C4CACF;
$grey6: #8D959C;
$grey7: #5C6770;
$grey8: #404A52;
$grey1: #F9FAFA;
$grey2: #F1F2F4;
$grey3: #E3E6E8;
$grey4: #D8DCDF;
$grey5: #C4CACF;
$grey6: #8D959C;
$grey7: #5C6770;
$grey8: #404A52;
$gray1: $grey1;
$gray2: $grey2;
$gray3: $grey3;
$gray4: $grey4;
$gray5: $grey5;
$gray6: $grey6;
$gray7: $grey7;
$gray8: $grey8;
$gray: $grey3;
$grey: $gray;
$blue: #001526;
$green: #3E7D39;
$green_success: #428623;
$green_secondary: rgba(62, 125, 57, 0.09);
$red_secondary: rgba(189, 12, 12, 0.2);
$red_tertiary: rgba(189, 12, 12, 0.03);
$red_primary: #EB2B35;
$black_primary: #1B1B1A;
$grey_disabled_input: #9E9E9E;