.markup-block {
  overflow-x: hidden;
  overflow-y: auto;
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: ($space-unit * 2);
  }
  h1 {
    @extend %primary-xl;
  }
  h2 {
    @extend %primary-l;
  }
  h3 {
    @extend %primary-m;
  }
  h4 {
    @extend %primary-s;
  }
  strong {
    font-weight: bold;
  }
  table {
    margin: 1.6rem 0;
  }
  td {
    min-width: 8rem;
    padding-right: 2rem;
  }
}
