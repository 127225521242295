@use "sass:math";

.container {
  position: relative;
  margin: 0 auto;
  width: 100%;

  // @each $breakpoint, $max-width in $grid-max-width {
  //   @include breakpoint($breakpoint, min) {
  //     max-width: $max-width;
  //   }
  // }
  @each $breakpoint, $metrics in $grid-metrics {
    @if $breakpoint== 'xs' {
      padding: 0 #{map-get($metrics, 'container-padding')};
    } @else {
      @include breakpoint($breakpoint, min) {
        padding: 0 #{map-get($metrics, 'container-padding')};
      }
    }
  }

  &--neg {
    @each $breakpoint, $metrics in $grid-metrics {
      @if $breakpoint== 'xs' {
        margin: 0 (map-get($metrics, 'container-padding') * -1);
      } @else {
        @include breakpoint($breakpoint, min) {
          margin: 0 (map-get($metrics, 'container-padding') * -1);
        }
      }
    }
  }

  &--halfright {
    padding-left: $space-unit * 0.5;
  }

  &--halfleft {
    padding-right: $space-unit * 0.5;
  }

  &--fluid {
    position: relative;
    width: 100%;

    @each $breakpoint, $metrics in $grid-metrics {
      @if $breakpoint== 'xs' {
        padding: 0 map-get($metrics, 'container-padding');
      } @else {
        @include breakpoint($breakpoint, min) {
          padding: 0 map-get($metrics, 'container-padding');
        }
      }
    }
  }
}

.row {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;

  @each $breakpoint, $metrics in $grid-metrics {
    @if $breakpoint== 'xs' {
      margin-right: map-get($metrics, 'half-gutter') * -1;
      margin-left: map-get($metrics, 'half-gutter') * -1;
    } @else {
      @include breakpoint($breakpoint, min) {
        margin-right: map-get($metrics, 'half-gutter') * -1;
        margin-left: map-get($metrics, 'half-gutter') * -1;
      }
    }
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  &--full-bleed {
    @each $breakpoint, $metrics in $grid-metrics {
      @if $breakpoint== 'xs' {
        margin-right: map-get($metrics, 'gutter') * -1;
        margin-left: map-get($metrics, 'gutter') * -1;
      } @else {
        @include breakpoint($breakpoint, min) {
          margin-right: map-get($metrics, 'gutter') * -1;
          margin-left: map-get($metrics, 'gutter') * -1;
        }
      }
    }

    [class^='col-'] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

//alignment ulitilities

.flex {
  display: flex;

  &-column{
    display: flex;
    flex-direction: column;
  }
  &-row{
    display: flex;
    flex-direction: row;
  }
  &-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-start{
    display: flex;
    justify-content: flex-start;
  }
  &-top{
    display: flex;
    align-items: flex-start;
  }
  &-end{
    display: flex;
    justify-content: flex-end;
  }
  &-rs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &-cs{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-vc{
    display: flex;
    align-items: center;
  }
  &-wrap {
    flex-wrap: wrap;
  }
}

.justify-start {
  justify-content: flex-start;
  text-align: left;
}

.justify-center {
  justify-content: center;
  // text-align: center;
}

.justify-end {
  justify-content: flex-end;
  text-align: right;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.align-top {
  align-items: flex-start;
}

.align-middle {
  align-items: center;
}

.align-bottom {
  align-items: flex-end;
}

// default grid

.col,
[class^='col-'] {
  flex: 1 0 auto;
  width: 100%;
  @each $breakpoint, $metrics in $grid-metrics {
    @if $breakpoint== 'xs' {
      padding-right: map-get($metrics, 'half-gutter');
      padding-left: map-get($metrics, 'half-gutter');
    } @else {
      @include breakpoint($breakpoint, min) {
        padding-right: map-get($metrics, 'half-gutter');
        padding-left: map-get($metrics, 'half-gutter');
      }
    }
  }
}

@for $i from 1 through $grid-columns {
  .col-#{$i} {
    flex-basis: math.div(100%, $grid-columns) * $i;
    max-width: math.div(100%, $grid-columns) * $i;
  }
}

@for $i from 0 through $grid-columns {
  .offset-#{$i} {
    @if $i==0 {
      margin-left: 0;
    } @else {
      margin-left: math.div(100%, $grid-columns) * $i;
    }
  }
}

@for $i from 0 through $grid-columns {
  .col-push-#{$i} {
    @if $i==0 {
      margin-right: 0;
    } @else {
      margin-right: math.div(100%, $grid-columns) * $i;
    }
  }
}

@each $breakpoint, $media in $grid-breakpoints {
  @include breakpoint($breakpoint, min) {
    .col-#{$breakpoint} {
      flex-grow: 1;
      max-width: 100%;
    }

    @for $i from 1 through $grid-columns {
      .col-#{$breakpoint}-#{$i} {
        flex-basis: math.div(100%, $grid-columns) * $i;
        max-width: math.div(100%, $grid-columns) * $i;
      }
    }

    @for $i from 0 through $grid-columns {
      .offset-#{$breakpoint}-#{$i} {
        @if $i==0 {
          margin-left: 0;
        } @else {
          margin-left: math.div(100%, $grid-columns) * $i;
        }
      }
    }
  }
}

%neg-container {
  @each $breakpoint, $metrics in $grid-metrics {
    @if $breakpoint== 'xs' {
      margin: 0 (-1 * map-get($metrics, 'container-padding'));
    } @else {
      @include breakpoint($breakpoint, min) {
        margin: 0 (-1 * map-get($metrics, 'container-padding'));
      }
    }

    > * {
      @if $breakpoint== 'xs' {
        padding: 0 map-get($metrics, 'container-padding');
      } @else {
        @include breakpoint($breakpoint, min) {
          padding: 0 map-get($metrics, 'container-padding');
        }
      }
    }
  }
}

@mixin col($i: 12, $grid-columns: 12) {
  @each $breakpoint, $metrics in $grid-metrics {
    @if $breakpoint== 'xs' {
      padding-right: map-get($metrics, 'half-gutter');
      padding-left: map-get($metrics, 'half-gutter');
      flex-basis: math.div(100%, $grid-columns) * $i;
      max-width: math.div(100%, $grid-columns) * $i;
    } @else {
      @include breakpoint($breakpoint, min) {
        padding-right: map-get($metrics, 'half-gutter');
        padding-left: map-get($metrics, 'half-gutter');
        flex-basis: math.div(100%, $grid-columns) * $i;
        max-width: math.div(100%, $grid-columns) * $i;
      }
    }
  }
  flex: 1 0 auto;
  width: 100%;
}

@mixin col-width($i: 12, $grid-columns: 12) {
  width: 100%;
  max-width: math.div(100%, $grid-columns) * $i;
}

@mixin modal-width($i: 12, $grid-columns: 12) {
  min-width: math.div(100%, $grid-columns) * $i;
  // to do: calcolare anche i gutter del container
}
