.checkout-pickup {
  &__wrapper {
    padding: 2rem;
    background-color: $white;
    border: 1px solid $black;
    border-radius: 8px;
  }

  &__header {
    position: relative;
    margin-bottom: 2rem;
    padding-left: 4rem;

    &::before {
      content: '';
      @include fixedSprite('shop');
      position: absolute;
      top: 0;
      left: 0;
    }

    h4 {
      font-size: 1.4rem;
    }

    span {
      display: block;
      color: $dark_grey;
      font-size: 1.2rem;
      margin-bottom: 0.4rem;
    }
  }

  .checkout-pickup__map {
    margin-bottom: $space-unit * 6;
  }

  .form-group-phone {
    max-width: 100%;
    flex-basis: 100%;
    padding: 0;
  }
}

#pickupinstore-options {
  display: block;
  height: unset;
  padding: 0;
  background: none;
  border: none;

  [role='radio'] {
    line-height: 1;
    /* &:not(:last-child) {
      margin-bottom: 2rem;
    } */
  }

  .radiobox__option {
    height: 100%;
    text-align: left;
    text-transform: unset;
    display: block;
    padding: 2rem;
    border: 1px solid #ddd;

    &.selected {
      border: 1px solid $black;
    }
  }

  .option-store__title {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .telephone {
    margin-top: 0.4rem;
    margin-bottom: 0;
    text-decoration: none;
  }

  .option-store {
    display: block;

    span {
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $dark_grey;
      font-weight: 400;

      &.CTA {
        color: $black;
      }
    }
  }

  &[role='radio'][aria-checked='true'] .radiobox__option {
    border: none;
  }
}