.modal.sizeguide-pdp-modal {
  &::-webkit-scrollbar {
    display: none;
  }
  width: 68rem;
  min-width: 68rem;
  max-height: min(80vh, 66.4rem);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 3.2rem 2.4rem 0;
  border-radius: 0.8rem;

  &.ReactModal__Content {
    transform: translate(0, 10%);

    &--after-open {
      transform: translate(0);
    }

    &--before-close {
      transform: translate(0, 10%);
    }
  }

  .modal{
    &__header{
      padding: 0;
      .dialog__title{
        font-size: 1.8rem;
        line-height: 2.2rem;
        padding: 0;
      }
    }
    &__close {
      padding: 3.2rem 2.4rem;
      &::before {
        @include fixedSprite('close-16');
      }
    }
    &__body {
      padding-top: 3.2rem;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2.4rem;
      }
    }

  }


  .sizeGuide {
    &_modelInfo {
      padding-bottom: 3.2rem;
    }
    &Modal {
      &__wrapper {
        &_levels {
          padding-bottom: 1.6rem;
        }
        &_body {
          padding-right: 1.6rem;
          overflow-y: auto;
          -webkit-overflow-scrolling: auto;

          &::-webkit-scrollbar {
            width: 0.2rem;
            visibility: visible;
            transition: all 0.3s ease-out;
          }

          &::-webkit-scrollbar-track {
            background-color: $grey;
            visibility: visible;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $red;
            transition: all 0.3s ease-out;
            visibility: visible;
          } 
        }
      }
    }
    &Table{
      &__wrapper{
        table,
        th,
        tr {
          border-collapse: collapse;
        }

        table {
          width: 100%;
          margin: 3.2rem 0;
          &:first-child {
            margin-top: 0;
          }

          th,
          td {
            padding: 1.2rem 0.8rem ;
            vertical-align: middle;
            text-align: center;
            height: 4.4rem;
            
            &:not(:first-child) {
              border-left: 0.03rem $grey_line solid;
            }            
          }

          thead {
            tr {
              background-color: $light_grey;

              th {
                width: 1%;
              }
            }
          }
        }         
      }
     
    }

    &Measure{
      &Modal {
        padding-top: 2.4rem;
      }

      &__card{
        padding: 0;
        display: flex;

        &_img {
          margin: auto;

          &-container {

            margin: auto 0;
          }
         
        }

        &_content {
          padding-left: 2.4rem;
          &_description{

            &_title {
              margin-bottom: 0.8rem;
            }
  
            &_text{
              line-height: 2rem;
              font-size: 1.2rem; 
            }
          }
        }
  

      }
    }
  
    &Accordion{
 
      &__header {
        padding: 0;
        padding-bottom: 0.8rem;

        &[aria-expanded='false']{
          border-bottom: solid 0.03rem $grey;
        }

        &[aria-expanded='true'] + .sizeGuideAccordion__panel{
          .sizeGuideTable__wrapper {
            margin-bottom: 1.4rem;
            &--international {
              table {
                margin-bottom: 0;
              }
            }
          }
        } 
      }
  
      &__panel {
        padding: 0;
        border-bottom: 0;

        
  
        table {
          margin-top: 0;
        }
      }
    }

    .text-baby {
      .text-baby-description {
        padding-left: 1.6rem;
      }

      .text-wrapper {
        .round-letter {
          border-radius: 50%;
          width: 2rem;
          min-width: 2rem;
          font-size: 1rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1.2rem;
        }

        p {
          padding-bottom: 1.6rem;
        }
      }
    }


  }
  .wrapper-international-sizes {
    .accordion__header {
      padding: 1.4rem 1.2rem;
    }

    .accordion__panel {
      padding: 0;

      table {
        margin: 0;
      }
    }
  }
}