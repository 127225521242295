.accordion__group {
  text-align: left;
  position: relative;

  .accordion__item {
    &:last-child {
      @include border('bottom');

      .accordion__header {
        border-bottom: initial;
      }
    }
  }
}

.accordion__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: $black;
  @include border('top');
  padding: 2.4rem 0;
  width: 100%;
  font-size: 1.4rem;

  font-weight: bold;

  &>span {
    text-align: left;
  }

  &:focus {
    outline: transparent;

    .header__open,
    .header__close {
      [data-whatintent='keyboard'] & {
        outline: $outline-width solid $outline-color;
      }
    }
  }

  &--uc {
    &>span {
      @extend %primary-xs-u;
    }
  }

  &--lc {
    &>span {
      @extend %primary-s;
    }
  }

  &.no-border {
    border-top: 0;
  }

  .header__open,
  .header__close {
    position: absolute;
    right: -0.8rem;
    top: 50%;
    transform: translateY(-50%);
    min-width: ($space-unit * 6);
    min-height: ($space-unit * 6);
    width: auto;
    height: auto;
    display: none;
    align-items: center;

    .text {
      @extend %primary-xs;
      display: none;
    }

    &::after {
      content: '';
      display: block;
      width: ($space-unit * 6);
      height: ($space-unit * 6);
    }
  }

  &--cta-text {
    &>span {
      width: calc(100% - 54px);
    }

    .header__open,
    .header__close {
      .text {
        display: block;
      }

      .text,
      &::after {
        opacity: 0.55;
      }
    }
  }

  &::after {
    content: '';
    @include fixedSprite('icon-accordion-dw');
    transition: transform 0.3s ease-in-out;
  }

  &[aria-expanded='false'] {
    .header__open {
      display: flex;
    }

    &::after {
      transform: rotate(90deg);
    }

    &+.accordion__panel {
      height: 0 !important;
      visibility: hidden;
    }
  }

  &[aria-expanded='true'] {
    &::after {
      transform: rotate(270deg);
    }

    .header__close {
      display: flex;
    }
  }

  &--payments {
    align-items: flex-start;

    .header__open,
    .header__close {
      top: 1rem;
      transform: initial;
    }

    >span {
      white-space: initial;
      line-height: 1.6;
    }
  }
}

.accordion__panel {
  overflow: hidden;
  visibility: visible;
  transition: all 0.3s ease-in-out;

  // @include animate;
  >* {
    padding-top: $space-unit;
  }

  &--grenoble {
    text-transform: uppercase;
  }
}