.otp-confirmation__container,
.login-registration,
.forgotpassword-container,
.thankyou__register {
  margin: 0 auto 8rem;
  max-width: 30.7vw;  
}

.login-registration {
  &__container {
    margin: auto;
  }


  .forgot-pw-error {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    &> {
      flex-basis: 50%;
      max-width: 50%;
    }

    &__pw {
      font-size: 1.2rem;
      line-height: 2rem;
      white-space: nowrap;
    }

    &__error {
      .invalid-feedback {
        text-align: right;
        margin-top: 0.8rem;
        margin-bottom: 0;
      }
    }
  }

  .formfields {
    .form-control {
      margin-bottom: ($space-unit * 3);
    }
  }

  &__rememberMe {
    &-container {
      margin-bottom: 1.6rem;

      .checkbox {
        margin-top: 0;

        &__field {
          #loginRememberMe-label {
            font-size: 1.2rem;
            line-height: 2.4rem;
            font-weight: 700;
          }
        }
      }
    }
  }

  &__tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__title {
      border-bottom: 0.1rem solid $grey3;
      padding: 1.4rem 1.6rem;
      text-align: center;
      line-height: 2.2rem;
      cursor: pointer;

      &.active {
        border-bottom-color: $red_primary;
      }
    }
  }

  &__section {
    &-container {
      padding-top: 3.2rem;
    }

    &__header {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      padding-bottom: 3.2rem;
    }

    &__login__social {
      display: grid;
      gap: 0.8rem;
      grid-template-columns: 1fr 1fr;
      padding-top: 1.6rem;

      &__btn {
        font-weight: 700;
        display: flex;
        gap: 0.8rem;
      }
    }

    &__registration {
      &__privacy {
        line-height: 2rem;
        font-size: 1.2rem;
        padding-top: 1.6rem;
      }

      &__list {
        padding: 2.4rem;
        background-color: $light_grey;
        color: $black_primary;
        margin-top: 3.2rem;
        
        &__text {
          padding: 0 1.5rem 1.6rem;
        }

        &__element {
          font-size: 1.4rem;
          line-height: 2.2rem;
          display: flex;
          gap: 1rem;
          list-style-type: none;
          padding-top: 0.8rem;
          padding-bottom: 0;
        }
      }
    }
  }



  .divider {
    margin: 2.4rem 0;
    background-color: $black;
  }

  .login-registration__credentials {
    @extend %primary-s;
    margin-bottom: $space-unit * 4;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #6f6f6f;
  }

  .login-tab {
    .tabs {
      .react-tabs__tab-panel {
        margin-top: ($space-unit * 8);
      }
    }
  }
}

.login-section-container {
  padding-top: calc(var(--ticker-height) + 11.2rem + 0.8rem);
}