// margin
.three-column-product--internal-border,
.three-column-product--external-border {
  .three-column-product__product-holder {
    padding: 0 2rem;
  }
}
.three-column-product--centered-top {
  padding-bottom: 0;
}
