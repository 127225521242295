.sticky-tray {
  position: fixed;
  bottom: 0;
  padding: 1.6rem 2.4rem;
  left: 0;
  width: 100%;
  background-color: $white;
  @include z('stickybar-m');
  // padding: ($space-unit * 4) 0;
  @include animate(0.4);
  transition-property: opacity;
  opacity: 0;
  visibility: hidden;
  display: none;

  // transform: translateY(100%);
  &.show {
    // transform: translateY(0);
    opacity: 1;
    visibility: visible;
    display: block;
  }

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: $space-line;
  //   background-color: $grey_line;
  // }
  &__container {
    width: 100%;
  }

  &__info {
    &-container {
      display: grid;
      grid-template-columns: 4.8rem 1fr;
      grid-column-gap: 4rem;
      padding: 0;
    }

    &_details {
      display: flex;
      flex-direction: row;
      justify-content: center;

      &_price {
        font-size: 1.4rem;
        line-height: 2.2rem;

        &_promo {
          display: flex;
          flex-direction: row;
          .tooltip {
            .sticky-tray__info_details_price_promo__tooltip_box {
              background-color: $black;
              border-radius: 0.4rem;
              padding: 1.6rem;
              text-align: center;
              font-size: 1.4rem;
              line-height: 2.2rem;
              font-weight: 400;
              color: $white;

              &::before {
                border-top-color: $black;
              }
            }
          }

          &__label {
            color: $red;
            font-weight: 400;
            line-height: 2rem;
            font-size: 1.2rem;
            text-transform: uppercase;
          }

          &__tooltip {
            &_icon {
              width: 1.2rem;
              height: 1.2rem;
              display: flex;
              padding-right: 0;
            }
          }
        }

      }

      &_name {
        border-right: solid $grey_line 0.03rem;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
      }
    }
  }

  &__name {
    @include text-shorten;
    line-height: 1.29;
    margin-bottom: 0.4rem;
  }

  &__selector {
    &_size {
      .selector-size-expanded {
        .selector {
          &__header {
            display: none;
          }

          &__menu {
            margin: 0;

            &__item {
              &__label {
                padding: 0.9rem 2rem;
                text-wrap: nowrap;
              }
            }

            &__list {
              flex-wrap: nowrap;
            }
          }
        }
      }

    }

    &_button {
      display: flex;
      align-items: center;

      button {
        height: 5.4rem;
        padding: 1.2rem 2.4rem;

        span {
          font-size: 1.2rem;
          line-height: 2rem;
          font-weight: 700;
        }
      }
    }
  }

  &__modal.modal {
    width: 100%;
    min-width: initial;
    max-width: initial;
    height: auto;
    right: 0;
    left: 0;
    bottom: 4.8rem;
    top: initial;
    padding-top: 5.5rem;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-delay: 0s;
    opacity: 1;

    &.ReactModal__Content {
      transform: translateY(3.2rem);
    }

    &.ReactModal__Content--after-open {
      transform: translateY(0);
    }

    &.ReactModal__Content--before-close {
      transform: translateY(3.2rem);
    }

    .selector-color-expanded {

      // border-top: initial;
      .selector__label {
        padding-top: 1.5rem;
      }

      .selector__menu {
        padding-top: 1.5rem;
        padding-bottom: 2.1rem;
      }
    }

    .selector-size-expanded {
      .selector__header {
        padding-top: 1.5rem;
      }

      .selector__menu {
        padding-top: 1.5rem;
        padding-bottom: 0.8rem;
      }
    }
  }

  &__overlay {
    &.overlay-modal {
      @include z('stickybar-o-m');
      transition-duration: 0.3s;
      background: $black_secondary;
    }
  }
}
