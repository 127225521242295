.modal.shopTheLookModal {
  max-height: 94vh;
  bottom: 0;
  top: unset;
  border-radius: 0.8rem 0.8rem 0 0;
  max-width: 100vw;
  padding-top: 0.8rem;
  width: 100%;
  height: fit-content;
  transform: translate(0, 0);
  left: 0;

  .modal {
    &__header {
      padding-top: 2.6rem;
      position: sticky;

      .dialog__title {
        justify-content: center;
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: 300;
        padding-bottom: 2.4rem;
      }

      &::before {
        content: '';
        background-color: $grey_line;
        width: 8rem;
        height: 0.2rem;
        border-radius: 1.6rem;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
    }
    &__close {
      display: none;
    }

    &__body {
      overflow: visible;
      .shopTheLookModal {
        &-carousel {
          overflow-x: clip;
          &-tiles {
            transition-property: transform;
          }
          &-tiles {
            .product-tile {
              &__color {
                display: none;

              }
              &__price {
                flex-wrap: nowrap;
              }
              &__info {
                padding: 0.8rem 0 0 0 ;
                max-width: 100%;
              }
              &__image-wrapper {
                .product-images {
                  &__tag {
                    bottom: 1.6rem;
                  }
                }                
              }

            }            
          }
        }

        &-scrollbar {
          position: relative;
          margin-top: 2.4rem;
          background-color: $grey;
          height: 0.2rem;
          border-radius: 0;
          left: 0;
          bottom: 0;
          .swiper-scrollbar-drag {
            background-color: $red;
          }
        }
      }
    }
  }
}