.row-pickup-point-container {
  height: 20rem;
  width: 100%;

  #pickupPointMap {
    display: block;
    height: 100%;
    border-radius: 0.8rem 0 0 0.8rem;

    .gm-style {
      .gm-style-iw {
        background-color: $black;
        padding: 1.6rem !important;
        max-width: 22rem !important;

        span,
        a {
          color: $white;
          font-size: 1.2rem;
          line-height: 2.2rem;

          &.text-bold {
            font-weight: 700;
          }
        }

        h2 {
          color: $white;
          font-size: 1.4rem;
          line-height: 2.2rem;
        }

        .gm-ui-hover-effect {
          top: 0 !important;
          right: 0 !important;
          filter: invert(1);
        }
      }

      .gm-style-iw-d {
        &::-webkit-scrollbar {
          display: none;
        }

        .rt-iw {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.8rem;

          .rt-iw__heading {
            gap: 0.4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-bottom: 0.1rem solid $white;
            padding-bottom: 0.8rem;
            text-align: center;
            position: relative;
            top: initial;
          }

          .rt-iw__contacts {
            display: flex;
            align-items: center;
            gap: 0.8rem;
          }

          .rt-store-current-opening {
            width: 100%;
            display: flex;
            justify-content: center;
            border-bottom: 0.1rem solid $white;
            padding-bottom: 0.8rem;

            .wrapper-store-open {
              display: flex;

              .CTA {
                display: flex;
                align-items: center;
                margin-right: 0.4rem;
              }
            }
          }
        }
      }

      .gm-style-iw-tc {
        &::after {
          background: $black;
        }
      }
    }
  }
}