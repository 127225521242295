.product-look {
  display: grid;
  grid-template-columns: calc(100%/3 - 3.2rem) 1fr;

  &__wrapper {
    padding: 0;
  }
  &__img {
    padding-right: 4.8rem;
    background-color: $white;
    z-index: 2;
  }

  &__actions {
    position: absolute;
    right: 1.2rem;
    bottom: 1.2rem;
    &__btn {
      width: 3.2rem;
      height: 3.2rem;
      padding: 0.4rem;
    }
  }

  &__wishlist {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    width: 3.2rem;
    height: 3.2rem;
    padding: 0.4rem;
    z-index: 1;
  }

  &__content {
    min-width: calc(65.65vw - 0.6rem);
    overflow-x: auto;
    padding-bottom: 1.6rem;

    &::-webkit-scrollbar {
      height: 0.2rem;
      visibility: visible;
      transition: all 0.3s ease-out;
    }

    &::-webkit-scrollbar-track {
      background-color: $grey;
      visibility: visible;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $red;
      transition: all 0.3s ease-out;
      visibility: visible;
    }

    &__tile {
      width: 18vw;
      min-width: 18vw;
      &:last-child {
        margin-right: 1.8rem;
      }
      .product-tile {
        &:hover {
          .product-tile {
            &__btn {
              visibility: visible;
            }
          }        
        }
        .tile-body {
          .price-wrapper {
            margin-top: 0.4rem;
            .price {
              margin: 0;
            }
          }
        }
        .wrapper-quick-add-to-bag {
          display: none;
        }
        &__info {
          padding: 0.8rem 0 0 0;
          display: flex;
          flex-direction: column;
        }

        &__color {
          display: none;
        }

        &__btn {
          visibility: hidden;
          &--prev {
            left: 1.6rem;
          }
          &--next {
            right: 1.6rem;
          }

          &.swiper-button-disabled {
            display: none;
          }
        }

        &__actions_btn {
          opacity: 1;
          position: relative;
          width: fit-content;
          height: fit-content;
          margin: 0;
          &-wrapper {
            padding-top: 0.8rem;
            display: flex;
          }
        }

        .cta-show-colors,
        .variation-color-plp {
          display: none;
        }
      }
    }
  }

  .product-tile__name {
    white-space: unset;
    margin-bottom: 0.8rem;
  }


  .product-look__image {
    margin-bottom: ($space-unit * 4);
  }

  .product-look__actions {
    margin-top: auto;
  }

  .btn-addtobag {
    margin-top: auto;
    padding: 0 0 0 2.2rem;
    text-align: left;
    background-color: transparent;
    color: $black;
    display: flex;
    height: auto;
    width: auto;
    justify-content: flex-start;

    span {
      text-transform: none;
      // font-weight: normal;
    }
    &::before {
      content: '';
      @include fixedSprite('bag');
      @include center('vertical');
      left: 0;
      width: 1.6rem;
      height: 1.6rem;
    }

    &:hover {
      opacity: 0.5;
      transform: none;
      transition: opacity 0.5s ease-in-out;
      span {
        &::before {
          transform: none;
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }
        &::after {
          display: none;
        }
      }
    }
  }
}
