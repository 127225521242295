.filters {
  @include reset-list;
  .filters__menu__menuitem {
    padding-right: 1.8rem;
    line-height: 1.6rem;
    position: relative;
    width: 100%;
    @include text-shorten;
    cursor: pointer;

    &[aria-disabled='true'] {
      cursor: initial;
      opacity: 0.25;
    }

    &:focus {
      @include focus-underline;
      overflow: initial;
    }

    .swatch {
      display: inline-block;
      margin-right: ($space-unit * 2);
      width: $space-unit * 2;
      height: $space-unit * 2;
    }

    &[aria-checked='true'] {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -0.2rem;
        margin-left: $space-unit;
        @include animate;
        @include fixedSprite('checkmark');
      }
    }

    &--clear {
      color: $black_secondary;
      margin-bottom: 3.2rem;
    }
  }
}

.filters__menuitem {
  position: relative;
  display: inline-block;
  height: 5.6rem;
  padding: ($space-unit * 5) 1.6rem ($space-unit * 5) 0;
  margin-right: ($space-unit * 4);
  @extend %primary-xs;

  &:focus {
    @include focus-underline;
  }

  // per animare prima la freccietta
  &.animate-arrow {
    &[aria-expanded='true'] {
      &::after {
        transform: translateY(-50%);
      }
    }
  }

  &[aria-expanded='true'] {
    &::after {
      transform: translateY(-50%) rotate(-180deg);
    }

    & + .filters__menu {
      visibility: visible;
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @include fixedSprite('arrow-down');
    @include animate(0.3);
  }
}

.filters__menu {
  position: absolute;
  top: auto;
  right: 0;
  // background: white;
  z-index: 1;
  display: block;
  visibility: hidden;
  opacity: 0;
  text-align: left;

  ul {
    @include reset-list;
    padding-bottom: 3.2rem;
    padding-top: $space-unit * 3;
    display: grid;
    grid-template-columns: 100%;
    column-gap: 4px;
    row-gap: 1.2rem;
  }

  &--size {
    .filters__menu__group-name {
      display: none;
    }
    ul {
      grid-auto-flow: column;
      grid-template-rows: repeat(9, auto);
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--eCommerceCategory {
    ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  // TODO da vedere con curto
  &--size-grouped {
    column-count: 3;
    padding-top: 1.4rem !important;

    .filters__menu__group {
      break-inside: avoid;
      position: relative;
      margin-bottom: 1.4rem;

      &::before {
        content: '';
        width: 0.1rem;
        height: 100%;
        position: absolute;
        background-color: $grey_line;
        left: -($space-unit * 4);
      }

      &:first-child {
        &::before {
          content: none;
        }
      }
    }

    .filters__menu__group-name {
      text-transform: capitalize;
      display: block;
    }
  }

  &--refinementColor {
    ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
