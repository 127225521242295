.myaccount-wrapper {
  padding-top: ($space-unit * 8);
  .myaccount-content {
    margin-top: 0;
  }

  .experience-region{
    margin: 4rem 0;

  }
  
  .boxes-wrapper{
    flex-direction: column;
  }

  .account-payment, .account-stefanelId{
    .box-wrapper{
      max-width: unset;
      max-height: unset;
      height: auto;

      .card-wrapper{
        position: relative;
        bottom: unset;
      }
    }

    .box-wrapper-details{
      margin-left: 0;
    }
  }
}

.account-dashboard__intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list__empty-message {
  width: 28.6rem;
  margin: ($space-unit * 20) auto;
  .empty-message__text {
    margin-bottom: ($space-unit * 4);
  }
}
