
.feedbackbag{
  &__wrapper[data-visible=true] {

    .feedbackbag {
      right: unset;
      width: calc(100vw - 3.2rem);
      padding: 2.4rem;
      top: calc(8.5rem + 1.6rem + var(--ticker-height));
      height: fit-content;
      bottom: 0;

      &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; 
        &--wishlist {
          top: 1.1rem;
        }     
      }

      &__bottom-btn-wrapper {

        width: 100%;
        &-wrapper {
          display: flex;
          gap: 1.6rem;
          overflow: hidden;
          justify-content: space-between;
        }
      }
    }
  }

  &__overlay{
    display: flex;
    justify-content: center;  
  }
}

.container-nav.collapse-translate:not(.nav-scrolled) .feedbackbag__wrapper[data-visible=true] .feedbackbag__container .feedbackbag__overlay .feedbackbag{
  top: calc(var(--ticker-height) + 6.4rem + 1.6rem);
}
