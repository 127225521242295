.form-group {
  &--gender-radio {
    .radio {
      flex-basis: 50%;
    }
  }
}

.row:last-child > *:last-child > .form-group {
  margin-bottom: 0;
}

.row:last-child > * > .form-group {
  margin-bottom: 1.6rem;
}