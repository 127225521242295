.modal.persistentModal {
  height:fit-content;
  top: unset;
  bottom: 0;
  padding-bottom: 3.2rem;
  padding-top: 0.8rem;
  border-radius: 0.8rem 0.8rem 0 0;
  left: 0;
  max-width: 100%;
  width: 100%;

  .modal {
    &__header {
      padding-top: 3.2rem;
      position: relative;

      .dialog__title {
        padding-bottom: 2.4rem;
        align-self: center;
      }

      &:before {
        content: '';
        background-color: $grey_line;
        width: 8rem;
        height: 0.2rem;
        border-radius: 1.6rem;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
    }

    &__close {
      display: none;
    }

    &__body {
      .persistentModal {
        &__body {
          &__text {
            padding-bottom: 0.8rem;
          }

          &__form {
            &__btn {
              margin-top: 0.8rem;
            }
          }

          &__guest {
            margin-top: 2.4rem;
            
            &__btn {
              margin-top: 2.4rem;
            }
          }
        }
      }
    }
  }
}