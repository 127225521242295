.product-tile {
  &.in-look {
    .product-tile__info {
      padding-bottom: 0;
      &.lookinfo {
        .product-tile__price {
          margin-bottom: 0.8rem;
        }
      }
    }
    .selector--size {
      @include border('top');
      margin-top: ($space-unit * 6);
      .selector__menu {
        background-color: $white;
      }
    }
  }
}
