.selector-size-expanded {
  .selector__label {
    &Few {
      margin-top: 0.8rem;
    }
  }
}

.product-selection {
  &__wrapper {
    .selector__label {
      &OOS {
        padding-top: 0.8rem;

      }
    }
  }
}