
body#account-thankyouregister {
  .thankyou__register {
    padding-top: calc(3.2rem + 9.6rem + 0.8rem + 2.4rem);

    &__container {
      .product-carousel__carousel-container {
        padding-left: 0;
      }
    }
    
    &__subtitle {
      margin: 1.6rem 0 3.2rem;
      max-width: 100%;
    }
  }
}