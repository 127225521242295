body#account-registerphone,
body#account-registercomplete,
body#account-otp,
body#account-stefanelId,
body#account-otpphone,
body#account-registeremail,
body#account-registersocialemail,
body#account-passwordreset,
body#account-changepassword {

  main {
    min-height: calc(var(--viewport-height) - 15.8rem - 6.6rem);
  }

  header {
    position: relative;
    top: 0;

    .logo-header {
      display: flex;
      justify-content: center;
      padding-top: 2.4rem;
    }

    .login__back {
      padding-top: 2.4rem;
      padding-left: 4.8rem;
      &__link {
        line-height: 2.2rem;
        width: fit-content;
      }
    }
  }

  .row {
    margin: 0;
  }

  .login-registration {
    margin-bottom: 5.6rem;
  }

  .register {
    &__steps{
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      padding-bottom: 1.2rem;
      border-bottom: 0.4rem solid $grey;
      position: relative;
      flex-direction: row;

      &.hide {
        display: none;
      }

      &[data-step='1'] {
        &::before {
          content: '';
          height: 0.4rem;
          width: 0%;
          background-color: $red_primary;
          z-index: 1;
          position: absolute;
          bottom: -0.4rem;
        }

        .step:nth-child(1) {
          color: $black;

          &::before {
            bottom: -1.6rem;
            background-color: $black;
          }
        }

        .step:not(:nth-child(1)) {
          &::before {
            width: 0.4rem;
            height: 0.4rem;
          }
        }
      }

      &[data-step='2'] {
        &::before {
          content: '';
          height: 0.4rem;
          width: 50%;
          background-color: $red_primary;
          z-index: 1;
          position: absolute;
          bottom: -0.4rem;
        }

        .step:nth-child(2) {
          color: $black;

          &::before {
            bottom: -1.6rem;
            background-color: $black;
          }
        }

        .step:not(:nth-child(2)) {
          &::before {
            width: 0.4rem;
            height: 0.4rem;
          }
        }
      }

      &[data-step='3'] {
        &::before {
          content: '';
          height: 0.4rem;
          width: 100%;
          background-color: $red_primary;
          z-index: 1;
          position: absolute;
          bottom: -0.4rem;
        }
        .step:nth-child(3) {
          color: $black;

          &::before {
            bottom: -1.6rem;
            background-color: $black;
          }
        }

        .step:not(:nth-child(3)) {
          &::before {
            width: 0.4rem;
            height: 0.4rem;
          }
        }
      }

      .step {
        position: relative;
        font-size: 1.2rem;
        line-height: 1.6rem;

        color: $dark_grey;
        text-transform: capitalize;

        &::before {
          content: '';
          position: absolute;
          bottom: -1.6rem;
          width: 0.8rem;
          height: 0.8rem;
          background-color: $dark_grey;
          z-index: 1;
        }

        &:nth-child(2) {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;

          &::before {
            left: 50%;
            transform: translateX(-50%);
          }
        }

        &:nth-child(3) {
          &::before {
            right: 0;
          }
        }

        &:disabled {
          cursor: initial;
        }
      }      
    }

    &_form {
      margin-top: 8rem;
      text-align: center;

      .invalid-feedback.globalerrors {
        margin-top: 0;
      }

      .social-row {
        margin-left: 0.1rem;
        margin-bottom: ($space-unit * 6);
  
        .social-button {
          height: ($space-unit * 4);
  
          .icon {
            height: 1.4rem;
            width: 1.4rem;
            margin-right: ($space-unit * 2);
          }
  
          .google {
            @include fixedSprite('social-google');
          }
  
          .facebook {
            @include fixedSprite('social-facebook');
            margin-left: ($space-unit * 6);
          }
        }
      }

      .loyalty {
        &-box {
          text-align: left;
          padding: 2.4rem;
          background-color: $light_grey;

          &__list {
            padding: 0;
            padding-bottom: 4rem;

            &__element {
              font-size: 1.4rem;
              line-height: 2.2rem;
              display: flex;
              gap: 1rem;
              list-style-type: none;
              padding-top: 0.8rem;
              padding-bottom: 0;
              color: $black_primary;

              &:first-child {
                padding-top: 0;
              }

              &__icon {
                height: 2.2rem;
              }
            }
          }

          &__switch {
            align-items: center;
            margin-bottom: 1.6rem;

            .checkbox {
              margin: 0;
            }
          }

          &__header {
            &__subtitle {
              padding: 1.6rem 0;
              line-height: 2.2rem;
              color: $black_primary;
            }
          }

          &__policy {
            font-size: 1.2rem;
            line-height: 2rem;
          }

          &__birthday {
            &__form {
              margin-bottom: 1.6rem;

              .form-group {
                margin-bottom: 0;
              }  
              
              .invalid-feedback.globalerrors {
                margin-top: 0.4rem;
                margin-bottom: 0;
              }
            }
          }
        }
      }
  
  
      .check-pwd-regex {
        padding: 0;
        justify-content: space-between;
        flex-wrap: wrap;
        color: $grey8;

        &__element {
          font-size: 1.2rem;
          line-height: 2rem;
          padding: 0;  
          gap: 0.4rem;        
        }

      }
  
      .button-cancel-registerphone {
        width: fit-content;
        margin: 1.2rem auto;
  
        a:not(.cta--animated) {
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 2.4rem;
          border-bottom: 1px solid;
          text-decoration: none;
        }
      }

      &__info {
        margin-top: 1.6rem;

        &__text {
          font-size: 1.2rem;
          line-height: 1.8rem;
          padding-bottom: 0.8rem;
          text-align: left;
        }

        &__gender {
          padding-bottom: 2.4rem;

          &:has(.invalid-feedback) {
            padding-bottom: 4.8rem;
          }

          .radio-wrapper {
            gap: 0.8rem;
            position: relative;

            .form-group,
            .radio {
              margin: 0;
              position: unset;

              input {
                display: none;

                &:checked +label {
                  background-color: $black_primary;
                  border-color: $black_primary;
                  color: $white;
                } 
              }

              label {
                padding: 0.8rem 1.6rem;
                border: 0.1rem solid $grey_line;
                border-radius: 0.4rem;
                font-size: 1.2rem;
                line-height: 1.6rem;
              }

              .invalid-feedback {
                position: absolute;
                left: 0;
              }
            }
          }
        }

        .form-group {
          margin-bottom: 1.2rem;
        }
      }
  
      &__label {
        color: $black_secondary;
        @extend %primary-xxs;
        margin-bottom: ($space-unit * 6);
      }

      &__title {
        font-size: 3.2rem;
        line-height: 4rem;
        font-weight: 300;
        color: $black_primary;
      }
  
      &__subtitle {
        margin-top: 0.8rem;
      }

      &__data,
      &__subtitle {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $dark_grey;
        margin-bottom: 2.4rem;
      }
      
      &__disclaimer {
        font-size: 1.2rem;
        line-height: 2rem;
        padding-top: 2.4rem
      }

      &__phone {
        display: flex;
        gap: 0.8rem;

        &__prefix {
          width: 17%;
          min-width: 7.6rem;
        }

        &__number {
          width: -webkit-fill-available;
        }
      }
  
      .gendercol .form-group {
        display: flex;
        flex: 0 1 auto;
        flex-wrap: wrap;
  
        .form-group__label {
          @include col-width(12);
        }
  
        .radio {
          &:nth-child(even) {
            @include col-width(4);
          }
  
          &:nth-child(odd) {
            @include col-width(8);
          }
        }
      }
  
      .form-group__label {
        min-height: ($space-unit * 5);
      }
  
      .channel-flags {
        padding-left: $space-unit * 4;
      }
  
      .register_form__newslettertitle {
        @extend %primary-s;
        margin-bottom: $space-unit * 4;
      }
  
      .line {
        @include border('top');
        margin-top: ($space-unit * 3);
        padding-top: ($space-unit * 6);
      }
  
      .password {
        margin-bottom: ($space-unit * 3);
      }
  
      .otpresend {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
  
        button {
          display: block;
          text-decoration: underline;
        }
      }
  
      &__legal {
        text-align: left;
        max-width: 92%;

        &__profile {
          font-size: 1.2rem;
          line-height: 2rem;
          padding-top: 2.4rem;
        }

        &__flag {
          margin-top: 1.6rem;
          font-size: 1.2rem;
          line-height: 2rem;
          align-items: center;
          gap: 1.6rem;

          .checkbox {
            margin: 0;
          }
        }
  
        .radio-wrapper {
          margin-top: 1.6rem;
  
          .form-group {
            margin-bottom: 0;
  
            .radio {
              margin-bottom: 2.4rem;
            }
          }
        }
      }
  
      .checkbox input[type=checkbox] {
        &#createOVSId,
        &#profilingPolicy,
        &#directMarketingPolicy {
          position: absolute;   
          width: 100%;
          opacity: 0;
        }
      }

      .form-select {
        #phonePrefix {
          padding: 0.6rem 1.2rem 0.6rem;
        }
  
        &::after {
          top: 16px;
        }
      }
    }
  }

  .invalid-feedback {
    line-height: 2rem;
    color: $oos_red;
    
    &::before {
      display: none;
    }
  }
}

body#account-profile,
body#account-stefanelid {

  main {
    .profilingPolicyContainer .row,
    .thirdMarketingPolicyContainer .row {
      margin: 2rem 0 0;

      .form-group {
        margin-bottom: 0;
      }
    }
    .checkbox {
      margin-top: 0;
    }   
    
    .profile-privacy {
      &__btn {
        .button {
          margin-top: 0;
          margin-bottom: 2.4rem;
        }
      }
    }
  }

  
  .edit-profile-form {
    &__text {
      font-size: 1.2rem;
      line-height: 1.8rem;
      padding-bottom: 0.8rem;
      text-align: left;
    }

    &__gender {
      padding-bottom: 2.4rem;

      &:has(.invalid-feedback) {
        padding-bottom: 4.8rem;
      }

      .radio-wrapper {
        gap: 0.8rem;
        position: relative;
        flex-wrap: wrap;

        .form-group,
        .radio {
          margin: 0;
          position: unset;

          input {
            display: none;

            &:checked +label {
              background-color: $black_primary;
              border-color: $black_primary;
              color: $white;
            } 
          }

          label {
            padding: 0.8rem 1.6rem;
            border: 0.1rem solid $grey_line;
            border-radius: 0.4rem;
            font-size: 1.2rem;
            line-height: 1.6rem;
          }

          .invalid-feedback {
            position: absolute;
            left: 0;
          }
        }
      }
    }
    
    .form-select {
      #phonePrefix {
        padding: 2.2rem 1.2rem 0.6rem;
      }

      &::after {
        top: 16px;
      }
    }
  }
}
