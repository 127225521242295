/* #order-confirm .receipt .thanks-and-receipts,
#order-confirm .receipt .thank-order-summary {
  padding: 4rem 2.4rem;
  width: 100%;
} */

#order-confirm .receipt {
  flex-direction: column;
}

header.checkout .checkout-header-wrapper>div.checkout-help .help-box {
  max-width: unset;
  min-width: unset;
  top: unset;
  width: 100%;
  bottom: 0;
  left: 0;
  transform: unset;
}

.edit-button p {
  display: none;
}

#checkout-begin header.checkout {
  .navigation {
    display: grid;
    grid-template-areas:
      'logo none none help'
      'logo dots dots dots';
    gap: 0 1rem;

    .logo-header {
      grid-area: logo;
    }

    .checkout-steps {
      grid-area: dots;
      width: 100%;
      margin: 0;
    }

    .need-help {
      grid-area: help;
    }
  }
}


.checkoutpage {
  padding-left: 0;
  padding-right: 0;

  >.row {
    margin-left: 0;
    margin-right: 0;

    >[class^='col-'] {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      padding-top: 3.6rem;
      padding-bottom: 6rem;
    }
  }
}

#order-confirm .checkout-container.checkoutpage.checkout-review {
  >.row {
    div.col-md-4.col-lg-5 {
      display: none;
    }
  }

  .text-section {
    padding-bottom: 3.2rem;
  }
}