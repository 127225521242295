.product-container {
  padding-top: 0;

  &.product-wrapper {
    display: flex;
  }
  .product-images {
    &__wishlist {
      top: 1.2rem;
      right: -0.4rem;
      left: unset;
    }

    &__tag {
      bottom: 1.6rem;
      left: -2.4rem;
    }

    &__stlButton {
      display: none;
    }

    &-container {
      min-height: calc((100vw)* 4.02999 /(3* 1.85));
    }
  }
  &.product-wrapper {
    position: sticky;
  }
}
.product-sidebar {
  padding-top: 1rem;
  width: 100%;
  background-color: $white;
  &--mobile {
    padding: 0 1.6rem;
    min-width: unset;
    max-width: unset;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0,0,0);
    z-index: 2;
    position: relative;
  }
  .find-in-store-cta-wrapper {
    display: none;
  }

  &__stlBtn {
    font-size: 1.4rem;
    line-height: 2rem;
    padding-bottom: 1.6rem;
    .shop-the-look-btn-label{
      color: $black;

    }
  }

  &::before {
    content: '';
    background-color: $grey_line;
    width: 8rem;
    height: 0.2rem;
    border-radius: 1.6rem;
    position: absolute;
    left: 50%;
    top: 0.8rem;
    transform: translateX(-50%);
  }

  &__details {
    border: none;
    padding-top: 2.6rem;
  }
}



