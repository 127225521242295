.unsubscriptionThankyou {
  &__wrapper {
    text-align: center;
    align-items: center;
    padding: 2.4rem 0;
  }

  &__subtitle {
    padding: 1.6rem 0 4rem;
  }

  &__container {
    margin-top: calc(var(--ticker-height) + 10.4rem);
  }
}