.product-detail {
  .product-added-to-bag {
    position: fixed;
    bottom: 0;
    left: 8px;
    z-index: 9;
    width: calc(100% - 16px);
    display: flex;
    align-items: center;
    padding: 8px;
    img {
      width: 48px;
      height: auto;
      margin-right: 24px;
    }
    span {
      width: 40%;
    }
    a {
      margin-left: auto;
      margin-right: 8px;
    }
  }
  &__info-assets {
    .content-asset:first-child {
      margin-top: 3rem;
    }
    .content-asset:last-child {
      margin-bottom: 6rem;
    }
  }
  .product-gallery-button {
    display: none;
  }
}
.product-description {
  margin-bottom: 6rem;
}

.product-bottom {
  &-container {
    padding: 0 1.6rem 2.4rem;
    z-index: 1;

    background: white;
  }
  &__header {
    display: none;
  }

  &__accordion {
    padding-top: 2.4rem;
    &_header {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 300;
      padding: 0;
      padding-bottom: 0.8rem;
      &:first-child {
        border-top: 0;

      }
      &[aria-expanded='false']{
        border-bottom: solid 0.03rem $grey_line;
      }

    }
    &_panel {
      border-bottom: solid $grey_line 0.03rem;
    }
  }
  
  &__content {
    &-container {
      padding: 0;
    }
    &_img {
      width: 23.1rem;
      margin: auto;
      &-wrapper {
        &:not(.product-bottom__content_description_img-wrapper) {
          display: none;
        }
      }
    }
    &_text {
      &--left {
        padding-top: 2.4rem;
      }
      &--right,
      &--left {
        gap: 1.6rem;
        padding-bottom: 3.2rem;
      }
      &_title {
        &--mobile {
          font-weight: 500;
          line-height: 2.2rem;
          font-size: 1.4rem;
        }
      }
    }
    &_description {
      flex-direction: column;
      display: flex;

      &_img {
        &-wrapper {
          padding: 1.6rem;
          background-color: $light_grey;
          margin-bottom: 1.6rem;
        }
      }

      &_details {
        &-container {
          order: -1;
          padding: 5.6rem 0;
          gap: 3.2rem;
          .find-in-store-cta {
            &-container {
              justify-content: flex-start;
            }
          }
        }

        &_promo{
          order: -1;
          padding-bottom: 2.4rem
        }
      }

      &_vestibility {
        &-container  {
          padding-top: 5.6rem;
          gap: 1.6rem;
        }

      }
    }

    &_lounge {
      &_user {
        &_title {
          display: none;
        }


      }
      &_how {

        &_list {
          margin-left: 0.4rem;
        }
      }
    }

    &_shippingReturn {
      display: flex;
      flex-direction: column;
    }
  }
}
