.three-column-product {
  display: flex;
  text-align: center;
  flex-direction: column;
  @include appearAnimation();
}

.three-column-product__product-holder {
  display: flex;
}

.show-text {
  .single-column__image-hover {
    display: none;
  }

  .single-column__base-image {
    display: block;
  }
}

.three-column-product__single-column {
  position: relative;
  padding-top: 50%;
  width: 100%;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 100vw;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:nth-child(1) {
    order: 0;
  }
  &:nth-child(2) {
    order: 2;
  }
  &.show-text {
    order: 1;
  }
}

.single-column__base-image {
  position: absolute;
  left: -1rem;
  right: -1rem;
  top: -1rem;
  bottom: -1rem;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  z-index: 2;
  display: none;
}

.single-column__image-hover {
  position: absolute;
  left: -1rem;
  right: -1rem;
  top: -1rem;
  bottom: -1rem;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  z-index: 2;
}

.single-column__text-hover {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @extend %primary-xs;
  text-align: center;
  width: 100%;
  padding: 0 ($space-unit * 8);
  z-index: 1;
}

.three-column-product__title {
  text-transform: uppercase;
  order: 1;
  max-width: calc(100% - (#{$space-unit} * 8));
  margin: ($space-unit * 12) auto ($space-unit * 3);
}

.three-column-product__cta {
  margin-bottom: ($space-unit * 6);
  order: 3;
}

.three-column-product__subtitle {
  @extend %primary-xs;
  text-align: center;
  margin: 0 auto ($space-unit * 4);
  width: 44rem;
  max-width: calc(100% - (#{$space-unit} * 8));
  order: 2;

  &:last-child {
    margin-bottom: 0;
  }
}

.three-column-product__description-mobile {
  position: static;
  transform: none;
  order: 4;
  width: 64.2rem;
  max-width: calc(100% - (#{$space-unit} * 8));
  margin: ($space-unit * 10) auto ($space-unit * 12);
  padding: 0 ($space-unit * 4);
}

// alignment
.three-column-product--centered-top {
  padding-bottom: 3.2rem;
  .three-column-product__product-holder {
    order: 4;
  }
}

.three-column-product--centered-bottom {
  padding-top: ($space-unit * 8);
  padding-bottom: ($space-unit * 7);
  .three-column-product__cta {
    margin-bottom: 0;
  }
  .three-column-product__product-holder,
  .three-column-product__description-mobile {
    order: 0;
  }
}

// margin
.three-column-product--internal-border {
  .three-column-product__product-holder {
    padding: 0 3.2rem;
  }
  .three-column-product__single-column {
    &:nth-child(3) {
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }
}

.three-column-product--external-border {
  .three-column-product__product-holder {
    padding: 0 3.2rem;
  }
}
