.refine {
  position: sticky;
  top: $header-height-mobile - 0.1rem;
  width: 100%;
  @include z(filters);

  .query-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 30vw;
  }
  .search-query {
    position: absolute;
    text-align: left;
    width: 100%;
    @include text-shorten;
  }

  sup {
    position: relative;
    top: -$space-unit;
    margin-left: 0.2rem;
    font-size: 1rem;
    color: $black_secondary;
  }

  .modal__close {
    display: none;
  }

  .refine__footer,
  .refine__header {
    display: none;
  }

  .refine__header__title {
    @extend %primary-m;
  }

  > .container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    padding-top: ($space-unit * 10) + 0.1rem;
    padding-bottom: ($space-unit * 2);
    position: relative;
    // @include gray-gradient;
    background-size: 100%;
  }

  .refine__options {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    // background: $gradient-separator;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    @include animate(0.3);

    & > .container {
      justify-content: space-between;
      align-items: center;
      display: flex;
      height: 5.6rem;
    }

    &.is-visible {
      height: 5.6rem;
      visibility: visible;
    }
  }
  .search-maincategories {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    li {
      margin-right: ($space-unit * 4);
      &:last-child {
        margin-right: 0;
      }
    }
    button:not(.active) {
      color: $black_secondary;
    }
  }
}

.wrapper-refine-button {
  flex-shrink: 0;
}

.refine__button,
.refine__toggle {
  @extend %primary-xs;
  position: relative;
}

.refine__button {
  margin-right: ($space-unit * 4);
  color: $black_secondary;

  &:last-of-type {
    margin-right: 0;
    color: $black;
  }
}

.refine__toggle {
  // padding: $space-unit 0;
  padding-right: 1.4rem;
  height: ($space-unit * 4);
  @include hover;

  &::after {
    content: '';
    position: absolute;
    right: -0.8rem;
    top: 50%;
    transform: translateY(-50%);
    @include fixedSprite('plus');
  }

  &[aria-expanded='true'] {
    &::after {
      @include fixedSprite('minus');
    }
  }
}
