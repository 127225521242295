.form-control {
  position: relative;
  input,
  textarea {
    @extend %primary-xs;
    border-radius: 0.8rem;
    appearance: none;
    height: $input-height;
    color: $black;
    line-height: 2.2rem;
    padding: 2.2rem 1.2rem 0.6rem;
    background-color: $white;
    width: 100%;
    border: $space-line solid $grey_line;
    transition: all 0.5s $easeOutQuart, outline 0s, border 0s, border-color 0s;

    &:disabled {
      cursor: none;
      pointer-events: none;
      opacity: $input-disabled-opacity;
    }

    &:read-only {
      cursor: none;
      pointer-events: none;
      border-color: $grey_disabled_input;
      background-color: $light_grey;
      color: $dark_grey;
    }

    &::placeholder {
      color: transparent;
      user-select: none;
    }

    &:focus {
      border-color: $black;
    }

    &:-webkit-autofill ~ label,
    &:focus ~ label,
    &.label-up ~ label {
      top: 6px;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

  }

  &.value-only {
    input {
      padding: 1.4rem 1.2rem;
    }
    label {
      display: none;
    }
  }

  &--outline {
    input {
      border: none;
      border-bottom: $space-line solid $input-outline;
      background-color: $white;
      border-radius: 0;
    }
  }

  &.label-up label {
    top: 6px;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

textarea {
  font-family: Lato;
  font-size: 1.4rem;
  line-height: 2.2rem;
  width: 100%;
}

textarea.form-control {
  display: block;
  height: 8rem;
  border: 0.1rem solid $grey3;
  border-radius: 0.4rem;
  margin-bottom: 0;
  padding: 1.2rem 0.8rem;

  &:not(:placeholder-shown, :focus, :-webkit-autofill)+label {
    opacity: 0;
  }

  &:focus+label {
    opacity: 0;
  }

  &+label {
    position: absolute;
    top: 1.5rem;
    left: 1.6rem;
    color: $grey7;
    opacity: 1;

    transition: all 0.4s $easeOutQuart;
  }
}
.disable-resize {
  resize: none;
}

.valid-tick {
  content: '';
  right: $space-unit * 2;
  width: ($space-unit * 2);
  height: ($space-unit * 2);
  @include fixedSprite('checkmark');
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.is-valid {
  .valid-tick {
    display: inline-block;
  }
}

.form-button-container {
  display: flex;
  .button {
    margin-top: 0 !important;
  }
}

.show-password {
  @extend %primary-xxs;
  position: absolute;
  top: 50%;
  right: 1.6rem;
  transform: translateY(-50%);
  height: 1.6rem;
  
  &.has-tick {
    right: ($space-unit * 8);
  }
}
