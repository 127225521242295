.product-hero,
.product-hero--placeholder {
  position: relative;
  padding-bottom: $product-ratio;
  overflow: hidden;
  background-color: $black;
}

.product-hero {
  width: 100%;
  text-align: left;
  display: block;
  margin-bottom: 0.8rem;

  picture,
  img {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    object-fit: cover;
    // top: 48%;
    // transform: translateY(-50%);
  }
}

.cursor {
  display: none;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  @include z(cursor);
  @include animate;

  &--hidden {
    @extend .cursor;
    opacity: 0;
  }
}

.th-player-external-element .th-controls,
.th-player .th-controls {
  display: none !important;
}

.productHeroSwiper {
  &__slide {
    &-wrapper {
      .product-image-button {
        cursor: url(../../images/zoom-in32.png) 16 16, zoom-in;
        background-position: -16px -16px;
      }

    }
  }

  &__btn {
    transform: translateY(50%);
    width: 2.4rem;
    height: 2.4rem;

    &--prev {
      right: unset;
      left: 2rem;
    }

    &--next {
      left: unset;
      right: 2rem;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  &__pagination {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6.6rem;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;

    .swiper-pagination-bullet {
      width: 0.5rem;
      height: 0.5rem;
      position: relative;
      margin: 0.2rem;
      opacity: 1;
      background-color: $white;
      border-radius: 0.5rem;

      &::before {
        background-color: $white;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 0.5rem;
      }

      &.swiper-pagination-bullet-active {
        width: 2.2rem;
        height: 0.3rem;
        background-color: $white;

        &::before {
          width: 100%;
          height: 0.3rem;
        }
      }

    }
  }

  &__container {
    transform: translateX(-48px);
    width: calc(100% + 48px);
    cursor: url(../../images/zoom-in32.png) 16 16, zoom-in;
    background-position: -16px -16px;

    .swiper-slide {
      @include animate();
      transition-property: transform;
      pointer-events: none;

      &.swiper-slide-active {
        opacity: 1 !important;
        pointer-events: auto;
      }
    }

    .productHeroSwiper__pagination {
      padding: 0;
      bottom: 1.6rem;
      right: 1.6rem;
      width: fit-content;
      left: unset;

      .swiper-pagination-bullet {
        &:not(:last-of-type) {
          margin: 0 1.4rem 0 0;
        }

        &:last-of-type {
          margin: 0;
        }
      }
    }

  }

}

.contactus-modal {
  .contact-us {
    .line {
      margin-top: 3.2rem;
      display: flex;
      align-items: flex-end;
    }

    &__desc {
      margin-top: 1.5rem;
      margin-bottom: 3.34rem;
    }
  }
}