.bag-summary-wrapper {
  order: 1;
  //position: sticky;
  bottom: 0;
  background-color: $white;
}

.bag-summary {
  position: sticky;
  top: 2.4rem;
  background: $white;
  margin-bottom: 12rem;
  @include z('stickybar');

  .collapsed-row {
    overflow: hidden;
    @include animate(0.3);
  }

  .row.has-borders {
    background-color: white;
  }

  .details-button {
    height: 1.6rem;
    margin-top: initial;
  }

  &__ctas {
    margin-bottom: 1.6rem;

    .divider {
      &-line {
        width: 10.65rem;
        height: 0.1rem;
        background-color: $black;
      }

      &-label {
        margin: 0 2.4rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }

    /* .expressCheckout__btn {
      font-size: 1.2rem;
      line-height: 2rem;
      font-weight: 700;
    } */

    .icon--payment-paypalbutton {
      height: 100%;
      width: 6rem;
    }
  }

  .has-borders {
    padding: 2.4rem;
    border-radius: 0.8rem;
    border: solid 1px #dddddd;
    margin-bottom: 1.6rem;
  }

  .items__label {
    button {
      @extend %primary-xs;
    }
  }

  .info-box {
    margin-top: 6.4rem;
  }

  .promo-card {
    .accordion__header {
      border-top: 0;
      padding: 0;
      margin-bottom: 0;
      transition: all 0.4s ease-out;

      &[aria-expanded="true"] {
        margin-bottom: 2.4rem;
      }

      .title {
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
  }

  .items {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1.4rem;
    line-height: 1.8rem;

    &.items--totals {
      align-items: flex-start;

      .text-gray.iva {
        color: $dark_grey;
        font-size: 1.2rem;
      }

      strong {
        font-weight: bold;
        text-transform: capitalize;
      }
    }

    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }

    &.items--dicount {
      color: $dark_grey;
    }

    &__divider {
      width: 100%;
      margin: 2.4rem 0;
      border-top: $space-line solid $grey_line;
    }

    &__label {
      text-align: left;
    }

    &__subtotal {
      margin-bottom: ($space-unit * 6);
      margin-top: 0;

      p {
        @extend %primary-xs;
      }
    }

    &__promocode {
      color: $dark_grey;
    }

    .point-wrapper {
      .label {
        font-weight: 400;
        font-size: 1rem;
        line-height: 2rem;
        text-transform: uppercase;

      }

      .value {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.9rem;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 90%;
      }
    }

    .card-code-wrapper {
      .label {
        font-weight: 400;
        font-size: 1.0rem;
        line-height: 2.0rem;
        text-transform: uppercase;

      }

      .value {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.9rem;

      }
    }
  }

  .list-spacing {
    margin-bottom: $space-unit * 4;
  }

  .accordion-mini {
    width: 100%;

    &__panel {
      >div {
        display: flex;
        gap: 2.4rem;
      }

      .form-control {
        flex-grow: 1;
      }

      .button {
        flex-basis: 40%;
      }
    }
  }

  .button-row {
    padding-top: $space-unit * 4;
  }

  .expecteddelivery {
    @include border('top');
    margin-top: $space-unit * 3;
  }

  .form-control input {
    padding: 1.2rem;
  }

  .loyalty-cart-box {
    .icon--loyalty {
      width: 2.4rem;
      height: 2.4rem;
      vertical-align: sub;
      margin-right: 0.8rem;
    }
  }

  +.items {
    display: grid;
    grid-template-columns: 47% 3% 47%;
  }
}

.accordion-summary {
  position: absolute;
  right: 0;
  top: 0;
}

.details-button {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  height: 1.4rem;
  align-items: center;
  @extend %primary-xxs;

  &__open {
    @extend .details-button;

    &::after {
      content: '';
      @include fixedSprite('minus');
    }
  }

  &__close {
    @extend .details-button;

    &::after {
      content: '';
      @include fixedSprite('plus');
    }
  }

  &__container {
    position: absolute;
    width: calc(100vw - var(--scrollbar-width));
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: flex-end;
    top: 2rem;
    // @include animate(0.3);
  }
}

.text-center {
  justify-content: center;
}