.widget-m-media-quote {
  .img-wrapper {
    margin-right: -24px;
    margin-left: -24px;
  }
  img {
    width: 100vw;
    height: 100vw;
    object-fit: cover;
    margin: 2rem 0;
  }

  .text {
    margin: 4rem auto 8rem auto;
    font-size: 3.6rem;
    line-height: 1.22;
  }
}
