.cookie-bar {
  padding: 2.8rem 0;
}
.cookie-center {
  max-height: 80vh;
  // &__form {
  //   .button {
  //     margin-top: 1.6rem;
  //   }
  // }
}
