.sizechart {
  margin-top: ($space-unit * 8);
  max-width: 80vw;

  .table {
    margin-bottom: 0;
  }

  &__filters {
    position: absolute;
    bottom: -4rem;
  }

  &--measure {
    flex-direction: row;
    // > img {
    //   max-width: 30%;
    // }
  }
}

.sizechart__mannequin {
  flex: 0 0 30%;
}

.sizechart__wrapper {
  flex: 1 0 70%;
}

.sizechart__legend {
  column-gap: ($space-unit * 8);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: column;
  grid-template-rows: repeat(6, auto);
}

// .sizechart__filters {
//   position: relative;
//   bottom: 0;
// }
