.product {
  &-sidebar {
    &__details {
      gap: 0.8rem;
    }
  }

  &-images {
    &__tag {
      left: -4rem;
    }
  }
}