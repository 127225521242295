.search-container {
  display: flex;

  .wrapper-search {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $white;
    border-radius: 8px;
    @include z;
  }

  .search-field {
    position: relative;
    display: flex;
    align-items: center;
    height: 56px;

    // border-bottom: 1px solid $grey;
    &::after {
      content: '';
      display: block;
      @include fixedSprite('search');
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 24px;
      transform: translateY(-50%);
      @include z;
    }

    input {
      padding: 0 24px 0 48px;
      @extend %primary-xs;
      appearance: initial;
      border: initial;
      background-color: initial;
      flex-grow: 1;
      height: 100%;
    }

    button {
      height: 100%;
      width: 56px;
      background-size: 16px;
      border-left: 1px solid $grey;
    }
  }

  .results-container {
    border-top: 1px solid $grey;
    padding: 32px 24px 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    a {
      @include del-link;
    }
  }

  .suggestion-item {
    color: $dark_grey;

    b {
      color: $black;
    }
  }

  .minitile-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .search-minitile {
    display: flex;
    gap: 16px;
    outline-offset: 1px;

    .img-wrapper {
      display: block;
      width: 90px;
      height: 120px;
      background: $light_grey;
    }

    img {
      width: inherit;
      -webkit-filter: blur(5px);
      filter: blur(5px);
      transition: filter 400ms, -webkit-filter 400ms;

      &.lazyloaded {
        -webkit-filter: blur(0);
        filter: blur(0);
      }
    }

    .minitile-info {
      flex-grow: 1;
      padding: 8px 0;
      line-height: 21px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 span {
        display: block;
        color: $medium_grey;
        margin-top: $space-unit;
      }
    }
  }
}