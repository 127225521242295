// COLOR SELECTOR USED IN: PDP, Reserve in boutique

.selector-color-expanded {
  gap: 1.6rem;
  .selector__menu {
    ul {
      &::-webkit-scrollbar {
        display: none;
      }
    }

    li {
      flex: none;
    }
  }
}
