.profileinfo {
  &.box {
    margin-bottom: 4.8rem;
  }

  .divider {
    margin: 2.4rem 0;
  }

  .profileinfo-block {
    &:not(:last-child):nth-child(even) {
      margin-bottom: 1.6rem;      
    }
    .label {
      color: $dark_grey;
      font-size: 1.2rem;
      line-height: 2rem;
      margin-bottom: 0.4rem;
    }
  }
 
  .personalinfo-edit {
    font-size: 1.2rem;
    line-height: 2.4rem;
  }

  .box-heading {
    margin-bottom: 2.4rem;
  }
}

.profile-modal {
  background-color: $white;
  border-radius: 0.8rem;
  .modal{
    &__header {
      .dialog__title{
        font-size: 1.8rem;
        line-height: 2.6rem;
        height: unset;
        padding-bottom: 4rem;
      }      
    }
    &__body {
      .form-group {
        input:read-only {
          border-color: $grey_line;
        }
      }
    }

    &__close {
      padding-right: 2.4rem 3.2rem;
    }
  }
  
  .box-center{
    margin: auto;
  }

  
  .wrapper-number{
    font-size: 1.6rem;
    font-weight: 400;
  }
}
