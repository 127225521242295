.cookie-bar {
  padding: 3.2rem 0;
}
.cookie-center {
  &__form {
    column-gap: 6rem;
  }
}


