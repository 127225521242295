.promo-module__inner-container {
  display: block;
}

.promo-module--external-border {
  padding: ($space-unit * 4);
}

.promo-module--internal-border {
  margin: ($space-unit * 4);
  .promo-module__promo-column {
    margin: $space-unit 0;
  }
}

.promo-module--center-upper-title-cta {
  .promo-module__promo-column {
    margin: 4.8rem 0;
  }
}

.promo-module--center-lower-title-cta {
  .promo-column__text-holder {
    margin-bottom: ($space-unit * 10);
  }
}

.promo-module--center-bottom {
  .promo-column__text-holder {
    bottom: 3.2rem;
  }
}

.promo-module--center-lower-cta {
  .promo-column__text-holder {
    padding-bottom: 3.2rem;
  }
  .promo-column__cta {
    margin-top: -3.2rem;
  }
}
