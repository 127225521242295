.countryselector-navigation {
  margin-top: 0.8rem;
}
.countryselector-wrapper {
  padding: 3.2rem 1.6rem 1.6rem;
  .modal__header {
    margin-bottom: 4rem;

    .dialog__title {
      justify-content: center;
      height: unset;
      font-size: 3.2rem;
      line-height: 4rem;
      font-weight: 300;
      color: $black;
    }

    .dialog__description {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $dark_grey;
      text-align: center;
      margin-top: 1.6rem;
      margin-bottom: 0;
    }
  }

  .modal__body {
  }
}
.country-selector-section {
  .country-selector-title {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 300;
    margin-bottom: 3.2rem;
  }
}

.country-selector-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  .country-selector-element {
    flex: 1 0 33%;
    list-style: none;
    margin-bottom: 1.6rem;
    @extend .Par;
    a {
      text-decoration: none;
    }
  }
}
