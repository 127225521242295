.unsubscriptionPage {
  &__container {
    margin: 13.6rem auto 0;
    width: 31vw;
    text-align: center;
  }
  &__header {
    padding-top: 3.2rem;

    &--error {
      margin-top: 4rem;
    }
  }
  &__body {
    margin: 0 auto;
    padding: 4rem 0;

    &__title {
      padding-bottom: 0.8rem;
    }

    .checkbox {
      input[type='checkbox'] {
        &+.unsubscriptionPage__body__checkbox__label {
          padding-left: 1.6rem;
        }        
      }
    }

    &__textarea {
      margin-top: 0.8rem;
    }

    &__checkbox {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        padding-bottom: 0.8rem;
        margin: 0;
      }
    }
    &__input {
      &_wrapper {
        padding: 2.4rem 0 4rem;
      }
    }
  }
}
.unsubscription-layout {
  .footer-bottom {
    padding: 0 4.8rem;

    .social-legals {
      padding: 2.4rem 0;
      margin: 0;
    }     
  } 
}
