.product-selection {
  // padding-top: ($space-unit * 6);

  .product-selection__heading--placeholder {
    width: 100%;
    display: block;
    position: relative;
  }

  .product-selection__heading {
    padding-top: ($space-unit * 4);
    // padding-bottom: $space-unit;
    &.container {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: $white;
      @include z;
    }
  }

  .product-selection__ctas {
    margin: 3.2rem 0 1.6rem;
    button {
      height: ($space-unit * 12);
      span {
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }
  }
}
