body.tingle-enabled {
  position: absolute;
  overflow: hidden !important;
  left: 0;
  right: 0;
  top: 0 !important;
}

html.html-tingle-enabled {
  overflow: hidden;
}

.modal {
  .fade {
    display: none;
  }
}

.tingle-modal {
  z-index: 1049; // 1050 for sizeGuida
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  background: rgba($black, 0.65);
  cursor: default;
  transition: opacity .35s cubic-bezier(0.165, 0.84, 0.44, 1);

  &.tingle-modal--visible {
    visibility: visible;
    opacity: 1;

    .tingle-modal-box__footer {
      bottom: 0;
    }

    .tingle-modal-box {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__close,
  &__closeLabel {
    display: none;
  }

  &--noClose {
    &.tingle-modal__close {
      display: none;
    }
  }

  &.modal-go-back {
    justify-content: center;

    .tingle-modal-box {
      background-color: $white;
      padding: 2.4rem;
      border-radius: 0.8rem;
      width: 46rem;

      .header-wrapper {
        font-size: 1.8rem;
        line-height: 2.6rem;
      }
    }
  }

  &.shipping-address-block {
    z-index: 1000;
  }

  &.shipping-address-block,
  &.change-country-modal {
    width: 100vw;
    height: 100vh;

    .tingle-modal-box {
      position: fixed;
      width: 47.6rem;
      height: calc(100vh - 4.8rem);
      top: 2.4rem;
      right: 2.4rem;
      background-color: $white;
      border-radius: 0.8rem;

      .modal-header {
        padding: 2.4rem;

        &.change-country-confirmation-header {
          padding: 3.2rem;
        }

        .modal-title {
          font-size: 1.8rem;
          line-height: 2.6rem;
        }
      }

      .change-country-confirmation-body {
        padding: 0.8rem 3.2rem 0;
      }

      .modal-content-body {
        height: 70vh;
        overflow: auto;
        padding-bottom: 2.4rem;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .modal-footer {
        position: absolute;
        width: 100%;
        left: 0;
        padding: 2.4rem;
        bottom: 0;
        background-color: $white;
        border-radius: 0 0 0.8rem 0.8rem;

        .change-country-confirmation-btn {
          margin-bottom: 0.8rem;
          font-weight: 700;
        }
      }
    }
  }
}

.quickview-modal .tingle-modal-box {
  position: relative;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: auto;
  max-width: 50rem;
  max-height: 90vh;
  background: #fff;
  cursor: auto;
  overflow-y: auto;
  transform: translateY(50px);
  opacity: 0;
  transition: all .35s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 0.8rem;

  &__content {
    .icon--close-16 {
      position: absolute;
      top: 2.4rem;
      right: 2.4rem;
      font-size: 2em;
      cursor: pointer;
    }
  }

  .tingle-modal-box__content {
    height: 100%;
    position: relative;

    .quickview-content {
      padding: 2.4rem;

      select {
        padding: 1.5rem;
        border-radius: 0.4rem;
        border: 2px solid $grey_line;
        cursor: pointer;
        padding-right: 4px;
        transition: all .4s ease;
        appearance: none;
        @include fixedSprite('chevron-down-gray');
        width: 100%;
        height: 100%;
        background-position: 96% 50%;
      }
    }

    .modal-footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      border-top: 1px solid $light_grey;

      .quick-edit-buttons {
        padding: 2rem 2.4rem;

        button {
          width: 100%;
        }
      }
    }
  }
}

.cart-remove-product .tingle-modal-box {
  position: relative;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: auto;
  max-width: 50rem;
  max-height: 90vh;
  background: #fff;
  cursor: auto;
  overflow-y: auto;
  transform: translateY(50px);
  opacity: 0;
  transition: all .35s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 0.4rem;

  &__content {
    .icon--close-16 {
      position: absolute;
      top: 2.4rem;
      right: 2.4rem;
      font-size: 2em;
      cursor: pointer;
    }

    .modal-body {
      text-align: center;
      max-width: 37rem;
      margin: auto;

      >* {
        margin: .8rem 0;
      }

      .modal-title {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.8rem;
      }
    }

    .modal-content {
      padding: 4rem 6rem;
    }

    .modal-footer {
      display: flex;
      gap: 1.6rem;
      margin: 2.4rem 0 0;
      justify-content: center;

      button {
        width: auto;
        padding: 1.2rem 2.4rem;
      }
    }

    button {
      margin-bottom: 0;

      &.js-apply-coupon {
        margin-bottom: 4.2rem;
      }
    }

    .bottom-buffer {
      margin-bottom: 10vh;
    }
  }

  .modal__closeIcon {
    position: absolute;
    top: 1.3rem;
    right: 1.3rem;
    font-size: 2em;
    cursor: pointer;
  }

  &__footer {
    padding: 1rem 2rem;
    width: auto;
    cursor: auto;
    text-align: right;

    &:after {
      display: table;
      clear: both;
      content: "";
    }

    &--sticky {
      position: fixed;
      bottom: -200px; // TODO: find a better way
      z-index: 10001;
      opacity: 1;
      transition: bottom .35s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    button {
      margin-bottom: 0;
    }
  }

  &.resize-modal-loyalty {
    width: 60vw;
  }
}

.header-enhanced-login .tingle-modal-box {
  position: relative;
  flex-shrink: 0;
  margin-top: 4.2rem;
  margin-right: 2.2rem;
  margin-left: auto;
  margin-bottom: auto;
  width: 100%;
  max-width: 48rem;
  max-height: 90vh;
  background: #fff;
  cursor: auto;
  overflow-y: auto;
  transform: translateY(50px);
  opacity: 0;
  transition: all .35s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 0.4rem;


  &__content {
    .modal {
      &-header {
        .modal__close {
          padding: 3.2rem 3.6rem;
        }        
      }

      &-title {
        font-size: 1.8rem;
        line-height: 2.6rem;
        margin-bottom: 4rem;
      }

      &-body {
        margin: auto;
  
  
        .login {
          &-form-container {
            .form-group__label {
              pointer-events: none;
            }          
          }
  
          &__btn {
            margin-top: 1.6rem;
          }

          &RememberMe {
            &__checkbox {
              margin: 0;
              display: flex;
              align-items: center;

              &__label {
                font-size: 1.2rem;
                line-height: 2.4rem;
                font-weight: 700;
              }
            }
          }
  
        }
  
        .password-reset {
          &__wrapper {
            margin: 1.6rem 0 2.4rem;
          }
        }

      }

      &-content {
        margin: 3.2rem;
  
        .social-button {
          &__label {
            font-size: 1.4rem;
            line-height: 2.4rem;          
          }
        }
        
        .divider {
          margin: 3.2rem 0;
        }
      }
    }

  }
}