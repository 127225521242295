.aftercare {
  &__title {
    text-align: center;
    margin-bottom: ($space-unit * 3);
    @extend %primary-m-u;
  }
  &__description {
    text-align: center;
    margin-bottom: ($space-unit * 6);
  }
  .line {
    margin-top: ($space-unit * 4);
    @include border('top');
  }
  .link-box {
    &__title {
      @extend %primary-s-u;
      margin-top: ($space-unit * 4);
      margin-bottom: ($space-unit * 3);
    }

    &__button.button {
      margin-top: ($space-unit * 4);
      width: auto;
      padding-left: 4.2rem;
      padding-right: 4.2rem;
      align-self: center;
    }
  }
  &_accordion {
    &__capitalize {
      text-transform: capitalize;
    }
  }
  &__form {
    margin-bottom: ($space-unit * 16);
    &__step-description {
      margin-bottom: ($space-unit * 6);
    }
    &__button-link {
      margin-top: ($space-unit * 4);
      margin-bottom: ($space-unit * 10);
    }
    .image-button {
      margin-bottom: $space-unit;
      border: $space-line solid $black_inactive;
      transition: none;
      display: flex;
      height: ($space-unit * 12);
      width: 100%;
      justify-content: flex-start;
      > label {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 $space-unit * 7;
        height: 100%;
      }
      &.image-button-add {
        > label {
          &::before {
            content: '';
            @include fixedSprite('plus');
            position: absolute;
            left: $space-unit;
          }
        }
      }
      &.image-button-remove {
        justify-content: space-between;
        padding: ($space-unit * 3);
        .text-button {
          color: $black_inactive;
        }
      }
      &.image-button-progress {
        padding: ($space-unit * 3);
      }
    }
    .submit-button {
      margin-top: ($space-unit * 10);
    }
    .accordion__group {
      .accordion__item {
        .accordion__header {
          height: 5.8rem;
        }
      }
    }
  }
  &__subtitle {
    margin-top: ($space-unit * 3);
    @extend %primary-m;
  }
  .bag-product {
    margin-top: ($space-unit * 4);
    &__product-content {
      &__image {
        margin-left: $space-unit * 4;
      }
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }
  .wrapper {
    margin-top: ($space-unit * 6);
    .detail-address {
      p {
        margin-bottom: $space-unit;
      }
    }
    .detail-row {
      margin-bottom: ($space-unit * 8);
    }
    .detail-title {
      text-transform: uppercase;
      margin-bottom: $space-unit;
    }
  }
  .submit-button {
    margin-top: ($space-unit * 10);
  }
  .mobile-button {
    margin-bottom: ($space-unit * 2);
  }
  .order-detail__column {
    margin-bottom: 0;
  }
  .orders-listing__order-detail {
    justify-content: start;
  }
}
