.login-registration,
.otp-confirmation__container,
.forgotpassword-container,
.thankyou__register {
  margin: 0 auto 6.4rem;
  max-width: 100vw;
}

.login-registration {
  &__section {
    &__header {
      gap: 1.6rem;
      &__title {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
      &__subtitle {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }

    &__login__social {
      gap: 1.6rem;
    }
  }

  &__rememberMe {
    &-container {
      margin-top: 3.2rem;
    }
  }

  .divider {
    margin: 3.2rem 0;
  }
}

.login-section-container {
  padding-top: calc(var(--ticker-height) + 9.6rem + 0.8rem + 3.2rem);
}