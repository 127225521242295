.text-block__inner-container {
  width: 69rem;
  margin: 0 auto;
  text-transform: uppercase;
  max-width: 100%;
  line-height: 1.3;
  text-align: center;
}

.text-block {
  padding: 4.8rem 1.6rem;
  @include appearAnimation();
}
