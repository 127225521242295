.findinstore {
  #findinstore {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 59.8rem;
    overflow: hidden;

    .findinstore-map-section {
      .findinstore-results {
        max-height: min(calc(59.8rem - 7rem - 18.1rem - 4.8rem - 5rem - 2.4rem - 2.4rem), calc(100vh - 4.8rem - 7rem - 18.1rem - 4.8rem - 5rem - 2.4rem - 2.4rem));
      }
    }
  }
}